// import { useEffect, useState } from "react";
import { GET_CALL_HISTORY } from "../query";
import { useStickyState } from "./useStickyState";
import { useGetCallHistoryQuery } from "../generated/graphql";

export function useCallHistory(first: number, page: number | undefined) {
  // const [historyData, setHistoryData] = useState<any>([]);
  const [numbers] = useStickyState(null, "twilio_numbers");
  const { data, error, loading, refetch, fetchMore } = useGetCallHistoryQuery({
    query: GET_CALL_HISTORY,
    variables: { first, page },
    onCompleted: data => {
      const lastDialNum =
        data && data.getCallHistory && data.getCallHistory.data.length !== 0
          ? numbers.includes(data.getCallHistory.data[0].from)
            ? data.getCallHistory.data[0].to
            : data.getCallHistory.data[0].from
          : "";

      localStorage.setItem("last_dial", JSON.stringify(lastDialNum));
    }
  });

  // useEffect(() => {
  //   getHistory({
  //     variables: {
  //       first,
  //       page
  //     }
  //   });
  // }, [first, page, getHistory]);

  // useEffect(() => {
  //   if (data) {
  //     setHistoryData(data);
  //   }
  // }, [data]);

  const handleFetchMore = (currentPage: number) => {
    // console.log("paginatorInfo", currentPage);
    if (fetchMore) {
      fetchMore({
        query: GET_CALL_HISTORY,
        variables: {
          first,
          page: currentPage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const data = Object.assign({}, fetchMoreResult);
          data.getCallHistory.data = [
            ...prev.getCallHistory.data,
            ...fetchMoreResult.getCallHistory.data
          ];
          // setHistoryData(data);
          return { ...data };
        }
      });
    }
  };

  return {
    data,
    loading,
    fetchMore,
    refetch,
    handleFetchMore,
    error
  };
}
