import React, { FC, useState } from "react";
import { ContactList } from "./List";
import { Button } from "antd";
import CreateContact from "./CreateContact";

interface UserContactProps {
  userTab: string
}

 const Contact: FC<UserContactProps> = ({userTab}) =>{
  const [visible, setVisible] = useState<boolean>(false);
  const [contactData, setContactData] = useState<any>(null);

  const onCancelModal = () => {
    setVisible(false);
    setContactData(null);
  };

  const handleEditContact = (
    id: string,
    numbers: { id: string; raw_number: string }[],
    first_name?: string | null,
    last_name?: string | null,
    company_name?: string | null
  ) => {
    const Numbers: { id: string; raw_number: string }[] = [];
    numbers &&
      numbers.map((num: any) =>
        Numbers.push({ id: num.id, raw_number: num.raw_number })
      );

    const contactData = {
      id,
      first_name,
      last_name,
      numbers: Numbers,
      company_name
    };

    setContactData(contactData);
    setVisible(true);
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}
      >
        {visible ? (
          <div
            onClick={onCancelModal}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <i className="icon-cancel" style={{cursor : 'pointer'}} />
          </div>
        ) : (
          <Button
            className="btnPrimary"
            onClick={() => {
              setVisible(true);
            }}
          >
            Create Contact
          </Button>
        )}
      </div>
      {visible ? (
        <CreateContact
          contactData={contactData}
          onCancelModal={onCancelModal}
        />
      ) : (
        <ContactList handleEditContact={handleEditContact} userTab={userTab}/>
      )}
    </div>
  );
}
export default Contact;
