import React from "react";
import { Typography, Skeleton } from "antd";
import { MyAvatar } from "../components/MyAvatar";
import { numberFormat } from "../utils/utils";

const { Title, Text } = Typography;

interface LeadDetailProps {
  name?: string;
  number?: string;
  loading: boolean;
}

export const LeadDetail: React.FC<LeadDetailProps> = ({
  name = "HQ User",
  number,
  loading
}) => {
  return (
    <div style={{ margin: 10, paddingTop: 15 }}>
      {loading ? (
        <>
          <LoadingLeadDetail number={number} />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <MyAvatar src={""} name={name} size={96} style={{ fontSize: 50 }} />
          <br />
          <br />
          <Title level={4}>{name}</Title>
          <Text style={{ textAlign: "center" }} strong>
            {number ? numberFormat(number.replace(/\+1/g, ``)) : ''}
          </Text>
          <br />
        </div>
      )}
    </div>
  );
};

interface LoadingLeadDetailProps {
  number: string | undefined;
}

export const LoadingLeadDetail: React.FC<LoadingLeadDetailProps> = ({
  number
}) => (
  <div style={{ textAlign: "center" }}>
    <Skeleton.Avatar active={true} size={96} />
    <br />
    <br />
    <Skeleton.Input style={{ width: 200 }} active={true} size="small" />
    <br />
    <br />
    <Skeleton.Input style={{ width: 200 }} active={true} size="small" />
    <br />
    <br />
    <Text strong>{number}</Text>
  </div>
);
