import React, { FC } from "react";
import { List, Button } from "antd";
import { TransferToType } from "../../generated/graphql";

export const QueueListItem: React.FC<any> = ({
  id,
  members_count,
  name,
  coldTransferCall
}) => {
  return (
    <List.Item
      actions={[
        <div onClick={() => coldTransferCall(id, TransferToType.Queue)}>
          <Button type="primary" shape="circle">
            <i className="icon-transfer-lead" />
          </Button>
        </div>
      ]}
    >
      <List.Item.Meta
        title={<>{name}</>}
        description={<>Members: {members_count}</>}
      />
    </List.Item>
  );
};
