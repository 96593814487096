export const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const secondsToDhms = (seconds: number) => {
  seconds = Number(seconds);
  // const d = Math.floor(seconds / (3600 * 24));
  const h = Math.floor((seconds % (3600 * 24)) / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = Math.floor(seconds % 60);

  return `${h ? `${h}h ` : ""}${m ? `${m}m ` : ""}${s ? `${s}s` : ""}`;
};

export const numberFormat = (number: string) => {
  const rawNumber = number.replace(/\+1/g, ``);
  return `(${rawNumber.substring(0, 3)})-${rawNumber.substring(
    3,
    6
  )}-${rawNumber.substring(6, rawNumber.length)}`;
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const dialFormatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  console.log("match", match);
  if (match) {
    const intlCode = match[1] ? "+1" : "";
    return [intlCode, match[2], match[3], match[4]].join("");
  }
  return null;
};
