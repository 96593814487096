import React, { FC, useEffect } from "react";
import { List, Spin } from "antd";
import { ListItem } from "./ListItem";
import { IsJsonString } from "../../utils/utils";
import { useStickyState } from "../../hooks/useStickyState";
import { useMissedCallHistory } from "../../hooks/useMissedCalls";

export const MissedCallList: FC = () => {
  const { loading, data, refetch, handleFetchMore } = useMissedCallHistory(
    30,
    1
  );
  const [numbers] = useStickyState(null, "twilio_numbers");
  // let recent = [];
  // if (window.localStorage.getItem("C_RECENT")) {
  //   recent = JSON.parse(window.localStorage.getItem("C_RECENT") || "[]");
  // }

  // eslint-disable-next-line
  const receiveCallEvent = (event: MessageEvent) => {
    const callInfo = IsJsonString(event.data) ? JSON.parse(event.data) : {};
    if (callInfo.action === "reject") {
      if (refetch) {
        refetch();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveCallEvent, false);

    return () => {
      window.removeEventListener("message", receiveCallEvent, false);
    };
  }, [receiveCallEvent]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {
      if (
        data &&
        data.getMiscalls &&
        data.getMiscalls.paginatorInfo.hasMorePages
      ) {
        handleFetchMore(data.getMiscalls.paginatorInfo.currentPage + 1);
      }
    }
  };

  // !numbers.includes(from) && status === "no-answer"

  return (
    <div
      style={{
        overflowX: "auto",
        maxHeight: "calc(100vh - 210px)",
        backgroundColor: "#fff"
      }}
      onScroll={handleScroll}
    >
      <Spin spinning={loading}>
        <List
          loading={false}
          itemLayout="horizontal"
          dataSource={
            data && data.getMiscalls && data.getMiscalls.data
              ? data.getMiscalls.data.filter(
                  item =>
                    !numbers.includes(item.from) && item.status === "no-answer"
                )
              : []
          }
          renderItem={item => <ListItem {...item} />}
        />
      </Spin>
    </div>
  );
};
