import React, { FC,useEffect } from "react";
import { List, Spin } from "antd";
import { useGetContact } from "../../hooks/useContacts";
import { ListItem } from "./Listitem";
import { Contact } from "../../generated/graphql";

interface ContactListProps {
  handleEditContact: (
    id: string,
    numbers: { id: string; raw_number: string }[],
    first_name?: string | null,
    last_name?: string | null,
    company_name?: string | null
  ) => void;
  userTab:string
}

export const ContactList: FC<ContactListProps> = ({ handleEditContact,userTab}) => {
  const { contactData, loading, refetch, handleFetchMore } = useGetContact(
    30,
    1
  );


useEffect(
  () => {
    if(userTab === '3'){
      refetch && refetch()
    }
  },
  [refetch, userTab]
);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {
      if (contactData.getContact.paginatorInfo.hasMorePages) {
        handleFetchMore(contactData.getContact.paginatorInfo.currentPage + 1);
      }
    }
  };

  return (
    <div
      style={{
        overflowX: "auto",
        maxHeight: "calc(100vh - 192px)",
        backgroundColor: "#fff"
      }}
      onScroll={handleScroll}
    >
      <Spin spinning={loading}>
        <List
          loading={false}
          itemLayout="horizontal"
          dataSource={
            contactData.getContact && contactData.getContact.data
              ? contactData.getContact.data
              : []
          }
          renderItem={(item: Contact) => (
            <ListItem
              {...item}
              refetchContacts={() => refetch && refetch()}
              handleEditContact={handleEditContact}
            />
          )}
        />
      </Spin>
    </div>
  );
};
