import React from "react";
import { List } from "antd";
import { ListItem } from "./ListItem";
// import { ListItemProps } from "antd/lib/list";
import { SearchLeadQuery } from "../../generated/graphql";
import { Loading } from "../Loading";

interface IndexProps {
  data?: SearchLeadQuery;
  loading: boolean;
}

export const UserList: React.FC<IndexProps> = ({ data, loading }) => {
  if (loading) {
    return (
      <div
        style={{
          overflowX: "auto",
          maxHeight: "calc(100vh - 210px)",
          backgroundColor: "#fff",
        }}
      >
        <Loading />
      </div>
    );
  }
  return (
    <List
      style={{ overflowX: "auto", maxHeight: "calc(100vh - 220px)", backgroundColor: "#fff" }}
      itemLayout="horizontal"
      dataSource={data?.searchConversationV2?.person || []}
      renderItem={(item) => item && <ListItem {...item} loading={loading} />}
    />
  );
};
