import React from "react";
import { Skeleton, List } from "antd";

export const Loading: React.FC = () => {
  return (
    <List
      itemLayout="vertical"
      size="large"
      dataSource={[1, 2]}
      renderItem={() => (
        <Skeleton active avatar>
          <List.Item.Meta />
        </Skeleton>
      )}
    />
  );
};
