import React from "react";
import { Avatar } from "antd";

interface MyIconProps {
  size?: number;
  bgColor?: string;
  color?: string;
  icon: string;
}

export const MyIcon: React.FC<MyIconProps> = ({
  size = 60,
  bgColor = "#fff",
  icon,
  color = "#110c11"
}) => {
  return (
    <Avatar
      size={size}
      style={{
        backgroundColor: bgColor,
        // padding: 12,
        // lineHeight: "32px",
        color: color,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
      icon={<i className={icon} style={{ fontSize: 18 }} />}
    />
  );
};
