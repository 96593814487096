import React, { FC } from "react";
import { List, Tooltip } from "antd";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { useStickyState } from "../../hooks/useStickyState";
import { numberFormat } from "../../utils/utils";

dayjs.extend(relativeTime);
dayjs.extend(utc);

export const ListItem: FC<any> = ({
  id,
  from,
  to,
  voicemail,
  caller,
  called,
  timestamp,
  contactNumberFrom,
  contactNumberTo,
  leadNumberFrom,
  leadNumberTo
}) => {
  const [numbers] = useStickyState(null, "twilio_numbers");
  const name = numbers.includes(from) ?
  contactNumberTo && contactNumberTo.contact
    ? `${contactNumberTo.contact?.first_name || ''} ${contactNumberTo.contact?.last_name || ''}`
    : leadNumberTo && leadNumberTo.lead ? `${leadNumberTo.lead?.first_name || ''} ${leadNumberTo.lead?.last_name || ''}` :  `${called?.first_name || ""} ${called?.last_name || ""}`
: 
contactNumberFrom && contactNumberFrom.contact
    ? `${contactNumberFrom.contact?.first_name || ''} ${contactNumberFrom.contact?.last_name || ''}`
    : leadNumberFrom && leadNumberFrom.lead ? `${leadNumberFrom.lead?.first_name || ''} ${leadNumberFrom.lead?.last_name || ''}` :  `${caller?.first_name || ""} ${caller?.last_name || ""}`

  const handleAudio = (id: string) => {
   const allAudio = document.querySelectorAll('audio');
   allAudio.forEach(item => {if(item.id !== id){
     item.pause()
   }})
  };

  return (
    <List.Item actions={[<></>]}>
      <List.Item.Meta
        title={
          <>
            <div>
              {`${
                name !== " "
                  ? `${name} (${numberFormat(
                      numbers.includes(from)
                        ? to.replace(/\+1/g, ``)
                        : from.replace(/\+1/g, ``)
                    )})`
                  : `${numberFormat(
                      numbers.includes(from)
                        ? to.replace(/\+1/g, ``)
                        : from.replace(/\+1/g, ``)
                    )}`
              }`}
            </div>
            <div>
              <Tooltip
                title={dayjs
                  .utc(timestamp)
                  .local()
                  .format("MM/DD/YYYY hh:mm:ss a")}
              >
                <span style={{ marginRight: "4px" }}>
                  {dayjs.utc(timestamp).fromNow()}
                </span>
              </Tooltip>
            </div>
          </>
        }
        description={
          <>
            <audio id={id} controls onPlay={() => handleAudio(id)}>
              <source src={voicemail.url} type="audio/mpeg"></source>
            </audio>
          </>
        }
      />
    </List.Item>
  );
};
