import React from "react";
import { List, Popconfirm, Tag } from "antd";
import { useDeleteBlockNumberMutation } from "../../generated/graphql";
import { formatPhoneNumber } from "../../utils/utils";

export const ListItem: React.FC<any> = ({
  id,
  tn_id,
  type,
  number,
  refetchBlock,
  handleEditBlockNumber
}) => {
  const [deleteNumber] = useDeleteBlockNumberMutation();

  const handleDeleteNumber = (id: string) => {
    deleteNumber({
      variables: {
        id
      }
    }).then(res => {
      if (res) {
        refetchBlock();
      }
    });
  };

  return (
    <List.Item
      actions={[
        <div
          onClick={() => {
            handleEditBlockNumber(id, number, type);
          }}
        >
          <i
            className="icon-edit"
            style={{ color: "#4ac367", fontSize: 16, cursor: "pointer" }}
            onClick={() => {}}
          />
        </div>,

        <Popconfirm
          title="Are you sure delete this number from block list?"
          onConfirm={() => {
            handleDeleteNumber(id);
          }}
          icon={null}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            style: { backgroundColor: "red", color: "#fff", border: "none" }
          }}
          placement="topRight"
        >
          <i
            className="icon-delete"
            style={{ color: "red", fontSize: 16, cursor: "pointer" }}
          />
        </Popconfirm>
      ]}
    >
      <List.Item.Meta
        title={
          <div>
            <span>{formatPhoneNumber(number.replace(/\+1/g, ``))}</span>
            <Tag style={{ marginLeft: 4 }} color="blue">
              {type}
            </Tag>
          </div>
        }
      />
    </List.Item>
  );
};
