import React, { useRef } from "react";
import { Numberpad } from "../Keypad";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../context/GlobalContext";
import { useAddToCallMutation } from "../../generated/graphql";

const handleCallStatus = (n: string) => {
  const eventJson = {
    type: "calling",
    number: n
  };
  window.parent.postMessage(JSON.stringify(eventJson), "*");
};

const DialPad = () => {
  // const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory();
  const { conn } = useGlobalContext();
  const [addtoCall] = useAddToCallMutation();

  const handleDialCall = (n: string) => {
    if (conn) {
      addtoCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: n
        }
      });
      history.push(`/call/${n}`);
    } else {
      history.push(`/call/${n}?out=true`);
      handleCallStatus(n);
    }
  };

  const ref = useRef<HTMLInputElement>(null);

  return (
    <div
      style={{
        height: "calc(100vh - 175px)",
        display: "flex",
        alignItems: "flex-end",
        paddingBottom: 4
        //  marginTop: "30%"
      }}
    >
      {/* <div
        style={{
          position: "absolute",
          bottom: 64,
          right: 15,
          cursor: "pointer"
        }}
        onClick={() => setVisible(true)}
      >
        <MyIcon icon="icon-dial-pad" bgColor="#001529" color="#fff" />
      </div> */}
      <Numberpad onCall={handleDialCall} link={ref} />
      {/* <Keypad
        onCall={handleDialCall}
        visible={visible}
        close={setVisible}
        hideDialer={() => setVisible(false)}
      /> */}
    </div>
  );
};

export default DialPad;
