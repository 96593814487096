import { Tabs, Tooltip } from "antd";
import React, { FC } from "react";
import { RouteComponentProps, StaticContext } from "react-router";
import Call from "../components/Call";
import History from "../components/History";
import Texts from "../components/Text";
import Users from "../components/Users";
import { useGlobalContext } from "../context/GlobalContext";
import { Base } from "../layouts/Base";

const { TabPane } = Tabs;

interface stateProps {
  tab: string;
  userTab: string
}

const HomePage: FC<RouteComponentProps<{}, StaticContext, stateProps>> = ({
  location: { state }
}) => {
  const { authority } = useGlobalContext();

  return (
    <Base>
      <Tabs
        defaultActiveKey={state && state.tab === "text" ? "5" : "1"}
        tabPosition="bottom"
        className="mainTabBar mainTabBar-w100"
        style={{ margin: "0 16px" }}
        tabBarStyle={{ borderTop: "2px solid whitesmoke" }}
      >
        {authority && authority.includes("Global Call") && (
          <>
            <TabPane
              tab={
                <Tooltip title="History">
                  <i className="icon-recently-viewed" />
                </Tooltip>
              }
              key="1"
            >
              <div className="tabContainer">
                <History />
              </div>
            </TabPane>
            {/* <TabPane tab={<i className="icon-lead-star" />} key="2">
          <div className="tabContainer">
            <Empty />
          </div>
        </TabPane> */}
            <TabPane
              tab={
                <Tooltip title="Users">
                  <i className="icon-add-user" />
                </Tooltip>
              }
              key="3"
            >
              <div className="tabContainer">
                <Users  userTab={state?.userTab || ''} />
              </div>
            </TabPane>
            <TabPane
              tab={
                <Tooltip title="Calls">
                  <i className="icon-call" />
                </Tooltip>
              }
              key="4"
            >
              <div className="tabContainer">
                <Call />
              </div>
            </TabPane>
          </>
        )}
        {authority && authority.includes("Global Text") && (
          <TabPane
            tab={
              <Tooltip title="Texts">
                <i className="icon-text-message" />
              </Tooltip>
            }
            key="5"
          >
            <div className="tabContainer">
              <Texts />
            </div>
          </TabPane>
        )}
      </Tabs>

      {/* <Tabs defaultActiveKey="2" animated={false} style={{ margin: "0 16px" }}>
        <TabPane tab="Search & History" key="1">
          <div style={{ marginBottom: 16 }}>
            {data ? <SearchList data={data} loading={loading} /> : <History />}
          </div>
        </TabPane>
        <TabPane tab="Team Members" key="2">
          <div style={{ marginBottom: 16 }}>
            <CrmMembers />
          </div>
        </TabPane>
        <TabPane tab="Queues" key="3">
          <div style={{ marginBottom: 16 }}>
            <Queue />
          </div>
        </TabPane>        
      </Tabs> */}
    </Base>
  );
};

export default HomePage;
