import React, { useState } from "react";
import { Form, Button, Input } from "antd";
import {
  useUpdateConversationMutation,
  Conversation
} from "../../../generated/graphql";
import { useStickyState } from "../../../hooks/useStickyState";

interface UpdateConversationProps {
  conversationData: Conversation;
  name: string;
  hideUpdateDetailsView: () => void;
  refetchConversation: () => void;
}

const UpdateConversation: React.FC<UpdateConversationProps> = ({
  name,
  hideUpdateDetailsView,
  conversationData,
  refetchConversation
}) => {
  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [form] = Form.useForm();
  const [grpName, setGrpName] = useState<string>(name);
  const [grpNameLength, setGrpNameLength] = useState<number>(name.length);

  const [addPeople] = useUpdateConversationMutation();

  const EditGroupName = () => {
    form.validateFields().then(values => {
      const input = {
        conversation_id: conversationData.id,
        name: values.groupName,
        is_group: true
      };

      addPeople({ variables: { input } }).then(res => {
        if (res) {
          refetchConversation();
          hideUpdateDetailsView();
        }
      });
    });
  };

  return (
    <div
      style={{
        height: "calc(100vh - 128px)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between"
      }}
    >
      <div className="grpInfoAddPeople">
        <Form form={form} initialValues={{ groupName: name }}>
          <Form.Item name="groupName" className="noMargin">
            <Input
              placeholder="Group Name"
              onChange={e => {
                setGrpName(e.target.value);
                setGrpNameLength(e.target.value.length);
              }}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Form>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px"
        }}
      >
        <Button
          style={{ width: "100%", marginRight: "8px" }}
          className="btnHover"
          onClick={hideUpdateDetailsView}
        >
          Cancel
        </Button>

        <Button
          style={{ width: "100%", marginRight: "8px" }}
          disabled={
            !(grpNameLength !== 0 && /\S/.test(grpName)) ||
            Number(conversationData.admin_id) !== Number(adminInfo.admininfo.id)
          }
          className={
            (grpNameLength !== 0 && /\S/.test(grpName)) &&
            Number(conversationData.admin_id) === Number(adminInfo.admininfo.id)
              ? "btnSecondary"
              : ""
          }
          onClick={() => EditGroupName()}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

export default UpdateConversation;
