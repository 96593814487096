import React, { useState, useEffect } from "react";
import { Connection } from "twilio-client";
import { DeviceStatus, CallStatus } from "../enum/Index";
import { useGlobalContext } from "../context/GlobalContext";
import { TwilioError } from "twilio-client/es5/twilio/errors";
import { useHistory } from "react-router-dom";
import { Incomming } from "./Incomming";
import {
  useGetCapabilityTokenLazyQuery,
  useAddToCallMutation,
  useColdTransferCallMutation,
  TransferToType,
  DirectionType
} from "../generated/graphql";
import { IsJsonString } from "../utils/utils";
import { KConnectionManager } from "../utils/KConnectionManager";
import CallTransfer from "./CallTransfer";
// const now = Math.floor(Date.now() / 1000);

interface PhoneProps {
  token: string;
  number: string;
}

const Phone: React.FC<PhoneProps> = ({ token, number }) => {
  const [getToken, { data }] = useGetCapabilityTokenLazyQuery();

  // useEffect(() => {
  //   function fetchToken() {
  //     getToken();
  //     if (data?.getCapabilityToken.token) {
  //       setToken([{
  //         token: data?.getCapabilityToken.token,
  //         expire: now + data?.getCapabilityToken.expire,
  //         number: data?.getCapabilityToken.number?.phone_number,
  //       }]);
  //     }
  //   }
  //   if (!token) {
  //     fetchToken();
  //     return;
  //   }
  //   if (token.expire <= now) {
  //     fetchToken();
  //     return;
  //   }
  // }, [data, getToken, setToken, token]);

  const {
    setStatus,
    setDevice,
    setConn,
    conn,
    status,
    activeNumber,
    setActiveNumber,
    setCallStatus
  } = useGlobalContext();
  const history = useHistory();
  const [visibleTrasnfer, setvisibleTrasnfer] = useState(false);
  const [addtoCall] = useAddToCallMutation();
  const [coldTransfer] = useColdTransferCallMutation();
  // eslint-disable-next-line
  const receiveCallEvent = (event: MessageEvent) => {
    const callInfo = IsJsonString(event.data) ? JSON.parse(event.data) : {};
    if (conn && callInfo.action === "accept") {
      conn.accept();
      history.push(`/call/${conn.parameters.From}`);
    }
    if (conn && callInfo.action === "reject") {
      conn.reject();
      setStatus(DeviceStatus.READY);
      setConn(null);
    }
    if (callInfo.action === "call") {
      const eventJson = {
        type: "calling_lead",
        number: callInfo.num
      };
      handleDialCall(callInfo.num);
      history.push(`/call/${callInfo.num}?out=true&n=${callInfo.name}`);
      window.parent.postMessage(JSON.stringify(eventJson), "*");
    }
  };

  const handleDialCall = (n: string) => {
    if (conn) {
      addtoCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: n
        }
      });
      history.push(`/call/${n}`);
    } else {
      history.push(`/call/${n}?out=true`);
      handleCallStatus(n);
    }
  };

  const handleCallStatus = (n: string) => {
    const eventJson = {
      type: "calling",
      number: n
    };
    window.parent.postMessage(JSON.stringify(eventJson), "*");
  };

  useEffect(() => {
    window.addEventListener("message", receiveCallEvent, false);

    return () => {
      window.removeEventListener("message", receiveCallEvent, false);
    };
  }, [receiveCallEvent]);

  useEffect(() => {
    if (token) {
      const N = number;
      const K = KConnectionManager.getInstance();
      const client = K.setClient(N, token);
      if (client) {
        client.on("connectionStateChanged", function(state) {
          console.log("connection status", state);
        });
      }
    }
  }, [token, activeNumber, number]);

  useEffect(() => {
    if (token) {
      const N = number;
      const K = KConnectionManager.getInstance();
      const device = K.setDevice(N, token, true);
      // const device = new Device();
      setActiveNumber(number);

      if (device) {
        // device.setup(token, { debug: true });

        device.on("ready", () => {
          setDevice(device);
          setStatus(DeviceStatus.READY);
        });
        device.on("connect", (connection: Connection) => {
          setConn(connection);
          setStatus(DeviceStatus.ON_CALL);
          setCallStatus(CallStatus.CONNECTING);
          K.StartTimer();
        });
        device.on("disconnect", () => {
          setStatus(DeviceStatus.READY);
          setConn(null);
          K.ClearTimer();
          history.push("/");
          const eventInfo = {
            type: "close"
          };
          window.parent.postMessage(JSON.stringify(eventInfo), "*");
          window.dispatchEvent(
            new CustomEvent("updateCallLogs", {
              detail: { fetchCallLogs: true }
            })
          );
        });
        device.on("incoming", connection => {
          setStatus(DeviceStatus.INCOMING);
          setConn(connection);

          const eventInfo = {
            type: "incoming",
            from: connection.parameters.From,
            name: connection.customParameters.get("name") || ""
          };
          window.parent.postMessage(JSON.stringify(eventInfo), "*");
          connection.on("reject", () => {
            setStatus(DeviceStatus.READY);
            setConn(null);
          });
        });
        device.on("cancel", () => {
          setStatus(DeviceStatus.READY);
          setConn(null);
          const eventInfo = {
            type: "cancel"
          };
          window.parent.postMessage(JSON.stringify(eventInfo), "*");
          history.push("/");
        });
        device.on("reject", () => {
          setStatus(DeviceStatus.READY);
          setConn(null);
          const eventInfo = {
            type: "reject"
          };
          window.parent.postMessage(JSON.stringify(eventInfo), "*");
          history.push("/");
        });

        const handleError = (err: TwilioError) => {
          if ([31205, 31204, 31207, 31202, 31201].includes(err.code)) {
            // setToken(null);
            getToken();
          }
        };

        if (device) {
          device.on("error", handleError);
        }

        return () => {
          device.destroy();
          setDevice(null);
          setStatus(DeviceStatus.OFFLINE);
        };
      }
    }
  }, [
    token,
    setStatus,
    setDevice,
    setConn,
    history,
    setActiveNumber,
    data,
    // setToken,
    getToken,
    number,
    setCallStatus
  ]);

  const coldTransferCall = (to: string, type: TransferToType) => {
    if (conn) {
      setvisibleTrasnfer(false);
      coldTransfer({
        variables: {
          call_sid: conn.parameters.CallSid,
          to,
          type,
          dir: DirectionType.Outbound
        }
      }).then(res => {
        if (res) {
          setStatus(DeviceStatus.READY);
        }
      });
    }
  };

  return (
    <>
      <CallTransfer
        visible={visibleTrasnfer}
        hideCallTransfer={() => setvisibleTrasnfer(false)}
        coldTransferCall={coldTransferCall}
      />
      <Incomming
        visible={status === DeviceStatus.INCOMING}
        showCallTransfer={() => setvisibleTrasnfer(true)}
        hideCallTransfer={() => setvisibleTrasnfer(false)}
      />
    </>
  );
};

export default Phone;
