import { Button, List, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";
import { useGetBlockNumbersLazyQuery } from "../../generated/graphql";
import { useStickyState } from "../../hooks/useStickyState";
import AddBlock from "./AddBlock";
import { ListItem } from "./ListItem";

interface UserListProps {
  userTab: string
}


export const BlockedNumberList: FC<UserListProps> = ({userTab}) => {

  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [
    blockedNumbers,
    { data, refetch, loading }
  ] = useGetBlockNumbersLazyQuery();

  useEffect(
    () => {
      if(userTab === '4'){
        refetch && refetch()
      }
    },
    [refetch, userTab]
  );

  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [editData, setEditData] = useState<{
    id: string;
    number: string;
    type: string;
  } | null>(null);

  useEffect(() => {
    if (blockedNumbers && adminInfo) {
      blockedNumbers({
        variables: { admin_id: adminInfo.admininfo.id }
      });
    }
  }, [blockedNumbers, adminInfo]);

  const hideBlockModal = () => {
    setIsEdit(false);
    setEditData(null);
  };

  const handleEditBlockNumber = (id: string, number: string, type: string) => {
    setEditData({ id, number: number.replace(/\+1/g, ``), type });
    setIsEdit(true);
  };

  return (
    <div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 8 }}
      >
        {isEdit ? (
          <i className="icon-cancel" onClick={hideBlockModal} />
        ) : (
          <Button className="btnPrimary" onClick={() => setIsEdit(true)}>
            Add
          </Button>
        )}
      </div>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "calc(100vh - 192px)",
          backgroundColor: "#fff"
        }}
      >
        {isEdit ? (
          <AddBlock
            visible={isEdit}
            hideBlockModal={hideBlockModal}
            editData={editData}
            refetchBlock={() => refetch && refetch()}
          />
        ) : (
          <Spin spinning={loading}>
            <List
              loading={false}
              itemLayout="horizontal"
              dataSource={
                data && data.getBlockNumbers ? data.getBlockNumbers : []
              }
              renderItem={item => (
                <ListItem
                  {...item}
                  refetchBlock={() => refetch && refetch()}
                  handleEditBlockNumber={handleEditBlockNumber}
                />
              )}
            />
          </Spin>
        )}
      </div>
    </div>
  );
};
