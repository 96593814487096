import React, { useState, useEffect } from "react";
import { Button, Popconfirm, List, Avatar, Select, Spin, Form } from "antd";
import { useLazyQuery } from "@apollo/client";
import {
  Conversation,
  SearchMemberPerson,
  useUpdateConversationMutation,
  MemberInput,
  ActionType,
  Member
} from "../../../generated/graphql";
import { formatPhoneNumber } from "../../../utils/utils";
import { SEARCH_LEAD } from "../../../query";
import { useStickyState } from "../../../hooks/useStickyState";

const { Option } = Select;

interface UpdateMembersProps {
  conversationData: Conversation;
  addPeopleView: boolean;
  hideUpdateDetailsView: () => void;
  hideAddPeopleView: () => void;
  showAddPeopleView: () => void;
  refetchConversation: () => void;
}

const UpdateMembers: React.FC<UpdateMembersProps> = ({
  hideUpdateDetailsView,
  conversationData,
  addPeopleView,
  hideAddPeopleView,
  showAddPeopleView,
  refetchConversation
}) => {
  const [form] = Form.useForm();
  const activeMembers = conversationData.members_details
    ? conversationData.members_details.filter(
        (m: any) => m.meta.status === true
      )
    : [];
  const members: string[] = [];
  activeMembers.forEach((m: any) => members.push(m.member.id));

  const [selectedMembersId, setSelectedMembersId] = useState<string[]>([]);
  const [selectedMembers, setSelectedMembers] = useState<Array<MemberInput>>(
    []
  );

  const [selectedMembersData, setSelectedMembersData] = useState<
    Array<SearchMemberPerson>
  >([]);

  const [addPeople] = useUpdateConversationMutation();
  const [search, { data, loading }] = useLazyQuery(SEARCH_LEAD, {
    fetchPolicy: `no-cache`
  });

  const [adminInfo] = useStickyState(null, "AdminInfo");

  useEffect(() => {
    form.setFieldsValue({ addMember: selectedMembersId });
  }, [form, selectedMembersId]);

  const handleIAddtem = (memberid: string, member: SearchMemberPerson) => {
    const newMemberIds = selectedMembersId;
    const newSelectedMembers = selectedMembers;
    const newSelectedMembersData = selectedMembersData;
    newMemberIds.push(memberid);
    newSelectedMembersData.push(member);
    form.setFieldsValue({ addMember: [...newMemberIds] });
    setSelectedMembersId([...newMemberIds]);

    if (member.typeClass && member.phone_no) {
      newSelectedMembers.push({
        member_id: member.id,
        member_type: member.typeClass,
        phone_no: member.phone_no
      });
    }
    setSelectedMembers([...newSelectedMembers]);
    setSelectedMembersData([...newSelectedMembersData]);
  };

  const handleRemoveItem = (memberid: string, member: SearchMemberPerson) => {
    setSelectedMembersId(memberIds =>
      memberIds.filter((m: string) => m !== memberid)
    );
    setSelectedMembers(members =>
      members.filter((m: MemberInput) => m.member_id !== member.id)
    );
    setSelectedMembersData(members =>
      members.filter((m: SearchMemberPerson) => m.id !== member.id)
    );
  };

  const handleChangeSelectedMember = (memberid: string[]) => {
    setSelectedMembersId(memberid);
    setSelectedMembers(members =>
      members.filter(
        (m: MemberInput) => m.phone_no && memberid.includes(m.phone_no)
      )
    );
    setSelectedMembersData(members =>
      members.filter(
        (m: SearchMemberPerson) => m.phone_no && memberid.includes(m.phone_no)
      )
    );
  };

  const handleAddPeople = () => {
    const input = {
      conversation_id: conversationData.id,
      action: ActionType.Add,
      is_group: conversationData.is_group,
      members: selectedMembers
    };
    addPeople({ variables: { input } }).then(res => {
      if (res) {
        refetchConversation();
        hideUpdateDetailsView();
        hideAddPeopleView();
      }
    });
  };

  const removePerson = (memberDetails: Member) => {
    if (memberDetails.member && memberDetails.member_type) {
      const input = {
        conversation_id: conversationData.id,
        action: ActionType.Remove,
        is_group: conversationData.is_group,
        members: [
          {
            member_id: memberDetails.member.id,
            member_type: memberDetails.member_type,
            phone_no: ""
          }
        ]
      };

      addPeople({ variables: { input } }).then(res => {
        if (res) {
          refetchConversation();
        }
      });
    }
  };

  const createGroup = () => {
    let gname = "";

    conversationData.members_details &&
      conversationData.members_details.forEach(
        (name: any) =>
          (gname = `${gname}${
            name.member.first_name !== null ? name.member.first_name : "HQ"
          }${" "}${
            name.member.first_name !== null ? name.member.last_name : "User"
          }, `)
      );

    selectedMembersData.forEach(
      (member: SearchMemberPerson, i) =>
        (gname = `${gname}${member.full_name ? member.full_name : "HQ User"}${
          selectedMembersData.length !== i + 1 ? ", " : ""
        } `)
    );

    const input = {
      conversation_id: conversationData.id,
      action: ActionType.Add,
      is_group: true,
      members: selectedMembers,
      name: gname
    };

    addPeople({ variables: { input } }).then(res => {
      if (res) {
        refetchConversation();
        hideUpdateDetailsView();
        hideAddPeopleView();
      }
    });
  };

  return addPeopleView ? (
    <div>
      <div className="grpInfoAddPeople">
        <Form form={form} initialValues={{ addMember: selectedMembersId }}>
          <Form.Item name="addMember" className="noMargin">
            <Select
              mode="multiple"
              placeholder="Search contact to Add"
              autoFocus
              size="large"
              value={selectedMembersId}
              onChange={(e: string[]) => handleChangeSelectedMember(e)}
              onSearch={(value: string) => {
                if (value !== "") {
                  search({
                    variables: {
                      query: value,
                      call: true
                    }
                  });
                }
              }}
              className="selectedMenu"
              dropdownClassName="addMemberDropdown"
              getPopupContainer={e => e.parentNode}
            >
              {data &&
                data.searchConversationV2 &&
                data.searchConversationV2.person.map(
                  (d: SearchMemberPerson) => {
                    return (
                      d.phone_no && (
                        <Option key={d.phone_no} value={d.phone_no}>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              flexDirection: "row",
                              alignItems: "center"
                            }}
                          >
                            <div className="hideCol">
                              {d.full_name !== null ? d.full_name : "HQ User"}
                            </div>
                          </div>
                        </Option>
                      )
                    );
                  }
                )}
            </Select>
          </Form.Item>
        </Form>
      </div>
      <Spin spinning={loading}>
        <div
          style={{
            margin: "0px 16px",
            height: "calc(100vh - 232px)",
            overflow: "auto"
          }}
        >
          {data &&
            data.searchConversationV2 &&
            data.searchConversationV2.person
              .filter((r: any) => !members.includes(r.id))
              .map((member: SearchMemberPerson) => (
                <List.Item
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (member && member.phone_no) {
                      if (selectedMembersId.includes(member.phone_no)) {
                        handleRemoveItem(member.phone_no, member);
                      } else {
                        handleIAddtem(member.phone_no, member);
                      }
                    }
                  }}
                >
                  <List.Item.Meta
                    avatar={
                      member.phone_no &&
                      selectedMembersId.includes(member.phone_no) ? (
                        <Avatar
                          size="large"
                          style={{ backgroundColor: "#4AC367" }}
                        >
                          <i className="icon-check-mark" />
                        </Avatar>
                      ) : (
                        <Avatar
                          size="large"
                          style={{
                            fontSize: "24px",
                            backgroundColor: `#595959`
                          }}
                        >
                          {member && member.full_name && member.full_name !== ""
                            ? member.full_name.charAt(0)
                            : "H"}
                        </Avatar>
                      )
                    }
                    title={
                      <span style={{ color: "#595959" }}>
                        {member.full_name || "HQ User"}
                      </span>
                    }
                    description={
                      <span className="textBlue">
                        {member.phone_no && formatPhoneNumber(member.phone_no)}
                        {member.is_primary ? (
                          <i
                            className="icon-verified"
                            style={{ color: "#4ac367" }}
                          />
                        ) : null}
                      </span>
                    }
                  />
                </List.Item>
              ))}
        </div>
      </Spin>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px"
        }}
      >
        <Button
          style={{ width: "100%", marginRight: "8px" }}
          className="btnHover"
          onClick={() => {
            hideUpdateDetailsView();
            hideAddPeopleView();
          }}
        >
          Cancel
        </Button>

        {conversationData.is_group ? (
          <Button
            style={{ width: "100%", marginRight: "8px" }}
            className={selectedMembers.length === 0 ? "" : "btnSecondary"}
            onClick={handleAddPeople}
            disabled={selectedMembers.length === 0}
          >
            Add People
          </Button>
        ) : (
          <Button
            style={{ width: "100%", marginRight: "8px" }}
            className={selectedMembers.length === 0 ? "" : "btnSecondary"}
            disabled={selectedMembers.length === 0}
            onClick={createGroup}
          >
            Create Group
          </Button>
        )}
      </div>
    </div>
  ) : (
    <div>
      <div
        style={{
          margin: "0 16px",
          height: "calc(100vh - 182px)",
          overflow: "auto"
        }}
      >
        {conversationData.members_details &&
          conversationData.members_details.map(detail => {
            const filteredNum =
              conversationData && conversationData.binding
                ? conversationData.binding.filter(
                    (bindNum: any) =>
                      detail &&
                      detail.member &&
                      detail.member.id &&
                      bindNum.member.member.id === detail.member.id
                  )
                : [];
            return (
              detail &&
              detail.meta &&
              detail.meta.status && (
                <div className="spaceBetween" style={{ marginRight: "12px" }}>
                  <List.Item
                    style={{ cursor: "pointer" }}
                    actions={[
                      detail.member &&
                      Number(conversationData.admin_id) ===
                        Number(adminInfo.admininfo.id) &&
                      Number(detail.member.id) !==
                        Number(adminInfo.admininfo.id) ? (
                        <Popconfirm
                          title="Are you sure you want to remove this member?"
                          onConfirm={() => removePerson(detail)}
                          icon={false}
                          placement="topRight"
                          okText="Yes"
                          cancelText="No"
                          okButtonProps={{
                            size: "large",
                            danger: true
                          }}
                          cancelButtonProps={{ size: "large" }}
                        >
                          <i className="icon-cancel iconCenter" />
                        </Popconfirm>
                      ) : (
                        <Button
                          type="text"
                          disabled
                          style={{ padding: 0, border: "none" }}
                        >
                          <i
                            className="icon-cancel iconCenter"
                            style={{
                              cursor: "not-allowed",
                              pointerEvents: "none"
                            }}
                          />
                        </Button>
                      )
                    ]}
                  >
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          size="large"
                          style={{
                            fontSize: "24px"
                            //   backgroundColor: '',
                          }}
                        >
                          {detail.member && detail.member.first_name
                            ? `${detail.member.first_name.charAt(0)}`
                            : "H"}
                        </Avatar>
                      }
                      title={
                        detail.member && (
                          <span style={{ color: "#595959" }}>{`${detail.member
                            .first_name || "HQ"} ${detail.member.last_name ||
                            "User"}`}</span>
                        )
                      }
                      description={
                        detail.member && (
                          <span className="textBlue">
                            {detail.member_type === "AGENT" &&
                            Number(conversationData.admin_id) ===
                              Number(detail.member.id)
                              ? "Admin"
                              : detail.member_type === "LEAD" &&
                                filteredNum &&
                                filteredNum[0]
                              ? formatPhoneNumber(filteredNum[0].bind_to || "")
                              : ""}

                            {/* {detail?.number} */}
                          </span>
                        )
                      }
                    />
                  </List.Item>
                </div>
              )
            );
          })}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "16px"
        }}
      >
        <Button
          style={{ width: "100%", marginRight: "8px" }}
          className="btnHover"
          onClick={hideUpdateDetailsView}
        >
          Cancel
        </Button>

        <Button
          style={{ width: "100%", marginRight: "8px" }}
          className={
            Number(conversationData.admin_id) === Number(adminInfo.admininfo.id)
              ? "btnSecondary"
              : ""
          }
          onClick={() => showAddPeopleView()}
          disabled={
            Number(conversationData.admin_id) !== Number(adminInfo.admininfo.id)
          }
        >
          Add People
        </Button>
      </div>
    </div>
  );
};

export default UpdateMembers;
