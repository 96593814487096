import React, { useState } from "react";
import { Modal, Form, Input, Radio, Button, Spin, message } from "antd";
import { useStickyState } from "../../hooks/useStickyState";
import { useBlockNumberV2Mutation } from "../../generated/graphql";

interface BlockModalProps {
  visible: boolean;
  hideBlockModal: () => void;
  editData: { id: string; number: string; type: string } | null;
  refetchBlock: () => void;
}

const AddBlock: React.FC<BlockModalProps> = ({
  visible,
  hideBlockModal,
  editData,
  refetchBlock
}) => {
  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [submitLoading, setsubmitLoading] = useState<boolean>(false);
  const [blockNumber] = useBlockNumberV2Mutation();

  const onFinish = (values: any) => {
    setsubmitLoading(true);
    if (adminInfo) {
      const input = {
        id: editData?.id || "0",
        numbers: [values.number],
        type: values.type,
        is_block: true
      };

      blockNumber({
        variables: {
          input
        }
      })
        .then(res => {
          if (res) {
            refetchBlock();
            hideBlockModal();
            setsubmitLoading(false);
          }
        })
        .catch(error => {
          if(error){
            error.graphQLErrors.map((errMsg: any) => {
              if (errMsg && errMsg.message) {
                message.error(errMsg.message);
              }
              return null
            });
            setsubmitLoading(false);
          }
          
        });
    }
  };

  return (
    <div>
      <Modal
        title="Add Block Number"
        visible={visible}
        onCancel={hideBlockModal}
        footer={null}
      >
        <Form name="Block" onFinish={onFinish} initialValues={editData || {}}>
          <Form.Item
            name="number"
            rules={[
              { required: true },
              { max: 11, message: "Max 11 digits allowed" },
              { min: 10, message: "Mobile number too short" }
            ]}
          >
            <Input placeholder="enter number" />
          </Form.Item>
          <Form.Item name="type" rules={[{ required: true }]}>
            <Radio.Group>
              <Radio value="BLOCK">BLOCK</Radio>
              <Radio value="SPAM">SPAM</Radio>
            </Radio.Group>
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                <span>
                  {submitLoading ? (
                    <Spin
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#fff"
                      }}
                      className="whiteLoader"
                      spinning={submitLoading}
                      tip={editData ? "Updating" : "Adding.."}
                    />
                  ) : editData ? (
                    "Update"
                  ) : (
                    "Add"
                  )}
                </span>
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddBlock;
