import React from "react";
import TextList from "./TextList";

export default function Texts() {
  return (
    <div>
      <TextList />
    </div>
  );
}
