import React, { FC } from "react";
import { Tabs, Input, Empty } from "antd";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { SEARCH_LEAD } from "../../query";
import { UserList } from "./List";
import CrmMembers from "../CrmMembers/List";
import Contact from "../Contact";
import { BlockedNumberList } from "../Blocked/List";

const { TabPane } = Tabs;

const { Search } = Input;

interface UserProps {
  userTab: string
}

const Users: FC<UserProps> =({userTab}) => {
  const [search, { data, loading }] = useLazyQuery(SEARCH_LEAD, {
    fetchPolicy: `no-cache`,
  });
  const history = useHistory();

  const callback=(key:string)=> {
    history.push({
      pathname: "/",
      state: { userTab: key }
    });
  }

  return (
    <Tabs defaultActiveKey="1" animated={false} className="mainTabBar" onChange={callback}>
      <TabPane tab="Leads" key="1">
        <br />
        <Search
          allowClear
          placeholder="Search leads and contacts"
          onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
            search({
              variables: {
                query: value.target.value,
                call: true,
              },
            });
          }}
        />
        <div style={{ marginBottom: 16 }}>
          {data ? (
            <UserList data={data} loading={loading} />
          ) : (
            <Empty description="No Data" style={{ marginTop: "16px" }} />
          )}
        </div>
      </TabPane>
      <TabPane tab="CRM User" key="2">
        <div style={{ marginBottom: 16 }}>
          <br />
          <CrmMembers />
        </div>
      </TabPane>
      <TabPane tab="Contacts" key="3">
        <div style={{ marginBottom: 16 }}>
          <Contact userTab={userTab}/>
        </div>
      </TabPane>
      <TabPane tab="Blocked" key="4">
        <div style={{ marginBottom: 16 }}>
          <BlockedNumberList userTab={userTab}/>
        </div>
      </TabPane>
    </Tabs>
  );
}


export default Users;