import React, { FC, useEffect } from "react";
import { List, Spin } from "antd";
import { ListItem } from "./ListItem";
import { useCallHistory } from "../../hooks/useCallHistory";
import { IsJsonString } from "../../utils/utils";

export const HistoryList: FC = () => {
  const {
    loading,
    data: historyData,
    refetch,
    handleFetchMore
  } = useCallHistory(30, 1);

  // let recent = [];
  // if (window.localStorage.getItem("C_RECENT")) {
  //   recent = JSON.parse(window.localStorage.getItem("C_RECENT") || "[]");
  // }

  useEffect(() => {
    const updateCallLogs = (data: any) => {
      if (data.detail.fetchCallLogs && refetch) {
        refetch();
      }
    };
    window.addEventListener("updateCallLogs", updateCallLogs, false);
    // return () => {
    //   window.removeEventListener("updateCallLogs", updateCallLogs, false);
    // };
  });

  // eslint-disable-next-line
  const receiveCallEvent = (event: MessageEvent) => {
    const callInfo = IsJsonString(event.data) ? JSON.parse(event.data) : {};
    if (callInfo.action === "reject") {
      if (refetch) {
        refetch();
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", receiveCallEvent, false);

    return () => {
      window.removeEventListener("message", receiveCallEvent, false);
    };
  }, [receiveCallEvent]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {
      if (
        historyData &&
        historyData.getCallHistory &&
        historyData.getCallHistory.paginatorInfo.hasMorePages
      ) {
        handleFetchMore(
          historyData.getCallHistory.paginatorInfo.currentPage + 1
        );
      }
    }
  };

  return (
    <div
      style={{
        overflowX: "auto",
        maxHeight: "calc(100vh - 192px)",
        backgroundColor: "#fff"
      }}
      onScroll={handleScroll}
    >
      <Spin spinning={loading}>
        <List
          loading={false}
          itemLayout="horizontal"
          dataSource={
            historyData &&
            historyData.getCallHistory &&
            historyData.getCallHistory.data
              ? historyData.getCallHistory.data
              : []
          }
          renderItem={item => <ListItem {...item} refetch={refetch} />}
        />
      </Spin>
    </div>
  );
};
