import { GET_MISSED_CALL_HISTORY } from "../query";
import { useGetMiscallsQuery } from "../generated/graphql";

export function useMissedCallHistory(first: number, page: number | undefined) {
  const { data, loading, refetch, fetchMore } = useGetMiscallsQuery({
    query: GET_MISSED_CALL_HISTORY,
    variables: { first, page }
  });

  const handleFetchMore = (currentPage: number) => {
    // console.log("paginatorInfo", currentPage);
    if (fetchMore && data) {
      fetchMore({
        variables: {
          first,
          page: currentPage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const data = Object.assign({}, fetchMoreResult);
          data.getMiscalls.data = [
            ...prev.getMiscalls.data,
            ...fetchMoreResult.getMiscalls.data
          ];
          return { ...data };
        }
      });
    }
  };

  return {
    data,
    loading,
    fetchMore,
    refetch,
    handleFetchMore
  };
}
