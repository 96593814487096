import React, { FC, useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { List, Spin, Select } from "antd";
import { useCallQueue } from "../../hooks/useCallQueue";
import { QueueListItem } from "./QueueListItem";
import { TransferToType } from "../../generated/graphql";
import { GET_COMPANIES } from "../../query";

interface QueueListProps {
  coldTransferCall: (to: string, type: TransferToType) => void;
}

const { Option } = Select;

export const QueueList: FC<QueueListProps> = ({ coldTransferCall }) => {
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [getCompanies, { data: companies }] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data.getLoginUserCompanies.length !== 0) {
        setSelectedCompany(Number(data.getLoginUserCompanies[0].id));
      }
    }
  });

  const { queueData, loading, handleFetchMore, refetch } = useCallQueue(
    10,
    1,
    selectedCompany
  );
  const [queueLoading, setQueueLoading] = useState<boolean>(false);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {
      if (queueData.getQueue.paginatorInfo.hasMorePages) {
        handleFetchMore(queueData.getQueue.paginatorInfo.currentPage + 1);
      }
    }
  };

  const onChangeCompany = (id: number) => {
    setSelectedCompany(id);
  };

  return (
    <>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "calc(100vh - 210px)",
          backgroundColor: "#fff"
        }}
        onScroll={handleScroll}
      >
        <div>
          {companies &&
          companies.getLoginUserCompanies &&
          companies.getLoginUserCompanies.length > 1 ? (
            <Select
              value={selectedCompany}
              style={{ width: 160 }}
              onChange={onChangeCompany}
            >
              {companies.getLoginUserCompanies.map((company: any) => (
                <Option value={Number(company.id)}>
                  {company.company_name}
                </Option>
              ))}
            </Select>
          ) : null}
        </div>
        <Spin spinning={loading || queueLoading}>
          <List
            loading={false}
            itemLayout="horizontal"
            dataSource={
              queueData && queueData.getQueue ? queueData.getQueue.data : []
            }
            renderItem={item => (
              <QueueListItem
                {...item}
                refetchQueue={refetch}
                setQueueLoading={setQueueLoading}
                coldTransferCall={coldTransferCall}
              />
            )}
          />
        </Spin>
      </div>
    </>
  );
};
