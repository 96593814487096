import { gql } from "@apollo/client";

export const GET_MY_NUMBERS = gql`
  query GetMyNumbers {
    getMyNumbers {
      id
      friendly_name
      phone_number
    }
  }
`;

export const GET_CAPABILITY_TOKEN = gql`
  query GetCapabilityToken($id: ID) {
    getCapabilityToken(number_id: $id) {
      token
      expire
      number {
        id
        friendly_name
        phone_number
      }
    }
  }
`;

export const SEARCH_LEAD = gql`
  query SearchLead($query: String!, $call: Boolean) {
    searchConversationV2(query: $query, call: $call) {
      group {
        id
        name
        default_number
        is_group
        members_details {
          id
          member_type
          member {
            id
            first_name
            last_name
            avatar
          }
          meta {
            is_admin
            status
          }
        }
      }
      person {
        id
        full_name
        avatar
        phone_no
        typeClass
        is_primary
      }
    }
  }
`;

export const GET_LEAD_FROM_NUMBER = gql`
  query GetLeadFromNumber($q: String!) {
    getLeadFromNumber(number: $q) {
      id
      first_name
      last_name
      email
    }
  }
`;

export const GET_CALL_HISTORY = gql`
  query getCallHistory($first: Int!, $page: Int) {
    getCallHistory(first: $first, page: $page) {
      data {
        id
        status
        from
        to
        is_forwarded
        is_transferred
        timestamp
        duration
        caller_type
        called_type
        blockNumberTo
        blockNumberFrom
        caller {
          id
          first_name
          last_name
        }
        called {
          id
          first_name
          last_name
        }
        recording {
          id
          sid
          source
          url
          duration
        }
        contactNumberFrom {
          id
          contact {
            id
            numbers {
              phone_number
            }
            first_name
            last_name            
          }
        }
        contactNumberTo {
          id
          contact {
            id
            numbers {
              phone_number
            }
            first_name
            last_name            
          }
        }
        leadNumberTo{
          lead{
            first_name
            last_name 
          }
        }
        leadNumberFrom{
          lead{
            first_name
            last_name 
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const GET_CRM_CONTACT = gql`
  query getCRMContact(
    $first: Int!
    $page: Int
    $company_id: Int!
    $key: String
  ) {
    getCRMContact(
      first: $first
      page: $page
      company_id: $company_id
      key: $key
    ) {
      data {
        id
        first_name
        last_name
        type
        numbers {
          id
          phone_number
          queue {
            id
            name
          }
        }
      }
      paginatorInfo {
        hasMorePages
        currentPage
        total
      }
    }
  }
`;

export const CALL_QUEUE = gql`
  mutation callQueue($input: queueInput) {
    callQueue(input: $input) {
      id
      name
      members {
        id
        first_name
        last_name
      }
    }
  }
`;

export const DELETE_QUEUE = gql`
  mutation deleteQueue($id: ID!) {
    deleteQueue(id: $id)
  }
`;

export const GET_QUEUE = gql`
  query getQueue($first: Int!, $page: Int, $company_id: [Int]) {
    getQueue(first: $first, page: $page, company_id: $company_id) {
      data {
        id
        name
        members {
          id
        }
        members_count
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const ASSIGN_QUEUE = gql`
  mutation assignQueue($input: assignQueueInput) {
    assignQueue(input: $input) {
      id
      name
    }
  }
`;

export const ADMIN_INFO = gql`
  query admininfo {
    admininfo {
      id
      first_name
      last_name
      type
      roles {
        id
        name
        permissions {
          id
          name
        }
      }
    }
  }
`;

export const CALL_HOLD = gql`
  mutation holdCall($call_sid: [String!]!, $hold: Boolean!) {
    holdCall(call_sid: $call_sid, hold: $hold)
  }
`;

export const ADDTO_CALL = gql`
  mutation addToCall($call_sid: String!, $to: String!) {
    addToCall(call_sid: $call_sid, to: $to)
  }
`;

export const GET_CALL_DETAILS = gql`
  query getCallDetail($call_sid: String!) {
    getCallDetail(call_sid: $call_sid) {
      id
      duration
      is_forwarded
      conference {
        id
        name
        owner
        participant {
          added_by
          sid
          number
          hold
          muted
          status
        }
      }
    }
  }
`;

export const REMOVE_FORM_CALL = gql`
  mutation removeFromCall($call_sid: String!, $participant_sid: String!) {
    removeFromCall(call_sid: $call_sid, participant_sid: $participant_sid)
  }
`;

export const COLD_TRANSFER_CALL = gql`
  mutation coldTransferCall(
    $call_sid: String!
    $to: String!
    $type: TransferToType!
    $dir: DirectionType!
  ) {
    coldTransferCall(call_sid: $call_sid, to: $to, type: $type, dir: $dir)
  }
`;

export const GET_CONTACT = gql`
  query getContact($first: Int!, $page: Int) {
    getContact(first: $first, page: $page) {
      data {
        id
        first_name
        last_name
        company_name
        numbers {
          id
          ext_no
          raw_number
          formatted_number
          blockNumber
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const CONTACT = gql`
  mutation contact($input: createContact!) {
    contact(input: $input) {
      id
      first_name
      last_name
      company_name
      numbers {
        id
        ext_no
        raw_number
        formatted_number
      }
    }
  }
`;

export const DELETE_CONTACT = gql`
  mutation deleteContact($id: ID!) {
    deleteContact(id: $id) {
      id
      first_name
      last_name
    }
  }
`;

export const DELETE_CONTACT_NUMBER = gql`
  mutation deleteContactNumber($id: ID!) {
    deleteContactNumber(id: $id) {
      id
      ext_no
      raw_number
      formatted_number
    }
  }
`;

export const GET_VM = gql`
  query getVM($first: Int!, $page: Int) {
    getVM(first: $first, page: $page) {
      data {
        id
        from
        to
        timestamp
        caller {
          id
          first_name
          last_name
        }
        called {
          id
          first_name
          last_name
        }
        contactNumberFrom {
          id
          contact {
            id
            numbers {
              phone_number
            }
            first_name
            last_name            
          }
        }
        contactNumberTo {
          id
          contact {
            id
            numbers {
              phone_number
            }
            first_name
            last_name            
          }
        }
        leadNumberTo{
          lead{
            first_name
            last_name 
          }
        }
        leadNumberFrom{
          lead{
            first_name
            last_name 
          }
        }
        voicemail {
          id
          sid
          source
          url
          duration
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const GET_BLOCKED_NUMBERS = gql`
  query getBlockNumbers($admin_id: ID!) {
    getBlockNumbers(admin_id: $admin_id) {
      id
      admin_id
      number
      type
    }
  }
`;

export const UPDATE_BLOCK_NUMBER = gql`
  mutation blockNumberV2($input: blockNumberInput) {
    blockNumberV2(input: $input) {
      id
      admin_id
      number
      type
    }
  }
`;

export const DELETE_BLOCK_NUMBER = gql`
  mutation deleteBlockNumber($id: ID!) {
    deleteBlockNumber(id: $id) {
      id
      admin_id
      number
      type
    }
  }
`;

export const GET_MISSED_CALL_HISTORY = gql`
  query getMiscalls($first: Int!, $page: Int) {
    getMiscalls(first: $first, page: $page) {
      data {
        id
        status
        from
        to
        is_forwarded
        is_transferred
        timestamp
        duration
        caller_type
        called_type
        blockNumberTo
        blockNumberFrom
        caller {
          id
          first_name
          last_name
        }
        called {
          id
          first_name
          last_name
        }
        contactNumberFrom {
          id
          contact {
            id
            numbers {
              phone_number
            }
            first_name
            last_name            
          }
        }
        contactNumberTo {
          id
          contact {
            id
            numbers {
              phone_number
            }
            first_name
            last_name            
          }
        }
        leadNumberTo{
          lead{
            first_name
            last_name 
          }
        }
        leadNumberFrom{
          lead{
            first_name
            last_name 
          }
        }
      }
      paginatorInfo {
        currentPage
        hasMorePages
      }
    }
  }
`;

export const GET_COMPANIES = gql`
  query getLoginUserCompanies {
    getLoginUserCompanies {
      id
      company_name
    }
  }
`;

// Text Query and Mutations

export const CONVERSATION_LIST = gql`
  query conversations($first: Int!, $page: Int) {
    conversations(first: $first, page: $page) {
      data {
        id
        name
        admin_id
        is_group
        default_number
        members_details {
          member_type
          member {
            id
            first_name
            last_name
            avatar
          }
          meta {
            status
          }
        }
        binding {
          id
          bind_from
          bind_to
          is_valid
          member {
            id
            member_type
            member {
              id
              first_name
              last_name
            }
          }
        }
        conversation_has_members {
          conversation_id
          message {
            id
            body
            created_at
            event {
              action
              affected
            }
            sender {
              member {
                id
                first_name
                last_name
              }
            }
            read_by_me {
              msg_id
              member_id
              read_at
            }
          }
        }
        latest_message {
          id
          body
          created_at
          event {
            action
            affected
          }
          sender {
            member {
              id
              first_name
              last_name
            }
          }
          read_by_me {
            msg_id
            member_id
            read_at
          }
        }
      }
      paginatorInfo {
        count
        currentPage
        hasMorePages
        total
      }
    }
  }
`;

export const CONVERSATION = gql`
  query conversation($id: ID!) {
    conversation(id: $id) {
      id
      name
      admin_id
      is_group
      default_number
      members_details {
        id
        member_type
        member {
          id
          first_name
          last_name
          avatar
        }
        meta {
          status
        }
      }
      binding {
        id
        bind_from
        bind_to
        is_valid
        member {
          id
          member_type
          member {
            id
            first_name
            last_name
          }
        }
      }
      conversation_has_members {
        conversation_id
        message {
          id
          body
          created_at
          event {
            action
            affected
          }
          sender {
            member {
              id
              first_name
              last_name
            }
          }
          read_by_me {
            msg_id
            member_id
            read_at
          }
        }
      }
      latest_message {
        id
        body
        created_at
        event {
          action
          affected
        }
        sender {
          member {
            id
            first_name
            last_name
          }
        }
        read_by_me {
          msg_id
          member_id
          read_at
        }
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query messages($conversationId: ID!, $first: Int!, $page: Int) {
    messages(conversationId: $conversationId, first: $first, page: $page) {
      data {
        id
        body
        type
        created_at
        event {
          action
          affected
        }
        smsMessages {
          id
          from
          to
          status
        }
        sender {
          member_type
          member {
            id
            first_name
            last_name
          }
        }
      }
      paginatorInfo {
        total
        currentPage
        hasMorePages
      }
    }
  }
`;

export const SEND_MESSAGE = gql`
  mutation sendMsg($input: SendMsgInput) {
    sendMsg(input: $input) {
      id
      body
      sender {
        member {
          first_name
          last_name
        }
      }
      created_at
    }
  }
`;

export const UPDATE_CONVERSATION = gql`
  mutation updateConversation($input: UpdateConversationInput) {
    updateConversation(input: $input) {
      id
      name
      is_group
      default_number
      members_details {
        id
        member_type
        member {
          id
          first_name
          last_name
          avatar
        }
        meta {
          is_admin
          status
        }
      }
      latest_message {
        id
        body
        created_at
        event {
          action
          affected
        }
        read_by_me {
          msg_id
          member_id
          read_at
        }
      }
    }
  }
`;

export const START_CONVERSATION = gql`
  mutation startConversation($input: StartConversationInput) {
    startConversation(input: $input) {
      id
      name
      is_group
      default_number
      members_details {
        id
        member_type
        member {
          id
          first_name
          last_name
        }
        meta {
          is_admin
          status
        }
      }
      latest_message {
        id
        body
        created_at
        event {
          action
          affected
        }
      }
      binding {
        id
        bind_from
        bind_to
        is_valid
        member {
          id
          member_type
          member {
            id
            first_name
            last_name
          }
        }
      }
    }
  }
`;

export const MESSAGE_STATUS = gql`
  query messageStatusInfo($input: messageStatusInfo) {
    messageStatusInfo(input: $input) {
      id
      from
      to
      status
    }
  }
`;

export const TEXT_CAPABILITY_TOKEN = gql`
  query getTextCapabilityToken($platform: CallDeviceType) {
    getTextCapabilityToken(platform: $platform) {
      token
      expire
    }
  }
`;
