import {
  HttpLink,
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const Cookies = require("js-cookie");

const authLink = setContext((_, { headers }) => {
  // const token = localStorage.getItem("authData");
  const token = Cookies.get("token");
  if (token !== "undefined" && token !== "" && token !== null) {
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  }
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    console.log("graphQLErrors", graphQLErrors);
    graphQLErrors.forEach((err: any) => {
      if (err.extensions.category === "authorization") {
        window.dispatchEvent(
          new CustomEvent("updateAuth", { detail: { fetchAuthority: true } })
        );
      }
    });
  }

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = new HttpLink({
  uri: "https://kcrmsaasapi.webmobtech.biz/graphql"
});

const cache = new InMemoryCache();

export const client = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache,
  credentials: "include",
  resolvers: {}
});

export const GProvider = ApolloProvider;
