import React, { useState, useEffect } from "react";
import { List, Button, Spin } from "antd";
import "./index.css";
import { useConversationList } from "../../../hooks/useConversationList";
import { ConversationItem } from "./ConversationItem";
import SearchList from "../SearchList";
import { KConnectionManager } from "../../../utils/KConnectionManager";
import { useStickyState } from "../../../hooks/useStickyState";

export default function TextList() {
  const K = KConnectionManager.getInstance();
  const syncClient = K.getTextClient("textClient");
  const {
    data,
    loading,
    refetch,
    handleFetchMore,
    fetchMoreLoading
  } = useConversationList(10, 1);
  const [searchView, setsearchView] = useState<boolean>(false);

  const [adminInfo] = useStickyState(null, "AdminInfo");

  useEffect(() => {
    if (syncClient && adminInfo.admininfo) {
      syncClient
        .map(`CONV_ADMIN_${adminInfo.admininfo.id}`)
        .then(function(map: any) {
          map.getItems().then(function(item: any) {});
          map.on("itemAdded", function({ item }: any) {
            if (item.data && refetch) {
              refetch();
            }
          });
        });
    }
  }, [syncClient, adminInfo, refetch]);

  const showNewConversetion = () => {
    setsearchView(true);
  };

  const hideNewConversetion = () => {
    setsearchView(false);
  };

  const handleScroll = (e: any) => {
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {
      if (
        data &&
        data.conversations &&
        data.conversations.paginatorInfo.hasMorePages
      ) {
        handleFetchMore(data.conversations.paginatorInfo.currentPage + 1);
      }
    }
  };

  return searchView ? (
    <SearchList hideNewConversetion={hideNewConversetion} />
  ) : (
    <div>
      <List.Item onClick={showNewConversetion} className="newConversationItem">
        <Button className="newMessage">
          <div className="newConversetion">
            <i className="icon-create-task" />
          </div>
          <span style={{ fontSize: "16px" }}>New Conversation</span>
        </Button>
      </List.Item>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "calc(100vh - 192px)",
          backgroundColor: "#fff"
        }}
        onScroll={handleScroll}
      >
        <Spin spinning={loading}>
          <List
            itemLayout="horizontal"
            dataSource={
              (data && data.conversations && data.conversations.data) || []
            }
            renderItem={(item: any) =>
              item && <ConversationItem data={item} loading={loading} />
            }
          />
          {fetchMoreLoading ? (
            <List.Item style={{ display: "flex", justifyContent: "center" }}>
              <Spin spinning />
            </List.Item>
          ) : null}
        </Spin>
      </div>
    </div>
  );
}
