import React from "react";
import { List, Popconfirm, Menu, Dropdown, message } from "antd";
import { useHistory } from "react-router-dom";
import { numberFormat } from "../../utils/utils";
import { useGlobalContext } from "../../context/GlobalContext";
import {
  useAddToCallMutation,
  Contact,
  useDeleteContactMutation,
  useBlockNumberV2Mutation,
  BlockType
} from "../../generated/graphql";

const handleCallStatus = (num: string | undefined | null) => {
  if (num) {
    const eventJson = {
      type: "calling",
      number: num
    };
    window.parent.postMessage(JSON.stringify(eventJson), "*");
  }
};

interface ListItemProps {
  handleEditContact: (
    id: string,
    numbers: any,
    first_name?: string | null,
    last_name?: string | null,
    company_name?: string | null
  ) => void;
  refetchContacts: () => void;
}

export const ListItem: React.FC<Contact & ListItemProps> = ({
  id,
  first_name,
  last_name,
  numbers,
  company_name,
  handleEditContact,
  refetchContacts
}) => {
  const { conn } = useGlobalContext();
  const [addtoCall] = useAddToCallMutation();
  const [deleteCOntact] = useDeleteContactMutation();

  const [blockNumber] = useBlockNumberV2Mutation();
  const history = useHistory();

  const handleDialCall = (n: string) => {
    if (conn) {
      addtoCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: n
        }
      });
      history.push(`/call/${n}`);
    } else {
      history.push(`/call/${n}?out=true`);
      handleCallStatus(n);
    }
  };

  const handleDeleteContact = (id: string) => {
    deleteCOntact({
      variables: {
        id
      }
    }).then(res => {
      if (res) {
        refetchContacts();
      }
    });
  };

  const handleBlockNumber = () => {
    const blockNumbers: string[] = [];
    numbers &&
      numbers.forEach(num => {
        if (num && num.formatted_number) {
          blockNumbers.push(num.formatted_number);
        }
      });

    const input = {
      is_block: true,
      id: "0",
      numbers: blockNumbers,
      type: BlockType.Block
    };

    blockNumber({
      variables: {
        input
      }
    }).then(res => {
      if (res) {
        refetchContacts();
      }
    }).catch(err => {
      if(err){
        err.graphQLErrors.map((errMsg: any) => {
          if (errMsg && errMsg.message) {
            message.error(errMsg.message);
          }
          return null
        });
      }
    })
  };

  const handleUnblockNumber = () => {
    const blockNumbers: string[] = [];
    numbers &&
      numbers.forEach(num => {
        if (num && num.formatted_number) {
          blockNumbers.push(num.formatted_number);
        }
      });

    const input = {
      is_block: false,
      id: "0",
      numbers: blockNumbers,
      type: BlockType.Block
    };

    blockNumber({
      variables: {
        input
      }
    }).then(res => {
      if (res) {
        refetchContacts();
      }
    });
  };

  const menu = (
    <Menu>
      {numbers && numbers.length !== 0 && (
        <Menu.Item
          key="0"
          onClick={() =>
            numbers.some(num => num?.blockNumber === 0)
              ? handleBlockNumber()
              : handleUnblockNumber()
          }
        >
          {numbers.some(num => num?.blockNumber === 0)
            ? "Block numbers"
            : "Unblock numbers"}
        </Menu.Item>
      )}      
      <Menu.Item key="1">
        <Popconfirm
          title="Are you sure delete this contact?"
          onConfirm={() => handleDeleteContact(id)}
          icon={null}
          okText="Yes"
          cancelText="No"
          okButtonProps={{
            style: { backgroundColor: "red", color: "#fff", border: "none" }
          }}
          placement="topRight"
        >
          Delete Number
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  return (
    <List.Item
      key={id}
      actions={[
        <div
          onClick={() => {
            const Numbers: { id: string; raw_number: string }[] = [];
            numbers &&
              numbers.map((num: any) =>
                Numbers.push({ id: num.id, raw_number: num.raw_number })
              );
            handleEditContact(id, Numbers, first_name, last_name, company_name);
          }}
        >
          <i
            className="icon-edit"
            style={{ color: "#4ac367", fontSize: 16, cursor: "pointer" }}
          />
        </div>,
        <div>
          <Dropdown overlay={menu} trigger={["click"]}>
            <i className="icon-email-trail" style={{ fontSize: 16 }} />
          </Dropdown>
        </div>

        // <div
        //   onClick={() => {
        //     if (numbers && numbers.length !== 0) {
        //       handleDialCall(numbers[0]?.formatted_number || "");
        //       handleCallStatus(numbers[0]?.formatted_number);
        //     }
        //   }}
        // >
        //   <i className="icon-call" style={{ color: "#4ac367", fontSize: 16 }} />
        // </div>
      ]}
    >
      <List.Item.Meta
        // avatar={
        //   <MyAvatar name={full_name || "HQ User"} src={avatar} size={38} />
        // }
        title={
          <>
            <div>
              {first_name || ""} {last_name || ""}
            </div>
            <div
              onClick={() => {
                if (numbers && numbers.length !== 0) {
                  handleDialCall(numbers[0]?.formatted_number || "");
                  handleCallStatus(numbers[0]?.formatted_number);
                }
              }}
              style={{ fontWeight: 400, cursor: "pointer" }}
            >
              {numbers && numbers.length !== 0 
                ? numberFormat(numbers[0]?.raw_number || "")
                : ""}
            </div>
          </>
        }
      />
    </List.Item>
  );
};
