import React, { FC } from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { List, Tooltip, Tag, Dropdown, Menu, message } from "antd";
// import { MyAvatar } from "../MyAvatar";
import { useHistory } from "react-router-dom";
import { useStickyState } from "../../hooks/useStickyState";
import { secondsToDhms, numberFormat } from "../../utils/utils";
import { LEAD_LINK } from "../../utils/constant";
import {
  useAddToCallMutation,
  BlockType,
  useBlockNumberV2Mutation
} from "../../generated/graphql";
import { useGlobalContext } from "../../context/GlobalContext";

dayjs.extend(relativeTime);
dayjs.extend(utc);

const handleCallStatus = (num: string) => {
  const eventJson = {
    type: "calling",
    number: num
  };
  window.parent.postMessage(JSON.stringify(eventJson), "*");
};

export const ListItem: React.FC<any> = ({
  timestamp,
  status,
  duration,
  caller,
  called,
  from,
  to,
  called_type,
  caller_type,
  is_forwarded,
  blockNumberTo,
  blockNumberFrom,
  recording,
  refetch,
  contactNumberFrom,
  contactNumberTo,
  leadNumberFrom,
  leadNumberTo
}) => {
  const { conn } = useGlobalContext();
  const [addtoCall] = useAddToCallMutation();
  const [blockNumber] = useBlockNumberV2Mutation();
  const history = useHistory();
  const [numbers] = useStickyState(null, "twilio_numbers");
  const name = numbers.includes(from)
    ? `${called?.first_name || ""} ${called?.last_name || ""}`
    : `${caller?.first_name || ""} ${caller?.last_name || ""}`;

  const handleDialCall = (n: string, name: string) => {
    if (conn) {
      addtoCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: n
        }
      });
      history.push(`/call/${n}`);
    } else {
      history.push(`/call/${n}?out=true&n=${name}`);
      handleCallStatus(n);
    }
  };

  const handleBlockNumber = (number: string, isBlock: number) => {
    const input = {
      is_block: !isBlock,
      id: "0",
      numbers: [number],
      type: BlockType.Block
    };
    
    blockNumber({
      variables: {
        input
      }
    }).then(res => {
      if (res) {
        refetch();
      }
    }).catch(err => {
      if(err){
        err.graphQLErrors.map((errMsg: any) => {
          if (errMsg && errMsg.message) {
            message.error(errMsg.message);
          }
          return null
        });
      }
    })
  };

  const menu = (
    <Menu>
      <Menu.Item key="0">
        {numbers.includes(from) ? (
          <div onClick={() => handleBlockNumber(to, blockNumberTo)}>
            {blockNumberTo ? "Unblock" : "Block"}
          </div>
        ) : (
          <div onClick={() => handleBlockNumber(from, blockNumberFrom)}>
            {blockNumberFrom ? "Unblock" : "Block"}
          </div>
        )}
      </Menu.Item>
    </Menu>
  );

  const handleAudio = (id: string) => {
    const allAudio = document.querySelectorAll('audio');
    allAudio.forEach(item => {if(item.id !== id){
      item.pause()
    }})
   };

  return (
    <List.Item
      actions={[
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end"
          }}
        >
          <div
            onClick={() =>
              handleDialCall(numbers.includes(from) ? to : from, name)
            }
          >
            <i
              className="icon-call"
              style={{ color: "#4ac367", fontSize: 16, cursor: "pointer" }}
            />
          </div>
          <p style={{ marginRight: "4px" }}>{secondsToDhms(duration)}</p>
        </div>,
        <div style={{ cursor: "pointer" }}>
          <Dropdown
            overlay={menu}
            trigger={["click"]}
            getPopupContainer={trigger => trigger}
          >
            <i className="icon-email-trail" style={{ fontSize: 16 }} />
          </Dropdown>
        </div>
      ]}
    >
      <List.Item.Meta
        // avatar={<MyAvatar name={name !== ' ' ? name : 'HQ User'} size={38} />}
        title={
          <>
            <div
              style={
                !numbers.includes(from) && status === "no-answer"
                  ? { color: "red", cursor: "pointer", marginRight: "4px" }
                  : { cursor: "pointer", marginRight: "4px" }
              }
              onClick={() => {
                // handleCallStatus(numbers.includes(from) ? to : from);
                if (numbers.includes(from)) {
                  if (called_type === "LEAD") {
                    window.open(`${LEAD_LINK}${called.id}`);
                  }
                } else {
                  if (caller_type === "LEAD") {
                    window.open(`${LEAD_LINK}${caller.id}`);
                  }
                }
              }}
            >
              {numbers.includes(from) ? (
                <>
                  {contactNumberTo && contactNumberTo.contact
                    ? `${contactNumberTo.contact?.first_name || ''} ${contactNumberTo.contact?.last_name || ''}`
                    : <span>{leadNumberTo && leadNumberTo.lead ? `${leadNumberTo.lead?.first_name || ''} ${leadNumberTo.lead?.last_name || ''}` :  name}</span>}
                </>
              ) : (
                <>
                  {contactNumberFrom && contactNumberFrom.contact
                    ? `${contactNumberFrom.contact?.first_name || ''} ${contactNumberFrom.contact?.last_name || ''}`
                    : <span>{leadNumberFrom && leadNumberFrom.lead ? `${leadNumberFrom.lead?.first_name || ''} ${leadNumberFrom.lead?.last_name || ''}` :  name}</span>}
                </>
              )}
              {is_forwarded ? <Tag color="blue">forwarded</Tag> : ""}
            </div>

            <div
              style={
                !numbers.includes(from) && status === "no-answer"
                  ? {
                      color: "red",
                      cursor: "pointer",
                      marginRight: "4px",
                      fontWeight: 400
                    }
                  : { cursor: "pointer", marginRight: "4px", fontWeight: 400 }
              }
              onClick={() =>
                handleDialCall(numbers.includes(from) ? to : from, name)
              }
            >
              {numberFormat(
                numbers.includes(from)
                  ? to.replace(/\+1/g, ``)
                  : from.replace(/\+1/g, ``)
              )}
            </div>
          </>
        }
        description={
          <div>
            <div
              style={
                !numbers.includes(from) && status === "no-answer"
                  ? { color: "red" }
                  : {}
              }
            >
              <i
                className={
                  numbers.includes(from)
                    ? `icon-mail-sent`
                    : `${
                        status === "no-answer"
                          ? "icon-missed-call"
                          : "icon-mail-recieve"
                      }`
                }
              />{" "}
              <Tooltip
                title={dayjs
                  .utc(timestamp)
                  .local()
                  .format("MM/DD/YYYY hh:mm:ss a")}
              >
                <span style={{ marginRight: "4px", cursor: 'pointer' }}>
                  {dayjs.utc(timestamp).fromNow()}
                </span>
              </Tooltip>
              <Tooltip title={status}>
                {" "}
                <i className="icon-client-information" style={{cursor: 'pointer'}} />{" "}
              </Tooltip>
            </div>
            {recording && (
              <audio id={recording.id} onPlay={() => handleAudio(recording.id)} controls style={{ width: "100%" }}>
                <source src={recording.url} type="audio/mpeg"></source>
              </audio>
            )}
          </div>
        }
      />
    </List.Item>
  );
};
