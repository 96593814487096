import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyQuery } from "@apollo/client";
// import { useStickyState } from "../hooks/useStickyState";
import { MyIcon } from "../components/MyIcon";
import { useGlobalContext } from "../context/GlobalContext";
// import { useLeadDetail } from "../hooks/useLeadDetail";
import { useQuery } from "../hooks/useQuery";
import { Base } from "../layouts/Base";
// import Timer from "../components/timer";
import NumberPad from "../components/NumberPad";
import { KConnectionManager } from "../utils/KConnectionManager";
import {
  useHoldCallMutation,
  useAddToCallMutation,
  ConferenceParticipant,
  useColdTransferCallMutation,
  TransferToType,
  DirectionType
} from "../generated/graphql";
import { Keypad } from "../components/Keypad";
import { GET_CALL_DETAILS } from "../query";
import {
  ParticipantDetails,
  LoadingParticipantDetail
} from "../components/ParticipantDetails";
import { useStickyState } from "../hooks/useStickyState";
import CallTransfer from "../components/CallTransfer";
import Timer from "../components/timer";

interface CallProps {
  number: string;
}

interface participantDetail {
  added_by: null | string;
  coaching: boolean;
  hold: boolean;
  muted: boolean;
  number: string;
  sid: string;
  status: string;
}

export const Call: React.FC<CallProps> = ({ number }) => {
  const { activeNumber, conn } = useGlobalContext();
  const K = KConnectionManager.getInstance();
  const device = K.getDevice(activeNumber);
  const syncClient = K.getClient(activeNumber);
  const query = useQuery();
  const history = useHistory();
  const [numbers] = useStickyState(null, "twilio_numbers");

  const [ParticipanDetails, setParticipanDetails] = useState<
    Array<participantDetail>
  >([]);

  const [PollEnable, setPollEnable] = useState<boolean>(true);
  const [numPad, setNumPad] = useState<boolean>(false);

  // const [isCallDetails, setIsCallDetails] = useState<boolean>(false);

  const [callDetails, { data, loading, refetch }] = useLazyQuery(
    GET_CALL_DETAILS,
    {
      fetchPolicy: `no-cache`,
      pollInterval: PollEnable ? 5000 : 0,
      onCompleted: data => {
        if (data.getCallDetail && data.getCallDetail.conference) {
          setPollEnable(false);
        } 
      }
    }
  );
  // eslint-disable-next-line
  // const [_, setRecent] = useStickyState([], "C_RECENT");
  const outbound = query.get("out") || false;
  // const { data, loading } = useLeadDetail(number);
  // const name = `${data?.getLeadFromNumber?.first_name || "HQ"} ${
  //   data?.getLeadFromNumber?.last_name || "User"
  // }`;

  useEffect(() => {
    if (outbound) {
      if (device) {
        device.connect({ To: number });
      }
    }
  }, [device, number, outbound]);

  useEffect(() => {
    if (conn) {
      callDetails({
        variables: {
          call_sid: conn.parameters.CallSid
        }
      });
    }
  }, [callDetails, conn]);

  // const handleCallDetailsView = () => {
  //   setIsCallDetails(!isCallDetails);
  // };

  useEffect(() => {
    if (syncClient && data && data.getCallDetail) {
      if (data.getCallDetail.conference) {
        // console.log("constId is: ", data.getCallDetail.conference.id);
        syncClient
          .map(`CONF_${data.getCallDetail.conference.id}`)
          .then(function(map: any) {
            const participanData: Array<participantDetail> = [];
            map.getItems().then(function(item: any) {
              item.items.map((item: any) => {
                if (item.descriptor.data) {
                  participanData.push(item.descriptor.data);
                }
                return null;
              });
              setParticipanDetails(participanData);
            });
            map.on("itemAdded", function({ item }: any) {
              // setParticipanDetails(participants => [
              //   ...participants,
              //   item.data
              // ]);
              if (refetch) {
                refetch();
              }
              // console.log("item added key", item.key, item.data);
            });
            map.on("itemUpdated", function({ item }: any) {
              setParticipanDetails(arr => {
                const participanData: Array<participantDetail> = [];
                arr.map(participant => {
                  if (participant.number === item.data.number) {
                    participanData.push(item.data);
                  } else {
                    participanData.push(participant);
                  }
                  return null;
                });
                return participanData;
              });
              // console.log("item updated key", item.key, item.data);
            });
          });
      }
    }
  }, [syncClient, data, refetch]);

  const updateHoldStatus = (sid: string, hold: boolean) => {
    setParticipanDetails(arr => {
      const participanData: Array<participantDetail> = [];
      arr.map(participant => {
        if (participant.sid === sid) {
          const UpdatedParticipant = {
            added_by: participant.added_by,
            coaching: participant.coaching,
            hold,
            muted: participant.muted,
            number: participant.number,
            sid: participant.sid,
            status: participant.status
          };

          participanData.push(UpdatedParticipant);
        } else {
          participanData.push(participant);
        }
        return null;
      });
      return participanData;
    });
  };

  const hangUp = () => {
    if (device) {
      device.disconnectAll();
      history.push("/");
      const eventInfo = {
        type: "close"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  function handleVisibleNumpad() {
    setNumPad(!numPad);
  }

  return (
    <Base onBack={() => history.goBack()}>
      {/* <div style={{ padding: "16px" }}>
        {isCallDetails ? (
          <div
            style={{ display: "flex", justifyContent: "flex-end" }}
            onClick={handleCallDetailsView}
          >
            <i className="icon-cancel" />
          </div>
        ) : (
          data &&
          !loading &&
          data.getCallDetail.conference &&
          data.getCallDetail.conference.participant.length >= 2 && (
            <div style={{ color: "#0055FD" }} onClick={handleCallDetailsView}>
              <i className="icon-lead-relationship" />
              <span style={{ marginLeft: "4px" }}>Manage People</span>
            </div>
          )
        )}
      </div> */}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div style={{ width: 30, float: "right", margin: "0 10px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            version="1.1"
            viewBox="0 0 290.262 290.262"
            xmlSpace="preserve"
          >
            <path
              fill="#36f"
              d="M278.743 33.612H11.519C5.157 33.612 0 38.77 0 45.131v200c0 6.361 5.157 11.519 11.519 11.519h267.225c6.361 0 11.519-5.157 11.519-11.519v-200c-.001-6.361-5.159-11.519-11.52-11.519zm-174.68 156.519l9.989-34.973H76.771l-9.993 34.973H43.896l25.709-90h22.888L81.914 137.16h37.279l10.579-37.029h22.89l-25.715 90h-22.884zm140.267-45c-7.604 26.613-32.658 45-61.585 45h-37.796l16.986-59.461h22.882l-11.768 41.199h16.327c14.014 0 27.128-10.797 31.644-26.607 4.555-15.945-2.865-26.871-17.393-26.871h-38.188l5.217-18.26h38.7c28.285 0 42.609 18.26 34.974 45z"
            ></path>
          </svg>
        </div>
      </div>
      <br />
      {/* {console.log("ParticipanDetails", ParticipanDetails)} */}
      <div>
        {!loading && ParticipanDetails.length >= 2 ? (
          <div>
            <div
              style={{
                overflowX: "hidden",
                overflowY: "auto",
                maxHeight: "calc(100vh - 264px)"
              }}
            >
              <Row gutter={[8, 8]} justify="center">
                {ParticipanDetails.length === 2
                  ? ParticipanDetails.map(
                      (participant: participantDetail) =>
                        !numbers.includes(participant.number) && (
                          <Col span={12}>
                            <ParticipantDetails
                              name={query.get("n") || ""}
                              Participant={participant}
                              loading={loading}
                              allParticipants={
                                data.getCallDetail.conference.participant
                              }
                              updateHoldStatus={updateHoldStatus}
                            />
                          </Col>
                        )
                    )
                  : ParticipanDetails.map(
                      (participant: participantDetail) =>
                        !numbers.includes(participant.number) &&
                        participant.status !== "participant-leave" && (
                          data.getCallDetail.is_forwarded && participant.status === "participant-init" ? null 
                          : <Col span={12}>
                            <ParticipantDetails
                              name={query.get("n") || ""}
                              Participant={participant}
                              loading={loading}
                              allParticipants={
                                data.getCallDetail.conference.participant
                              }
                              updateHoldStatus={updateHoldStatus}
                            />
                          </Col>
                        )
                    )}
              </Row>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Timer time={K.GetTimer()} />
            </div>
          </div>
        ) : (
          <div>
            <LoadingParticipantDetail />
          </div>
        )}
        {!loading && ParticipanDetails.length !== 0 ? (
          <div
            style={{
              position: "absolute",
              bottom: 25,
              left: "50%",
              transform: " translate(-50%, 0%)",
              width: "100%"
            }}
          >
            <Actions callDetails={ParticipanDetails} />
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              bottom: 25,
              left: "50%",
              transform: " translate(-50%, 0%)",
              width: "100%"
            }}
          >
           <NumberPad visible={numPad} hideNumberPad={() => setNumPad(false)} />
            <Row
              gutter={[24, 24]}
              justify="center"
              style={{ marginTop: 30, padding: "0 50px 0 50px" }}
            >
              <Col span={8}>
                <div onClick={hangUp} className="dflex-center">
                  <MyIcon
                    icon="icon-fill-call-end"
                    bgColor="red"
                    color="#fff"
                    size={48}
                  />
                </div>
              </Col>
              <Col span={8} className="dflex-center">
                <div onClick={handleVisibleNumpad}>
                  <MyIcon
                    bgColor={numPad ? "#110c11" : "gainsboro"}
                    color={numPad ? "gainsboro" : "#110c11"}
                    icon="icon-new-dialer"
                    size={48}
                  />
                </div>
              </Col>
            </Row>
          </div>
        )}
        {/* <div
          style={{
            fontSize: "16px",
            display: "flex",
            justifyContent: "center"
          }}
        >
          <Timer />
        </div> */}
      </div>
    </Base>
  );
};

interface ActionProps {
  callDetails: Array<ConferenceParticipant>;
}

export const Actions: React.FC<ActionProps> = ({ callDetails }) => {
  const [mute, setMute] = useState<boolean>(false);
  const [numPad, setNumPad] = useState<boolean>(false);
  const [keypadVisible, setKeypadVisible] = useState<boolean>(false);
  const [transferVisible, setTransferVisible] = useState<boolean>(false);
  const [holdCall] = useHoldCallMutation();
  const [addtoCall] = useAddToCallMutation();
  const [coldTransfer] = useColdTransferCallMutation();
  const { conn, activeNumber } = useGlobalContext();
  const [numbers] = useStickyState(null, "twilio_numbers");

  const K = KConnectionManager.getInstance();
  const device = K.getDevice(activeNumber);

  const history = useHistory();

  const myObj = callDetails.filter(participant =>
    numbers.includes(participant.number)
  );

  const participantNumbers = callDetails.filter(
    participant => !numbers.includes(participant.number)
  );

  const joinedParticipantNumbers = callDetails.filter(
    participant =>
      !numbers.includes(participant.number) &&
      participant.status === "participant-join"
  );

  const joinedMyObj = callDetails.filter(
    participant =>
      numbers.includes(participant.number) &&
      participant.status === "participant-join"
  );

  const initParticipantNumbers = callDetails.filter(
    participant =>
      !numbers.includes(participant.number) &&
      participant.status === "participant-init"
  );

  // useEffect(() => {
  //   if (conn) {
  //     window
  //       .fetch(
  //         `https://c33fe152bf7f.ngrok.io/com/test/${conn.parameters.CallSid}`
  //       )
  //       .then(response => response.json())
  //       .then(data => {
  //         console.log("sid reponse", data);
  //         setConferenceId(data.conference_id);
  //       })
  //       .catch(error => {
  //         console.error("Error:", error);
  //       });
  //   }
  // });

  function handleMute() {
    if (conn) {
      setMute(m => {
        conn.mute(!m);
        return !m;
      });
    }
  }

  function handleVisibleNumpad() {
    setNumPad(!numPad);
  }

  const handleCallHold = (sid: string | null | undefined) => {
    if (conn && numbers && sid) {
      // const holdSids: string[] = [];
      // callDetails.filter(participant =>
      //   !numbers.includes(participant.number) && participant.sid
      //     ? holdSids.push(participant.sid)
      //     : null
      // );

      holdCall({
        variables: {
          call_sid: [sid],
          hold: !myObj[0].hold
        }
      });
    }
  };

  const handleVisibleKeypad = () => {
    setKeypadVisible(!keypadVisible);
  };

  const handleUpdateCall = (num: string) => {
    if (conn && numbers) {
      setKeypadVisible(false);

      const holdSids: string[] = [];
      callDetails.filter(participant =>
        !numbers.includes(participant.number) && participant.sid
          ? holdSids.push(participant.sid)
          : null
      );

      holdCall({
        variables: {
          call_sid: holdSids,
          hold: true
        }
      }).then(({ data }) => {
        if (data) {
          addtoCall({
            variables: {
              call_sid: conn.parameters.CallSid,
              to: num
            }
          }).then(res => {});
        }
      });
    }
  };

  const hangUp = () => {
    if (device) {
      device.disconnectAll();
      history.push("/");
      const eventInfo = {
        type: "close"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  const hideCallTransfer = () => {
    setTransferVisible(false);
  };

  const coldTransferCall = (to: string, type: TransferToType) => {
    if (conn) {
      coldTransfer({
        variables: {
          call_sid: conn.parameters.CallSid,
          to,
          type,
          dir: DirectionType.Outbound
        }
      });
    }
  };

  return (
    <>
      <Keypad
        onCall={handleUpdateCall}
        visible={keypadVisible}
        close={setKeypadVisible}
        hideDialer={() => setKeypadVisible(false)}
      />
      <NumberPad visible={numPad} hideNumberPad={() => setNumPad(false)} />
      <CallTransfer
        visible={transferVisible}
        hideCallTransfer={hideCallTransfer}
        coldTransferCall={coldTransferCall}
        participants={participantNumbers}
      />
      <Row
        gutter={[24, 24]}
        justify="center"
        style={{ marginTop: 30, padding: "0 50px 0 50px" }}
      >
        <Col span={8}>
          <div onClick={handleMute} className="dflex-center">
            <MyIcon
              bgColor={mute ? "#110c11" : "gainsboro"}
              color={mute ? "gainsboro" : "#110c11"}
              icon={mute ? "icon-fill-mic-off" : "icon-fill-mic-on"}
              size={48}
            />
          </div>
        </Col>
        <Col span={8} className="dflex-center">
          <div onClick={handleVisibleNumpad}>
            <MyIcon
              bgColor={numPad ? "#110c11" : "gainsboro"}
              color={numPad ? "gainsboro" : "#110c11"}
              icon="icon-new-dialer"
              size={48}
            />
          </div>
        </Col>
        {myObj.length !== 0 && (
          <Col span={8}>
            <div
              onClick={() => handleCallHold(myObj[0].sid)}
              className="dflex-center"
            >
              <MyIcon
                bgColor={myObj[0].hold ? "#110c11" : "gainsboro"}
                color={myObj[0].hold ? "gainsboro" : "#110c11"}
                icon={myObj[0].hold ? "icon-fill-call-hold" : "icon-call-hold"}
                size={48}
              />
            </div>
          </Col>
        )}
        {callDetails.length >= 2 && (
          <Col span={8}>
            <div onClick={handleVisibleKeypad} className="dflex-center">
              <MyIcon
                bgColor="gainsboro"
                color="#110c11"
                icon="icon-create-task"
                size={48}
              />
            </div>
          </Col>
        )}
        {callDetails.length >= 2 &&
          joinedMyObj.length !== 0 &&
          joinedParticipantNumbers.length === 1 &&
          initParticipantNumbers.length === 0 && (
            <Col span={8}>
              <div
                className="dflex-center"
                onClick={() => setTransferVisible(true)}
              >
                <MyIcon
                  bgColor="gainsboro"
                  color="#110c11"
                  icon="icon-transfer-lead"
                  size={48}
                />
              </div>
            </Col>
          )}
        <Col span={8}>
          <div onClick={hangUp} className="dflex-center">
            <MyIcon
              icon="icon-fill-call-end"
              bgColor="red"
              color="#fff"
              size={48}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};
