import React, { CSSProperties } from "react";
import { Avatar } from "antd";

interface MyAvatarProps {
  src?: string | null | undefined;
  name: string;
  size?: number | "small" | "large" | "default" | undefined;
  style?: CSSProperties;
}

export const MyAvatar: React.FC<MyAvatarProps> = ({
  src,
  name,
  size,
  style,
}) => {
  name = capitalizeFirstLetter(name);
  return src ? (
    <Avatar src={src} size={size} style={style} />
  ) : (
    <Avatar size={size} style={style}>
      {name[0]}
    </Avatar>
  );
};

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
