import { useEffect, useState } from "react";
import { GET_CONTACT } from "../query";
import { useLazyQuery } from "@apollo/client";

export function useGetContact(first: number, page: number | undefined) {
  const [contactData, setContactData] = useState<any>([]);
  const [getContact, { data, loading, refetch, fetchMore }] = useLazyQuery(
    GET_CONTACT,
    {
      fetchPolicy: `network-only`
    }
  );

  useEffect(() => {
    getContact({
      variables: {
        first,
        page
      }
    });
  }, [first, page, getContact]);

  useEffect(() => {
    if (data) {
      setContactData(data);
    }
  }, [data]);

  const handleFetchMore = (currentPage: number) => {
    // console.log("paginatorInfo", currentPage);
    if (fetchMore && data) {
      fetchMore({
        variables: {
          first,
          page: currentPage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const data = Object.assign({}, fetchMoreResult);
          data.getContact.data = [
            ...contactData.getContact.data,
            ...fetchMoreResult.getContact.data
          ];
          setContactData(data);
        }
      });
    }
  };

  return {
    contactData,
    loading,
    fetchMore,
    refetch,
    handleFetchMore
  };
}
