import { useEffect } from "react";
import { useGetLeadFromNumberLazyQuery } from "../generated/graphql";

export function useLeadDetail(number: string | undefined) {
  const [getLead, { data, loading }] = useGetLeadFromNumberLazyQuery();

  useEffect(() => {
    if (number) {
      getLead({
        variables: {
          q: number,
        },
      });
    }
  }, [number, getLead]);
  return { data, loading };
}
