import React from "react";
import { List, Skeleton, Button } from "antd";
import { numberFormat } from "../../utils/utils";
import { TransferToType } from "../../generated/graphql";

interface ListItemProps {
  loading: boolean;
  first_name?: string | null | undefined;
  last_name?: string | null | undefined;
  phone_no?: string | null | undefined;
  avatar?: string | null | undefined;
  numbers?: any[] | null | undefined;
  id?: string;
  coldTransferCall: (to: string, type: TransferToType) => void;
}

export const AgentListItem: React.FC<ListItemProps> = ({
  id,
  loading,
  first_name,
  last_name,
  numbers,
  coldTransferCall
}) => {
  const name = `${first_name || ""} ${last_name || ""}`;

  return (
    <List.Item
      key={id}
      actions={[
        <div>
          <Button
            type="primary"
            shape="circle"
            onClick={() =>
              numbers &&
              coldTransferCall(numbers[0].phone_number, TransferToType.Number)
            }
          >
            <i className="icon-transfer-lead" />
          </Button>
        </div>
      ]}
    >
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          title={
            <>
              <div>{name}</div>
              <div style={{ fontWeight: 400 }}>
                {numbers && numbers.length !== 0
                  ? numberFormat(numbers[0].phone_number.replace(/\+1/g, ``))
                  : ""}
              </div>
            </>
          }
        />
      </Skeleton>
    </List.Item>
  );
};
