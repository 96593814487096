import React, { useEffect } from "react";
import { useAdmininfoLazyQuery } from "../generated/graphql";
import { useGlobalContext } from "../context/GlobalContext";

const SetUserAuthority: React.FC = () => {
  const [adminInfo, { data: adminData, refetch }] = useAdmininfoLazyQuery();
  const { setAuthority } = useGlobalContext();

  useEffect(() => {
    if (adminInfo) {
      adminInfo();
    }
  }, [adminInfo]);

  useEffect(() => {
    const setAuthorities = (adminData: any) => {
      const permissions: string[] = [];
      adminData?.admininfo?.roles.map((role: any) => {
        role?.permissions.map((permission: any) =>
          permissions.push(permission.name)
        );
      });

      setAuthority(permissions);
    };
    if (adminData) {      
      setAuthorities(adminData);
      localStorage.setItem("AdminInfo", JSON.stringify(adminData));
    }
  }, [adminData, setAuthority]);

  useEffect(() => {
    const updateAuth = (data: any) => {
      if (data.detail.fetchAuthority && refetch) {
        refetch();
      }
    };
    window.addEventListener("updateAuth", updateAuth, false);
    // return () => {
    //   window.removeEventListener("updateAuth", updateAuth, false);
    // };
  });

  return null;
};

export default SetUserAuthority;
