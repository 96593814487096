import { GET_VM } from "../query";
import { useGetVmQuery } from "../generated/graphql";

export function UseGetVM(first: number, page: number | undefined) {
  const { data, loading, refetch, fetchMore } = useGetVmQuery({
    query: GET_VM,
    variables: { first, page }
  });

  const handleFetchMore = (currentPage: number) => {
    if (fetchMore && data) {
      fetchMore({
        query: GET_VM,
        variables: {
          first,
          page: currentPage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const data = Object.assign({}, fetchMoreResult);
          data.getVM.data = [...prev.getVM.data, ...fetchMoreResult.getVM.data];
          return { ...data };
        }
      });
    }
  };

  return {
    data,
    loading,
    fetchMore,
    refetch,
    handleFetchMore
  };
}
