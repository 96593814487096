import React from "react";
import { Typography, Skeleton, Row, Col } from "antd";
import { MyAvatar } from "../components/MyAvatar";
import { numberFormat } from "../utils/utils";
import { MyIcon } from "./MyIcon";
import {
  useHoldCallMutation,
  ConferenceParticipant,
  useRemoveFromCallMutation
} from "../generated/graphql";
import { useGlobalContext } from "../context/GlobalContext";
import { useLeadDetail } from "../hooks/useLeadDetail";
// import { KConnectionManager } from "../utils/KConnectionManager";

const { Title, Text } = Typography;

interface ParticipantDetailProps {
  name?: string;
  loading: boolean;
  Participant: ConferenceParticipant;
  allParticipants: Array<ConferenceParticipant>;
  updateHoldStatus: (sid: string, hold: boolean) => void;
}

export const ParticipantDetails: React.FC<ParticipantDetailProps> = ({
  name = "HQ User",
  Participant: { number, sid, hold, added_by, status },
  allParticipants,
  updateHoldStatus
}) => {
  // const [numbers] = useStickyState(null, "twilio_numbers");
  // const myObj = allParticipants.filter(participant =>
  //   numbers.includes(participant.number)
  // );
  const { data, loading } = useLeadDetail(number);
  // const K = KConnectionManager.getInstance();

  return (
    <div style={{ margin: 10, paddingTop: 15 }}>
      {loading ? (
        <>
          <LoadingParticipantDetail />
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <MyAvatar
            src={""}
            name={data?.getLeadFromNumber?.first_name || ""}
            size={64}
            style={{ fontSize: 50 }}
          />
          <br />
          <br />
          <Title level={4}>
            {data?.getLeadFromNumber
              ? `${data.getLeadFromNumber?.first_name || ""} ${data
                  .getLeadFromNumber?.last_name || ""}`
              : ""}
          </Title>
          <Text style={{ textAlign: "center" }} strong>
            {number ? numberFormat(number.replace(/\+1/g, ``)) : ""}
          </Text>
          <br />
          {/* {((myObj.length !== 0 &&
            status === "participant-join" &&
            added_by === myObj[0].sid) ||
            allParticipants.length === 2) && ( */}
          <Actions
            sid={sid}
            hold={hold}
            added_by={added_by}
            updateHoldStatus={updateHoldStatus}
            showCallEnd={allParticipants.length > 2}
            status={status}
            // mySid={myObj[0].sid}
          />
          {/* )} */}

          {/* {status === "participant-join" && (
            <div style={{marginTop: '8px'}}>
              <Timer time={K.GetTimer()} />
            </div>
          )} */}
        </div>
      )}
    </div>
  );
};

export const LoadingParticipantDetail = () => (
  <div style={{ textAlign: "center" }}>
    <Skeleton.Avatar active={true} size={96} />
    <br />
    <br />
    <Skeleton.Input style={{ width: 200 }} active={true} size="small" />
    <br />
    <br />
    <Skeleton.Input style={{ width: 200 }} active={true} size="small" />
    <br />
    <br />
    {/* <Text strong>{number}</Text> */}
  </div>
);

interface ActionProps {
  sid?: string | null;
  hold: boolean;
  added_by?: string | null;
  updateHoldStatus: (sid: string, hold: boolean) => void;
  showCallEnd: boolean;
  status: string;
}

export const Actions: React.FC<ActionProps> = ({
  sid,
  hold,
  added_by,
  updateHoldStatus,
  showCallEnd,
  status
}) => {
  const [holdCall] = useHoldCallMutation();
  const [removeFromCall] = useRemoveFromCallMutation();
  const { conn } = useGlobalContext();

  const handleCallHold = () => {
    if (conn && sid) {
      updateHoldStatus(sid, !hold);
      holdCall({
        variables: {
          call_sid: [sid],
          hold: !hold
        }
      });
    }
  };

  const handleCallHangup = () => {
    if (conn && sid) {
      removeFromCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          participant_sid: sid
        }
      });
    }
  };

  return (
    <>
      <Row gutter={[16, 24]} justify="center" style={{ marginTop: 8 }}>
        <Col>
          {status === "participant-join" && (
            <div onClick={handleCallHold}>
              <MyIcon
                bgColor={hold ? "#110c11" : "gainsboro"}
                color={hold ? "gainsboro" : "#110c11"}
                icon={hold ? "icon-fill-call-hold" : "icon-call-hold"}
                size={32}
              />
            </div>
          )}
        </Col>
        {showCallEnd && (
          <Col>
            <div
              onClick={handleCallHangup}
              // style={added_by === mySid ? {} : { cursor: "not-allowed" }}
            >
              <MyIcon
                icon="icon-fill-call-end"
                bgColor="red"
                color="#fff"
                size={32}
              />
            </div>
          </Col>
        )}
      </Row>
    </>
  );
};
