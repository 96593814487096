import React, { useState, useEffect } from "react";

interface timerProps {
  time: number;
}

const Timer: React.FC<timerProps> = ({ time }) => {
  const [seconds, setSeconds] = useState(time);
  useEffect(() => {
    let interval = setInterval(() => {
      setSeconds(seconds => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, [seconds]);
  return <span>{new Date(seconds * 1000).toISOString().substr(11, 8)}</span>;
};
export default Timer;
