import { Device } from "twilio-client";
import SyncClient from "twilio-sync";

export class KConnectionManager {
  private static instance: KConnectionManager;
  private devices: Map<string, Device> = new Map();
  private clients: Map<string, SyncClient> = new Map();
  private textclient: Map<string, SyncClient> = new Map();
  private callTime: number = 0;
  private interval: ReturnType<typeof setTimeout> = setInterval(() => {}, 1000);

  public static getInstance(): KConnectionManager {
    if (!KConnectionManager.instance) {
      KConnectionManager.instance = new KConnectionManager();
    }
    return KConnectionManager.instance;
  }

  public setDevice(
    number: string,
    token: string,
    debug: boolean = false
  ): Device | undefined {
    const device = new Device();
    device.setup(token, { debug: false });
    this.devices.set(number, device);
    return this.getDevice(number);
  }

  public StartTimer = () => {
    this.interval = setInterval(() => {
      this.callTime += 1;
    }, 1000);
  };

  public ClearTimer = () => {
    // () => clearInterval(interval);
    this.callTime = 0;
    clearInterval(this.interval);
  };

  public GetTimer = () => {
    return this.callTime;
  };

  public setClient(
    number: string,
    token: string,
    debug: boolean = false
  ): SyncClient | undefined {
    const client = new SyncClient(token, { logLevel: "info" });
    this.clients.set(number, client);
    return this.getClient(number);
  }

  public setTextClient(
    number: string,
    token: string,
    debug: boolean = false
  ): SyncClient | undefined {
    const client = new SyncClient(token, { logLevel: "info" });
    this.textclient.set(number, client);
    return this.getTextClient(number);
  }

  public getDevice(number: string): Device | undefined {
    return this.devices.get(number);
  }

  public getClient(number: string): SyncClient | undefined {
    return this.clients.get(number);
  }

  public getTextClient(number: string): SyncClient | undefined {
    return this.textclient.get(number);
  }

  public destoryDevice(number: string): void {
    this.devices.delete(number);
  }

  public debug(): IterableIterator<[string, Device]> {
    return this.devices.entries();
  }
}
