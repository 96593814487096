import React from "react";
import { List, Avatar, Typography } from "antd";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { Conversation } from "../../../generated/graphql";
import { useStickyState } from "../../../hooks/useStickyState";

interface ConversationItemProps {
  data: Conversation;
  loading: boolean;
}

const { Paragraph } = Typography;

export const ConversationItem: React.FC<ConversationItemProps> = ({
  loading,
  data,
  data: { id, is_group, name, latest_message, conversation_has_members }
}) => {
  const history = useHistory();
  const [adminInfo] = useStickyState(null, "AdminInfo");

  const difference = latest_message
    ? dayjs().diff(dayjs(latest_message.created_at), "day")
    : null;

  const activeMember = data.members_details
    ? data.members_details.filter((m: any) => m.meta.status === true)
    : [];
  const imActive = data.members_details
    ? data.members_details.filter(
        (m: any) =>
          m.meta.status === true && m.member.id === adminInfo.admininfo.id
      )
    : [];

  const openConversation = () => {
    history.push(`/text/${id}`);
  };

  return (
    <>
      {is_group ? (
        <List.Item
          onClick={openConversation}
          className={activeMember.length >= 3 ? "grpAvatar pointer" : "pointer"}
        >
          <List.Item.Meta
            avatar={
              // eslint-disable-next-line no-nested-ternary
              activeMember.length < 4 ? (
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: "#011689"
                  }}
                  className="iconCenter"
                >
                  {activeMember.length === 1 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <div style={{ display: "flex" }}>
                        <Avatar
                          icon={<i className="icon-add-user" />}
                          className="bgTransparent  font-lg"
                        />
                      </div>
                    </div>
                  )}
                  {activeMember.length === 2 && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <Avatar
                          size="large"
                          icon={<i className="icon-add-user" />}
                          className="bgTransparent font-lg"
                        />
                        <Avatar
                          size="large"
                          icon={<i className="icon-add-user" />}
                          className="bgTransparent font-lg"
                        />
                      </div>
                    </div>
                  )}
                  {activeMember.length === 3 && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Avatar
                          size="large"
                          icon={<i className="icon-add-user" />}
                          className="bgTransparent font-lg"
                        />
                      </div>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <Avatar
                          size="large"
                          icon={<i className="icon-add-user" />}
                          className="bgTransparent font-lg"
                        />
                        <Avatar
                          size="large"
                          icon={<i className="icon-add-user" />}
                          className="bgTransparent font-lg"
                        />
                      </div>
                    </div>
                  )}
                </Avatar>
              ) : activeMember.length > 4 ? (
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: "#011689"
                  }}
                  className="iconCenter"
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className="bgTransparent font-lg"
                      />
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className="bgTransparent font-lg"
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className={"bgTransparent font-lg"}
                      />
                      <div className={"grpRemainCount"}>
                        <Avatar className={"bgTransparent font-lg"}>
                          {activeMember.length - 3}
                        </Avatar>
                      </div>
                    </div>
                  </div>
                </Avatar>
              ) : (
                <Avatar
                  size="large"
                  style={{
                    backgroundColor: "#011689"
                  }}
                  className="iconCenter"
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className={"bgTransparent font-lg"}
                      />
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className={"bgTransparent font-lg"}
                      />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className={"bgTransparent font-lg"}
                      />
                      <Avatar
                        size="large"
                        icon={<i className="icon-add-user" />}
                        className={"bgTransparent font-lg"}
                      />
                    </div>
                  </div>
                </Avatar>
              )
            }
            title={
              <div className="SpaceBetween nameEllipsis fontWeightRegular">
                <Paragraph
                  ellipsis
                  style={
                    imActive.length === 0
                      ? conversation_has_members &&
                        conversation_has_members.message &&
                        conversation_has_members.message.read_by_me
                        ? { color: "#595959", width: "75%" }
                        : { color: "#000", fontWeight: 500, width: "75%" }
                      : latest_message && latest_message.read_by_me
                      ? { color: "#595959", width: "75%" }
                      : { color: "#000", fontWeight: 500, width: "75%" }
                  }
                  className="noMargin"
                >
                  {name
                    ? name
                    : activeMember.map((detail: any, i: number) =>
                        i + 1 < activeMember.length
                          ? `${detail.member.first_name} ${detail.member.last_name}, `
                          : `${detail.member.first_name} ${detail.member.last_name} `
                      )}
                </Paragraph>{" "}
                {latest_message && (
                  <span
                    style={
                      imActive.length === 0
                        ? conversation_has_members &&
                          conversation_has_members.message &&
                          conversation_has_members.message.read_by_me
                          ? { color: "#a5a5a5" }
                          : { color: "#000", fontWeight: 500 }
                        : latest_message && latest_message.read_by_me
                        ? { color: "#a5a5a5" }
                        : { color: "#000", fontWeight: 500 }
                    }
                  >
                    {difference === 0 ? (
                      dayjs
                        .utc(latest_message.created_at, "YYYY-MM-DD hh:mm:ss")
                        .local()
                        .format("hh:mm A")
                    ) : (
                      <span>
                        {difference === 1
                          ? "YESTERDAY"
                          : dayjs
                              .utc(
                                latest_message.created_at,
                                "YYYY-MM-DD hh:mm:ss"
                              )
                              .local()
                              .format("MM/DD/YYYY")}
                      </span>
                    )}
                  </span>
                )}
              </div>
            }
            description={
              imActive.length === 0 &&
              conversation_has_members &&
              conversation_has_members.message ? (
                <div>
                  {conversation_has_members.message.body !== "attach" &&
                  conversation_has_members.message.body !== "detach" ? (
                    <Paragraph
                      ellipsis
                      style={
                        conversation_has_members.message.read_by_me
                          ? { color: "#595959" }
                          : { color: "#000", fontWeight: 500 }
                      }
                      className="noMargin"
                    >
                      {conversation_has_members.message.body}
                    </Paragraph>
                  ) : null}
                  {conversation_has_members.message.body === "attach" ? (
                    <Paragraph
                      ellipsis
                      style={
                        conversation_has_members.message.read_by_me
                          ? { color: "#595959" }
                          : { color: "#000", fontWeight: 500 }
                      }
                      className="noMargin"
                    >
                      {conversation_has_members.message.sender &&
                      conversation_has_members.message.sender.member
                        ? `${conversation_has_members.message.sender.member
                            .first_name || ""} ${conversation_has_members
                            .message.sender.member.last_name || ""} `
                        : ""}
                      added
                      {data.members_details &&
                        data.members_details.map((m: any) =>
                          conversation_has_members.message &&
                          conversation_has_members.message.event &&
                          conversation_has_members.message.event.affected &&
                          conversation_has_members.message.event.affected.includes(
                            m.member.id
                          )
                            ? ` ${m.member.first_name} ${m.member.last_name} `
                            : ""
                        )}
                    </Paragraph>
                  ) : null}

                  {conversation_has_members.message.body === "detach" ? (
                    <Paragraph
                      ellipsis
                      style={
                        conversation_has_members.message.read_by_me
                          ? { color: "#595959" }
                          : { color: "#000", fontWeight: 500 }
                      }
                      className="noMargin"
                    >
                      {conversation_has_members.message.sender &&
                      conversation_has_members.message.sender.member
                        ? `${conversation_has_members.message.sender.member
                            .first_name || ""} ${conversation_has_members
                            .message.sender.member.last_name || ""} `
                        : ""}
                      Removed
                      {data.members_details &&
                        data.members_details.map((m: any) =>
                          conversation_has_members.message &&
                          conversation_has_members.message.event &&
                          conversation_has_members.message.event.affected &&
                          conversation_has_members.message.event.affected.includes(
                            m.member.id
                          )
                            ? ` ${m.member.first_name} ${m.member.last_name} `
                            : ""
                        )}
                    </Paragraph>
                  ) : null}
                </div>
              ) : (
                <div>
                  {latest_message &&
                  latest_message.body !== "attach" &&
                  latest_message.body !== "detach" ? (
                    <Paragraph
                      ellipsis
                      style={
                        latest_message.read_by_me
                          ? { color: "#595959" }
                          : { color: "#000", fontWeight: 500 }
                      }
                      className="noMargin"
                    >
                      {latest_message.body}
                    </Paragraph>
                  ) : null}
                  {latest_message && latest_message.body === "attach" ? (
                    <Paragraph
                      ellipsis
                      style={
                        latest_message.read_by_me
                          ? { color: "#595959" }
                          : { color: "#000", fontWeight: 500 }
                      }
                      className="noMargin"
                    >
                      {latest_message &&
                      latest_message.sender &&
                      latest_message.sender.member
                        ? `${latest_message.sender.member.first_name} ${latest_message.sender.member.last_name} `
                        : ""}
                      added
                      {data.members_details &&
                        data.members_details.map((m: any) =>
                          latest_message.event &&
                          latest_message.event.affected &&
                          latest_message.event.affected.includes(m.member.id)
                            ? ` ${m.member.first_name} ${m.member.last_name} `
                            : ""
                        )}
                    </Paragraph>
                  ) : null}

                  {latest_message && latest_message.body === "detach" ? (
                    <Paragraph
                      ellipsis
                      style={
                        latest_message.read_by_me
                          ? { color: "#595959" }
                          : { color: "#000", fontWeight: 500 }
                      }
                      className="noMargin"
                    >
                      {latest_message &&
                      latest_message.sender &&
                      latest_message.sender.member
                        ? `${latest_message.sender.member.first_name} ${latest_message.sender.member.last_name} `
                        : ""}
                      Removed
                      {data.members_details &&
                        data.members_details.map((m: any) =>
                          latest_message.event &&
                          latest_message.event.affected &&
                          latest_message.event.affected.includes(m.member.id)
                            ? ` ${m.member.first_name} ${m.member.last_name} `
                            : ""
                        )}
                    </Paragraph>
                  ) : null}
                </div>
              )
            }
          />
        </List.Item>
      ) : (
        <List.Item key={id} onClick={openConversation} className="pointer">
          {/* <Skeleton avatar title={false} loading={loading} active> */}
          <List.Item.Meta
            avatar={
              <Avatar
                size="large"
                style={{
                  fontSize: "24px",
                  backgroundColor: "#00a2ae"
                }}
              >
                {data.members_details &&
                  data.members_details.map((m: any) =>
                    m.member.id !== adminInfo.admininfo.id
                      ? `${
                          m.member.first_name !== null
                            ? m.member.first_name.toUpperCase().charAt(0)
                            : "H"
                        }`
                      : ""
                  )}
              </Avatar>
            }
            title={
              <div className="SpaceBetween fontWeightRegular">
                <span
                  style={
                    latest_message && latest_message.read_by_me
                      ? { color: "#595959" }
                      : { color: "#000", fontWeight: 500 }
                  }
                >
                  {data.members_details &&
                    data.members_details.map((m: any) =>
                      m.member.id !== adminInfo.admininfo.id
                        ? `${
                            m.member_type === "CONTACT"
                              ? m.member.first_name || "HQ"
                              : `${
                                  m.member.first_name !== null
                                    ? m.member.first_name
                                    : "HQ"
                                } ${
                                  m.member.last_name !== null
                                    ? m.member.last_name
                                    : "User"
                                }`
                          }`
                        : ""
                    )}
                </span>
                <span
                  style={
                    latest_message && latest_message.read_by_me
                      ? { color: "#a5a5a5" }
                      : { color: "#000", fontWeight: 500 }
                  }
                >
                  {latest_message ? (
                    <span>
                      {difference === 0 ? (
                        dayjs
                          .utc(latest_message.created_at, "YYYY-MM-DD hh:mm:ss")
                          .local()
                          .format("hh:mm A")
                      ) : (
                        <span>
                          {difference === 1
                            ? "YESTERDAY"
                            : dayjs
                                .utc(
                                  latest_message.created_at,
                                  "YYYY-MM-DD hh:mm:ss"
                                )
                                .local()
                                .format("MM/DD/YYYY")}
                        </span>
                      )}
                    </span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            }
            description={
              <Paragraph
                ellipsis
                style={
                  latest_message && latest_message.read_by_me
                    ? { color: "#595959" }
                    : { color: "#000", fontWeight: 500 }
                }
                className="noMargin"
              >
                {latest_message ? latest_message.body : ""}
              </Paragraph>
            }
          />
          {/* </Skeleton> */}
        </List.Item>
      )}
    </>
  );
};
