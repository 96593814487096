import React, { useEffect, useState } from "react";
import { set } from "idb-keyval";
import dayjs from "dayjs";
import {
  useGetCapabilityTokenLazyQuery,
  useGetTextCapabilityTokenLazyQuery,
  CallDeviceType
} from "./../generated/graphql";
import { NUM_STORAGE } from "../utils/KCustomStorage";
import Phone from "../components/Phone";
import { useGlobalContext } from "../context/GlobalContext";
import { KConnectionManager } from "../utils/KConnectionManager";
import { useStickyState } from "../hooks/useStickyState";

interface numberPorops {
  id: string;
}

export const Setup: React.FC<numberPorops> = ({ id }) => {
  const [token] = useStickyState(null, "text_token");
  const [getNumbers, { data: cdata }] = useGetCapabilityTokenLazyQuery();
  const [phone, setPhone] = useState<{ token: string; number: string }[]>([]);
  const { setActiveNumber } = useGlobalContext();

  const [getTextCapability, { data }] = useGetTextCapabilityTokenLazyQuery();

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);

    function fetchToken() {
      getTextCapability({
        variables: {
          platform: CallDeviceType.Web
        }
      });
    }
    if (!token) {
      fetchToken();
      return;
    } else {
      if (token.expires <= now) {
        fetchToken();
        return;
      } else {        
        setTextClient(token.token);
      }
    }
  }, [getTextCapability, token]);

  const setTextClient = (token: string) => {
    const K = KConnectionManager.getInstance();
    const client = K.setTextClient("textClient", token);
    if (client) {
      client.on("connectionStateChanged", function(state) {
        console.log("text connection status", state);
      });
    }
  };

  useEffect(() => {
    if (data) {
      const dt = Date.now();
      const textCapability = {
        token: data.getTextCapabilityToken.token,
        expires: dayjs(dt)
          .add(data.getTextCapabilityToken.expire, "seconds")
          .unix()
      };
      localStorage.setItem("text_token", JSON.stringify(textCapability));
    }
  }, [data]);

  // Only if no data in storage
  useEffect(() => {
    if (cdata) {
    const number: string = cdata.getCapabilityToken.number?.phone_number || "";
      const token = cdata.getCapabilityToken;

      const n: {
        token: string;
        number: string;
      }[] = [{ token: token.token, number }];

      // set(`COM_${company}`, numbers, COM_STORAGE);
      set(number, token, NUM_STORAGE);
      setPhone(n);

      if (n[0] !== undefined) {
        setActiveNumber(n[0].number);
      }
    }
  }, [cdata, setActiveNumber]);

  // hydrate
  useEffect(() => {
    async function hydrate() {
      // cl("Checking if present in DB");
      // const c = await get(`COM_${company}`, COM_STORAGE);
      // if (c) {
      //   const n = await getMany(c, NUM_STORAGE);
      //   console.log(n, "NN");
      //   if (n[0] !== undefined) {
      //     setPhone(n);
      //     setActiveNumber(n[0].number);
      //     return;
      //   }
      // }
      getNumbers({ variables: { id } });
    }
    hydrate();
  }, [getNumbers, setActiveNumber, id]);

  return (
    <>
      {phone.map(
        ({ token, number } = { token: "", number: "" }, i) =>
          token && <Phone token={token} number={number} key={i} />
      )}
    </>
  );
};
