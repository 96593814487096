import React from "react";
import { Drawer, Tabs } from "antd";
import { QueueList } from "./QueueList";
import { TransferToType, ConferenceParticipant } from "../../generated/graphql";
import { AgentList } from "./AgentList";

interface CallTransferProps {
  visible: boolean;
  hideCallTransfer: () => void;
  coldTransferCall: (to: string, type: TransferToType) => void;
  participants?: Array<ConferenceParticipant>;
}

const { TabPane } = Tabs;
const CallTransfer: React.FC<CallTransferProps> = ({
  visible,
  hideCallTransfer,
  coldTransferCall,
  participants
}) => {
  return (
    <div>
      <Drawer
        bodyStyle={{ padding: 0 }}
        placement={"bottom"}
        closable={false}
        onClose={() => {}}
        visible={visible}
        height={400}
        afterVisibleChange={() => {}}
        headerStyle={{ padding: "0 8px", textAlign: "right" }}
        title={
          <i
            style={{ fontSize: "12px", cursor: "pointer" }}
            onClick={hideCallTransfer}
            className="icon-cancel"
          />
        }
      >
        <Tabs
          defaultActiveKey="1"
          animated={false}
          centered
          style={{ margin: "0 16px" }}
        >
          <TabPane tab="Agent" key="dial">
            <AgentList coldTransferCall={coldTransferCall} participants={participants} />
          </TabPane>
          <TabPane tab="Queue" key="call">
            <QueueList coldTransferCall={coldTransferCall} />
          </TabPane>
        </Tabs>
      </Drawer>
    </div>
  );
};

export default CallTransfer;
