import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { Button, Input, Modal, Spin } from 'antd'

const Cookies = require("js-cookie");

const Login: React.FC = () => {
    const history = useHistory();
    const [token, setToken] = useState<string>('')
    const [Loading, setLoading] = useState<boolean>(false)

    const handleSubmit = () => {
       setLoading(true);
       Cookies.set('token', token);
       setTimeout(() => {
         setLoading(false);
         history.push('/');
       }, 1000);
    }

    return (
        <div style={{height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Modal
             title="Enter token" 
             visible 
             closeIcon={<div/>}
             footer={
               <div>
                  <Button
                    type="primary"
                    disabled={token === ''}
                    onClick={handleSubmit}                     
                  >
                    {Loading ? <Spin className="whiteLoader" /> : 'Submit'}
                  </Button>
               </div>
            }>
              <Input placeholder="Enter token" onChange={(e) => setToken(e.target.value)} value={token} />
            </Modal>
        </div>
    )
}

export default Login;