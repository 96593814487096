import React from "react";
import { List, Skeleton } from "antd";
// import { MyAvatar } from "../MyAvatar";
// import { LEAD_LINK } from "../../utils/constant";
import { useHistory } from "react-router-dom";
import { numberFormat } from "../../utils/utils";
import { useGlobalContext } from "../../context/GlobalContext";
import { useAddToCallMutation } from "../../generated/graphql";

interface ListItemProps {
  loading: boolean;
  first_name?: string | null | undefined;
  last_name?: string | null | undefined;
  phone_no?: string | null | undefined;
  avatar?: string | null | undefined;
  numbers?: any[] | null | undefined;
  id?: string;
  dispplaySettings: (numbers: any, name: string) => void;
}

const handleCallStatus = (num: string | undefined | null) => {
  if (num) {
    const eventJson = {
      type: "calling",
      number: num
    };
    window.parent.postMessage(JSON.stringify(eventJson), "*");
  }
};

export const ListItem: React.FC<ListItemProps> = ({
  id,
  loading,
  first_name,
  last_name,
  numbers,
  dispplaySettings
}) => {
  const name = `${first_name || ""} ${last_name || ""}`;
  const { conn } = useGlobalContext();
  const [addtoCall] = useAddToCallMutation();
  // const [adminInfo] = useStickyState(null, "AdminInfo");
  // const [blockNumber] = useBlockNumberMutation();
  const history = useHistory();

  // const handleBlockNumber = () => {
  //   console.log("adminInfo", adminInfo, numbers);
  //   if (adminInfo && numbers) {
  //     blockNumber({
  //       variables: {
  //         admin_id: adminInfo.admininfo.id,
  //         id: "0",
  //         number: numbers[0].phone_number,
  //         type: BlockType.Block
  //       }
  //     });
  //   }
  // };

  // const handleSpamNumber = () => {
  //   if (adminInfo && numbers) {
  //     blockNumber({
  //       variables: {
  //         admin_id: adminInfo.admininfo.id,
  //         id: "0",
  //         number: numbers[0].phone_number,
  //         type: BlockType.Spam
  //       }
  //     });
  //   }
  // };

  // const menu = (
  //   <Menu>
  //     <Menu.Item key="0" onClick={handleBlockNumber}>
  //       Block Number
  //     </Menu.Item>
  //     <Menu.Item key="1" onClick={handleSpamNumber}>
  //       Spam Number
  //     </Menu.Item>
  //   </Menu>
  // );

  const handleDialCall = (n: string) => {
    if (conn) {
      addtoCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: n
        }
      });
      history.push(`/call/${n}`);
    } else {
      history.push(`/call/${n}?out=true`);
      handleCallStatus(n);
    }
  };

  return (
    <List.Item
      key={id}
      actions={[
        <div>
          <div
            onClick={() => {
              if (numbers && numbers.length !== 0) {
                handleDialCall(numbers[0].phone_number);
                handleCallStatus(numbers[0].phone_number);
              }
            }}
          >
            <i
              className="icon-call"
              style={{ color: "#4ac367", fontSize: 16, cursor: "pointer" }}
            />
          </div>
          {/* <div>
            <i
              className="icon-settings"
              style={{ color: "#4ac367", fontSize: 16 }}
              onClick={() =>
                numbers && numbers.length !== 0
                  ? dispplaySettings(numbers, name)
                  : {}
              }
            />
          </div> */}
        </div>
        // <div>
        //   <Dropdown overlay={menu} trigger={["click"]}>
        //     <i className="icon-email-trail" style={{ fontSize: 16 }} />
        //   </Dropdown>
        // </div>
      ]}
    >
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          // avatar={
          //   <MyAvatar name={full_name || "HQ User"} src={avatar} size={38} />
          // }
          title={
            <>
              <div>{name}</div>
              <div
                onClick={() => {
                  if (numbers && numbers.length !== 0) {
                    handleDialCall(numbers[0].phone_number);
                    handleCallStatus(numbers[0].phone_number);
                  }
                }}
                style={{ fontWeight: 400, cursor: "pointer" }}
              >
                {numbers && numbers.length !== 0
                  ? numberFormat(numbers[0].phone_number.replace(/\+1/g, ``))
                  : ""}
              </div>
            </>
          }
        />
      </Skeleton>
    </List.Item>
  );
};
