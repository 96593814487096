import React, { useState, useEffect } from "react";
import {
  List,
  Form,
  Input,
  Select,
  Tabs,
  Avatar,
  Spin,
  Empty,
  Button
} from "antd";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import "./index.css";
import { SEARCH_LEAD } from "../../../query";
import {
  SearchMemberPerson,
  MemberInput,
  useStartConversationMutation
} from "../../../generated/graphql";
import { useStickyState } from "../../../hooks/useStickyState";
import { formatPhoneNumber } from "../../../utils/utils";

const { Option } = Select;
const { TabPane } = Tabs;

interface StartConversationProps {
  hideStartConversation: () => void;
}

const StartConversation: React.FC<StartConversationProps> = ({
  hideStartConversation
}) => {
  const [form] = Form.useForm();
  const [adminInfo] = useStickyState(null, "AdminInfo");
  const history = useHistory();
  const [search, { data, loading }] = useLazyQuery(SEARCH_LEAD, {
    fetchPolicy: `no-cache`
  });

  const [selectedMembersId, setSelectedMembersId] = useState<string[]>([]);

  const [selectedMembers, setSelectedMembers] = useState<Array<MemberInput>>(
    []
  );
  const [selectedMembersData, setSelectedMembersData] = useState<
    Array<SearchMemberPerson>
  >([]);

  const [createConversation] = useStartConversationMutation();

  useEffect(() => {
    form.setFieldsValue({ addMember: selectedMembersId });
  }, [form, selectedMembersId]);

  const handleIAddtem = (memberid: string, member: SearchMemberPerson) => {
    const newMemberIds = selectedMembersId;
    const newSelectedMembers = selectedMembers;
    const newSelectedMembersData = selectedMembersData;
    newMemberIds.push(memberid);
    newSelectedMembersData.push(member);
    form.setFieldsValue({ addMember: [...newMemberIds] });
    setSelectedMembersId([...newMemberIds]);

    if (member.typeClass && member.phone_no) {
      newSelectedMembers.push({
        member_id: member.id,
        member_type: member.typeClass,
        phone_no: member.phone_no
      });
    }
    setSelectedMembers([...newSelectedMembers]);
    setSelectedMembersData([...newSelectedMembersData]);
  };

  const handleRemoveItem = (memberid: string, member: SearchMemberPerson) => {
    setSelectedMembersId(memberIds =>
      memberIds.filter((m: string) => m !== memberid)
    );
    setSelectedMembers(members =>
      members.filter((m: MemberInput) => m.member_id !== member.id)
    );
    setSelectedMembersData(members =>
      members.filter((m: SearchMemberPerson) => m.id !== member.id)
    );
  };

  const handleChangeSelectedMember = (memberid: string[]) => {
    setSelectedMembersId(memberid);
    setSelectedMembers(members =>
      members.filter(
        (m: MemberInput) => m.phone_no && memberid.includes(m.phone_no)
      )
    );
    setSelectedMembersData(members =>
      members.filter(
        (m: SearchMemberPerson) => m.phone_no && memberid.includes(m.phone_no)
      )
    );
  };

  const handleStartConversation = () => {
    const grpName = form.getFieldValue("groupName");
    let gname = "";

    selectedMembersData.forEach((name, i) => {
      gname = `${gname}${name.full_name !== null ? name.full_name : "HQ User"}${
        selectedMembersData.length > 1 && selectedMembersData.length !== i + 1
          ? ", "
          : ""
      }`;
    });

    const input = {
      name: grpName !== undefined && grpName !== "" ? grpName : gname,
      is_group: selectedMembers.length !== 0,
      default_number: "",
      members: selectedMembers
    };

    createConversation({ variables: { input } }).then(({ data }) => {
      if (data && data.startConversation) {
        history.push(`/text/${data.startConversation.id}`);
      }
    });
  };

  return (
    <div>
      <div className="groupContanier">
        <Form form={form} initialValues={{ groupName: "" }}>
          <List.Item>
            <Form.Item
              name="groupName"
              className="noMargin"
              style={{ width: "100%" }}
            >
              <Input
                prefix={
                  <i
                    className="icon-lead-added"
                    style={{ fontSize: "20px", cursor: "auto" }}
                  />
                }
                className="groupNameText"
                placeholder="Name Your Group"
                //   onChange={e => {
                //     this.setState({
                //       grpnameCount: e.target.value.length
                //     });
                //   }}
                suffix={
                  <i
                    className="icon-cancel"
                    onClick={hideStartConversation}
                    style={{ fontSize: "14px", cursor: "pointer" }}
                  />
                }
              />
            </Form.Item>
          </List.Item>
          <List.Item>
            <Form.Item
              name="addMember"
              className="noMargin"
              style={{ width: "100%" }}
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Search contact to start conversation"
                value={selectedMembersId}
                onChange={(e: string[]) => handleChangeSelectedMember(e)}
                onSearch={(value: string) => {
                  if (value !== "") {
                    search({
                      variables: {
                        query: value,
                        call: true
                      }
                    });
                  }
                }}
                className="selectedMenu"
                dropdownClassName="addMemberDropdown"
                getPopupContainer={e => e.parentNode}
              >
                {data &&
                  data.searchConversationV2 &&
                  data.searchConversationV2.person.map(
                    (item: SearchMemberPerson) => {
                      return (
                        item.phone_no && (
                          <Option key={item.phone_no} value={item.phone_no}>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                alignItems: "center"
                              }}
                            >
                              <div className="hideCol">
                                {item.full_name !== null
                                  ? item.full_name
                                  : "HQ User"}
                              </div>
                            </div>
                          </Option>
                        )
                      );
                    }
                  )}
              </Select>
            </Form.Item>
          </List.Item>
        </Form>
      </div>
      <div>
        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane tab="Leads" key="1">
            <div
              style={{
                height: `calc(100vh - ${
                  selectedMembersId.length > 0 ? "340px" : "292px"
                })`,
                overflowY: "auto"
              }}
            >
              {!loading &&
              data &&
              data.searchConversationV2 &&
              data.searchConversationV2.person.filter(
                (item: SearchMemberPerson) => item.typeClass === "LEAD"
              ).length === 0 ? (
                <Empty description="No leads found" />
              ) : null}
              {loading ? (
                <List.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Spin spinning />
                </List.Item>
              ) : null}

              {data &&
                data.searchConversationV2 &&
                data.searchConversationV2.person
                  .filter(
                    (item: SearchMemberPerson) => item.typeClass === "LEAD"
                  )
                  .map((item: SearchMemberPerson) => (
                    <List.Item
                      className="pointer"
                      style={
                        item.phone_no &&
                        selectedMembersId.includes(item.phone_no)
                          ? { backgroundColor: "rgba(0, 85, 253, 0.08)" }
                          : {}
                      }
                      onClick={() => {
                        if (item && item.phone_no) {
                          if (selectedMembersId.includes(item.phone_no)) {
                            handleRemoveItem(item.phone_no, item);
                          } else {
                            handleIAddtem(item.phone_no, item);
                          }
                        }
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          item.phone_no &&
                          selectedMembersId.includes(item.phone_no) ? (
                            <Avatar
                              size="large"
                              style={{ backgroundColor: "#4AC367" }}
                            >
                              <i className="icon-check-mark" />
                            </Avatar>
                          ) : (
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px",
                                backgroundColor: "#595959"
                              }}
                            >
                              {item.full_name ? item.full_name.charAt(0) : "H"}
                            </Avatar>
                          )
                        }
                        title={
                          <span style={{ color: "#595959" }}>
                            {item.full_name ? item.full_name : "HQ User"}
                          </span>
                        }
                        description={
                          <span className="textBlue">
                            {item.phone_no && formatPhoneNumber(item.phone_no)}
                            {item.is_primary ? (
                              <i
                                className="icon-verified"
                                style={{ color: "#4ac367" }}
                              />
                            ) : null}
                          </span>
                        }
                      />
                    </List.Item>
                  ))}
            </div>
          </TabPane>
          <TabPane tab="CRM Users" key="2">
            <div
              style={{
                height: `calc(100vh - ${
                  selectedMembersId.length > 0 ? "340px" : "292px"
                })`,
                overflowY: "auto"
              }}
            >
              {!loading &&
              data &&
              data.searchConversationV2 &&
              data.searchConversationV2.person.filter(
                (item: SearchMemberPerson) =>
                  item.typeClass === "AGENT" &&
                  item.id !== adminInfo.admininfo.id
              ).length === 0 ? (
                <Empty description="No CRM users found" />
              ) : null}
              {loading ? (
                <List.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Spin spinning />
                </List.Item>
              ) : null}

              {data &&
                data.searchConversationV2 &&
                data.searchConversationV2.person
                  .filter(
                    (item: SearchMemberPerson) =>
                      item.typeClass === "AGENT" &&
                      item.id !== adminInfo.admininfo.id
                  )
                  .map((item: SearchMemberPerson) => (
                    <List.Item
                      className="pointer"
                      style={
                        item.phone_no &&
                        selectedMembersId.includes(item.phone_no)
                          ? { backgroundColor: "rgba(0, 85, 253, 0.08)" }
                          : {}
                      }
                      onClick={() => {
                        if (item && item.phone_no) {
                          if (selectedMembersId.includes(item.phone_no)) {
                            handleRemoveItem(item.phone_no, item);
                          } else {
                            handleIAddtem(item.phone_no, item);
                          }
                        }
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          item.phone_no &&
                          selectedMembersId.includes(item.phone_no) ? (
                            <Avatar
                              size="large"
                              style={{ backgroundColor: "#4AC367" }}
                            >
                              <i className="icon-check-mark" />
                            </Avatar>
                          ) : (
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px",
                                backgroundColor: "#595959"
                              }}
                            >
                              {item.full_name ? item.full_name.charAt(0) : "H"}
                            </Avatar>
                          )
                        }
                        title={
                          <span style={{ color: "#595959" }}>
                            {item.full_name ? item.full_name : "HQ User"}
                          </span>
                        }
                        description={
                          <span className="textBlue"> {item.phone_no}</span>
                        }
                      />
                    </List.Item>
                  ))}
            </div>
          </TabPane>
        </Tabs>
        {selectedMembersId.length > 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px"
            }}
          >
            <Button className="btnSecondary" onClick={handleStartConversation}>
              Start Conversation
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default StartConversation;
