import React from "react";
import { Tabs } from "antd";
import { HistoryList } from "./List";
import { MissedCallList } from "./MissedCallList";
import { VoiceMessageList } from "../VoiceMessages/List";

const { TabPane } = Tabs;

export default function History() {
  return (
    <Tabs
      defaultActiveKey="1"
      animated={false}
      className="mainTabBar"
    >
      <TabPane tab="All Calls" key="1">
        <div style={{ marginBottom: 16 }}>
          <br/>
          <HistoryList />
        </div>
      </TabPane>
      <TabPane tab="Missed Call" key="2">
        <br/>
        <MissedCallList />
      </TabPane>
      <TabPane tab="Voice Message" key="3">
        <div style={{ marginBottom: 16 }}>
          <VoiceMessageList />
        </div>
      </TabPane>
    </Tabs>
  );
}
