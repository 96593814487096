import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
  QueryParamProvider,
  ExtendedStringifyOptions,
  transformSearchStringJsonSafe
} from "use-query-params";
import { GProvider, client } from "./options/GProvider";
import App from "./App";
import "./antd.less";
import "./index.css";

const queryStringifyOptions: ExtendedStringifyOptions = {
  transformSearchString: transformSearchStringJsonSafe
};


ReactDOM.render(
  <React.StrictMode>
    <GProvider client={client}>
      <Router>
        <QueryParamProvider
          ReactRouterRoute={Route}
          stringifyOptions={queryStringifyOptions}
        >
          <App />
        </QueryParamProvider>
      </Router>
    </GProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
