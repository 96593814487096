import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-01-01 13:00:00`. */
  DateTime: any;
  Upload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  HashId: any;
};

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<Admin>;
  /** Search for Lead */
  search?: Maybe<Array<Lead>>;
  /** Lead Imp Note */
  connectedLeads?: Maybe<ConnectedLeadPaginator>;
  /** Lead Last Touch */
  leadLastTouch?: Maybe<LeadLastTouchType>;
  /** lead Reasons */
  leadReasons?: Maybe<Array<Maybe<LeadReasons>>>;
  /** lead Reasons */
  leadIdByReasons?: Maybe<Array<Maybe<LeadReasons>>>;
  /** Return total leads */
  leads?: Maybe<LeadPaginator>;
  /** Return single leads */
  lead?: Maybe<Lead>;
  /** All Count */
  leadCount?: Maybe<LeadCount>;
  /** Lead Imp Note */
  leadImpNote?: Maybe<Array<Maybe<LeadImpNote>>>;
  /** Lead By Ids */
  leadByIds?: Maybe<Array<Maybe<Lead>>>;
  /** Return total lead Tags */
  leadTags?: Maybe<Array<LeadTag>>;
  /** Return total sources */
  sources?: Maybe<SourcePaginator>;
  /** Return single source */
  source?: Maybe<Source>;
  todos?: Maybe<TodoTaskPaginator>;
  todosCount?: Maybe<GlobalTodoCountType>;
  leadByAdmin: Array<Maybe<Admin>>;
  leadTodos?: Maybe<TodoData>;
  todotypes: Array<TodoType>;
  fetchAdminSearch?: Maybe<SaveSearchPaginator>;
  adminAssignNumbers?: Maybe<Array<Maybe<AdminAssignNumbers>>>;
  checkadminType?: Maybe<CheckAdminResponse>;
  /** get All Permission */
  permissions?: Maybe<Array<Maybe<Permission>>>;
  /** Company Feature Permission */
  companyFeaturePermission?: Maybe<Array<Maybe<CompanyFeature>>>;
  /** get All Roles */
  roles?: Maybe<Array<Maybe<Role>>>;
  /** get All Roles */
  companyRoles?: Maybe<Array<Maybe<Company>>>;
  /** get All Roles & Permission */
  rolePermission?: Maybe<Array<Maybe<RolePermission>>>;
  /** get Company Roles & Permission */
  companyRolePermission?: Maybe<Array<Maybe<RolePermission>>>;
  /** get Working Hours */
  workingHour?: Maybe<Array<Maybe<WorkingHour>>>;
  appointments?: Maybe<AppointmentPaginator>;
  appointment?: Maybe<Appointment>;
  getAppointments?: Maybe<AppointmentData>;
  clientMeetingAppointments?: Maybe<ClientMeetingData>;
  appointmentTypes: Array<AppointmentType>;
  /** get all Activities */
  activities?: Maybe<ActivityDataPaginator>;
  /** get all Activities */
  activity: Activity;
  /** Auto Mail Activity */
  autoMailactivity?: Maybe<LeadAutoMail>;
  countries?: Maybe<CountryPaginator>;
  country?: Maybe<Country>;
  form?: Maybe<Form>;
  stages?: Maybe<Array<Maybe<Stage>>>;
  stage?: Maybe<Stage>;
  /** Search for Lead */
  propertyhomes?: Maybe<PropertyHomeData>;
  /** Search Saved */
  searchSaved?: Maybe<UserSaveSearchesPaginator>;
  showing?: Maybe<ShowingData>;
  contactAgent?: Maybe<ContactAgent>;
  restrictedField?: Maybe<RestrictedFieldType>;
  restrictedFieldV2?: Maybe<Array<Maybe<RestrictedFieldType>>>;
  popup?: Maybe<PopupTypeData>;
  tabCount?: Maybe<TabTypeData>;
  shortUrlQuery?: Maybe<Array<Maybe<ShortUrlType>>>;
  /** Search Saved */
  userSavedSearch?: Maybe<UserSaveSearchesPaginator>;
  /** Addresss Search */
  addressSearch?: Maybe<AddressSearchType>;
  /** Addresss live Search */
  addressLiveSearch?: Maybe<Array<Maybe<AddressSearch>>>;
  /** Get property */
  getProperty: Array<Property>;
  /** Get Buyside data */
  buySide?: Maybe<LeadContractPaginator>;
  /** Get Listingside data */
  listingSide?: Maybe<LeadListingPaginator>;
  /** Cashflow data */
  cashflow: Array<CashData>;
  /** Actual income graph */
  actualIncome: Array<Income>;
  /** Projected closing graph */
  projectClosing: Array<Income>;
  /** Buy-side graph */
  buySideGraph: Array<GraphData>;
  /** Listing graph */
  listingGraph: Array<GraphData>;
  /** Income graph */
  IncomeGraph: Array<IncomeData>;
  /** Overall cashflow graph */
  OverAllCashflowGraph: Array<IncomeData>;
  /** Offer summary */
  offerSummary: Array<OfferSummary>;
  /** Detail summary */
  detailSummary: Array<DetailSummary>;
  /** Get leadOffers data */
  offersReporting?: Maybe<LeadOfferPaginator>;
  /** Get mls property data */
  mlsProperty: Array<PropertyData>;
  /** Total Leads */
  cycleTimeReporting?: Maybe<CycleTimeReportData>;
  /** Dashboard Leads */
  dashboardLeads?: Maybe<DashboardLeadsType>;
  /** Dashboard Annual Leads */
  dashboardAnnualLeads?: Maybe<DashboardLeadsType>;
  /** Dashboard Monthly Leads */
  dashboardMonthlyLeads?: Maybe<DashboardLeadsType>;
  /** Dashboard Today Leads */
  dashboardTodayLeads?: Maybe<DashboardLeadsType>;
  /** Dashboard One Sent Listing */
  sentListingOneStar?: Maybe<DashboardSentListingType>;
  /** Dashboard Two Three Sent Listing */
  sentListingTwoThreeStar?: Maybe<DashboardSentListingType>;
  /** Dashboard lead Summary Report */
  leadSummaryReport?: Maybe<LeadSummaryReportingTypeData>;
  /** Dashboard weekly Showing Report */
  weeklyShowingReporting?: Maybe<DashboardShowingReportingType>;
  /** Dashboard repeat Showing Report */
  repeatShowingReporting?: Maybe<DashboardShowingReportingType>;
  /** Points Summary For Admin */
  dashboardAdminPoints?: Maybe<DashBoardAdminPoints>;
  /** Showing Report */
  shoingReport?: Maybe<ShoingReportData>;
  /** Email Statistics Report */
  emailStatisticsReport?: Maybe<Array<Maybe<EmailStatisticsType>>>;
  /** Admin Email Statistics Report */
  autoAdminEmailStatisticsReport?: Maybe<AutoAdminEmailStatisticsTypePaginator>;
  /** Reporting Notes */
  reportingNotes?: Maybe<NoteReportPaginator>;
  /** Reporting Mails */
  reportingMails?: Maybe<EmailReportPaginator>;
  /** Reporting Appointment */
  reportingAppointment?: Maybe<AppointmentReportPaginator>;
  /** Reporting Todo */
  reportingTask?: Maybe<TaskReportPaginator>;
  /** Reporting Send Listing */
  reportingSendListing?: Maybe<SendListingReportPaginator>;
  /** Admin Reporting Points */
  reportingAdminPoints?: Maybe<PointsReportPaginator>;
  /** Realtor Reporting Points */
  reportingRealtorPoints?: Maybe<RealtorData>;
  /** Reporting Brokerage Email Statistics */
  brokerageEmailStatistics?: Maybe<BrokerageEmailStatistics>;
  /** Points Summary For Realtor */
  dashboardRealtorPoints?: Maybe<Array<Maybe<DashBoardPointsReport>>>;
  /** User Type Count */
  userTypeCount?: Maybe<Array<Maybe<PointsReport>>>;
  /** total reputation */
  totalReputation?: Maybe<ReputationResponse>;
  /** getTime */
  getTime?: Maybe<PowerHour>;
  /** Get All Lender Financing Type */
  lenderFinancingType: Array<LenderFinancingType>;
  /** Get All Lender Approved */
  lenderApproved?: Maybe<LenderApprovedPaginator>;
  /** Get login User Companies */
  getLoginUserCompanies?: Maybe<Array<Company>>;
  /** Get ALL territories with pagination & Order By */
  territories?: Maybe<TerritoryPaginator>;
  /** Get ALL territories with pagination & Order By */
  companyTerritories?: Maybe<CompanyTerritoryPaginator>;
  /** Single territory get by id */
  territory?: Maybe<Territory>;
  /** company wise zipcode */
  zipcode?: Maybe<ZipcodePaginator>;
  /** company wise zipcode */
  companyZipcode?: Maybe<CompanyZipCodePaginator>;
  /** Get All offices */
  offices?: Maybe<OfficePaginator>;
  /** Get All company Offices */
  companyOffices?: Maybe<CompanyOfficePaginator>;
  /** get Single Office */
  office?: Maybe<Office>;
  /** get brokerages */
  companies?: Maybe<CompanyPaginator>;
  /** get All Language */
  languages: Array<Language>;
  /** Get All Week Of Days */
  weekofdays: Array<WeekOfDay>;
  /** Get all note with pagination & Order By */
  notes?: Maybe<NotePaginator>;
  /** Single territory get by id */
  noteById?: Maybe<Note>;
  /** get Template Category */
  templateCategories: Array<Maybe<TemplateCategory>>;
  templates?: Maybe<TemplatePaginator>;
  /** Return single source */
  template: Template;
  /** Template Fields */
  templatefields: Array<Maybe<TemplateField>>;
  /** Calendar search query */
  calendars?: Maybe<CalendarData>;
  gmailCred?: Maybe<GmailCredType>;
  gmailGetConnectUrl?: Maybe<GmailConnectUrlResponse>;
  mailThreads?: Maybe<MailThreadPaginator>;
  draftMails?: Maybe<SingleMailPaginator>;
  mailThread?: Maybe<Array<Maybe<SingleMail>>>;
  leadMailThreads?: Maybe<LeadMailThread>;
  leadMailThread?: Maybe<Array<Maybe<SingleLeadMail>>>;
  leadAutoMailThread?: Maybe<LeadAutoMailType>;
  leadMailThreadsV2?: Maybe<LeadMailThreadsV2>;
  /** Email Search */
  emailSearch?: Maybe<Array<Maybe<EmailSearchData>>>;
  /** Lead Search */
  leadSearchV2?: Maybe<Array<Maybe<GlobalSearchData>>>;
  /** Lead List */
  leadList?: Maybe<LeadData>;
  /** Lead List */
  leadListV3?: Maybe<LeadData>;
  /** Calendar search query */
  transferleads?: Maybe<TransferLeadData>;
  /** Get all Notification */
  notifications?: Maybe<NotificationData>;
  /** Calendar search query */
  liveLeadAnalytics?: Maybe<LiveLeadData>;
  conversations?: Maybe<ConversationPaginator>;
  conversation?: Maybe<Conversation>;
  conversationById?: Maybe<Conversation>;
  messages?: Maybe<MessagePaginator>;
  conversationNumbers?: Maybe<TwilioNumbersData>;
  twilioNumbers?: Maybe<TwilioNumbersPaginator>;
  twilioNumbersSync?: Maybe<Array<Maybe<TwilioNumbers>>>;
  searchConversationV2?: Maybe<Sv2>;
  messageStatusInfo?: Maybe<Array<Maybe<SmsMessage>>>;
  getMyNumbers: Array<MyNumbers>;
  getCapabilityToken: CapabilityToken;
  getTextCapabilityToken: TextCapabilityToken;
  getLeadFromNumber?: Maybe<LeadFromNumber>;
  getCallHistory?: Maybe<CallDetailPaginator>;
  getMiscalls?: Maybe<CallDetailPaginator>;
  getCRMContact?: Maybe<CallContactPaginator>;
  setCallBinding?: Maybe<BindingResponse>;
  delCallBinding?: Maybe<BindingResponse>;
  getQueue?: Maybe<QueuePaginator>;
  getCallDetail?: Maybe<CallDetail>;
  getBlockNumbers?: Maybe<Array<Maybe<BlockNumber>>>;
  getContact?: Maybe<ContactPaginator>;
  getVM?: Maybe<CallDetailPaginator>;
  /** Calendar search query */
  matchedpropertylead?: Maybe<MatchedpropertyData>;
  /** Calendar search query */
  matchedpropertyleadV2?: Maybe<MatchedpropertyData>;
  /** Calendar search query */
  matchedpropertyleadV3?: Maybe<MatchedpropertyV3Data>;
  /** daily send listing */
  todayMatchProperty?: Maybe<TodayMatchPropertyType>;
  /** Get All lead offer type */
  leadOfferType: Array<LeadOfferType>;
  /** Get All lead offer type */
  leadContract?: Maybe<LeadContractPaginator>;
  /** Get All lead offer type */
  leadOffers?: Maybe<LeadOfferPaginator>;
  /** Get All lead Sold */
  leadSold?: Maybe<LeadSoldPaginator>;
  /** Get All lead listing */
  leadListing?: Maybe<LeadListingPaginator>;
  settings?: Maybe<SettingsResponse>;
  /** Get ALL TimeZone */
  timezones?: Maybe<TimeZonePaginator>;
  admininfo?: Maybe<Admin>;
  admins?: Maybe<AdminData>;
  companyAdmins?: Maybe<AdminsPaginator>;
  /** Admin By Ids */
  adminByIds?: Maybe<Array<Maybe<Admin>>>;
};


export type QueryAdminArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QuerySearchArgs = {
  search: Scalars['String'];
};


export type QueryConnectedLeadsArgs = {
  input?: Maybe<ConnectLeadQuery>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryLeadLastTouchArgs = {
  input?: Maybe<LeadLastTouchInput>;
};


export type QueryLeadIdByReasonsArgs = {
  input?: Maybe<LeadIdByReasonsInput>;
};


export type QueryLeadsArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryLeadArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryLeadCountArgs = {
  input?: Maybe<LeadCountInput>;
};


export type QueryLeadImpNoteArgs = {
  input?: Maybe<LeadImpNoteInput>;
};


export type QueryLeadByIdsArgs = {
  input?: Maybe<LeadByIdsInput>;
};


export type QueryLeadTagsArgs = {
  lead_id: Scalars['Int'];
};


export type QuerySourcesArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QuerySourceArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryTodosArgs = {
  input?: Maybe<GlobalTodoInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTodosCountArgs = {
  input?: Maybe<GlobalTodoCountInput>;
};


export type QueryLeadByAdminArgs = {
  input?: Maybe<LeadByAdminInput>;
};


export type QueryLeadTodosArgs = {
  input?: Maybe<LeadTodoInput>;
};


export type QueryFetchAdminSearchArgs = {
  lead_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryAdminAssignNumbersArgs = {
  input: AdminAssignNumberInput;
};


export type QueryCheckadminTypeArgs = {
  input: CheckAdminInput;
};


export type QueryCompanyFeaturePermissionArgs = {
  input?: Maybe<CompanyFeaturePermissionInput>;
};


export type QueryCompanyRolesArgs = {
  input?: Maybe<CompanyRoleInput>;
};


export type QueryCompanyRolePermissionArgs = {
  input?: Maybe<CompanyRolePermissionInput>;
};


export type QueryAppointmentsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryAppointmentArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetAppointmentsArgs = {
  input: GetAppointmentsInput;
};


export type QueryClientMeetingAppointmentsArgs = {
  input: GetAppointmentsInput;
};


export type QueryActivitiesArgs = {
  lead_id: Scalars['Int'];
  activity_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryActivityArgs = {
  lead_id: Scalars['Int'];
  activity_type?: Maybe<Array<Maybe<Scalars['String']>>>;
  cursor: Scalars['Date'];
};


export type QueryAutoMailactivityArgs = {
  id: Scalars['Int'];
  leadId?: Maybe<Scalars['Int']>;
};


export type QueryCountriesArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCountryArgs = {
  id: Scalars['ID'];
};


export type QueryFormArgs = {
  id: Scalars['ID'];
  lead_id: Scalars['Int'];
};


export type QueryStageArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryPropertyhomesArgs = {
  input: PropertyDataInput;
};


export type QuerySearchSavedArgs = {
  input: PropertyGetInput;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryShowingArgs = {
  input: PropertyDataInput;
};


export type QueryContactAgentArgs = {
  input: PropertyDataInput;
};


export type QueryRestrictedFieldArgs = {
  input: RestrictedFieldInput;
};


export type QueryRestrictedFieldV2Args = {
  input: RestrictedFieldInputV2;
};


export type QueryPopupArgs = {
  input: PopupFieldInput;
};


export type QueryTabCountArgs = {
  input: TabFieldInput;
};


export type QueryShortUrlQueryArgs = {
  input: ShortUrlInput;
};


export type QueryUserSavedSearchArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryAddressSearchArgs = {
  input?: Maybe<AddressSearchInput>;
};


export type QueryAddressLiveSearchArgs = {
  input?: Maybe<AddressLiveSearchInput>;
};


export type QueryGetPropertyArgs = {
  lead_id: Scalars['Int'];
  offer_type?: Maybe<Scalars['String']>;
};


export type QueryBuySideArgs = {
  input?: Maybe<BuySideInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryListingSideArgs = {
  input?: Maybe<ListingSideInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCashflowArgs = {
  input?: Maybe<CashFlowInput>;
};


export type QueryActualIncomeArgs = {
  input?: Maybe<ActualIncomeInput>;
};


export type QueryProjectClosingArgs = {
  input?: Maybe<ActualIncomeInput>;
};


export type QueryBuySideGraphArgs = {
  input?: Maybe<BuySideGraphInput>;
};


export type QueryListingGraphArgs = {
  input?: Maybe<BuySideGraphInput>;
};


export type QueryIncomeGraphArgs = {
  input?: Maybe<BuySideGraphInput>;
};


export type QueryOverAllCashflowGraphArgs = {
  input?: Maybe<BuySideGraphInput>;
};


export type QueryOfferSummaryArgs = {
  input?: Maybe<SummaryInput>;
};


export type QueryDetailSummaryArgs = {
  input?: Maybe<SummaryInput>;
};


export type QueryOffersReportingArgs = {
  input?: Maybe<OffersInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryMlsPropertyArgs = {
  input?: Maybe<RestrictedFieldInputV2>;
};


export type QueryCycleTimeReportingArgs = {
  input?: Maybe<CycleTimeReportInput>;
};


export type QueryDashboardLeadsArgs = {
  input?: Maybe<DashboardLeadsInput>;
};


export type QueryDashboardAnnualLeadsArgs = {
  input?: Maybe<DashboardLeadsInput>;
};


export type QueryDashboardMonthlyLeadsArgs = {
  input?: Maybe<DashboardLeadsInput>;
};


export type QueryDashboardTodayLeadsArgs = {
  input?: Maybe<DashboardLeadsInput>;
};


export type QuerySentListingOneStarArgs = {
  input?: Maybe<DashboardSentListingInput>;
};


export type QuerySentListingTwoThreeStarArgs = {
  input?: Maybe<DashboardSentListingInput>;
};


export type QueryLeadSummaryReportArgs = {
  input?: Maybe<LeadSummaryReportingInput>;
};


export type QueryWeeklyShowingReportingArgs = {
  input?: Maybe<DashboardShowingReportingInput>;
};


export type QueryRepeatShowingReportingArgs = {
  input?: Maybe<DashboardShowingReportingInput>;
};


export type QueryDashboardAdminPointsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  company_id: Scalars['Int'];
};


export type QueryShoingReportArgs = {
  input?: Maybe<ShoingReportInput>;
};


export type QueryEmailStatisticsReportArgs = {
  input?: Maybe<EmailStatisticsInput>;
};


export type QueryAutoAdminEmailStatisticsReportArgs = {
  input?: Maybe<AutoAdminEmailStatisticsInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingNotesArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingMailsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingAppointmentArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingTaskArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingSendListingArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingAdminPointsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  range_type?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryReportingRealtorPointsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  range_type?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  first: Scalars['Int'];
};


export type QueryBrokerageEmailStatisticsArgs = {
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
  company_id?: Maybe<Scalars['Int']>;
};


export type QueryDashboardRealtorPointsArgs = {
  id: Scalars['Int'];
  from: Scalars['DateTime'];
  to: Scalars['DateTime'];
};


export type QueryLenderFinancingTypeArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
};


export type QueryLenderApprovedArgs = {
  input?: Maybe<LenderApprovedQueryInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTerritoriesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCompanyTerritoriesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTerritoryArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryZipcodeArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCompanyZipcodeArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryOfficesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryCompanyOfficesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryOfficeArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryCompaniesArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryLanguagesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
};


export type QueryNotesArgs = {
  lead_id: Scalars['Int'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryNoteByIdArgs = {
  input?: Maybe<GetNotById>;
};


export type QueryTemplateCategoriesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  type: Scalars['String'];
};


export type QueryTemplatesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  category_id?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryTemplatefieldsArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
};


export type QueryCalendarsArgs = {
  admin_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  appointment_type?: Maybe<Array<Scalars['Int']>>;
  date?: Maybe<Scalars['String']>;
  is_all_day?: Maybe<Scalars['Int']>;
  appointment_status?: Maybe<Array<Maybe<AppointmentStatus>>>;
  company_id?: Maybe<Scalars['Int']>;
};


export type QueryMailThreadsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryDraftMailsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryMailThreadArgs = {
  threadId: Scalars['String'];
};


export type QueryLeadMailThreadsArgs = {
  leadId: Scalars['ID'];
  first: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryLeadMailThreadArgs = {
  leadId: Scalars['ID'];
  threadId: Scalars['String'];
  currentMailId: Scalars['Int'];
};


export type QueryLeadAutoMailThreadArgs = {
  input?: Maybe<LeadAutoMailInput>;
};


export type QueryLeadMailThreadsV2Args = {
  leadId: Scalars['ID'];
  first: Scalars['Int'];
  page: Scalars['Int'];
};


export type QueryEmailSearchArgs = {
  input?: Maybe<GlobalSeachInput>;
};


export type QueryLeadSearchV2Args = {
  input?: Maybe<GlobalSeachInput>;
};


export type QueryLeadListArgs = {
  input?: Maybe<LeadListInput>;
};


export type QueryLeadListV3Args = {
  input?: Maybe<LeadListInputV3>;
};


export type QueryTransferleadsArgs = {
  lead_id?: Maybe<Scalars['Int']>;
};


export type QueryNotificationsArgs = {
  input?: Maybe<GetNotificationsInput>;
};


export type QueryLiveLeadAnalyticsArgs = {
  input?: Maybe<LiveLeadPaginationInput>;
};


export type QueryConversationsArgs = {
  lead_id?: Maybe<Scalars['ID']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryConversationArgs = {
  id: Scalars['ID'];
};


export type QueryConversationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryMessagesArgs = {
  conversationId: Scalars['ID'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryConversationNumbersArgs = {
  input?: Maybe<ConversationNumberInput>;
};


export type QueryTwilioNumbersArgs = {
  input?: Maybe<TwilioNumbersInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QuerySearchConversationV2Args = {
  query?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['Boolean']>;
  call?: Maybe<Scalars['Boolean']>;
};


export type QueryMessageStatusInfoArgs = {
  input?: Maybe<MessageStatusInfo>;
};


export type QueryGetCapabilityTokenArgs = {
  number_id?: Maybe<Scalars['ID']>;
  platform?: Maybe<CallDeviceType>;
};


export type QueryGetTextCapabilityTokenArgs = {
  platform?: Maybe<CallDeviceType>;
};


export type QueryGetLeadFromNumberArgs = {
  number: Scalars['String'];
};


export type QueryGetCallHistoryArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetMiscallsArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetCrmContactArgs = {
  company_id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QuerySetCallBindingArgs = {
  input?: Maybe<CallBinding>;
};


export type QueryDelCallBindingArgs = {
  input?: Maybe<CallBinding>;
};


export type QueryGetQueueArgs = {
  company_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetCallDetailArgs = {
  call_sid: Scalars['String'];
};


export type QueryGetBlockNumbersArgs = {
  admin_id: Scalars['ID'];
};


export type QueryGetContactArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryGetVmArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryMatchedpropertyleadArgs = {
  input?: Maybe<MatchedPropertyLeadInput>;
};


export type QueryMatchedpropertyleadV2Args = {
  input?: Maybe<MatchedPropertyLeadInputV2>;
};


export type QueryMatchedpropertyleadV3Args = {
  input?: Maybe<MatchedPropertyLeadInputV2>;
};


export type QueryTodayMatchPropertyArgs = {
  input?: Maybe<TodayMatchPropertyInput>;
};


export type QueryLeadOfferTypeArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
};


export type QueryLeadContractArgs = {
  input?: Maybe<LeadContractQueryInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryLeadOffersArgs = {
  input?: Maybe<LeadOfferQueryInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryLeadSoldArgs = {
  input?: Maybe<LeadSoldQueryInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryLeadListingArgs = {
  input?: Maybe<LeadListingQueryInput>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryTimezonesArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryAdminsArgs = {
  input?: Maybe<AdminInput>;
};


export type QueryCompanyAdminsArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
  company_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type QueryAdminByIdsArgs = {
  input?: Maybe<AdminByIdInput>;
};

export type Admin = {
  __typename?: 'Admin';
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  dre_no?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  timezoneInfo?: Maybe<TimeZone>;
  lead_percentage?: Maybe<Scalars['String']>;
  commission_percentage?: Maybe<Scalars['String']>;
  appointmentInvites: Array<Appointment>;
  appType?: Maybe<Scalars['String']>;
  realtorPersonal?: Maybe<RealtorPersonal>;
  realtorInfo?: Maybe<RealtorInfo>;
  realtorBankDetail?: Maybe<RealtorBankDetail>;
  conciergePersonal?: Maybe<ConciergePersonal>;
  conciergeBankDetail?: Maybe<ConciergeBankDetail>;
  roles?: Maybe<Array<Maybe<Role>>>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
  officesUnique?: Maybe<Array<Office>>;
  officeUser?: Maybe<Array<Office>>;
  territories?: Maybe<Array<Territory>>;
  territoryCount?: Maybe<Scalars['Int']>;
  statustext?: Maybe<Scalars['String']>;
  templates?: Maybe<Array<Template>>;
  type?: Maybe<Scalars['String']>;
  typeClass?: Maybe<Scalars['String']>;
  userOfficeTerritory?: Maybe<Array<UserOfficeTerritory>>;
  leadAssignRealtorCount?: Maybe<Scalars['Int']>;
  leadAssignConciergeCount?: Maybe<Scalars['Int']>;
  adminSignature?: Maybe<Array<Maybe<AdminSignatures>>>;
  action?: Maybe<Scalars['Int']>;
  hq_contact_no?: Maybe<Scalars['String']>;
  hq_star_rating?: Maybe<Scalars['String']>;
  hq_recent_deals?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
  lenderApproved?: Maybe<LenderApproved>;
  twilioNumbers?: Maybe<Array<Maybe<TwilioNumbers>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  referral_due?: Maybe<Scalars['String']>;
};


export type AdminCompaniesArgs = {
  status?: Maybe<Scalars['Int']>;
};

export type TimeZone = {
  __typename?: 'TimeZone';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Appointment = {
  __typename?: 'Appointment';
  id: Scalars['ID'];
  lead_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['Int']>;
  end_timestamp?: Maybe<Scalars['Int']>;
  is_all_day?: Maybe<Scalars['Int']>;
  is_send_email?: Maybe<Scalars['Int']>;
  is_send_sms?: Maybe<Scalars['Int']>;
  reminder_frequesncy?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  appointmenttype?: Maybe<AppointmentType>;
  appointmentInvites: Array<Admin>;
  appointmentInvitesLead: Array<Lead>;
  appointmentInvitesEmail?: Maybe<Array<Maybe<AppointmentsEmail>>>;
  properties: Array<Maybe<AppointmentProperty>>;
  calendars?: Maybe<Array<Calendar>>;
  created_at?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  createBy?: Maybe<Admin>;
  create_app_type?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

export type AppointmentType = {
  __typename?: 'AppointmentType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Lead = {
  __typename?: 'Lead';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  rating?: Maybe<Scalars['Int']>;
  is_buyer?: Maybe<Scalars['Int']>;
  is_seller?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  analytics_id?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<LeadEmail>>>;
  mobiles?: Maybe<Array<Maybe<LeadMobile>>>;
  source?: Maybe<Source>;
  address?: Maybe<Array<Maybe<LeadAddress>>>;
  todotasks?: Maybe<TodoTaskPaginator>;
  impnote?: Maybe<Array<Maybe<LeadImpNote>>>;
  appointments?: Maybe<Array<Maybe<Appointment>>>;
  tags?: Maybe<Array<Maybe<LeadTag>>>;
  appType?: Maybe<Scalars['String']>;
  user?: Maybe<UserHq>;
  connectedLeads?: Maybe<Array<Maybe<ConnectedLead>>>;
  stage?: Maybe<Stage>;
  assignRealtor?: Maybe<Array<Maybe<AssignRealtor>>>;
  assignConcierge?: Maybe<Array<Maybe<AssignRealtor>>>;
  leadPreferences?: Maybe<LeadPreferencesLead>;
  lenderApproved?: Maybe<LenderApproved>;
  leadReasons?: Maybe<Array<Maybe<LeadReasons>>>;
  company_id?: Maybe<Scalars['Int']>;
};


export type LeadTodotasksArgs = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
};


export type LeadImpnoteArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
};


export type LeadEmail = {
  __typename?: 'LeadEmail';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  is_primary?: Maybe<Scalars['Boolean']>;
  subscribe_at?: Maybe<Scalars['DateTime']>;
  unsubscribed?: Maybe<Scalars['Boolean']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  lead?: Maybe<Lead>;
};

export type LeadMobile = {
  __typename?: 'LeadMobile';
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  raw_number?: Maybe<Scalars['String']>;
  formatted_number?: Maybe<Scalars['String']>;
  is_blacklisted?: Maybe<Scalars['Boolean']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  is_primary?: Maybe<Scalars['Boolean']>;
  geo_permission?: Maybe<Scalars['String']>;
  lead?: Maybe<Lead>;
};

export type Source = {
  __typename?: 'Source';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  leads?: Maybe<Array<Maybe<Lead>>>;
};

export type LeadAddress = {
  __typename?: 'LeadAddress';
  id: Scalars['ID'];
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip_code: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  country: Country;
  lead: Lead;
  other?: Maybe<Scalars['String']>;
};

export type Country = {
  __typename?: 'Country';
  id: Scalars['ID'];
  name: Scalars['String'];
};

/** A paginated list of TodoTask items. */
export type TodoTaskPaginator = {
  __typename?: 'TodoTaskPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of TodoTask items. */
  data: Array<TodoTask>;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

export type TodoTask = {
  __typename?: 'TodoTask';
  id: Scalars['ID'];
  lead_id?: Maybe<Scalars['Int']>;
  task_date_time?: Maybe<Scalars['String']>;
  is_completed?: Maybe<Scalars['Int']>;
  priority?: Maybe<Scalars['String']>;
  todo_type_id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  lead?: Maybe<Lead>;
  leadleads?: Maybe<Lead>;
  admin?: Maybe<Admin>;
  realtor?: Maybe<Admin>;
  calendars?: Maybe<Array<Calendar>>;
  createdBy?: Maybe<Admin>;
};

export type Calendar = {
  __typename?: 'Calendar';
  id?: Maybe<Scalars['Int']>;
  op_type?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
  appointment?: Maybe<GetAppointmentType>;
};

export type GetAppointmentType = {
  __typename?: 'getAppointmentType';
  id: Scalars['ID'];
  lead_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['Int']>;
  end_timestamp?: Maybe<Scalars['Int']>;
  is_all_day?: Maybe<Scalars['Int']>;
  is_send_email?: Maybe<Scalars['Int']>;
  is_send_sms?: Maybe<Scalars['Int']>;
  reminder_frequesncy?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  properties?: Maybe<Array<Maybe<DbProperty>>>;
  appointment_invites?: Maybe<Array<Maybe<Admin>>>;
  appointment_invites_lead?: Maybe<Array<Maybe<Lead>>>;
  appointment_invites_email?: Maybe<Array<Maybe<AppointmentsEmail>>>;
  create_by?: Maybe<Admin>;
  appointmenttype?: Maybe<AppointmentType>;
  created_at?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  create_app_type?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

export type DbProperty = {
  __typename?: 'dbProperty';
  property_id?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
};

export type AppointmentsEmail = {
  __typename?: 'appointmentsEmail';
  email?: Maybe<Scalars['String']>;
};

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  field: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type LeadImpNote = {
  __typename?: 'LeadImpNote';
  id: Scalars['ID'];
  note: Scalars['String'];
};

export type LeadTag = {
  __typename?: 'LeadTag';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  lead_tag_type?: Maybe<Scalars['String']>;
};

export type UserHq = {
  __typename?: 'UserHq';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type ConnectedLead = {
  __typename?: 'ConnectedLead';
  id: Scalars['ID'];
  lead_id: Scalars['Int'];
  connect_id: Scalars['Int'];
  relationship: Scalars['String'];
  lead?: Maybe<Lead>;
};

export type Stage = {
  __typename?: 'Stage';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order_weight?: Maybe<Scalars['String']>;
  chileStage?: Maybe<Array<Maybe<Stage>>>;
  parentStage?: Maybe<Stage>;
};

export type AssignRealtor = {
  __typename?: 'assignRealtor';
  id?: Maybe<Scalars['ID']>;
  admin?: Maybe<Admin>;
  status?: Maybe<Scalars['String']>;
};

export type LeadPreferencesLead = {
  __typename?: 'leadPreferencesLead';
  id: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
};

export type LenderApproved = {
  __typename?: 'LenderApproved';
  id: Scalars['ID'];
  lead?: Maybe<Lead>;
  createdBy?: Maybe<Admin>;
  approval_amount?: Maybe<Scalars['String']>;
  financingType?: Maybe<LenderFinancingType>;
  loan_officer_name?: Maybe<Scalars['String']>;
  mortgage_co_name?: Maybe<Scalars['String']>;
};

export type LenderFinancingType = {
  __typename?: 'LenderFinancingType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type LeadReasons = {
  __typename?: 'leadReasons';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  order_weight?: Maybe<Scalars['Int']>;
};

export type AppointmentProperty = {
  __typename?: 'appointmentProperty';
  property?: Maybe<PropertyInfo>;
};

export type PropertyInfo = {
  __typename?: 'PropertyInfo';
  id?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  address_full?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['String']>;
  full_bathrooms?: Maybe<Scalars['String']>;
  bathrooms_total_integer?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  living_area?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  public_remarks?: Maybe<Scalars['String']>;
  media?: Maybe<Array<Maybe<PropertyInfoMedia>>>;
  media_status?: Maybe<Scalars['Int']>;
  media_relation?: Maybe<Array<Maybe<MediaRelationData>>>;
};

export type PropertyInfoMedia = {
  __typename?: 'PropertyInfoMedia';
  url?: Maybe<Scalars['String']>;
};

export type MediaRelationData = {
  __typename?: 'mediaRelationData';
  pid?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
};

export type RealtorPersonal = {
  __typename?: 'RealtorPersonal';
  avatar?: Maybe<Scalars['Upload']>;
  personal_email: Scalars['String'];
  personal_phone_no: Scalars['String'];
  dob: Scalars['String'];
  address?: Maybe<Address>;
  languages?: Maybe<Array<Maybe<Language>>>;
};


export type Address = {
  __typename?: 'Address';
  id: Scalars['Int'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country?: Maybe<Country>;
};

export type Language = {
  __typename?: 'Language';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type RealtorInfo = {
  __typename?: 'RealtorInfo';
  drivingLicense?: Maybe<Document>;
  carInsurance?: Maybe<Document>;
  realtorLicense?: Maybe<Document>;
  realtor_license_no?: Maybe<Scalars['String']>;
  realtor_license_expire_date?: Maybe<Scalars['String']>;
  income_goal?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  technology_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  realtorInfoWod?: Maybe<Array<WeekOfDay>>;
};

export type Document = {
  __typename?: 'Document';
  id: Scalars['ID'];
  image: Scalars['String'];
};

export type WeekOfDay = {
  __typename?: 'WeekOfDay';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RealtorBankDetail = {
  __typename?: 'RealtorBankDetail';
  account_name: Scalars['String'];
  bank_name: Scalars['String'];
  account_number: Scalars['String'];
  routing_number: Scalars['String'];
  address?: Maybe<Address>;
  account_type: Scalars['String'];
  account_type_value: Scalars['String'];
};

export type ConciergePersonal = {
  __typename?: 'ConciergePersonal';
  personal_email: Scalars['String'];
  personal_phone_no: Scalars['String'];
  dob: Scalars['String'];
  address?: Maybe<Address>;
  idCard?: Maybe<Document>;
  languages?: Maybe<Array<Maybe<Language>>>;
};

export type ConciergeBankDetail = {
  __typename?: 'ConciergeBankDetail';
  bank_name: Scalars['String'];
  account_number: Scalars['String'];
  routing_number: Scalars['String'];
  account_type: Scalars['String'];
  account_type_value: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  id: Scalars['ID'];
  name: Scalars['String'];
  company_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type Permission = {
  __typename?: 'Permission';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Office = {
  __typename?: 'Office';
  id: Scalars['ID'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone_no: Scalars['String'];
  officeterritories?: Maybe<Array<Territory>>;
  address?: Maybe<Address>;
  admins?: Maybe<Array<Maybe<Admin>>>;
  createdBy?: Maybe<Admin>;
  officeManager?: Maybe<Admin>;
  companyOffice?: Maybe<Company>;
  officeAdmin?: Maybe<Array<Maybe<Admin>>>;
};

export type Territory = {
  __typename?: 'Territory';
  id: Scalars['ID'];
  name: Scalars['String'];
  territoryInfo?: Maybe<Array<TerritoryInfo>>;
  offices?: Maybe<Array<Office>>;
  admins?: Maybe<Array<Admin>>;
  userOfficeTerritory?: Maybe<Array<Maybe<UserOfficeTerritory>>>;
};

export type TerritoryInfo = {
  __typename?: 'TerritoryInfo';
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<State>;
  city?: Maybe<City>;
  county?: Maybe<County>;
  zipcode?: Maybe<Zipcode>;
  company?: Maybe<Company>;
  territory?: Maybe<Territory>;
};

export type State = {
  __typename?: 'State';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type City = {
  __typename?: 'City';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type County = {
  __typename?: 'County';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type Zipcode = {
  __typename?: 'Zipcode';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  territoryDetails?: Maybe<Array<Maybe<Territory>>>;
};

export type Company = {
  __typename?: 'Company';
  id?: Maybe<Scalars['ID']>;
  company_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  no_ISAs?: Maybe<Scalars['Int']>;
  no_realtor?: Maybe<Scalars['Int']>;
  lead_percentage?: Maybe<Scalars['Int']>;
  action?: Maybe<Scalars['Int']>;
  created_by?: Maybe<Admin>;
  roles?: Maybe<Array<Maybe<Role>>>;
};

export type UserOfficeTerritory = {
  __typename?: 'userOfficeTerritory';
  id?: Maybe<Scalars['ID']>;
  lead_percentage?: Maybe<Scalars['String']>;
  office: Office;
  territory?: Maybe<Territory>;
  admin?: Maybe<Admin>;
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  templateCategories?: Maybe<Array<Maybe<TemplateCategory>>>;
};

export type TemplateCategory = {
  __typename?: 'TemplateCategory';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AdminSignatures = {
  __typename?: 'AdminSignatures';
  id: Scalars['ID'];
  signature?: Maybe<Scalars['String']>;
  is_primary?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
};

export type TwilioNumbers = {
  __typename?: 'twilioNumbers';
  id?: Maybe<Scalars['ID']>;
  friendly_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  area_code?: Maybe<Scalars['String']>;
  sms_app_sid?: Maybe<Scalars['String']>;
  voice_app_sid?: Maybe<Scalars['String']>;
  is_selected?: Maybe<Scalars['Boolean']>;
  queue?: Maybe<Queue>;
};

export type Queue = {
  __typename?: 'Queue';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<MemberDetail>>>;
  members_count?: Maybe<Scalars['Int']>;
  company?: Maybe<Company>;
};

export type MemberDetail = {
  __typename?: 'memberDetail';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
};

export type ConnectLeadQuery = {
  lead_id: Scalars['Int'];
};

/** A paginated list of ConnectedLead items. */
export type ConnectedLeadPaginator = {
  __typename?: 'ConnectedLeadPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ConnectedLead items. */
  data: Array<ConnectedLead>;
};

export type LeadLastTouchInput = {
  lead_id: Scalars['Int'];
};

export type LeadLastTouchType = {
  __typename?: 'LeadLastTouchType';
  message?: Maybe<Scalars['String']>;
  mail_last_activity_at?: Maybe<Scalars['String']>;
  mail_last_activity_type?: Maybe<Scalars['Int']>;
};

export type LeadIdByReasonsInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of Lead items. */
export type LeadPaginator = {
  __typename?: 'LeadPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Lead items. */
  data: Array<Lead>;
};

export type LeadCountInput = {
  lead_id: Scalars['ID'];
};

export type LeadCount = {
  __typename?: 'LeadCount';
  mail?: Maybe<Scalars['Int']>;
  call?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['Int']>;
  calendar?: Maybe<Scalars['Int']>;
  cancelled_count?: Maybe<Scalars['Int']>;
  confirmed_count?: Maybe<Scalars['Int']>;
  no_listing_matches_today?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  inquiry_at?: Maybe<Scalars['String']>;
  last_visit?: Maybe<Scalars['String']>;
  mail_last_activity_at?: Maybe<Scalars['String']>;
  mail_last_activity_type?: Maybe<Scalars['Int']>;
};

export type LeadImpNoteInput = {
  lead_id: Scalars['Int'];
};

export type LeadByIdsInput = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  key?: Maybe<Scalars['String']>;
};

/** A paginated list of Source items. */
export type SourcePaginator = {
  __typename?: 'SourcePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Source items. */
  data: Array<Source>;
};

export type GlobalTodoInput = {
  tap: Scalars['String'];
  priority?: Maybe<Array<Scalars['String']>>;
  task_types?: Maybe<Array<Scalars['Int']>>;
  admin_id: Scalars['Int'];
  high_priority?: Maybe<Scalars['Int']>;
};

export type GlobalTodoCountInput = {
  priority?: Maybe<Array<Scalars['String']>>;
  task_types?: Maybe<Array<Scalars['Int']>>;
  admin_id: Scalars['Int'];
  high_priority?: Maybe<Scalars['Int']>;
};

export type GlobalTodoCountType = {
  __typename?: 'globalTodoCountType';
  today_tomorrow?: Maybe<Scalars['Int']>;
  upcoming?: Maybe<Scalars['Int']>;
  overdue?: Maybe<Scalars['Int']>;
  completed?: Maybe<Scalars['Int']>;
  other_task?: Maybe<Scalars['Int']>;
};

export type LeadByAdminInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

export type LeadTodoInput = {
  tab: Scalars['String'];
  lead_id: Scalars['Int'];
};

export type TodoData = {
  __typename?: 'todoData';
  data?: Maybe<Array<TodoTask>>;
  todoCount?: Maybe<TodoDataCount>;
};

export type TodoDataCount = {
  __typename?: 'todoDataCount';
  upcoming_count?: Maybe<Scalars['Int']>;
  overdue_count?: Maybe<Scalars['Int']>;
  completed_count?: Maybe<Scalars['Int']>;
};

export type TodoType = {
  __typename?: 'TodoType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

/** A paginated list of SaveSearch items. */
export type SaveSearchPaginator = {
  __typename?: 'SaveSearchPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of SaveSearch items. */
  data: Array<SaveSearch>;
};

export type SaveSearch = {
  __typename?: 'SaveSearch';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
  admin_id?: Maybe<Scalars['Int']>;
};

export type AdminAssignNumberInput = {
  admin_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AdminAssignNumbers = {
  __typename?: 'adminAssignNumbers';
  id?: Maybe<Scalars['Int']>;
  tn_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  twilio_numbers?: Maybe<TwilioNumbers>;
};

export type CheckAdminInput = {
  id?: Maybe<Scalars['ID']>;
  type?: Maybe<Scalars['String']>;
};

export type CheckAdminResponse = {
  __typename?: 'checkAdminResponse';
  error?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
};

export type CompanyFeaturePermissionInput = {
  company_id?: Maybe<Scalars['Int']>;
};

export type CompanyFeature = {
  __typename?: 'CompanyFeature';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  companyFeaturePermissions?: Maybe<Array<Maybe<CompanyFeaturePermission>>>;
};

export type CompanyFeaturePermission = {
  __typename?: 'CompanyFeaturePermission';
  id?: Maybe<Scalars['Int']>;
  permission_id?: Maybe<Scalars['Int']>;
  permissions?: Maybe<Permission>;
};

export type CompanyRoleInput = {
  company_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RolePermission = {
  __typename?: 'RolePermission';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
};

export type CompanyRolePermissionInput = {
  company_id?: Maybe<Scalars['Int']>;
};

export type WorkingHour = {
  __typename?: 'WorkingHour';
  id?: Maybe<Scalars['ID']>;
  day?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
};

/** A paginated list of Appointment items. */
export type AppointmentPaginator = {
  __typename?: 'AppointmentPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Appointment items. */
  data: Array<Appointment>;
};

export type GetAppointmentsInput = {
  lead_id?: Maybe<Scalars['ID']>;
  tab: Scalars['String'];
};

export type AppointmentData = {
  __typename?: 'AppointmentData';
  data?: Maybe<Array<Maybe<GetAppointmentType>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
  appointmentCount?: Maybe<AppointmentDataCount>;
};

export type AppointmentDataCount = {
  __typename?: 'appointmentDataCount';
  upcoming_count?: Maybe<Scalars['Int']>;
  past_appointment_count?: Maybe<Scalars['Int']>;
  cancelled_count?: Maybe<Scalars['Int']>;
  no_show_count?: Maybe<Scalars['Int']>;
};

export type ClientMeetingData = {
  __typename?: 'ClientMeetingData';
  appointmentData?: Maybe<Array<Maybe<GetAppointmentType>>>;
  appointmentCount?: Maybe<ClientMeetindDataCount>;
};

export type ClientMeetindDataCount = {
  __typename?: 'clientMeetindDataCount';
  upcoming_count?: Maybe<Scalars['Int']>;
  past_appointment_count?: Maybe<Scalars['Int']>;
  cancelled_count?: Maybe<Scalars['Int']>;
};

/** A paginated list of ActivityData items. */
export type ActivityDataPaginator = {
  __typename?: 'ActivityDataPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of ActivityData items. */
  data: Array<ActivityData>;
};

export type ActivityData = {
  __typename?: 'ActivityData';
  id?: Maybe<Scalars['ID']>;
  log_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  properties?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
  causer?: Maybe<Admin>;
  created_at?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  timeSpentPretty?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};


export type Activity = {
  __typename?: 'Activity';
  cursor?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<ActivityData>>>;
  total?: Maybe<Scalars['String']>;
};

export type LeadAutoMail = {
  __typename?: 'leadAutoMail';
  unSubscribeUrl?: Maybe<Scalars['String']>;
  autoMail?: Maybe<AutoMailsSent>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
};

export type AutoMailsSent = {
  __typename?: 'autoMailsSent';
  id?: Maybe<Scalars['Int']>;
  date_time?: Maybe<Scalars['DateTime']>;
  created_by?: Maybe<Admin>;
  archive_properties?: Maybe<Array<Maybe<ArchiveProperties>>>;
};

export type ArchiveProperties = {
  __typename?: 'archiveProperties';
  archive_id?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
};

/** A paginated list of Country items. */
export type CountryPaginator = {
  __typename?: 'CountryPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Country items. */
  data: Array<Country>;
};

export type Form = {
  __typename?: 'Form';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  questions?: Maybe<Array<Question>>;
};


export type FormQuestionsArgs = {
  orderBy?: Maybe<Array<OrderByClause>>;
};

export type Question = {
  __typename?: 'Question';
  id: Scalars['ID'];
  title: Scalars['String'];
  field_placeholder: Scalars['String'];
  options?: Maybe<Array<Maybe<Option>>>;
  questiontype?: Maybe<QuestionType>;
  questionAnswer?: Maybe<Array<LeadQuestionAnswer>>;
};


export type QuestionQuestionAnswerArgs = {
  lead_id: Scalars['Int'];
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  option_type?: Maybe<Scalars['String']>;
  option_label?: Maybe<Scalars['String']>;
  questionType?: Maybe<QuestionType>;
  parentOption?: Maybe<Array<Maybe<Option>>>;
  parentOptionAnswer?: Maybe<LeadQuestionAnswer>;
};

export type QuestionType = {
  __typename?: 'QuestionType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LeadQuestionAnswer = {
  __typename?: 'leadQuestionAnswer';
  id: Scalars['ID'];
  options?: Maybe<Array<Maybe<Option>>>;
  answer?: Maybe<Scalars['String']>;
  is_primary?: Maybe<Scalars['Int']>;
  is_valid?: Maybe<Scalars['Int']>;
  option_type?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  is_follow_task?: Maybe<Scalars['Int']>;
  follow_task_date?: Maybe<Scalars['String']>;
};

export type PropertyDataInput = {
  lead_id: Scalars['ID'];
  page: Scalars['Int'];
  first: Scalars['Int'];
};

export type PropertyHomeData = {
  __typename?: 'PropertyHomeData';
  data?: Maybe<Array<Maybe<PropertyHome>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type PropertyHome = {
  __typename?: 'PropertyHome';
  id: Scalars['ID'];
  property_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<PropertyTags>>;
};

export type PropertyTags = {
  __typename?: 'PropertyTags';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type PropertyGetInput = {
  lead_id: Scalars['ID'];
};

/** A paginated list of UserSaveSearches items. */
export type UserSaveSearchesPaginator = {
  __typename?: 'UserSaveSearchesPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of UserSaveSearches items. */
  data: Array<UserSaveSearches>;
};

export type UserSaveSearches = {
  __typename?: 'UserSaveSearches';
  id: Scalars['ID'];
  name: Scalars['String'];
  query?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
  auto?: Maybe<Scalars['Int']>;
};

export type ShowingData = {
  __typename?: 'ShowingData';
  data?: Maybe<Array<Maybe<ShowingType>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ShowingType = {
  __typename?: 'ShowingType';
  id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
  appointment_type_id?: Maybe<Scalars['Int']>;
  event_calender_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['String']>;
  end_timestamp?: Maybe<Scalars['String']>;
  is_all_day?: Maybe<Scalars['Int']>;
  is_send_email?: Maybe<Scalars['Int']>;
  is_send_sms?: Maybe<Scalars['Int']>;
  reminder_frequesncy?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  create_app_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  create_by?: Maybe<Admin>;
  properties?: Maybe<Array<Maybe<DbProperty>>>;
  appointment_invites?: Maybe<Array<Maybe<Admin>>>;
  appointment_invites_lead?: Maybe<Array<Maybe<Lead>>>;
  appointmenttype?: Maybe<AppointmentType>;
};

export type ContactAgent = {
  __typename?: 'ContactAgent';
  data?: Maybe<Array<Maybe<InterestedProperties>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type InterestedProperties = {
  __typename?: 'InterestedProperties';
  id: Scalars['ID'];
  status?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  property_id?: Maybe<Scalars['String']>;
  financing?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['String']>;
};

export type RestrictedFieldInput = {
  mls_id?: Maybe<Scalars['String']>;
};

export type RestrictedFieldType = {
  __typename?: 'restrictedFieldType';
  mls_id?: Maybe<Scalars['String']>;
  agent_first_name?: Maybe<Scalars['String']>;
  agent_last_name?: Maybe<Scalars['String']>;
  agent_mls_id?: Maybe<Scalars['String']>;
  office?: Maybe<Scalars['String']>;
  office_phone?: Maybe<Scalars['String']>;
  confidential_remarks?: Maybe<Scalars['String']>;
  showing_instructions?: Maybe<Scalars['String']>;
  supra_box?: Maybe<Scalars['String']>;
  commission?: Maybe<Scalars['String']>;
  disclosures?: Maybe<Scalars['String']>;
  associated_docs?: Maybe<Scalars['String']>;
  occupied?: Maybe<Scalars['String']>;
  occupant_name?: Maybe<Scalars['String']>;
  occupant_phone?: Maybe<Scalars['String']>;
  sale_conditions?: Maybe<Scalars['String']>;
  hoa_yn?: Maybe<Scalars['String']>;
  hoa_name?: Maybe<Scalars['String']>;
  hoa_amount?: Maybe<Scalars['String']>;
  hoa_phone?: Maybe<Scalars['String']>;
  hoa_paid?: Maybe<Scalars['String']>;
  Planned_unit_development?: Maybe<Scalars['String']>;
  water_source?: Maybe<Scalars['String']>;
  sewer_septic?: Maybe<Scalars['String']>;
  seller_will_consider?: Maybe<Scalars['String']>;
  concessions?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
  multiple_offers?: Maybe<Scalars['String']>;
  SP_OP?: Maybe<Scalars['String']>;
  selling_price?: Maybe<Scalars['String']>;
  selling_date?: Maybe<Scalars['String']>;
  pending_date?: Maybe<Scalars['String']>;
  listing_key?: Maybe<Scalars['String']>;
  mls_name?: Maybe<Scalars['String']>;
};

export type RestrictedFieldInputV2 = {
  mls_id?: Maybe<Array<Scalars['String']>>;
};

export type PopupFieldInput = {
  lead_id: Scalars['ID'];
  popup_id: Scalars['ID'];
  page: Scalars['Int'];
  first: Scalars['Int'];
};

export type PopupTypeData = {
  __typename?: 'popupTypeData';
  data?: Maybe<Array<Maybe<PopupType>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type PopupType = {
  __typename?: 'popupType';
  id?: Maybe<Scalars['Int']>;
  property_id?: Maybe<Scalars['String']>;
  popup_id?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['String']>;
};

export type TabFieldInput = {
  lead_id: Scalars['ID'];
};

export type TabTypeData = {
  __typename?: 'tabTypeData';
  saved_home_count?: Maybe<Scalars['Int']>;
  search_saved_count?: Maybe<Scalars['Int']>;
  showing_count?: Maybe<Scalars['Int']>;
  contact_agent_count?: Maybe<Scalars['Int']>;
  buyer_count?: Maybe<Scalars['Int']>;
  get_a_call_section?: Maybe<Scalars['Int']>;
};

export type ShortUrlInput = {
  mls_ids: Array<Scalars['String']>;
};

export type ShortUrlType = {
  __typename?: 'shortUrlType';
  mls_id?: Maybe<Scalars['String']>;
  shortUrl?: Maybe<Scalars['String']>;
};

export type AddressSearchInput = {
  key?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
};

export type AddressSearchType = {
  __typename?: 'AddressSearchType';
  data?: Maybe<Array<Maybe<AddressSearch>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type AddressSearch = {
  __typename?: 'AddressSearch';
  display?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type AddressLiveSearchInput = {
  key?: Maybe<Scalars['String']>;
};

export type Property = {
  __typename?: 'Property';
  id?: Maybe<Scalars['Int']>;
  address?: Maybe<Address>;
};

export type BuySideInput = {
  key?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderByClauseAdmin>;
  company_id: Scalars['Int'];
  realtor_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  commission_HUD_Status?: Maybe<Array<Maybe<Scalars['String']>>>;
  received_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  brokerage_paid_agent?: Maybe<Array<Maybe<Scalars['Int']>>>;
  payment_method?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type OrderByClauseAdmin = {
  field: Scalars['String'];
  order: Scalars['String'];
};

/** A paginated list of LeadContract items. */
export type LeadContractPaginator = {
  __typename?: 'LeadContractPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of LeadContract items. */
  data: Array<LeadContract>;
};

export type LeadContract = {
  __typename?: 'LeadContract';
  id?: Maybe<Scalars['Int']>;
  lead?: Maybe<Lead>;
  createdBy?: Maybe<Admin>;
  realtor?: Maybe<Admin>;
  contract_date?: Maybe<Scalars['String']>;
  commission_rate?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
  estimated_close_date?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  contract_price?: Maybe<Scalars['String']>;
  offer?: Maybe<LeadOffer>;
  contract_status?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['String']>;
  appraisal_due?: Maybe<Scalars['String']>;
  inspection_due?: Maybe<Scalars['String']>;
  loan_due?: Maybe<Scalars['String']>;
  client_credit?: Maybe<Scalars['String']>;
  referral_due?: Maybe<Scalars['String']>;
  commission_hud_status?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  brokerage_paid_agent?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  other_payment?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
};

export type LeadOffer = {
  __typename?: 'LeadOffer';
  id?: Maybe<Scalars['Int']>;
  lead?: Maybe<Lead>;
  leadOfferType?: Maybe<LeadOfferType>;
  createdBy?: Maybe<Admin>;
  realtor?: Maybe<Admin>;
  offer_date?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  offer_amount?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  offer_type?: Maybe<Scalars['String']>;
  offer_status?: Maybe<Scalars['String']>;
  offer_closing_status?: Maybe<Scalars['String']>;
  closing_price?: Maybe<Scalars['String']>;
  closing_date?: Maybe<Scalars['String']>;
  accepted_price?: Maybe<Scalars['String']>;
  agent_submitted_date?: Maybe<Scalars['String']>;
  contract?: Maybe<Contact>;
  property_address?: Maybe<Scalars['String']>;
};

export type LeadOfferType = {
  __typename?: 'LeadOfferType';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  numbers?: Maybe<Array<Maybe<ContactNumber>>>;
};

export type ContactNumber = {
  __typename?: 'ContactNumber';
  id: Scalars['ID'];
  ext_no?: Maybe<Scalars['String']>;
  raw_number?: Maybe<Scalars['String']>;
  formatted_number?: Maybe<Scalars['String']>;
  blockNumber?: Maybe<Scalars['Int']>;
  contact?: Maybe<CallContact>;
};

export type CallContact = {
  __typename?: 'CallContact';
  id: Scalars['ID'];
  numbers?: Maybe<Array<Maybe<FromNumber>>>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type FromNumber = {
  __typename?: 'FromNumber';
  id?: Maybe<Scalars['ID']>;
  phone_number: Scalars['String'];
  queue?: Maybe<Queue>;
};

export type ListingSideInput = {
  key?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderByClauseAdmin>;
  company_id: Scalars['Int'];
  realtor_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  commission_HUD_Status?: Maybe<Array<Maybe<Scalars['String']>>>;
  commission_executed?: Maybe<Array<Maybe<Scalars['String']>>>;
  received_status?: Maybe<Array<Maybe<Scalars['String']>>>;
  brokerage_paid_agent?: Maybe<Array<Maybe<Scalars['Int']>>>;
  payment_method?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A paginated list of LeadListing items. */
export type LeadListingPaginator = {
  __typename?: 'LeadListingPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of LeadListing items. */
  data: Array<LeadListing>;
};

export type LeadListing = {
  __typename?: 'LeadListing';
  id?: Maybe<Scalars['Int']>;
  lead?: Maybe<Lead>;
  createdBy?: Maybe<Admin>;
  realtor?: Maybe<Admin>;
  agreement_signed_date?: Maybe<Scalars['String']>;
  commission_rate?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
  estimated_live_date?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  listing_price?: Maybe<Scalars['String']>;
  remodel_sale?: Maybe<Scalars['Int']>;
  llc_project?: Maybe<Scalars['Int']>;
  offer?: Maybe<LeadOffer>;
  listing_expiration?: Maybe<Scalars['String']>;
  closing_date?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['String']>;
  inspection_due?: Maybe<Scalars['String']>;
  appraisal_due?: Maybe<Scalars['String']>;
  loan_due?: Maybe<Scalars['String']>;
  third_party_fees?: Maybe<Scalars['String']>;
  fee_captured?: Maybe<Scalars['Int']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
  listing_status?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  commission_hud_status?: Maybe<Scalars['String']>;
  brokerage_paid_agent?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  other_payment?: Maybe<Scalars['String']>;
  commission_demand_executed?: Maybe<Scalars['String']>;
};

export type CashFlowInput = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  company_id: Scalars['Int'];
};

export type CashData = {
  __typename?: 'CashData';
  month?: Maybe<Scalars['String']>;
  range?: Maybe<Scalars['String']>;
  gross_income?: Maybe<Scalars['Float']>;
  kendrick_income?: Maybe<Scalars['Float']>;
  buy_fees?: Maybe<Scalars['Float']>;
  listing_fees?: Maybe<Scalars['Float']>;
  buy_closing?: Maybe<Scalars['Int']>;
  listing_closing?: Maybe<Scalars['Int']>;
  buy_mls_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  listing_mls_ids?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ActualIncomeInput = {
  company_id: Scalars['Int'];
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type Income = {
  __typename?: 'Income';
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type BuySideGraphInput = {
  company_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};

export type GraphData = {
  __typename?: 'GraphData';
  date?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['String']>;
  total_fees?: Maybe<Scalars['Float']>;
  dateRange?: Maybe<Array<Maybe<Range>>>;
};

export type Range = {
  __typename?: 'Range';
  date_range?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
};

export type IncomeData = {
  __typename?: 'IncomeData';
  month?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
  range?: Maybe<Scalars['String']>;
};

export type SummaryInput = {
  company_id: Scalars['Int'];
  realtor_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  is_type?: Maybe<Scalars['String']>;
};

export type OfferSummary = {
  __typename?: 'OfferSummary';
  monthName?: Maybe<Scalars['String']>;
  total_offer?: Maybe<Scalars['Int']>;
  total_accepted?: Maybe<Scalars['Int']>;
  declined_offers?: Maybe<Scalars['Int']>;
  total_closed?: Maybe<Scalars['Int']>;
  cancelled_deals?: Maybe<Scalars['Int']>;
  total_closing_amount?: Maybe<Scalars['Float']>;
};

export type DetailSummary = {
  __typename?: 'DetailSummary';
  monthName?: Maybe<Scalars['String']>;
  actual_listing?: Maybe<Scalars['Int']>;
  in_contract?: Maybe<Scalars['Int']>;
  closed?: Maybe<Scalars['Int']>;
  cancelled?: Maybe<Scalars['Int']>;
  realtor_amount?: Maybe<Scalars['Float']>;
  closing_amount?: Maybe<Scalars['Float']>;
  kendrick_comm?: Maybe<Scalars['Float']>;
  listing_in_contract?: Maybe<Scalars['Int']>;
  listing_closed?: Maybe<Scalars['Int']>;
  listing_cancelled?: Maybe<Scalars['Int']>;
  listing_realtor_amount?: Maybe<Scalars['Float']>;
  listing_closing_amount?: Maybe<Scalars['Float']>;
  listing_kendrick_comm?: Maybe<Scalars['Float']>;
};

export type OffersInput = {
  key?: Maybe<Scalars['String']>;
  orderBy?: Maybe<OrderByClauseAdmin>;
  company_id: Scalars['Int'];
  realtor_id?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  closing_status?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** A paginated list of LeadOffer items. */
export type LeadOfferPaginator = {
  __typename?: 'LeadOfferPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of LeadOffer items. */
  data: Array<LeadOffer>;
};

export type PropertyData = {
  __typename?: 'PropertyData';
  mls_id?: Maybe<Scalars['String']>;
  mls_name?: Maybe<Scalars['String']>;
  cover?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  bedrooms?: Maybe<Scalars['String']>;
  bathrooms_total_integer?: Maybe<Scalars['String']>;
  living_area?: Maybe<Scalars['String']>;
  address_full?: Maybe<Scalars['String']>;
};

export type CycleTimeReportInput = {
  company_id?: Maybe<Scalars['Int']>;
  export_data?: Maybe<Scalars['Boolean']>;
  admin_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  source_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating?: Maybe<Array<Maybe<Scalars['Int']>>>;
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  page: Scalars['Int'];
  first: Scalars['Int'];
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
};

export enum ReportingUserAction {
  Inactive = 'INACTIVE',
  Active = 'ACTIVE'
}

export enum ReportingUserType {
  Realtor = 'REALTOR',
  Concierge = 'CONCIERGE',
  Admin = 'ADMIN',
  Superconcierge = 'SUPERCONCIERGE'
}

export type CycleTimeReportData = {
  __typename?: 'cycleTimeReportData';
  data?: Maybe<Array<Maybe<CycleTimeReport>>>;
  headerData?: Maybe<HeaderCycleTimeReportType>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type CycleTimeReport = {
  __typename?: 'cycleTimeReport';
  month?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['Int']>;
  archive_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dead?: Maybe<Scalars['Int']>;
  dead_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  new?: Maybe<Scalars['Int']>;
  new_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  attempted_contact?: Maybe<Scalars['Int']>;
  attempted_contact_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  has_a_realtor?: Maybe<Scalars['Int']>;
  has_a_realtor_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contacted?: Maybe<Scalars['Int']>;
  contacted_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  showing?: Maybe<Scalars['Int']>;
  showing_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  in_contract?: Maybe<Scalars['Int']>;
  in_contract_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sold?: Maybe<Scalars['Int']>;
  sold_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  total_lead?: Maybe<Scalars['Int']>;
  total_lead_lead?: Maybe<Array<Maybe<Scalars['String']>>>;
  lead_offer?: Maybe<Scalars['Int']>;
  lead_offer_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type HeaderCycleTimeReportType = {
  __typename?: 'headerCycleTimeReportType';
  archive?: Maybe<Scalars['Int']>;
  archive_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  dead?: Maybe<Scalars['Int']>;
  dead_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  new?: Maybe<Scalars['Int']>;
  new_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  attempted_contact?: Maybe<Scalars['Int']>;
  attempted_contact_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  has_a_realtor?: Maybe<Scalars['Int']>;
  has_a_realtor_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  contacted?: Maybe<Scalars['Int']>;
  contacted_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  showing?: Maybe<Scalars['Int']>;
  showing_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  in_contract?: Maybe<Scalars['Int']>;
  in_contract_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sold?: Maybe<Scalars['Int']>;
  sold_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  total_lead?: Maybe<Scalars['Int']>;
  total_lead_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lead_offer?: Maybe<Scalars['Int']>;
  lead_offer_lead?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DashboardLeadsInput = {
  company_id: Scalars['Int'];
  stage_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  source_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  rating?: Maybe<Array<Maybe<Scalars['Int']>>>;
  annualLeads?: Maybe<DashboardLeadsAnnual>;
  monthlyLeads?: Maybe<DashboardMonthlyLeads>;
  todayLeads?: Maybe<DashboardTodayLeads>;
};

export type DashboardLeadsAnnual = {
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type DashboardMonthlyLeads = {
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type DashboardTodayLeads = {
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type DashboardLeadsType = {
  __typename?: 'dashboardLeadsType';
  total_lead?: Maybe<Scalars['Int']>;
  annualLeads?: Maybe<Scalars['Int']>;
  monthlyLeads?: Maybe<Scalars['Int']>;
  todayLeads?: Maybe<Scalars['Int']>;
  totalLeadCrm?: Maybe<Scalars['Int']>;
};

export type DashboardSentListingInput = {
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  company_id: Scalars['Int'];
};

export type DashboardSentListingType = {
  __typename?: 'dashboardSentListingType';
  oneStarSentListing?: Maybe<OneStarSentListingType>;
  twoThreeStarSentListing?: Maybe<TwoThreeStarSentListingType>;
};

export type OneStarSentListingType = {
  __typename?: 'oneStarSentListingType';
  autoSentListing?: Maybe<Scalars['Int']>;
  autoSentLead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  availableSentListingOneStart?: Maybe<Scalars['Int']>;
  autoAvailableLead?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type TwoThreeStarSentListingType = {
  __typename?: 'twoThreeStarSentListingType';
  sentListingTwoThreeStart?: Maybe<Scalars['Int']>;
  sendListingLeads?: Maybe<Array<Maybe<Scalars['Int']>>>;
  availableSentListingTwoThreeStart?: Maybe<Scalars['Int']>;
  availableLeads?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type LeadSummaryReportingInput = {
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  page: Scalars['Int'];
  first: Scalars['Int'];
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id: Scalars['Int'];
};

export type LeadSummaryReportingTypeData = {
  __typename?: 'leadSummaryReportingTypeData';
  data?: Maybe<Array<Maybe<LeadSummaryReportingType>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type LeadSummaryReportingType = {
  __typename?: 'leadSummaryReportingType';
  id?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  leads?: Maybe<Scalars['Int']>;
  offers?: Maybe<Scalars['Int']>;
  leadOffer?: Maybe<Array<Maybe<Leads>>>;
  contracts?: Maybe<Scalars['Int']>;
  contractLead?: Maybe<Array<Maybe<Leads>>>;
  PropertySold?: Maybe<Scalars['Int']>;
  soldLead?: Maybe<Array<Maybe<Leads>>>;
  LeadOnOff?: Maybe<Scalars['String']>;
  totalShowingLead?: Maybe<Scalars['Int']>;
  totalShowing?: Maybe<Scalars['Int']>;
  leadInfo?: Maybe<Array<Maybe<Lead>>>;
  totalShowingLeads?: Maybe<Array<Maybe<Scalars['Int']>>>;
  monthLeads?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type Leads = {
  __typename?: 'Leads';
  lead_id: Scalars['ID'];
};

export type DashboardShowingReportingInput = {
  company_id: Scalars['Int'];
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
  r_start_date?: Maybe<Scalars['DateTime']>;
  r_end_date?: Maybe<Scalars['DateTime']>;
  dashboardQuery?: Maybe<Scalars['Boolean']>;
};

export type DashboardShowingReportingType = {
  __typename?: 'dashboardShowingReportingType';
  weeklyShowingLead?: Maybe<Scalars['Int']>;
  weeklyShowing?: Maybe<Scalars['Int']>;
  weeklyLead?: Maybe<Array<Maybe<Scalars['Int']>>>;
  repeatShowingLead?: Maybe<Scalars['Int']>;
  repeatShowing?: Maybe<Scalars['Int']>;
  repeatLead?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DashBoardAdminPoints = {
  __typename?: 'DashBoardAdminPoints';
  realtor_count?: Maybe<Scalars['Int']>;
  power_hour_count?: Maybe<Scalars['Int']>;
  points_count?: Maybe<Scalars['Int']>;
  total_points_count?: Maybe<Scalars['Int']>;
};

export type ShoingReportInput = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  source?: Maybe<Array<Maybe<Scalars['Int']>>>;
  star?: Maybe<Array<Maybe<Scalars['Int']>>>;
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  orderBy?: Maybe<OrderByClause>;
  page: Scalars['Int'];
  first: Scalars['Int'];
  company_id?: Maybe<Scalars['Int']>;
};

export type ShoingReportData = {
  __typename?: 'shoingReportData';
  exportData?: Maybe<Array<Maybe<ShoingReportType>>>;
  data?: Maybe<Array<Maybe<ShoingReportType>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type ShoingReportType = {
  __typename?: 'shoingReportType';
  id?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  lead_assign_concierge_count?: Maybe<Scalars['Int']>;
  lead_assign_realtor_count?: Maybe<Scalars['Int']>;
  leadFirstShowingComm?: Maybe<Array<Maybe<ShowingLeadInfo>>>;
  leadFirstShowingNonComm?: Maybe<Array<Maybe<ShowingLeadInfo>>>;
  leadRepeatComm?: Maybe<Array<Maybe<ShowingLeadInfo>>>;
  leadRepeatNonComm?: Maybe<Array<Maybe<ShowingLeadInfo>>>;
  leadOfferComm?: Maybe<Array<Maybe<ShowingLeadInfo>>>;
  leadOfferNonComm?: Maybe<Array<Maybe<ShowingLeadInfo>>>;
};

export type ShowingLeadInfo = {
  __typename?: 'ShowingLeadInfo';
  id?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
};

export type EmailStatisticsInput = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  from_campaign?: Maybe<Scalars['Date']>;
  to_campaign?: Maybe<Scalars['Date']>;
};

export type EmailStatisticsType = {
  __typename?: 'emailStatisticsType';
  name?: Maybe<Scalars['String']>;
  sent_mail?: Maybe<Scalars['Int']>;
  un_subscribe?: Maybe<Scalars['Int']>;
  bounce?: Maybe<Scalars['Int']>;
  complaints?: Maybe<Scalars['Int']>;
  nb_visits?: Maybe<Scalars['Int']>;
  nb_actions?: Maybe<Scalars['Int']>;
  max_actions?: Maybe<Scalars['Int']>;
  nb_uniq_visitors?: Maybe<Scalars['Int']>;
  nb_users?: Maybe<Scalars['Int']>;
  subscribe_count?: Maybe<Scalars['Int']>;
  openrate?: Maybe<IdSubdatatableInput>;
  ctr?: Maybe<IdSubdatatableInput>;
};

export type IdSubdatatableInput = {
  __typename?: 'idSubdatatableInput';
  nb_visits?: Maybe<Scalars['Int']>;
  label?: Maybe<Scalars['String']>;
};

export type AutoAdminEmailStatisticsInput = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Array<Maybe<ReportingUserType>>>;
  orderBy?: Maybe<OrderByClause>;
  action?: Maybe<Array<Maybe<ReportingUserAction>>>;
  company_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of autoAdminEmailStatisticsType items. */
export type AutoAdminEmailStatisticsTypePaginator = {
  __typename?: 'autoAdminEmailStatisticsTypePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of autoAdminEmailStatisticsType items. */
  data: Array<AutoAdminEmailStatisticsType>;
};

export type AutoAdminEmailStatisticsType = {
  __typename?: 'autoAdminEmailStatisticsType';
  id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  sent_mail?: Maybe<Scalars['Int']>;
  sent_text?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Int']>;
  dailySendListing?: Maybe<Array<Maybe<Leads>>>;
  dailySendListingText?: Maybe<Array<Maybe<Leads>>>;
};

/** A paginated list of NoteReport items. */
export type NoteReportPaginator = {
  __typename?: 'NoteReportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of NoteReport items. */
  data: Array<NoteReport>;
};

export type NoteReport = {
  __typename?: 'NoteReport';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  notes_count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  notes?: Maybe<Array<Maybe<Leads>>>;
};

/** A paginated list of EmailReport items. */
export type EmailReportPaginator = {
  __typename?: 'EmailReportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of EmailReport items. */
  data: Array<EmailReport>;
};

export type EmailReport = {
  __typename?: 'EmailReport';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mails_count?: Maybe<Scalars['Int']>;
  sent_mail_count?: Maybe<Scalars['Int']>;
  received_mail_count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  sentMail?: Maybe<Array<Maybe<MailLead>>>;
  receivedMail?: Maybe<Array<Maybe<MailLead>>>;
};

export type MailLead = {
  __typename?: 'MailLead';
  id?: Maybe<Scalars['Int']>;
  mailsToLead?: Maybe<Array<Maybe<Lead>>>;
};

/** A paginated list of AppointmentReport items. */
export type AppointmentReportPaginator = {
  __typename?: 'AppointmentReportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of AppointmentReport items. */
  data: Array<AppointmentReport>;
};

export type AppointmentReport = {
  __typename?: 'AppointmentReport';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  showing_appointment_count?: Maybe<Scalars['Int']>;
  appointments_count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  appointments?: Maybe<Array<Maybe<Leads>>>;
  showingAppointment?: Maybe<Array<Maybe<Leads>>>;
};

/** A paginated list of TaskReport items. */
export type TaskReportPaginator = {
  __typename?: 'TaskReportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of TaskReport items. */
  data: Array<TaskReport>;
};

export type TaskReport = {
  __typename?: 'TaskReport';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  todo_created_count?: Maybe<Scalars['Int']>;
  todo_completed_count?: Maybe<Scalars['Int']>;
  overdue_realtor_count?: Maybe<Scalars['Int']>;
  overdue_concierge_count?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  todoCreated?: Maybe<Array<Maybe<Leads>>>;
  todoCompleted?: Maybe<Array<Maybe<Leads>>>;
  taskRealtor?: Maybe<Array<Maybe<Leads>>>;
  taskConcierge?: Maybe<Array<Maybe<Leads>>>;
};

/** A paginated list of SendListingReport items. */
export type SendListingReportPaginator = {
  __typename?: 'SendListingReportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of SendListingReport items. */
  data: Array<SendListingReport>;
};

export type SendListingReport = {
  __typename?: 'SendListingReport';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  send_listing?: Maybe<Scalars['Int']>;
  send_listing_email?: Maybe<Scalars['Int']>;
  send_listing_text?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  archiveAdmin?: Maybe<Array<Maybe<Leads>>>;
  archiveDailySendListing?: Maybe<Array<Maybe<Leads>>>;
  archiveAdminText?: Maybe<Array<Maybe<Leads>>>;
};

/** A paginated list of PointsReport items. */
export type PointsReportPaginator = {
  __typename?: 'PointsReportPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of PointsReport items. */
  data: Array<PointsReport>;
};

export type PointsReport = {
  __typename?: 'PointsReport';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Int']>;
  reputations?: Maybe<Array<Maybe<KReputation>>>;
  date?: Maybe<Scalars['String']>;
  leadAssignRealtor?: Maybe<Array<Maybe<RealtorResponse>>>;
  leadAssignConcierge?: Maybe<Array<Maybe<ConciergeResponse>>>;
  archive_admin_count?: Maybe<Scalars['Int']>;
  total_points_count?: Maybe<Scalars['Int']>;
  total_power_hour_count?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type KReputation = {
  __typename?: 'KReputation';
  TextCreated?: Maybe<Scalars['Int']>;
  PhoneCallCreated?: Maybe<Scalars['Int']>;
  LeftVMCreated?: Maybe<Scalars['Int']>;
  EmailCreated?: Maybe<Scalars['Int']>;
  InPersonMeetingCreated?: Maybe<Scalars['Int']>;
  ShowingCreated?: Maybe<Scalars['Int']>;
  LeadOfferCreated?: Maybe<Scalars['Int']>;
  LeadContractCreated?: Maybe<Scalars['Int']>;
  LeadSoldCreated?: Maybe<Scalars['Int']>;
  ListingAppointmentCreated?: Maybe<Scalars['Int']>;
  PowerHourCreated?: Maybe<Scalars['Int']>;
  MlsSentCreated?: Maybe<Scalars['Int']>;
  LenderApprovedCreated?: Maybe<Scalars['Int']>;
  NewMlsSearchCreated?: Maybe<Scalars['Int']>;
  ReferredToLenderCreated?: Maybe<Scalars['Int']>;
  OfficeMeetingCreated?: Maybe<Scalars['Int']>;
  LeadListingCreated?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['Int']>;
};

export type RealtorResponse = {
  __typename?: 'RealtorResponse';
  lead_id?: Maybe<Scalars['Int']>;
};

export type ConciergeResponse = {
  __typename?: 'ConciergeResponse';
  lead_id?: Maybe<Scalars['Int']>;
};

export type RealtorData = {
  __typename?: 'RealtorData';
  realtorPoints?: Maybe<Array<Maybe<RelatorPointsReport>>>;
  realtorInfoData?: Maybe<RealtorInfoData>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type RelatorPointsReport = {
  __typename?: 'RelatorPointsReport';
  TextCreated?: Maybe<Scalars['Int']>;
  PhoneCallCreated?: Maybe<Scalars['Int']>;
  LeftVMCreated?: Maybe<Scalars['Int']>;
  EmailCreated?: Maybe<Scalars['Int']>;
  InPersonMeetingCreated?: Maybe<Scalars['Int']>;
  ShowingCreated?: Maybe<Scalars['Int']>;
  LeadOfferCreated?: Maybe<Scalars['Int']>;
  LeadContractCreated?: Maybe<Scalars['Int']>;
  LeadSoldCreated?: Maybe<Scalars['Int']>;
  ListingAppointmentCreated?: Maybe<Scalars['Int']>;
  PowerHourCreated?: Maybe<Scalars['Int']>;
  MlsSentCreated?: Maybe<Scalars['Int']>;
  LenderApprovedCreated?: Maybe<Scalars['Int']>;
  NewMlsSearchCreated?: Maybe<Scalars['Int']>;
  ReferredToLenderCreated?: Maybe<Scalars['Int']>;
  OfficeMeetingCreated?: Maybe<Scalars['Int']>;
  LeadListingCreated?: Maybe<Scalars['Int']>;
  total_points?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  archive_admin_count?: Maybe<Scalars['Int']>;
  leadAssignRealtor?: Maybe<Array<Maybe<RealtorResponse>>>;
  total_points_count?: Maybe<Scalars['Int']>;
  total_power_hour_count?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type RealtorInfoData = {
  __typename?: 'realtorInfoData';
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Int']>;
  total_power_hour_count?: Maybe<Scalars['Int']>;
  total_points_count?: Maybe<Scalars['Int']>;
  total_realtor_lead?: Maybe<Scalars['Int']>;
};

export type BrokerageEmailStatistics = {
  __typename?: 'BrokerageEmailStatistics';
  companyDetail?: Maybe<Company>;
  bounceRatio?: Maybe<Scalars['String']>;
  complaintRatio?: Maybe<Scalars['String']>;
  freezeStatus?: Maybe<SubDomain>;
  statistic?: Maybe<Array<Maybe<SendEmailStatistics>>>;
};

export type SubDomain = {
  __typename?: 'SubDomain';
  brokerage?: Maybe<Company>;
  domain_name?: Maybe<Scalars['String']>;
  auto_freeze?: Maybe<Scalars['Int']>;
  is_default?: Maybe<Scalars['Int']>;
};

export type SendEmailStatistics = {
  __typename?: 'SendEmailStatistics';
  category?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type DashBoardPointsReport = {
  __typename?: 'DashBoardPointsReport';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  total_points?: Maybe<Scalars['Int']>;
  total_power_hour?: Maybe<Scalars['Int']>;
  weekly_total_points?: Maybe<Scalars['Int']>;
  weekly_total_power_hour?: Maybe<Scalars['Int']>;
};

export type ReputationResponse = {
  __typename?: 'ReputationResponse';
  totalPoints?: Maybe<Scalars['Int']>;
  weeklyPoints?: Maybe<Scalars['Int']>;
};

export type PowerHour = {
  __typename?: 'PowerHour';
  id: Scalars['ID'];
  admin_id: Scalars['ID'];
  status?: Maybe<Scalars['Int']>;
  start_timestamp?: Maybe<Scalars['String']>;
  end_timestamp?: Maybe<Scalars['String']>;
  is_running?: Maybe<Scalars['Int']>;
};

export type LenderApprovedQueryInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of LenderApproved items. */
export type LenderApprovedPaginator = {
  __typename?: 'LenderApprovedPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of LenderApproved items. */
  data: Array<LenderApproved>;
};

/** A paginated list of Territory items. */
export type TerritoryPaginator = {
  __typename?: 'TerritoryPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Territory items. */
  data: Array<Territory>;
};

/** A paginated list of CompanyTerritory items. */
export type CompanyTerritoryPaginator = {
  __typename?: 'CompanyTerritoryPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CompanyTerritory items. */
  data: Array<CompanyTerritory>;
};

export type CompanyTerritory = {
  __typename?: 'CompanyTerritory';
  id?: Maybe<Scalars['ID']>;
  company_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  territoryDetails?: Maybe<Array<Maybe<TerritoryInfo>>>;
};

/** A paginated list of Zipcode items. */
export type ZipcodePaginator = {
  __typename?: 'ZipcodePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Zipcode items. */
  data: Array<Zipcode>;
};

/** A paginated list of CompanyZipCode items. */
export type CompanyZipCodePaginator = {
  __typename?: 'CompanyZipCodePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CompanyZipCode items. */
  data: Array<CompanyZipCode>;
};

export type CompanyZipCode = {
  __typename?: 'CompanyZipCode';
  id?: Maybe<Scalars['ID']>;
  company_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  companyZipCodes?: Maybe<Array<Maybe<Zipcode>>>;
};

/** A paginated list of Office items. */
export type OfficePaginator = {
  __typename?: 'OfficePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Office items. */
  data: Array<Office>;
};

/** A paginated list of CompanyOffice items. */
export type CompanyOfficePaginator = {
  __typename?: 'CompanyOfficePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CompanyOffice items. */
  data: Array<CompanyOffice>;
};

export type CompanyOffice = {
  __typename?: 'CompanyOffice';
  id?: Maybe<Scalars['ID']>;
  company_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  offices?: Maybe<Array<Maybe<Office>>>;
};

/** A paginated list of Company items. */
export type CompanyPaginator = {
  __typename?: 'CompanyPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Company items. */
  data: Array<Company>;
};

/** A paginated list of Note items. */
export type NotePaginator = {
  __typename?: 'NotePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Note items. */
  data: Array<Note>;
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  note: Scalars['String'];
  note_action?: Maybe<Scalars['String']>;
  save_pin: Scalars['Int'];
  todotask?: Maybe<TodoTask>;
  created_at?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Admin>;
};

export type GetNotById = {
  id: Scalars['ID'];
  lead_id: Scalars['Int'];
};

/** A paginated list of Template items. */
export type TemplatePaginator = {
  __typename?: 'TemplatePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Template items. */
  data: Array<Template>;
};

export type TemplateField = {
  __typename?: 'TemplateField';
  field_name?: Maybe<Scalars['String']>;
  field_value?: Maybe<Scalars['String']>;
};

export enum AppointmentStatus {
  Confirmed = 'Confirmed',
  Cancelled = 'Cancelled',
  NoShow = 'NoShow'
}

export type CalendarData = {
  __typename?: 'CalendarData';
  data?: Maybe<Array<Maybe<Calendar>>>;
  properties?: Maybe<Array<PropertyInfo>>;
};

export type GmailCredType = {
  __typename?: 'gmailCredType';
  id?: Maybe<Scalars['ID']>;
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type GmailConnectUrlResponse = {
  __typename?: 'GmailConnectUrlResponse';
  meta?: Maybe<Meta>;
  url: Scalars['String'];
};

export type Meta = {
  __typename?: 'meta';
  code?: Maybe<Scalars['Int']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** A paginated list of MailThread items. */
export type MailThreadPaginator = {
  __typename?: 'MailThreadPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of MailThread items. */
  data: Array<MailThread>;
};

export type MailThread = {
  __typename?: 'MailThread';
  id: Scalars['ID'];
  thread_id: Scalars['String'];
  subject: Scalars['String'];
  mail_count: Scalars['Int'];
  latestMail?: Maybe<SingleMail>;
};

export type SingleMail = {
  __typename?: 'SingleMail';
  id: Scalars['Int'];
  mail_thread_id?: Maybe<Scalars['String']>;
  reply_id?: Maybe<Scalars['String']>;
  mail_id?: Maybe<Scalars['String']>;
  sub_mail_count?: Maybe<Scalars['Int']>;
  rich_text?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  meta?: Maybe<MailMeta>;
  created_at?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
  external_attachments?: Maybe<Array<Maybe<Attachment>>>;
  inline_attachments?: Maybe<Array<Maybe<Attachment>>>;
  thread?: Maybe<MailThread>;
};

export type MailMeta = {
  __typename?: 'MailMeta';
  id: Scalars['ID'];
  to?: Maybe<Array<Maybe<MailMetaResponse>>>;
  cc?: Maybe<Array<Maybe<MailMetaResponse>>>;
  bcc?: Maybe<Array<Maybe<MailMetaResponse>>>;
  subject?: Maybe<Scalars['String']>;
  from?: Maybe<From>;
};

export type MailMetaResponse = {
  __typename?: 'MailMetaResponse';
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type From = {
  __typename?: 'from';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  id: Scalars['Int'];
  type: Scalars['String'];
  file_name: Scalars['String'];
  mime_type: Scalars['String'];
  size: Scalars['String'];
  path?: Maybe<Scalars['String']>;
};

/** A paginated list of SingleMail items. */
export type SingleMailPaginator = {
  __typename?: 'SingleMailPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of SingleMail items. */
  data: Array<SingleMail>;
};

export type LeadMailThread = {
  __typename?: 'LeadMailThread';
  data?: Maybe<Array<Maybe<SingleLeadMail>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type SingleLeadMail = {
  __typename?: 'SingleLeadMail';
  id: Scalars['Int'];
  mail_thread_id?: Maybe<Scalars['String']>;
  reply_id?: Maybe<Scalars['String']>;
  mail_id?: Maybe<Scalars['String']>;
  sub_mail_count?: Maybe<Scalars['Int']>;
  rich_text?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  meta?: Maybe<MailMeta>;
  created_at?: Maybe<Scalars['String']>;
  snippet?: Maybe<Scalars['String']>;
  external_attachments?: Maybe<Array<Maybe<Attachment>>>;
  inline_attachments?: Maybe<Array<Maybe<Attachment>>>;
  thread?: Maybe<MailThread>;
};

export type LeadAutoMailInput = {
  lead_id: Scalars['ID'];
  page: Scalars['Int'];
  first: Scalars['Int'];
};

export type LeadAutoMailType = {
  __typename?: 'leadAutoMailType';
  data?: Maybe<Array<Maybe<AutoMailsSent>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type LeadMailThreadsV2 = {
  __typename?: 'leadMailThreadsV2';
  unSubscribeUrl?: Maybe<Scalars['String']>;
  data?: Maybe<Array<Maybe<LeadMailThreadsV2Data>>>;
  mails?: Maybe<Array<Maybe<SingleLeadMail>>>;
  autoMail?: Maybe<Array<Maybe<AutoMailsSent>>>;
  properties?: Maybe<Array<Maybe<PropertyInfo>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type LeadMailThreadsV2Data = {
  __typename?: 'leadMailThreadsV2Data';
  id?: Maybe<Scalars['Int']>;
  automailable_type?: Maybe<Scalars['String']>;
  automailable_id?: Maybe<Scalars['Int']>;
};

export type GlobalSeachInput = {
  key?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
};

export type EmailSearchData = {
  __typename?: 'EmailSearchData';
  lead_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type GlobalSearchData = {
  __typename?: 'GlobalSearchData';
  lead_id?: Maybe<Scalars['Int']>;
  full_name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  type?: Maybe<IndexEnum>;
};

export enum IndexEnum {
  Email = 'email',
  Mobile = 'mobile',
  Name = 'name',
  Adminemail = 'adminemail',
  Adminname = 'adminname'
}

export type LeadListInput = {
  key?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  first: Scalars['Int'];
};

export type LeadData = {
  __typename?: 'LeadData';
  data?: Maybe<Array<Maybe<LeadSearch>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type LeadSearch = {
  __typename?: 'LeadSearch';
  id: Scalars['ID'];
  full_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  is_buyer?: Maybe<Scalars['Boolean']>;
  is_seller?: Maybe<Scalars['Boolean']>;
  financing?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['String']>;
  inquiry_at?: Maybe<Scalars['String']>;
  last_visit?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  source?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  user?: Maybe<SearchUser>;
  assign_realtor?: Maybe<Assignee>;
  assign_concierge?: Maybe<Assignee>;
  lead_preferences?: Maybe<LeadPreferences>;
  leads_mails_count?: Maybe<Scalars['Int']>;
  mail_last_activity_at?: Maybe<Scalars['String']>;
  mail_last_activity_type?: Maybe<Scalars['Int']>;
  is_transfer?: Maybe<Scalars['Int']>;
  primary_email?: Maybe<Scalars['String']>;
  company_name?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
};

export type SearchUser = {
  __typename?: 'SearchUser';
  id: Scalars['ID'];
  is_social?: Maybe<Scalars['Boolean']>;
  social_type?: Maybe<Scalars['String']>;
};

export type Assignee = {
  __typename?: 'Assignee';
  id?: Maybe<Scalars['ID']>;
  full_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type LeadPreferences = {
  __typename?: 'LeadPreferences';
  bd?: Maybe<Scalars['Int']>;
  ba?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  sqft?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
};

export type LeadListInputV3 = {
  key?: Maybe<Scalars['String']>;
  page: Scalars['Int'];
  first: Scalars['Int'];
  houzquest?: Maybe<HouzquestFilter>;
  is_company?: Maybe<Scalars['Boolean']>;
};

export type HouzquestFilter = {
  form: Scalars['Date'];
  to: Scalars['Date'];
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type TransferLeadData = {
  __typename?: 'transferLeadData';
  company_id?: Maybe<Scalars['Int']>;
  realtor_old?: Maybe<TransferLeadDataInfo>;
  realtor_new?: Maybe<TransferLeadDataInfo>;
  concierge_old?: Maybe<TransferLeadDataInfo>;
  concierge_new?: Maybe<TransferLeadDataInfo>;
};

export type TransferLeadDataInfo = {
  __typename?: 'transferLeadDataInfo';
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  admin?: Maybe<Admin>;
};

export type GetNotificationsInput = {
  page: Scalars['Int'];
  first: Scalars['Int'];
};

export type NotificationData = {
  __typename?: 'notificationData';
  data?: Maybe<Array<Maybe<Notification>>>;
  unread_count?: Maybe<Scalars['Int']>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type Notification = {
  __typename?: 'Notification';
  id?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  sender?: Maybe<Admin>;
  receiver?: Maybe<Admin>;
  lead?: Maybe<Lead>;
};

export type LiveLeadPaginationInput = {
  page?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  user?: Maybe<Scalars['Boolean']>;
};

export type LiveLeadData = {
  __typename?: 'liveLeadData';
  data?: Maybe<Array<Maybe<Lead>>>;
  analyticsData?: Maybe<Array<Maybe<LiveLeadAnalyticsData>>>;
  see_more?: Maybe<Scalars['Boolean']>;
  last_page?: Maybe<Scalars['Int']>;
};

export type LiveLeadAnalyticsData = {
  __typename?: 'liveLeadAnalyticsData';
  visitorId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  customVariables?: Maybe<Scalars['String']>;
  actionDetails?: Maybe<Array<Maybe<LiveLeadAnalyticsActionDetails>>>;
};

export type LiveLeadAnalyticsActionDetails = {
  __typename?: 'liveLeadAnalyticsActionDetails';
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  serverTimePretty?: Maybe<Scalars['String']>;
  timeSpentPretty?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  eventCategory?: Maybe<Scalars['String']>;
  eventAction?: Maybe<Scalars['String']>;
  subtitle?: Maybe<Scalars['String']>;
  eventName?: Maybe<Scalars['String']>;
};

/** A paginated list of Conversation items. */
export type ConversationPaginator = {
  __typename?: 'ConversationPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Conversation items. */
  data: Array<Conversation>;
};

export type Conversation = {
  __typename?: 'Conversation';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  admin_id?: Maybe<Scalars['Int']>;
  is_group: Scalars['Boolean'];
  default_number?: Maybe<Scalars['String']>;
  members_details?: Maybe<Array<Maybe<Member>>>;
  binding?: Maybe<Array<Maybe<ConversationBindings>>>;
  latest_message?: Maybe<Message>;
  conversation_has_members?: Maybe<ConversationHasMembers>;
};

export type Member = {
  __typename?: 'Member';
  id: Scalars['ID'];
  member_type?: Maybe<ConversationMemberType>;
  member?: Maybe<MemberDetail>;
  meta?: Maybe<Info>;
};

export enum ConversationMemberType {
  Agent = 'AGENT',
  Lead = 'LEAD',
  Contact = 'CONTACT'
}

export type Info = {
  __typename?: 'info';
  is_admin?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['Boolean']>;
};

export type ConversationBindings = {
  __typename?: 'ConversationBindings';
  id?: Maybe<Scalars['Int']>;
  member_id?: Maybe<Scalars['Int']>;
  bind_from?: Maybe<Scalars['String']>;
  bind_to?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Int']>;
  member?: Maybe<Member>;
};

export type Message = {
  __typename?: 'Message';
  id: Scalars['ID'];
  body?: Maybe<Scalars['String']>;
  is_automated?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['DateTime']>;
  type?: Maybe<Scalars['String']>;
  event?: Maybe<MessageEvent>;
  sender?: Maybe<Member>;
  read_by_me?: Maybe<ReadBy>;
  sms_messages?: Maybe<Array<Maybe<SmsMessage>>>;
  smsMessages?: Maybe<Array<Maybe<SmsMessage>>>;
};

export type MessageEvent = {
  __typename?: 'MessageEvent';
  action?: Maybe<Scalars['String']>;
  affected?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type ReadBy = {
  __typename?: 'readBy';
  msg_id?: Maybe<Scalars['ID']>;
  member_id?: Maybe<Scalars['ID']>;
  read_at?: Maybe<Scalars['DateTime']>;
};

export type SmsMessage = {
  __typename?: 'SmsMessage';
  id: Scalars['ID'];
  direction?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  sms_message_error?: Maybe<SmsMessageError>;
};

export type SmsMessageError = {
  __typename?: 'SmsMessageError';
  error_code?: Maybe<Scalars['String']>;
  error_msg?: Maybe<Scalars['String']>;
};

export type ConversationHasMembers = {
  __typename?: 'ConversationHasMembers';
  conversation_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Message>;
};

/** A paginated list of Message items. */
export type MessagePaginator = {
  __typename?: 'MessagePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Message items. */
  data: Array<Message>;
};

export type ConversationNumberInput = {
  conversation_id: Scalars['ID'];
};

export type TwilioNumbersData = {
  __typename?: 'twilioNumbersData';
  numbers?: Maybe<Array<Maybe<TwilioNumbers>>>;
  is_chat?: Maybe<Scalars['Boolean']>;
};

export type TwilioNumbersInput = {
  query?: Maybe<Scalars['String']>;
  company_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
  admin_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of twilioNumbers items. */
export type TwilioNumbersPaginator = {
  __typename?: 'twilioNumbersPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of twilioNumbers items. */
  data: Array<TwilioNumbers>;
};

export type Sv2 = {
  __typename?: 'SV2';
  group?: Maybe<Array<Maybe<Conversation>>>;
  person?: Maybe<Array<Maybe<SearchMemberPerson>>>;
};

export type SearchMemberPerson = {
  __typename?: 'searchMemberPerson';
  id: Scalars['ID'];
  full_name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  is_primary?: Maybe<Scalars['Int']>;
  typeClass?: Maybe<ConversationMemberType>;
};

export type MessageStatusInfo = {
  message_id?: Maybe<Scalars['Int']>;
};

export type MyNumbers = {
  __typename?: 'myNumbers';
  id: Scalars['ID'];
  friendly_name: Scalars['String'];
  phone_number: Scalars['String'];
};

export enum CallDeviceType {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type CapabilityToken = {
  __typename?: 'capabilityToken';
  token: Scalars['String'];
  expire: Scalars['Int'];
  number?: Maybe<MyNumbers>;
};

export type TextCapabilityToken = {
  __typename?: 'textCapabilityToken';
  token: Scalars['String'];
  expire: Scalars['Int'];
};

export type LeadFromNumber = {
  __typename?: 'LeadFromNumber';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** A paginated list of CallDetail items. */
export type CallDetailPaginator = {
  __typename?: 'CallDetailPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CallDetail items. */
  data: Array<CallDetail>;
};

export type CallDetail = {
  __typename?: 'CallDetail';
  id: Scalars['ID'];
  status: Scalars['String'];
  from: Scalars['String'];
  to?: Maybe<Scalars['String']>;
  ext?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  is_forwarded: Scalars['Boolean'];
  is_transferred: Scalars['Boolean'];
  caller_type?: Maybe<ConversationMemberType>;
  caller?: Maybe<MemberDetail>;
  called_type?: Maybe<ConversationMemberType>;
  called?: Maybe<MemberDetail>;
  timestamp?: Maybe<Scalars['DateTime']>;
  conference?: Maybe<Conference>;
  voicemail?: Maybe<Recording>;
  recording?: Maybe<Recording>;
  blockNumberTo?: Maybe<Scalars['Int']>;
  blockNumberFrom?: Maybe<Scalars['Int']>;
  contactNumberFrom?: Maybe<ContactNumber>;
  contactNumberTo?: Maybe<ContactNumber>;
  leadNumberTo?: Maybe<LeadMobile>;
  leadNumberFrom?: Maybe<LeadMobile>;
};

export type Conference = {
  __typename?: 'Conference';
  id: Scalars['ID'];
  name: Scalars['String'];
  sid: Scalars['String'];
  owner: Scalars['String'];
  participant?: Maybe<Array<Maybe<ConferenceParticipant>>>;
};

export type ConferenceParticipant = {
  __typename?: 'ConferenceParticipant';
  sid?: Maybe<Scalars['String']>;
  number: Scalars['String'];
  added_by?: Maybe<Scalars['String']>;
  hold: Scalars['Boolean'];
  muted: Scalars['Boolean'];
  status: Scalars['String'];
};

export type Recording = {
  __typename?: 'Recording';
  id: Scalars['ID'];
  sid: Scalars['String'];
  source: Scalars['String'];
  url: Scalars['String'];
  duration: Scalars['Int'];
  adminassignQueue?: Maybe<Array<Maybe<Assignqueue>>>;
};

export type Assignqueue = {
  __typename?: 'Assignqueue';
  id?: Maybe<Scalars['Int']>;
};

/** A paginated list of CallContact items. */
export type CallContactPaginator = {
  __typename?: 'CallContactPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of CallContact items. */
  data: Array<CallContact>;
};

export type CallBinding = {
  phone_no: Scalars['String'];
  device_type: CallDeviceType;
  token: Scalars['String'];
};

export type BindingResponse = {
  __typename?: 'bindingResponse';
  status: Scalars['String'];
};

/** A paginated list of Queue items. */
export type QueuePaginator = {
  __typename?: 'QueuePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Queue items. */
  data: Array<Queue>;
};

export type BlockNumber = {
  __typename?: 'BlockNumber';
  id: Scalars['ID'];
  admin_id: Scalars['ID'];
  number: Scalars['String'];
  type: BlockType;
};

export enum BlockType {
  Spam = 'SPAM',
  Block = 'BLOCK'
}

/** A paginated list of Contact items. */
export type ContactPaginator = {
  __typename?: 'ContactPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Contact items. */
  data: Array<Contact>;
};

export type MatchedPropertyLeadInput = {
  lastpage?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
};

export type MatchedpropertyData = {
  __typename?: 'matchedpropertyData';
  data?: Maybe<Array<Maybe<LeadSearch>>>;
  see_more?: Maybe<Scalars['Boolean']>;
  last_page?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type MatchedPropertyLeadInputV2 = {
  lastpage?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  first: Scalars['Int'];
  ratings?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sources?: Maybe<Array<Maybe<Scalars['Int']>>>;
  stages?: Maybe<Array<Maybe<Scalars['Int']>>>;
  lead_preferencesvthree?: Maybe<LeadPreferencesvthree>;
  orderBy?: Maybe<OrderByClauseMatching>;
  lastInquiry?: Maybe<MpLastInquiryVthree>;
  lastTouch?: Maybe<LastTouchDate>;
  assignLeads?: Maybe<AssignLeads>;
  lastVisit?: Maybe<LastVisitHouzquest>;
  leadTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  houzquest?: Maybe<HouzquestFilter>;
  is_valid_number?: Maybe<Scalars['Int']>;
  is_valid_email?: Maybe<Scalars['Int']>;
  companies?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type LeadPreferencesvthree = {
  min_price?: Maybe<Scalars['String']>;
  max_price?: Maybe<Scalars['String']>;
  zip?: Maybe<Array<Maybe<Scalars['String']>>>;
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
};

export type OrderByClauseMatching = {
  field: Scalars['String'];
  order: Scalars['String'];
};

export type MpLastInquiryVthree = {
  start_created_at?: Maybe<Scalars['String']>;
  end_created_at?: Maybe<Scalars['String']>;
  start_last_activity_at?: Maybe<Scalars['String']>;
  end_last_activity_at?: Maybe<Scalars['String']>;
};

export type LastTouchDate = {
  start_last_touch_at?: Maybe<Scalars['String']>;
  end_last_touch_at?: Maybe<Scalars['String']>;
};

export type AssignLeads = {
  access?: Maybe<Array<Maybe<Scalars['Int']>>>;
  no_realtor?: Maybe<Scalars['Boolean']>;
  no_concierge?: Maybe<Scalars['Boolean']>;
};

export type LastVisitHouzquest = {
  start_last_visit_at?: Maybe<Scalars['String']>;
  end_last_visit_at?: Maybe<Scalars['String']>;
};

export type MatchedpropertyV3Data = {
  __typename?: 'matchedpropertyV3Data';
  data?: Maybe<Array<Maybe<LeadSearch>>>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

export type TodayMatchPropertyInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

export type TodayMatchPropertyType = {
  __typename?: 'TodayMatchPropertyType';
  no_listing_matches_today?: Maybe<Scalars['Boolean']>;
  is_exclude?: Maybe<Scalars['Int']>;
  is_text_exclude?: Maybe<Scalars['Int']>;
};

export type LeadContractQueryInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

export type LeadOfferQueryInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

export type LeadSoldQueryInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

/** A paginated list of LeadSold items. */
export type LeadSoldPaginator = {
  __typename?: 'LeadSoldPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of LeadSold items. */
  data: Array<LeadSold>;
};

export type LeadSold = {
  __typename?: 'LeadSold';
  id?: Maybe<Scalars['Int']>;
  lead?: Maybe<Lead>;
  createdBy?: Maybe<Admin>;
  leadOfferType?: Maybe<LeadOfferType>;
  leadSoldAssign?: Maybe<Array<Maybe<LeadSoldAssign>>>;
  property_address?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  sold_date?: Maybe<Scalars['String']>;
  sold_price?: Maybe<Scalars['String']>;
  commission_percentage?: Maybe<Scalars['Float']>;
  concessions?: Maybe<Scalars['Float']>;
  lender_name?: Maybe<Scalars['String']>;
  contract?: Maybe<LeadContract>;
};

export type LeadSoldAssign = {
  __typename?: 'leadSoldAssign';
  id?: Maybe<Scalars['Int']>;
  admin?: Maybe<Admin>;
  status?: Maybe<Scalars['String']>;
  assign_type?: Maybe<Scalars['Int']>;
};

export type LeadListingQueryInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

export type SettingsResponse = {
  __typename?: 'SettingsResponse';
  data?: Maybe<Settings>;
};

export type Settings = {
  __typename?: 'settings';
  gmailStatus?: Maybe<Scalars['Boolean']>;
};

/** A paginated list of TimeZone items. */
export type TimeZonePaginator = {
  __typename?: 'TimeZonePaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of TimeZone items. */
  data: Array<TimeZone>;
};

export type AdminInput = {
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  actionFilter?: Maybe<Array<Maybe<ActionFilter>>>;
  typeFilter?: Maybe<Array<Maybe<TypeFilter>>>;
  orderBy?: Maybe<OrderByClauseAdmin>;
  key?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
};

export type ActionFilter = {
  action?: Maybe<Scalars['Int']>;
};

export type TypeFilter = {
  type?: Maybe<Scalars['String']>;
};

export type AdminData = {
  __typename?: 'adminData';
  data: Array<Admin>;
  paginatorInfo?: Maybe<PaginatorInfo>;
};

/** A paginated list of Admins items. */
export type AdminsPaginator = {
  __typename?: 'AdminsPaginator';
  /** Pagination information about the list of items. */
  paginatorInfo: PaginatorInfo;
  /** A list of Admins items. */
  data: Array<Admins>;
};

export type Admins = {
  __typename?: 'Admins';
  id?: Maybe<Scalars['ID']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  action?: Maybe<Scalars['Int']>;
  phone_no?: Maybe<Scalars['String']>;
};

export type AdminByIdInput = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  key?: Maybe<Scalars['String']>;
};

/** fix to define blank Mutaion */
export type Mutation = {
  __typename?: 'Mutation';
  adminsss?: Maybe<Admin>;
  connectlead?: Maybe<ConnectedLead>;
  deleteConnectLead?: Maybe<ConnectedLead>;
  /** Lead Dead Reason */
  addleadDeadReason?: Maybe<Lead>;
  /** Register new lead */
  registerLead?: Maybe<Lead>;
  /** Update existing lead */
  updateLead?: Maybe<Lead>;
  /** Update Email */
  updateLeadEmail?: Maybe<Array<Maybe<LeadEmail>>>;
  updateLeadMobile?: Maybe<Array<Maybe<LeadMobile>>>;
  singleUpdateLead?: Maybe<Lead>;
  /** Accept lead request */
  acceptLeadRequest?: Maybe<LeadAssign>;
  /** Lead Exclude */
  leadExclude?: Maybe<LeadSubscribeType>;
  /** Lead Text Exclude */
  leadTextExclude?: Maybe<LeadTextSubscribeType>;
  /** Lead Email Validate */
  leadEmailValidate?: Maybe<LeadEmailValidateType>;
  /** Lead Mobile Validate */
  leadMobileValidate?: Maybe<LeadEmailValidateType>;
  createLeadTag?: Maybe<LeadTag>;
  deleteLeadTag?: Maybe<LeadTag>;
  createToDoTask?: Maybe<TodoTask>;
  updateToDoTask?: Maybe<TodoTask>;
  completedToDoTask?: Maybe<TodoTask>;
  deleteToDoTask?: Maybe<TodoTask>;
  /** Admin Save Search */
  adminSaveSearch?: Maybe<SaveSearch>;
  createAdmin?: Maybe<CreateAdminResponse>;
  updateAdmin?: Maybe<UpdateAdminResponse>;
  activeAdmin?: Maybe<ActiveAdminResponse>;
  inActiveAdmin?: Maybe<InActiveAdminResponse>;
  deleteTerritory?: Maybe<DeleteTerritoryResponse>;
  adminAssignNumberAddUpdate?: Maybe<Admin>;
  /** Admin Assign Permission */
  adminAssignPermission?: Maybe<Array<Maybe<Permission>>>;
  /** Role Assign Permission */
  roleAssignPermission?: Maybe<Array<Maybe<RolePermission>>>;
  /** Admin Assign Role */
  adminAssignRole?: Maybe<Array<Maybe<Role>>>;
  /** store Working Hours */
  userWorkingHour?: Maybe<Array<Maybe<WorkingHour>>>;
  createAppointment?: Maybe<Appointment>;
  updateAppointment?: Maybe<Appointment>;
  deleteAppointment?: Maybe<Appointment>;
  appointmentStatusUpdate?: Maybe<Appointment>;
  /** Save & Update Question Answer */
  saveQuestion?: Maybe<Form>;
  /** Delete Question Answer */
  deleteQuestionAnswer?: Maybe<Form>;
  saveStage?: Maybe<Stage>;
  /** Agent Saved Search */
  agentSavedSearch?: Maybe<UserSaveSearches>;
  /** Agent Saved Search */
  deleteSavedSearch?: Maybe<UserSaveSearches>;
  /** Agent Saved Search */
  userAgentSavedSearch?: Maybe<UserSaveSearches>;
  /** Agent Saved Search */
  userDeleteSavedSearch?: Maybe<UserSaveSearches>;
  startPowerHour?: Maybe<PowerHour>;
  createLenderApproved?: Maybe<LenderApproved>;
  updateLenderApproved?: Maybe<LenderApproved>;
  deleteLenderApproved?: Maybe<LenderApproved>;
  /** Create Territory Function */
  createTerritory?: Maybe<Territory>;
  /** Update Territory Function */
  updateTerritory?: Maybe<Territory>;
  /** New Office Create */
  createOffice?: Maybe<Office>;
  /** Update Office */
  updateOffice?: Maybe<Office>;
  /** Add New User */
  addNewUser: Admin;
  /** Add New Realtor Personal */
  realtorPersonal: Admin;
  /** Add New Realtor Info */
  realtorInfo: Admin;
  /** Add New Realtor Bank Detail */
  realtorBankDetail: Admin;
  /** Update All Realtor Info */
  updaterealtorDetail: Admin;
  /** Add New Concierge Personal */
  conciergePersonal: Admin;
  /** Add New Concierge Bank Detail */
  conciergeBankDetail: Admin;
  /** Update All Realtor Info */
  updateconciergeDetail: Admin;
  /** create Note */
  createNote?: Maybe<Note>;
  /** update Note */
  updateNote?: Maybe<Note>;
  /** Delete Note */
  deleteNote?: Maybe<Note>;
  changeNote?: Maybe<Array<Maybe<Note>>>;
  createTemplate: Template;
  updateTemplate: Template;
  deleteTemplate: Template;
  exportTemplate: ExportTemplate;
  gmailConnectCb?: Maybe<GmailConnectResponse>;
  yahooConnect?: Maybe<GmailConnectResponse>;
  otherServer?: Maybe<GmailConnectResponse>;
  composeMail?: Maybe<ComposeMailResponse>;
  sendMail?: Maybe<SendMailResponse>;
  disconnectGmail?: Maybe<GmailConnectResponse>;
  /** send Listing Mail */
  sendListingSentMail?: Maybe<SendListingSentMailType>;
  /** send Listing Mail 2 */
  sendListingSentMailV2?: Maybe<SendListingSentMailType>;
  gmailCredSave?: Maybe<GmailCredType>;
  /** Transfer lead */
  transferLead?: Maybe<Lead>;
  /** Mass Transfer Lead */
  massTransferLead?: Maybe<Lead>;
  /** create Note */
  notificationStatusUpdate?: Maybe<Notification>;
  sendMsg?: Maybe<Message>;
  startConversation?: Maybe<Conversation>;
  /** Update name of conversation, add or remove member from conversation. */
  updateConversation?: Maybe<Conversation>;
  searchConversation?: Maybe<T>;
  conversationNumberUpdate?: Maybe<Conversation>;
  holdCall?: Maybe<Scalars['Boolean']>;
  addToCall?: Maybe<Scalars['Boolean']>;
  removeFromCall?: Maybe<Scalars['String']>;
  coldTransferCall?: Maybe<Scalars['String']>;
  callQueue?: Maybe<Queue>;
  assignQueue?: Maybe<Queue>;
  deleteQueue: Scalars['String'];
  blockNumber: BlockNumber;
  blockNumberV2?: Maybe<Array<BlockNumber>>;
  deleteBlockNumber: BlockNumber;
  deleteContact: Contact;
  deleteContactNumber: ContactNumber;
  contact?: Maybe<Contact>;
  /** daily send listing */
  storeDailySendListing?: Maybe<DailySendListing>;
  /** New Lead Offer Create */
  createLeadOffer?: Maybe<LeadOffer>;
  /** New Lead Offer Update */
  updateLeadOffer?: Maybe<LeadOffer>;
  /** Lead Offer delete */
  deleteLeadOffer?: Maybe<LeadOffer>;
  /** New Lead Contract Create */
  createLeadContract?: Maybe<LeadContract>;
  /** New Lead Contract Update */
  updateLeadContract?: Maybe<LeadContract>;
  /** Lead Contract Delete */
  deleteLeadContract?: Maybe<LeadContract>;
  /** New Lead Sold Create */
  createLeadSold?: Maybe<LeadSold>;
  /** New Lead Sold Update */
  updateLeadSold?: Maybe<LeadSold>;
  /** Lead Sold Delete */
  deleteLeadSold?: Maybe<LeadSold>;
  /** New Lead Listing Create */
  createLeadListing?: Maybe<LeadListing>;
  /** New Lead Listing Update */
  updateLeadListing?: Maybe<LeadListing>;
  /** Lead Listing Delete */
  deleteLeadListing?: Maybe<LeadListing>;
  googleLogin: AuthPayload;
  refreshToken: RefreshTokenPayload;
  logout: LogoutResponse;
  sigUpdate?: Maybe<SigUpdate>;
  login: AuthPayload;
  updatePassword: UpdatePasswordResponse;
  forgotPassword: UpdatePasswordResponse;
};


/** fix to define blank Mutaion */
export type MutationAdminsssArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** fix to define blank Mutaion */
export type MutationConnectleadArgs = {
  input?: Maybe<ConnectLead>;
};


/** fix to define blank Mutaion */
export type MutationDeleteConnectLeadArgs = {
  input?: Maybe<DeleteConnectLead>;
};


/** fix to define blank Mutaion */
export type MutationAddleadDeadReasonArgs = {
  input?: Maybe<AddleadDeadReasonInput>;
};


/** fix to define blank Mutaion */
export type MutationRegisterLeadArgs = {
  input?: Maybe<RegisterLeadInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadArgs = {
  input?: Maybe<UpdateLeadInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadEmailArgs = {
  input?: Maybe<UpdateLeadEmailInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadMobileArgs = {
  input?: Maybe<UpdateLeadMobileInput>;
};


/** fix to define blank Mutaion */
export type MutationSingleUpdateLeadArgs = {
  input?: Maybe<SingleUpdateLeadInput>;
};


/** fix to define blank Mutaion */
export type MutationAcceptLeadRequestArgs = {
  input?: Maybe<AcceptLeadInput>;
};


/** fix to define blank Mutaion */
export type MutationLeadExcludeArgs = {
  input?: Maybe<LeadExcludeInput>;
};


/** fix to define blank Mutaion */
export type MutationLeadTextExcludeArgs = {
  input?: Maybe<LeadTextExcludeInput>;
};


/** fix to define blank Mutaion */
export type MutationLeadEmailValidateArgs = {
  input?: Maybe<LeadEmailValidateInput>;
};


/** fix to define blank Mutaion */
export type MutationLeadMobileValidateArgs = {
  input?: Maybe<LeadMobileValidateInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateLeadTagArgs = {
  input?: Maybe<CreateLeadTagInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteLeadTagArgs = {
  input?: Maybe<DeleteLeadTagInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateToDoTaskArgs = {
  input?: Maybe<CreateTodoTaskInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateToDoTaskArgs = {
  input?: Maybe<UpdateTodoTaskInput>;
};


/** fix to define blank Mutaion */
export type MutationCompletedToDoTaskArgs = {
  input?: Maybe<CompletedToDoTaskInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteToDoTaskArgs = {
  input?: Maybe<DeleteToDoTaskInput>;
};


/** fix to define blank Mutaion */
export type MutationAdminSaveSearchArgs = {
  input?: Maybe<SaveSearchInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateAdminArgs = {
  input?: Maybe<CreateAdminInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateAdminArgs = {
  input?: Maybe<UpdateAdminInput>;
};


/** fix to define blank Mutaion */
export type MutationActiveAdminArgs = {
  input?: Maybe<ActiveAdminInput>;
};


/** fix to define blank Mutaion */
export type MutationInActiveAdminArgs = {
  input?: Maybe<InActiveAdminInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteTerritoryArgs = {
  input?: Maybe<DeleteTerritoryInput>;
};


/** fix to define blank Mutaion */
export type MutationAdminAssignNumberAddUpdateArgs = {
  input: AdminAssignNumberInput;
};


/** fix to define blank Mutaion */
export type MutationAdminAssignPermissionArgs = {
  input?: Maybe<AdminAssignPermissionInput>;
};


/** fix to define blank Mutaion */
export type MutationRoleAssignPermissionArgs = {
  input?: Maybe<RoleAssignPermissionInput>;
};


/** fix to define blank Mutaion */
export type MutationAdminAssignRoleArgs = {
  input?: Maybe<AdminAssignRoleInput>;
};


/** fix to define blank Mutaion */
export type MutationUserWorkingHourArgs = {
  input?: Maybe<WorkingHourInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateAppointmentArgs = {
  input?: Maybe<CreateAppointmentInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateAppointmentArgs = {
  input?: Maybe<UpdateAppointmentInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteAppointmentArgs = {
  input?: Maybe<DeleteAppointmentInput>;
};


/** fix to define blank Mutaion */
export type MutationAppointmentStatusUpdateArgs = {
  input?: Maybe<AppointmentStatusUpdateInput>;
};


/** fix to define blank Mutaion */
export type MutationSaveQuestionArgs = {
  input?: Maybe<QuestionInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteQuestionAnswerArgs = {
  input?: Maybe<DeleteQuestionAnswer>;
};


/** fix to define blank Mutaion */
export type MutationSaveStageArgs = {
  input?: Maybe<SaveStageInput>;
};


/** fix to define blank Mutaion */
export type MutationAgentSavedSearchArgs = {
  input: AgentSaveSearchInput;
};


/** fix to define blank Mutaion */
export type MutationDeleteSavedSearchArgs = {
  input: DeleteSavedSearchInput;
};


/** fix to define blank Mutaion */
export type MutationUserAgentSavedSearchArgs = {
  input: UserAgentSaveSearchInput;
};


/** fix to define blank Mutaion */
export type MutationUserDeleteSavedSearchArgs = {
  input: DeleteSavedSearchInput;
};


/** fix to define blank Mutaion */
export type MutationCreateLenderApprovedArgs = {
  input?: Maybe<CreateLenderApprovedInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLenderApprovedArgs = {
  input?: Maybe<UpdateLenderApprovedInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteLenderApprovedArgs = {
  input?: Maybe<DeleteLenderApprovedInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateTerritoryArgs = {
  input?: Maybe<CreateTerritoryInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateTerritoryArgs = {
  input?: Maybe<UpdateTerritoryInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateOfficeArgs = {
  input?: Maybe<CreateOfficeInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateOfficeArgs = {
  input?: Maybe<UpdateOfficeInput>;
};


/** fix to define blank Mutaion */
export type MutationAddNewUserArgs = {
  input?: Maybe<AddNewUserInput>;
};


/** fix to define blank Mutaion */
export type MutationRealtorPersonalArgs = {
  input?: Maybe<RealtorPerosnalInput>;
};


/** fix to define blank Mutaion */
export type MutationRealtorInfoArgs = {
  input?: Maybe<RealtorInfoInput>;
};


/** fix to define blank Mutaion */
export type MutationRealtorBankDetailArgs = {
  input?: Maybe<RealtorBankDetailInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdaterealtorDetailArgs = {
  input?: Maybe<UpdateRealtorDetailInput>;
};


/** fix to define blank Mutaion */
export type MutationConciergePersonalArgs = {
  input?: Maybe<ConciergerPerosnalInput>;
};


/** fix to define blank Mutaion */
export type MutationConciergeBankDetailArgs = {
  input?: Maybe<ConciergerBankDetailInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateconciergeDetailArgs = {
  input?: Maybe<UpdateConciergerInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateNoteArgs = {
  input?: Maybe<CreateNoteInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateNoteArgs = {
  input?: Maybe<UpdateNoteInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteNoteArgs = {
  input?: Maybe<DeleteNoteInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateTemplateArgs = {
  input?: Maybe<CreateTemplateInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateTemplateArgs = {
  input?: Maybe<UpdateTemplateInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteTemplateArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** fix to define blank Mutaion */
export type MutationExportTemplateArgs = {
  type: Scalars['String'];
};


/** fix to define blank Mutaion */
export type MutationGmailConnectCbArgs = {
  input?: Maybe<GmailConnectInput>;
};


/** fix to define blank Mutaion */
export type MutationYahooConnectArgs = {
  input?: Maybe<YahooConnectInput>;
};


/** fix to define blank Mutaion */
export type MutationOtherServerArgs = {
  input?: Maybe<OtherConnectInput>;
};


/** fix to define blank Mutaion */
export type MutationComposeMailArgs = {
  input?: Maybe<ComposeMailInput>;
};


/** fix to define blank Mutaion */
export type MutationSendMailArgs = {
  input?: Maybe<SendMailInput>;
};


/** fix to define blank Mutaion */
export type MutationSendListingSentMailArgs = {
  input?: Maybe<SendListingSentMailInput>;
};


/** fix to define blank Mutaion */
export type MutationSendListingSentMailV2Args = {
  input?: Maybe<SendListingSentMailInputV2>;
};


/** fix to define blank Mutaion */
export type MutationGmailCredSaveArgs = {
  input?: Maybe<GmailCredInput>;
};


/** fix to define blank Mutaion */
export type MutationTransferLeadArgs = {
  input?: Maybe<TransferLeadInput>;
};


/** fix to define blank Mutaion */
export type MutationMassTransferLeadArgs = {
  input?: Maybe<MassTransferLeadInput>;
};


/** fix to define blank Mutaion */
export type MutationNotificationStatusUpdateArgs = {
  input?: Maybe<NotificationStatusUpdateInput>;
};


/** fix to define blank Mutaion */
export type MutationSendMsgArgs = {
  input?: Maybe<SendMsgInput>;
};


/** fix to define blank Mutaion */
export type MutationStartConversationArgs = {
  input?: Maybe<StartConversationInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateConversationArgs = {
  input?: Maybe<UpdateConversationInput>;
};


/** fix to define blank Mutaion */
export type MutationSearchConversationArgs = {
  query: Scalars['String'];
  page?: Maybe<Scalars['Int']>;
};


/** fix to define blank Mutaion */
export type MutationConversationNumberUpdateArgs = {
  input?: Maybe<ConversationNumberUpdateInput>;
};


/** fix to define blank Mutaion */
export type MutationHoldCallArgs = {
  call_sid: Array<Scalars['String']>;
  hold: Scalars['Boolean'];
};


/** fix to define blank Mutaion */
export type MutationAddToCallArgs = {
  call_sid: Scalars['String'];
  to: Scalars['String'];
};


/** fix to define blank Mutaion */
export type MutationRemoveFromCallArgs = {
  call_sid: Scalars['String'];
  participant_sid: Scalars['String'];
};


/** fix to define blank Mutaion */
export type MutationColdTransferCallArgs = {
  call_sid: Scalars['String'];
  to: Scalars['String'];
  type: TransferToType;
  dir: DirectionType;
};


/** fix to define blank Mutaion */
export type MutationCallQueueArgs = {
  input?: Maybe<QueueInput>;
};


/** fix to define blank Mutaion */
export type MutationAssignQueueArgs = {
  input?: Maybe<AssignQueueInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteQueueArgs = {
  id: Scalars['ID'];
};


/** fix to define blank Mutaion */
export type MutationBlockNumberArgs = {
  id: Scalars['ID'];
  admin_id: Scalars['ID'];
  number: Scalars['String'];
  type: BlockType;
};


/** fix to define blank Mutaion */
export type MutationBlockNumberV2Args = {
  input?: Maybe<BlockNumberInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteBlockNumberArgs = {
  id: Scalars['ID'];
};


/** fix to define blank Mutaion */
export type MutationDeleteContactArgs = {
  id: Scalars['ID'];
};


/** fix to define blank Mutaion */
export type MutationDeleteContactNumberArgs = {
  id: Scalars['ID'];
};


/** fix to define blank Mutaion */
export type MutationContactArgs = {
  input: CreateContact;
};


/** fix to define blank Mutaion */
export type MutationStoreDailySendListingArgs = {
  input?: Maybe<DailySendListingInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateLeadOfferArgs = {
  input?: Maybe<CreateLeadOfferInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadOfferArgs = {
  input?: Maybe<UpdateLeadOfferInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteLeadOfferArgs = {
  input?: Maybe<DeleteLeadOfferInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateLeadContractArgs = {
  input?: Maybe<CreateLeadContractInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadContractArgs = {
  input?: Maybe<UpdateLeadContractInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteLeadContractArgs = {
  input?: Maybe<DeleteLeadContractInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateLeadSoldArgs = {
  input?: Maybe<CreateLeadSoldInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadSoldArgs = {
  input?: Maybe<UpdateLeadSoldInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteLeadSoldArgs = {
  input?: Maybe<DeleteLeadSoldInput>;
};


/** fix to define blank Mutaion */
export type MutationCreateLeadListingArgs = {
  input?: Maybe<CreateLeadListingInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdateLeadListingArgs = {
  input?: Maybe<UpdateLeadListingInput>;
};


/** fix to define blank Mutaion */
export type MutationDeleteLeadListingArgs = {
  input?: Maybe<DeleteLeadListingInput>;
};


/** fix to define blank Mutaion */
export type MutationGoogleLoginArgs = {
  input?: Maybe<GoogleLoginInput>;
};


/** fix to define blank Mutaion */
export type MutationRefreshTokenArgs = {
  input?: Maybe<RefreshTokenInput>;
};


/** fix to define blank Mutaion */
export type MutationLoginArgs = {
  input?: Maybe<LoginInput>;
};


/** fix to define blank Mutaion */
export type MutationUpdatePasswordArgs = {
  input: UpdatePassword;
};


/** fix to define blank Mutaion */
export type MutationForgotPasswordArgs = {
  input: ForgotPasswordInput;
};

export type ConnectLead = {
  lead_id: Scalars['Int'];
  connect_id: Scalars['Int'];
  relationship: Scalars['String'];
};

export type DeleteConnectLead = {
  lead_id: Scalars['Int'];
  connect_id: Scalars['Int'];
};

export type AddleadDeadReasonInput = {
  lead_id?: Maybe<Scalars['Int']>;
  lead_reason_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RegisterLeadInput = {
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  source: Scalars['Int'];
  is_buyer?: Maybe<Scalars['Int']>;
  is_seller?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  bathroom?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  bedroom?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
  realtor?: Maybe<Scalars['Int']>;
};

export type UpdateLeadInput = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  is_buyer?: Maybe<Scalars['Int']>;
  is_seller?: Maybe<Scalars['Int']>;
  emails?: Maybe<LeadEmailRelation>;
  mobiles?: Maybe<LeadMobileRelation>;
  source?: Maybe<LeadSourceRelation>;
  address?: Maybe<LeadAddressRelation>;
  impnote?: Maybe<LeadImpNoteRelation>;
};

export type LeadEmailRelation = {
  create?: Maybe<Array<LeadEmailInput>>;
  update?: Maybe<Array<UpadteLeadEmailInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type LeadEmailInput = {
  email: Scalars['String'];
  is_primary: Scalars['Boolean'];
  valid_email?: Maybe<Scalars['Boolean']>;
};

export type UpadteLeadEmailInput = {
  id: Scalars['ID'];
  email: Scalars['String'];
  is_primary?: Maybe<Scalars['Boolean']>;
  valid_email?: Maybe<Scalars['Boolean']>;
};

export type LeadMobileRelation = {
  create?: Maybe<Array<LeadMobileInput>>;
  update?: Maybe<Array<UpadteLeadMobileInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type LeadMobileInput = {
  raw_number: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  is_primary?: Maybe<Scalars['Boolean']>;
};

export type UpadteLeadMobileInput = {
  id: Scalars['ID'];
  raw_number: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  is_valid?: Maybe<Scalars['Boolean']>;
  is_primary?: Maybe<Scalars['Boolean']>;
};

export type LeadSourceRelation = {
  connect?: Maybe<Scalars['ID']>;
};

export type LeadAddressRelation = {
  create?: Maybe<Array<CreateLeadAddressInput>>;
  update?: Maybe<Array<UpdateLeadAddressInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type CreateLeadAddressInput = {
  country?: Maybe<LeadAddressCountryRelation>;
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip_code: Scalars['String'];
  label: Scalars['String'];
  other?: Maybe<Scalars['String']>;
};

export type LeadAddressCountryRelation = {
  connect: Scalars['ID'];
};

export type UpdateLeadAddressInput = {
  id: Scalars['Int'];
  country?: Maybe<LeadAddressCountryRelation>;
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zip_code: Scalars['String'];
  label: Scalars['String'];
  other?: Maybe<Scalars['String']>;
};

export type LeadImpNoteRelation = {
  create?: Maybe<Array<CreateImpNoteInput>>;
  update?: Maybe<Array<UpdateImpNoteInput>>;
  delete?: Maybe<Array<Scalars['ID']>>;
};

export type CreateImpNoteInput = {
  note: Scalars['String'];
};

export type UpdateImpNoteInput = {
  id: Scalars['ID'];
  note: Scalars['String'];
};

export type UpdateLeadEmailInput = {
  id: Scalars['ID'];
  emails?: Maybe<LeadEmailRelation>;
};

export type UpdateLeadMobileInput = {
  id: Scalars['ID'];
  mobiles?: Maybe<LeadMobileRelation>;
};

export type SingleUpdateLeadInput = {
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  is_buyer?: Maybe<Scalars['Int']>;
  is_seller?: Maybe<Scalars['Int']>;
  source_id?: Maybe<Scalars['Int']>;
};

export type AcceptLeadInput = {
  lead_id: Scalars['ID'];
};

export type LeadAssign = {
  __typename?: 'LeadAssign';
  lead_id?: Maybe<Scalars['Int']>;
};

export type LeadExcludeInput = {
  lead_id: Scalars['Int'];
  is_exclude: Scalars['Int'];
};

export type LeadSubscribeType = {
  __typename?: 'LeadSubscribeType';
  id?: Maybe<Scalars['Int']>;
  is_exclude?: Maybe<Scalars['Int']>;
};

export type LeadTextExcludeInput = {
  lead_id: Scalars['Int'];
  is_exclude: Scalars['Int'];
};

export type LeadTextSubscribeType = {
  __typename?: 'LeadTextSubscribeType';
  id?: Maybe<Scalars['Int']>;
  is_exclude?: Maybe<Scalars['Int']>;
};

export type LeadEmailValidateInput = {
  email?: Maybe<Array<Scalars['String']>>;
};

export type LeadEmailValidateType = {
  __typename?: 'LeadEmailValidateType';
  valid?: Maybe<Scalars['Boolean']>;
};

export type LeadMobileValidateInput = {
  mobile?: Maybe<Array<Scalars['String']>>;
};

export type CreateLeadTagInput = {
  lead_id: Scalars['Int'];
  tag_ids?: Maybe<TagIds>;
};

export type TagIds = {
  ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type DeleteLeadTagInput = {
  id: Scalars['Int'];
  lead_id: Scalars['Int'];
};

export type CreateTodoTaskInput = {
  note_id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
  realtor_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  task_date_time?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  todo_type_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
};

export type UpdateTodoTaskInput = {
  id: Scalars['Int'];
  lead_id?: Maybe<Scalars['Int']>;
  realtor_id?: Maybe<Scalars['Int']>;
  admin_id?: Maybe<Scalars['Int']>;
  task_date_time: Scalars['String'];
  priority?: Maybe<Scalars['String']>;
  todo_type_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  is_completed?: Maybe<Scalars['Int']>;
  is_duplicate?: Maybe<Scalars['Int']>;
};

export type CompletedToDoTaskInput = {
  id?: Maybe<Scalars['Int']>;
  is_completed?: Maybe<Scalars['Int']>;
};

export type DeleteToDoTaskInput = {
  id: Scalars['Int'];
};

export type SaveSearchInput = {
  lead_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  query: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type CreateAdminInput = {
  company_id: Scalars['Int'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  dre_no?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  avatar: Scalars['Upload'];
  type?: Maybe<Scalars['String']>;
  office_id?: Maybe<Scalars['Int']>;
  territory?: Maybe<Array<Maybe<TerritoryData>>>;
  current_weight?: Maybe<Scalars['Int']>;
  hq_contact_no?: Maybe<Scalars['String']>;
  hq_star_rating?: Maybe<Scalars['String']>;
  hq_recent_deals?: Maybe<Scalars['String']>;
  twilio_numbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  role_ids: Array<Maybe<Scalars['String']>>;
  queue?: Maybe<Array<Maybe<QueueInputAdmin>>>;
  referral_due?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
};

export type TerritoryData = {
  territory_id?: Maybe<Scalars['Int']>;
  lead_percentage?: Maybe<Scalars['String']>;
};

export type QueueInputAdmin = {
  id?: Maybe<Scalars['Int']>;
  queue_id?: Maybe<Scalars['ID']>;
  number_id?: Maybe<Scalars['ID']>;
  action?: Maybe<ActionType>;
};

export enum ActionType {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type CreateAdminResponse = {
  __typename?: 'CreateAdminResponse';
  adminSignature?: Maybe<AdminSignatures>;
};

export type UpdateAdminInput = {
  id?: Maybe<Scalars['ID']>;
  company_id: Array<Maybe<Scalars['Int']>>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  dre_no?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['Upload']>;
  type?: Maybe<Scalars['String']>;
  office_id?: Maybe<Scalars['Int']>;
  territory?: Maybe<Array<Maybe<UpdateUserOfficeTerritory>>>;
  current_weight?: Maybe<Scalars['Int']>;
  hq_contact_no?: Maybe<Scalars['String']>;
  hq_star_rating?: Maybe<Scalars['String']>;
  hq_recent_deals?: Maybe<Scalars['String']>;
  twilio_numbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  role_ids: Array<Maybe<Scalars['Int']>>;
  queue?: Maybe<Array<Maybe<QueueInputAdmin>>>;
  referral_due?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
};

export type UpdateUserOfficeTerritory = {
  id?: Maybe<Scalars['Int']>;
  territory_id?: Maybe<Scalars['Int']>;
  lead_percentage?: Maybe<Scalars['String']>;
};

export type UpdateAdminResponse = {
  __typename?: 'UpdateAdminResponse';
  adminSignature?: Maybe<AdminSignatures>;
};

export type ActiveAdminInput = {
  id: Scalars['ID'];
};

export type ActiveAdminResponse = {
  __typename?: 'ActiveAdminResponse';
  admin?: Maybe<Admin>;
};

export type InActiveAdminInput = {
  id: Scalars['ID'];
};

export type InActiveAdminResponse = {
  __typename?: 'InActiveAdminResponse';
  admin?: Maybe<Admin>;
};

export type DeleteTerritoryInput = {
  id: Scalars['Int'];
};

export type DeleteTerritoryResponse = {
  __typename?: 'DeleteTerritoryResponse';
  admin?: Maybe<Admin>;
};

export type AdminAssignPermissionInput = {
  permission_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type RoleAssignPermissionInput = {
  role_permission_ids: Scalars['String'];
  company_id?: Maybe<Scalars['Int']>;
};

export type AdminAssignRoleInput = {
  role_ids?: Maybe<Array<Maybe<Scalars['Int']>>>;
  admin_id?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type WorkingHourInput = {
  workingHours: Array<WorkingHourDay>;
};

export type WorkingHourDay = {
  day: Scalars['Int'];
  start_time: Scalars['String'];
  end_time: Scalars['String'];
};

export type CreateAppointmentInput = {
  lead_id?: Maybe<Scalars['Int']>;
  appointmenttype: Scalars['Int'];
  description: Scalars['String'];
  allday?: Maybe<Scalars['Int']>;
  is_send_email?: Maybe<Scalars['Int']>;
  is_send_sms?: Maybe<Scalars['Int']>;
  reminder_frequesncy?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['String']>;
  end_timestamp?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  appointment_invites: Array<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  property_id?: Maybe<Array<Maybe<PropertyDataCreateInput>>>;
};

export type PropertyDataCreateInput = {
  id?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
};

export type UpdateAppointmentInput = {
  id: Scalars['Int'];
  lead_id?: Maybe<Scalars['Int']>;
  appointmenttype: Scalars['Int'];
  description: Scalars['String'];
  allday?: Maybe<Scalars['Int']>;
  is_send_email?: Maybe<Scalars['Int']>;
  is_send_sms?: Maybe<Scalars['Int']>;
  reminder_frequesncy?: Maybe<Scalars['String']>;
  start_timestamp?: Maybe<Scalars['String']>;
  end_timestamp?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  appointment_invites: Array<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  property_id?: Maybe<Array<Maybe<PropertyDataUpdateInput>>>;
  status?: Maybe<Scalars['String']>;
};

export type PropertyDataUpdateInput = {
  id?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
};

export type DeleteAppointmentInput = {
  id: Scalars['Int'];
};

export type AppointmentStatusUpdateInput = {
  id: Scalars['Int'];
  status: Scalars['String'];
};

export type QuestionInput = {
  id: Scalars['Int'];
  lead_id: Scalars['Int'];
  question?: Maybe<Scalars['String']>;
};

export type DeleteQuestionAnswer = {
  id: Scalars['Int'];
  lead_id: Scalars['Int'];
  question_id: Scalars['Int'];
};

export type SaveStageInput = {
  lead_id?: Maybe<Scalars['Int']>;
  stage_id?: Maybe<Scalars['Int']>;
};

export type AgentSaveSearchInput = {
  lead_id: Scalars['ID'];
  name: Scalars['String'];
  query: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type DeleteSavedSearchInput = {
  id?: Maybe<Scalars['Int']>;
};

export type UserAgentSaveSearchInput = {
  name: Scalars['String'];
  query: Scalars['String'];
  id?: Maybe<Scalars['ID']>;
};

export type CreateLenderApprovedInput = {
  lead_id: Scalars['Int'];
  created_by?: Maybe<Scalars['Int']>;
  approval_amount?: Maybe<Scalars['String']>;
  financing_type_id?: Maybe<Scalars['Int']>;
  loan_officer_name?: Maybe<Scalars['String']>;
  mortgage_co_name?: Maybe<Scalars['String']>;
};

export type UpdateLenderApprovedInput = {
  lead_id: Scalars['Int'];
  id: Scalars['Int'];
  approval_amount?: Maybe<Scalars['String']>;
  financing_type_id?: Maybe<Scalars['Int']>;
  loan_officer_name?: Maybe<Scalars['String']>;
  mortgage_co_name?: Maybe<Scalars['String']>;
};

export type DeleteLenderApprovedInput = {
  id: Scalars['Int'];
  lead_id: Scalars['Int'];
};

export type CreateTerritoryInput = {
  name: Scalars['String'];
  company_id: Scalars['Int'];
  territoryInfo: Array<CreateTerritoryInfoArr>;
};

export type CreateTerritoryInfoArr = {
  zipcode_id: Scalars['Int'];
};

export type UpdateTerritoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  company_id: Scalars['Int'];
  territoryInfo: Array<UpdateTerritoryInfoArr>;
  realtors?: Maybe<Array<Maybe<UpdateRealtorInfoArr>>>;
};

export type UpdateTerritoryInfoArr = {
  id?: Maybe<Scalars['Int']>;
  zipcode_id: Scalars['Int'];
};

export type UpdateRealtorInfoArr = {
  id?: Maybe<Scalars['Int']>;
  realtor_id?: Maybe<Scalars['Int']>;
  lead_percentage?: Maybe<Scalars['String']>;
};

export type CreateOfficeInput = {
  company_id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone_no: Scalars['String'];
  territory_ids: Array<Scalars['Int']>;
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  office_manager: Scalars['Int'];
};

export type UpdateOfficeInput = {
  id: Scalars['ID'];
  company_id: Scalars['Int'];
  name: Scalars['String'];
  email: Scalars['String'];
  phone_no: Scalars['String'];
  territory_ids: Array<Scalars['Int']>;
  realtors?: Maybe<Array<Maybe<Scalars['Int']>>>;
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  office_manager: Scalars['Int'];
};

export type AddNewUserInput = {
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  role_id: Scalars['Int'];
  role_type: Scalars['String'];
};

export type RealtorPerosnalInput = {
  admin_id: Scalars['Int'];
  personal_email: Scalars['String'];
  personal_phone_no: Scalars['String'];
  dob: Scalars['String'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  language: Array<Scalars['Int']>;
  avatar?: Maybe<Scalars['Upload']>;
};

export type RealtorInfoInput = {
  admin_id: Scalars['Int'];
  drivinglicense?: Maybe<Scalars['Upload']>;
  carinsurance?: Maybe<Scalars['Upload']>;
  realtorlicense?: Maybe<Scalars['Upload']>;
  realtor_license_no?: Maybe<Scalars['String']>;
  realtor_license_expire_date?: Maybe<Scalars['String']>;
  income_goal: Scalars['String'];
  timezone: Scalars['Int'];
  weekofday: Array<Scalars['Int']>;
};

export type RealtorBankDetailInput = {
  admin_id: Scalars['Int'];
  account_name: Scalars['String'];
  bank_name: Scalars['String'];
  account_number: Scalars['String'];
  routing_number: Scalars['String'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  account_type: Scalars['String'];
  account_type_value: Scalars['String'];
};

export type UpdateRealtorDetailInput = {
  admin_id: Scalars['Int'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  leads: Scalars['Int'];
  is_verify: Scalars['Int'];
  realtorPersonal: UpdateRealtorPersonalInput;
  realtorInfo: UpdateRealtorInfo;
  realtorBankDetail: UpdateRealtorBankDetailInput;
  officeTerritory?: Maybe<OfficeTerritoryInput>;
};

export type UpdateRealtorPersonalInput = {
  personal_email: Scalars['String'];
  personal_phone_no: Scalars['String'];
  dob: Scalars['String'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  language: Array<Scalars['Int']>;
  avatar?: Maybe<Scalars['Upload']>;
};

export type UpdateRealtorInfo = {
  drivinglicense?: Maybe<Scalars['Upload']>;
  carinsurance?: Maybe<Scalars['Upload']>;
  realtorlicense?: Maybe<Scalars['Upload']>;
  realtor_license_no?: Maybe<Scalars['String']>;
  realtor_license_expire_date?: Maybe<Scalars['String']>;
  income_goal: Scalars['String'];
  concierge_fee: Scalars['String'];
  technology_fee: Scalars['String'];
  tc_fee: Scalars['String'];
  timezone: Scalars['Int'];
  weekofday: Array<Scalars['Int']>;
};

export type UpdateRealtorBankDetailInput = {
  account_name: Scalars['String'];
  bank_name: Scalars['String'];
  account_number: Scalars['String'];
  routing_number: Scalars['String'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  account_type: Scalars['String'];
  account_type_value: Scalars['String'];
};

export type OfficeTerritoryInput = {
  commission_percentage: Scalars['String'];
  office_id: Scalars['Int'];
  user_territory?: Maybe<Array<UserTerritory>>;
};

export type UserTerritory = {
  territory_id?: Maybe<Scalars['Int']>;
  lead_percentage?: Maybe<Scalars['String']>;
};

export type ConciergerPerosnalInput = {
  admin_id: Scalars['Int'];
  personal_email: Scalars['String'];
  personal_phone_no: Scalars['String'];
  dob: Scalars['String'];
  idcard: Scalars['Upload'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  avatar: Scalars['Upload'];
  language: Array<Scalars['Int']>;
  timezone: Scalars['Int'];
};

export type ConciergerBankDetailInput = {
  admin_id: Scalars['Int'];
  bank_name: Scalars['String'];
  account_number: Scalars['String'];
  routing_number: Scalars['String'];
  account_type: Scalars['String'];
  account_type_value: Scalars['String'];
};

export type UpdateConciergerInput = {
  admin_id: Scalars['Int'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  leads: Scalars['Int'];
  is_verify: Scalars['Int'];
  conciergerPersonal: UpdateConciergerPersonal;
  conciergerBankDetail: UpdateConciergerBankDetail;
  officeTerritory?: Maybe<OfficeTerritoryInput>;
};

export type UpdateConciergerPersonal = {
  personal_email: Scalars['String'];
  personal_phone_no: Scalars['String'];
  dob: Scalars['String'];
  idcard: Scalars['Upload'];
  address: Scalars['String'];
  unit: Scalars['String'];
  city: Scalars['String'];
  zipcode: Scalars['String'];
  state: Scalars['String'];
  country_id: Scalars['Int'];
  avatar: Scalars['Upload'];
  language: Array<Scalars['Int']>;
  timezone: Scalars['Int'];
};

export type UpdateConciergerBankDetail = {
  bank_name: Scalars['String'];
  account_number: Scalars['String'];
  routing_number: Scalars['String'];
  account_type: Scalars['String'];
  account_type_value: Scalars['String'];
};

export type CreateNoteInput = {
  lead_id: Scalars['Int'];
  note: Scalars['String'];
  note_action?: Maybe<Scalars['String']>;
  save_pin: Scalars['Int'];
};

export type UpdateNoteInput = {
  id: Scalars['ID'];
  note: Scalars['String'];
  lead_id: Scalars['Int'];
  note_action?: Maybe<Scalars['String']>;
  save_pin: Scalars['Int'];
};

export type DeleteNoteInput = {
  id: Scalars['ID'];
  lead_id: Scalars['Int'];
};

export type CreateTemplateInput = {
  category_id: Scalars['Int'];
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: Scalars['String'];
};

export type UpdateTemplateInput = {
  id: Scalars['ID'];
  category_id: Scalars['Int'];
  name: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  type: Scalars['String'];
};

export type ExportTemplate = {
  __typename?: 'ExportTemplate';
  url: Scalars['String'];
};

export type GmailConnectInput = {
  code: Scalars['String'];
};

export type GmailConnectResponse = {
  __typename?: 'GmailConnectResponse';
  meta?: Maybe<Meta>;
};

export type YahooConnectInput = {
  user?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type OtherConnectInput = {
  user?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  imap_host?: Maybe<Scalars['String']>;
  imap_port?: Maybe<Scalars['String']>;
  smtp_host?: Maybe<Scalars['String']>;
  smtp_port?: Maybe<Scalars['String']>;
};

export type ComposeMailInput = {
  mailId?: Maybe<Scalars['Int']>;
  leadsId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  replyId?: Maybe<Scalars['String']>;
  mail: MailInput;
};

export type MailInput = {
  cc?: Maybe<Array<Maybe<MailFomat>>>;
  bcc?: Maybe<Array<Maybe<MailFomat>>>;
  to?: Maybe<Array<Maybe<MailFomat>>>;
  message?: Maybe<Scalars['String']>;
  subject?: Maybe<Scalars['String']>;
};

export type MailFomat = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
};

export type ComposeMailResponse = {
  __typename?: 'ComposeMailResponse';
  meta: Meta;
  mailId?: Maybe<Scalars['Int']>;
};

export type SendMailInput = {
  mailId: Scalars['Int'];
  is_match_property?: Maybe<Scalars['Boolean']>;
};

export type SendMailResponse = {
  __typename?: 'SendMailResponse';
  meta?: Maybe<Meta>;
};

export type SendListingSentMailInput = {
  property_id?: Maybe<Array<Scalars['String']>>;
  lead_id?: Maybe<Scalars['Int']>;
  mail: MailInput;
};

export type SendListingSentMailType = {
  __typename?: 'sendListingSentMailType';
  meta?: Maybe<Meta>;
};

export type SendListingSentMailInputV2 = {
  property_id?: Maybe<Array<Scalars['String']>>;
  lead_id?: Maybe<Scalars['Int']>;
  mail?: Maybe<MailInput>;
  is_text?: Maybe<Scalars['Boolean']>;
  is_email?: Maybe<Scalars['Boolean']>;
  text_message?: Maybe<Scalars['String']>;
};

export type GmailCredInput = {
  client_id?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

export type TransferLeadInput = {
  realtor_id?: Maybe<Scalars['Int']>;
  primary_realtor_id?: Maybe<Scalars['Int']>;
  concierge_id?: Maybe<Scalars['Int']>;
  primary_concierge_id?: Maybe<Scalars['Int']>;
  office_manager_id?: Maybe<Scalars['Int']>;
  concierge_manager_id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  company_id?: Maybe<Scalars['Int']>;
};

export type MassTransferLeadInput = {
  realtor_id?: Maybe<Scalars['Int']>;
  primary_realtor_id?: Maybe<Scalars['Int']>;
  concierge_id?: Maybe<Scalars['Int']>;
  primary_concierge_id?: Maybe<Scalars['Int']>;
  office_manager_id?: Maybe<Scalars['Int']>;
  concierge_manager_id?: Maybe<Scalars['Int']>;
  lead_id: Array<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type NotificationStatusUpdateInput = {
  id: Scalars['ID'];
};

export type SendMsgInput = {
  conversation_id: Scalars['ID'];
  body: Scalars['String'];
};

export type StartConversationInput = {
  name?: Maybe<Scalars['String']>;
  is_group: Scalars['Boolean'];
  default_number?: Maybe<Scalars['String']>;
  members: Array<Maybe<MemberInput>>;
};

export type MemberInput = {
  member_id?: Maybe<Scalars['ID']>;
  member_type: ConversationMemberType;
  phone_no?: Maybe<Scalars['String']>;
};

export type UpdateConversationInput = {
  conversation_id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  action?: Maybe<ActionType>;
  members?: Maybe<Array<Maybe<MemberInput>>>;
  is_group: Scalars['Boolean'];
};

export type T = {
  __typename?: 't';
  group?: Maybe<Array<Maybe<Conversation>>>;
  person?: Maybe<Array<Maybe<SearchMember>>>;
};

export type SearchMember = {
  __typename?: 'searchMember';
  id: Scalars['ID'];
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  full_name?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  phone_no?: Maybe<Scalars['String']>;
  typeClass?: Maybe<ConversationMemberType>;
};

export type ConversationNumberUpdateInput = {
  conversation_id: Scalars['ID'];
  twilio_number: Scalars['ID'];
};

export enum TransferToType {
  Vm = 'VM',
  Queue = 'QUEUE',
  Number = 'NUMBER'
}

export enum DirectionType {
  Inbound = 'INBOUND',
  Outbound = 'OUTBOUND'
}

export type QueueInput = {
  id?: Maybe<Scalars['ID']>;
  company_id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  member_id: Array<Scalars['ID']>;
};

export type AssignQueueInput = {
  id: Scalars['ID'];
  number_id: Scalars['ID'];
  action: ActionType;
};

export type BlockNumberInput = {
  id: Scalars['ID'];
  numbers: Array<Scalars['String']>;
  type: BlockType;
  is_block?: Maybe<Scalars['Boolean']>;
};

export type CreateContact = {
  id: Scalars['ID'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  company_name?: Maybe<Scalars['String']>;
  numbers?: Maybe<Array<Maybe<CreateNumber>>>;
};

export type CreateNumber = {
  id: Scalars['ID'];
  ext_no?: Maybe<Scalars['String']>;
  raw_number: Scalars['String'];
};

export type DailySendListingInput = {
  lead_id?: Maybe<Scalars['Int']>;
};

export type DailySendListing = {
  __typename?: 'DailySendListing';
  id?: Maybe<Scalars['Int']>;
  date_time?: Maybe<Scalars['String']>;
  lead?: Maybe<Lead>;
};

export type CreateLeadOfferInput = {
  lead_id?: Maybe<Scalars['Int']>;
  lead_offer_id?: Maybe<Scalars['Int']>;
  offer_date?: Maybe<Scalars['String']>;
  property_address?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['String']>;
  offer_amount?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  realtor_id?: Maybe<Scalars['Int']>;
  client_name?: Maybe<Scalars['String']>;
  offer_type: Scalars['String'];
  offer_status?: Maybe<Scalars['String']>;
  offer_closing_status?: Maybe<Scalars['String']>;
  closing_price?: Maybe<Scalars['String']>;
  closing_date?: Maybe<Scalars['String']>;
  accepted_price?: Maybe<Scalars['String']>;
  agent_submitted_date?: Maybe<Scalars['String']>;
};

export type UpdateLeadOfferInput = {
  id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
  lead_offer_id?: Maybe<Scalars['Int']>;
  offer_date?: Maybe<Scalars['String']>;
  property_address_id?: Maybe<Scalars['Int']>;
  offer_amount?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  realtor_id?: Maybe<Scalars['Int']>;
  client_name?: Maybe<Scalars['String']>;
  offer_type: Scalars['String'];
  offer_status?: Maybe<Scalars['String']>;
  offer_closing_status?: Maybe<Scalars['String']>;
  closing_price?: Maybe<Scalars['String']>;
  closing_date?: Maybe<Scalars['String']>;
  accepted_price?: Maybe<Scalars['String']>;
  agent_submitted_date?: Maybe<Scalars['String']>;
};

export type DeleteLeadOfferInput = {
  id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
};

export type CreateLeadContractInput = {
  lead_id: Scalars['Int'];
  contract_date: Scalars['String'];
  mls_id?: Maybe<Scalars['String']>;
  estimated_close_date: Scalars['String'];
  contract_price: Scalars['String'];
  commission_rate: Scalars['String'];
  realtor_id: Scalars['Int'];
  offer_id?: Maybe<Scalars['Int']>;
  contract_status?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['String']>;
  appraisal_due?: Maybe<Scalars['String']>;
  inspection_due?: Maybe<Scalars['String']>;
  loan_due?: Maybe<Scalars['String']>;
  referral_due?: Maybe<Scalars['String']>;
  client_credit?: Maybe<Scalars['String']>;
  commission_hud_status?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  brokerage_paid_agent?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  other_payment?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
};

export type UpdateLeadContractInput = {
  id?: Maybe<Scalars['Int']>;
  lead_id: Scalars['Int'];
  contract_date: Scalars['String'];
  mls_id?: Maybe<Scalars['String']>;
  estimated_close_date: Scalars['String'];
  contract_price: Scalars['String'];
  commission_rate: Scalars['String'];
  realtor_id: Scalars['Int'];
  offer_id?: Maybe<Scalars['Int']>;
  contract_status?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['String']>;
  appraisal_due?: Maybe<Scalars['String']>;
  inspection_due?: Maybe<Scalars['String']>;
  loan_due?: Maybe<Scalars['String']>;
  referral_due?: Maybe<Scalars['String']>;
  client_credit?: Maybe<Scalars['String']>;
  commission_hud_status?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  brokerage_paid_agent?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  other_payment?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
};

export type DeleteLeadContractInput = {
  id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
};

export type CreateLeadSoldInput = {
  lead_id: Scalars['Int'];
  contract_id?: Maybe<Scalars['Int']>;
  offer_type_id?: Maybe<Scalars['Int']>;
  property_address?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  sold_date?: Maybe<Scalars['String']>;
  sold_price?: Maybe<Scalars['String']>;
  commission_percentage?: Maybe<Scalars['String']>;
  concessions?: Maybe<Scalars['String']>;
  lender_name?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  primary_realtor: Scalars['Int'];
  secondary_realtor?: Maybe<Scalars['Int']>;
  primary_concierge?: Maybe<Scalars['Int']>;
  secondary_concierge?: Maybe<Scalars['Int']>;
};

export type UpdateLeadSoldInput = {
  id: Scalars['Int'];
  contract_id?: Maybe<Scalars['Int']>;
  lead_id: Scalars['Int'];
  offer_type_id?: Maybe<Scalars['Int']>;
  property_address?: Maybe<Scalars['String']>;
  mls_id?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
  sold_date?: Maybe<Scalars['String']>;
  sold_price?: Maybe<Scalars['String']>;
  commission_percentage?: Maybe<Scalars['String']>;
  other?: Maybe<Scalars['String']>;
  concessions?: Maybe<Scalars['String']>;
  lender_name?: Maybe<Scalars['String']>;
  primary_realtor: Scalars['Int'];
  secondary_realtor?: Maybe<Scalars['Int']>;
  primary_concierge?: Maybe<Scalars['Int']>;
  secondary_concierge?: Maybe<Scalars['Int']>;
};

export type DeleteLeadSoldInput = {
  id: Scalars['Int'];
  lead_id?: Maybe<Scalars['Int']>;
};

export type CreateLeadListingInput = {
  lead_id: Scalars['Int'];
  agreement_signed_date: Scalars['String'];
  mls_id?: Maybe<Scalars['String']>;
  estimated_live_date: Scalars['String'];
  listing_price: Scalars['String'];
  commission_rate: Scalars['String'];
  realtor_id: Scalars['Int'];
  remodel_sale: Scalars['Int'];
  llc_project: Scalars['Int'];
  offer_id?: Maybe<Scalars['Int']>;
  listing_status?: Maybe<Scalars['String']>;
  listing_expiration?: Maybe<Scalars['String']>;
  closing_date?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['String']>;
  inspection_due?: Maybe<Scalars['String']>;
  appraisal_due?: Maybe<Scalars['String']>;
  loan_due?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
  third_party_fees?: Maybe<Scalars['String']>;
  fee_captured?: Maybe<Scalars['Int']>;
  transaction_status?: Maybe<Scalars['String']>;
  commission_hud_status?: Maybe<Scalars['String']>;
  brokerage_paid_agent?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  other_payment?: Maybe<Scalars['String']>;
  commission_demand_executed?: Maybe<Scalars['String']>;
};

export type UpdateLeadListingInput = {
  id?: Maybe<Scalars['Int']>;
  lead_id: Scalars['Int'];
  agreement_signed_date: Scalars['String'];
  mls_id?: Maybe<Scalars['String']>;
  estimated_live_date: Scalars['String'];
  listing_price: Scalars['String'];
  commission_rate: Scalars['String'];
  realtor_id: Scalars['Int'];
  remodel_sale: Scalars['Int'];
  llc_project: Scalars['Int'];
  offer_id?: Maybe<Scalars['Int']>;
  listing_expiration?: Maybe<Scalars['String']>;
  closing_date?: Maybe<Scalars['String']>;
  effective_date?: Maybe<Scalars['String']>;
  inspection_due?: Maybe<Scalars['String']>;
  appraisal_due?: Maybe<Scalars['String']>;
  loan_due?: Maybe<Scalars['String']>;
  concierge_fee?: Maybe<Scalars['String']>;
  tech_fee?: Maybe<Scalars['String']>;
  tc_fee?: Maybe<Scalars['String']>;
  commission_due_to_agent?: Maybe<Scalars['String']>;
  third_party_fees?: Maybe<Scalars['String']>;
  fee_captured?: Maybe<Scalars['Int']>;
  listing_status?: Maybe<Scalars['String']>;
  transaction_status?: Maybe<Scalars['String']>;
  commission_hud_status?: Maybe<Scalars['String']>;
  brokerage_paid_agent?: Maybe<Scalars['Int']>;
  payment_method?: Maybe<Scalars['String']>;
  other_payment?: Maybe<Scalars['String']>;
  commission_demand_executed?: Maybe<Scalars['String']>;
};

export type DeleteLeadListingInput = {
  id?: Maybe<Scalars['Int']>;
  lead_id?: Maybe<Scalars['Int']>;
};

export type GoogleLoginInput = {
  access_token?: Maybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  access_token: Scalars['String'];
  refresh_token: Scalars['String'];
  expires_in: Scalars['Int'];
  token_type: Scalars['String'];
  user?: Maybe<Admin>;
};

export type RefreshTokenInput = {
  refresh_token?: Maybe<Scalars['String']>;
};

export type RefreshTokenPayload = {
  __typename?: 'RefreshTokenPayload';
  access_token: Scalars['String'];
  refresh_token: Scalars['String'];
  expires_in: Scalars['Int'];
  token_type: Scalars['String'];
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  status: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};

export type SigUpdate = {
  __typename?: 'sigUpdate';
  message?: Maybe<Scalars['String']>;
};

export type LoginInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type UpdatePassword = {
  old_password: Scalars['String'];
  password: Scalars['String'];
  password_confirmation: Scalars['String'];
};

export type UpdatePasswordResponse = {
  __typename?: 'UpdatePasswordResponse';
  meta?: Maybe<Meta>;
};

export type ForgotPasswordInput = {
  email: Scalars['String'];
};


export type AdminAssignNumberAddUpdateInput = {
  admin_id?: Maybe<Scalars['Int']>;
  twilio_numbers?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type AppointmentInvites = {
  __typename?: 'AppointmentInvites';
  appointment_id: Scalars['Int'];
  admin_id: Scalars['Int'];
};

export type AppointmentInvitesType = {
  __typename?: 'appointmentInvitesType';
  id?: Maybe<Scalars['Int']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type CashFlow = {
  __typename?: 'CashFlow';
  month?: Maybe<Scalars['String']>;
  gross_commission?: Maybe<Scalars['String']>;
  kendrick_commission?: Maybe<Scalars['String']>;
  potential_fee?: Maybe<Scalars['String']>;
  closing_projected?: Maybe<Scalars['String']>;
  total_fee?: Maybe<Scalars['String']>;
  total_closing?: Maybe<Scalars['String']>;
  buy_sides_fees?: Maybe<Scalars['String']>;
  buy_sides_closing?: Maybe<Scalars['String']>;
  listing_fee?: Maybe<Scalars['String']>;
  listing_closings?: Maybe<Scalars['String']>;
};

export type CycleTimeReportItem = {
  __typename?: 'cycleTimeReportItem';
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  stageCount?: Maybe<Scalars['Int']>;
};

export type Calenderable = Appointment | TodoTask;

export type GoogleConnectInput = {
  code?: Maybe<Scalars['String']>;
};

export type Mail = {
  __typename?: 'Mail';
  id: Scalars['ID'];
  mail_id?: Maybe<Scalars['String']>;
  thread_id?: Maybe<Scalars['String']>;
  plain_text?: Maybe<Scalars['String']>;
  metadata?: Maybe<MailMeta>;
};

export type MailMetaFrom = {
  __typename?: 'MailMetaFrom';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export enum MailType {
  Unread = 'UNREAD',
  Read = 'READ'
}

export type AssignConcierge = {
  __typename?: 'assignConcierge';
  id?: Maybe<Scalars['ID']>;
  admin?: Maybe<Admin>;
  status?: Maybe<Scalars['String']>;
};

export type TransferLead = {
  __typename?: 'TransferLead';
  id?: Maybe<Scalars['Int']>;
  realtor_id?: Maybe<Scalars['Int']>;
  concierge_id?: Maybe<Scalars['Int']>;
  office_manager_id?: Maybe<Scalars['Int']>;
  concierge_manager_id?: Maybe<Scalars['Int']>;
};

export type LiveLead = {
  __typename?: 'liveLead';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type ConversationTextInput = {
  to?: Maybe<Scalars['String']>;
};

export type ConversationTextType = {
  __typename?: 'ConversationTextType';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};

export type CallHistory = {
  __typename?: 'CallHistory';
  id: Scalars['ID'];
  status: Scalars['String'];
  from: Scalars['String'];
  to: Scalars['String'];
  duration: Scalars['Int'];
  caller_type?: Maybe<ConversationMemberType>;
  caller?: Maybe<MemberDetail>;
  called_type?: Maybe<ConversationMemberType>;
  called?: Maybe<MemberDetail>;
  timestamp: Scalars['DateTime'];
};

export type ToLead = {
  __typename?: 'ToLead';
  id: Scalars['ID'];
  full_name?: Maybe<Scalars['String']>;
};

export type ToAdmin = {
  __typename?: 'ToAdmin';
  id: Scalars['ID'];
  full_name?: Maybe<Scalars['String']>;
};

export type CapabilityTokenAll = {
  __typename?: 'capabilityTokenAll';
  token: Scalars['String'];
  expire: Scalars['Int'];
  number: Scalars['String'];
};

export enum TransferType {
  Warm = 'WARM',
  Cold = 'COLD'
}

export type MatchedpropertyleadData = {
  __typename?: 'matchedpropertyleadData';
  name?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['String']>;
  lead_id?: Maybe<Scalars['Int']>;
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type GetMyNumbersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyNumbersQuery = (
  { __typename?: 'Query' }
  & { getMyNumbers: Array<(
    { __typename?: 'myNumbers' }
    & Pick<MyNumbers, 'id' | 'friendly_name' | 'phone_number'>
  )> }
);

export type GetCapabilityTokenQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type GetCapabilityTokenQuery = (
  { __typename?: 'Query' }
  & { getCapabilityToken: (
    { __typename?: 'capabilityToken' }
    & Pick<CapabilityToken, 'token' | 'expire'>
    & { number?: Maybe<(
      { __typename?: 'myNumbers' }
      & Pick<MyNumbers, 'id' | 'friendly_name' | 'phone_number'>
    )> }
  ) }
);

export type SearchLeadQueryVariables = Exact<{
  query: Scalars['String'];
  call?: Maybe<Scalars['Boolean']>;
}>;


export type SearchLeadQuery = (
  { __typename?: 'Query' }
  & { searchConversationV2?: Maybe<(
    { __typename?: 'SV2' }
    & { group?: Maybe<Array<Maybe<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'name' | 'default_number' | 'is_group'>
      & { members_details?: Maybe<Array<Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'member_type'>
        & { member?: Maybe<(
          { __typename?: 'memberDetail' }
          & Pick<MemberDetail, 'id' | 'first_name' | 'last_name' | 'avatar'>
        )>, meta?: Maybe<(
          { __typename?: 'info' }
          & Pick<Info, 'is_admin' | 'status'>
        )> }
      )>>> }
    )>>>, person?: Maybe<Array<Maybe<(
      { __typename?: 'searchMemberPerson' }
      & Pick<SearchMemberPerson, 'id' | 'full_name' | 'avatar' | 'phone_no' | 'typeClass' | 'is_primary'>
    )>>> }
  )> }
);

export type GetLeadFromNumberQueryVariables = Exact<{
  q: Scalars['String'];
}>;


export type GetLeadFromNumberQuery = (
  { __typename?: 'Query' }
  & { getLeadFromNumber?: Maybe<(
    { __typename?: 'LeadFromNumber' }
    & Pick<LeadFromNumber, 'id' | 'first_name' | 'last_name' | 'email'>
  )> }
);

export type GetCallHistoryQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetCallHistoryQuery = (
  { __typename?: 'Query' }
  & { getCallHistory?: Maybe<(
    { __typename?: 'CallDetailPaginator' }
    & { data: Array<(
      { __typename?: 'CallDetail' }
      & Pick<CallDetail, 'id' | 'status' | 'from' | 'to' | 'is_forwarded' | 'is_transferred' | 'timestamp' | 'duration' | 'caller_type' | 'called_type' | 'blockNumberTo' | 'blockNumberFrom'>
      & { caller?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, called?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, recording?: Maybe<(
        { __typename?: 'Recording' }
        & Pick<Recording, 'id' | 'sid' | 'source' | 'url' | 'duration'>
      )>, contactNumberFrom?: Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'CallContact' }
          & Pick<CallContact, 'id' | 'first_name' | 'last_name'>
          & { numbers?: Maybe<Array<Maybe<(
            { __typename?: 'FromNumber' }
            & Pick<FromNumber, 'phone_number'>
          )>>> }
        )> }
      )>, contactNumberTo?: Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'CallContact' }
          & Pick<CallContact, 'id' | 'first_name' | 'last_name'>
          & { numbers?: Maybe<Array<Maybe<(
            { __typename?: 'FromNumber' }
            & Pick<FromNumber, 'phone_number'>
          )>>> }
        )> }
      )>, leadNumberTo?: Maybe<(
        { __typename?: 'LeadMobile' }
        & { lead?: Maybe<(
          { __typename?: 'Lead' }
          & Pick<Lead, 'first_name' | 'last_name'>
        )> }
      )>, leadNumberFrom?: Maybe<(
        { __typename?: 'LeadMobile' }
        & { lead?: Maybe<(
          { __typename?: 'Lead' }
          & Pick<Lead, 'first_name' | 'last_name'>
        )> }
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages'>
    ) }
  )> }
);

export type GetCrmContactQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  company_id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
}>;


export type GetCrmContactQuery = (
  { __typename?: 'Query' }
  & { getCRMContact?: Maybe<(
    { __typename?: 'CallContactPaginator' }
    & { data: Array<(
      { __typename?: 'CallContact' }
      & Pick<CallContact, 'id' | 'first_name' | 'last_name' | 'type'>
      & { numbers?: Maybe<Array<Maybe<(
        { __typename?: 'FromNumber' }
        & Pick<FromNumber, 'id' | 'phone_number'>
        & { queue?: Maybe<(
          { __typename?: 'Queue' }
          & Pick<Queue, 'id' | 'name'>
        )> }
      )>>> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'hasMorePages' | 'currentPage' | 'total'>
    ) }
  )> }
);

export type CallQueueMutationVariables = Exact<{
  input?: Maybe<QueueInput>;
}>;


export type CallQueueMutation = (
  { __typename?: 'Mutation' }
  & { callQueue?: Maybe<(
    { __typename?: 'Queue' }
    & Pick<Queue, 'id' | 'name'>
    & { members?: Maybe<Array<Maybe<(
      { __typename?: 'memberDetail' }
      & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
    )>>> }
  )> }
);

export type DeleteQueueMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteQueueMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteQueue'>
);

export type GetQueueQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
  company_id?: Maybe<Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>>;
}>;


export type GetQueueQuery = (
  { __typename?: 'Query' }
  & { getQueue?: Maybe<(
    { __typename?: 'QueuePaginator' }
    & { data: Array<(
      { __typename?: 'Queue' }
      & Pick<Queue, 'id' | 'name' | 'members_count'>
      & { members?: Maybe<Array<Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id'>
      )>>> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages'>
    ) }
  )> }
);

export type AssignQueueMutationVariables = Exact<{
  input?: Maybe<AssignQueueInput>;
}>;


export type AssignQueueMutation = (
  { __typename?: 'Mutation' }
  & { assignQueue?: Maybe<(
    { __typename?: 'Queue' }
    & Pick<Queue, 'id' | 'name'>
  )> }
);

export type AdmininfoQueryVariables = Exact<{ [key: string]: never; }>;


export type AdmininfoQuery = (
  { __typename?: 'Query' }
  & { admininfo?: Maybe<(
    { __typename?: 'Admin' }
    & Pick<Admin, 'id' | 'first_name' | 'last_name' | 'type'>
    & { roles?: Maybe<Array<Maybe<(
      { __typename?: 'Role' }
      & Pick<Role, 'id' | 'name'>
      & { permissions?: Maybe<Array<Maybe<(
        { __typename?: 'Permission' }
        & Pick<Permission, 'id' | 'name'>
      )>>> }
    )>>> }
  )> }
);

export type HoldCallMutationVariables = Exact<{
  call_sid: Array<Scalars['String']> | Scalars['String'];
  hold: Scalars['Boolean'];
}>;


export type HoldCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'holdCall'>
);

export type AddToCallMutationVariables = Exact<{
  call_sid: Scalars['String'];
  to: Scalars['String'];
}>;


export type AddToCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addToCall'>
);

export type GetCallDetailQueryVariables = Exact<{
  call_sid: Scalars['String'];
}>;


export type GetCallDetailQuery = (
  { __typename?: 'Query' }
  & { getCallDetail?: Maybe<(
    { __typename?: 'CallDetail' }
    & Pick<CallDetail, 'id' | 'duration' | 'is_forwarded'>
    & { conference?: Maybe<(
      { __typename?: 'Conference' }
      & Pick<Conference, 'id' | 'name' | 'owner'>
      & { participant?: Maybe<Array<Maybe<(
        { __typename?: 'ConferenceParticipant' }
        & Pick<ConferenceParticipant, 'added_by' | 'sid' | 'number' | 'hold' | 'muted' | 'status'>
      )>>> }
    )> }
  )> }
);

export type RemoveFromCallMutationVariables = Exact<{
  call_sid: Scalars['String'];
  participant_sid: Scalars['String'];
}>;


export type RemoveFromCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeFromCall'>
);

export type ColdTransferCallMutationVariables = Exact<{
  call_sid: Scalars['String'];
  to: Scalars['String'];
  type: TransferToType;
  dir: DirectionType;
}>;


export type ColdTransferCallMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'coldTransferCall'>
);

export type GetContactQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { getContact?: Maybe<(
    { __typename?: 'ContactPaginator' }
    & { data: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company_name'>
      & { numbers?: Maybe<Array<Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id' | 'ext_no' | 'raw_number' | 'formatted_number' | 'blockNumber'>
      )>>> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages'>
    ) }
  )> }
);

export type ContactMutationVariables = Exact<{
  input: CreateContact;
}>;


export type ContactMutation = (
  { __typename?: 'Mutation' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'first_name' | 'last_name' | 'company_name'>
    & { numbers?: Maybe<Array<Maybe<(
      { __typename?: 'ContactNumber' }
      & Pick<ContactNumber, 'id' | 'ext_no' | 'raw_number' | 'formatted_number'>
    )>>> }
  )> }
);

export type DeleteContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactMutation = (
  { __typename?: 'Mutation' }
  & { deleteContact: (
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'first_name' | 'last_name'>
  ) }
);

export type DeleteContactNumberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteContactNumberMutation = (
  { __typename?: 'Mutation' }
  & { deleteContactNumber: (
    { __typename?: 'ContactNumber' }
    & Pick<ContactNumber, 'id' | 'ext_no' | 'raw_number' | 'formatted_number'>
  ) }
);

export type GetVmQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetVmQuery = (
  { __typename?: 'Query' }
  & { getVM?: Maybe<(
    { __typename?: 'CallDetailPaginator' }
    & { data: Array<(
      { __typename?: 'CallDetail' }
      & Pick<CallDetail, 'id' | 'from' | 'to' | 'timestamp'>
      & { caller?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, called?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, contactNumberFrom?: Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'CallContact' }
          & Pick<CallContact, 'id' | 'first_name' | 'last_name'>
          & { numbers?: Maybe<Array<Maybe<(
            { __typename?: 'FromNumber' }
            & Pick<FromNumber, 'phone_number'>
          )>>> }
        )> }
      )>, contactNumberTo?: Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'CallContact' }
          & Pick<CallContact, 'id' | 'first_name' | 'last_name'>
          & { numbers?: Maybe<Array<Maybe<(
            { __typename?: 'FromNumber' }
            & Pick<FromNumber, 'phone_number'>
          )>>> }
        )> }
      )>, leadNumberTo?: Maybe<(
        { __typename?: 'LeadMobile' }
        & { lead?: Maybe<(
          { __typename?: 'Lead' }
          & Pick<Lead, 'first_name' | 'last_name'>
        )> }
      )>, leadNumberFrom?: Maybe<(
        { __typename?: 'LeadMobile' }
        & { lead?: Maybe<(
          { __typename?: 'Lead' }
          & Pick<Lead, 'first_name' | 'last_name'>
        )> }
      )>, voicemail?: Maybe<(
        { __typename?: 'Recording' }
        & Pick<Recording, 'id' | 'sid' | 'source' | 'url' | 'duration'>
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages'>
    ) }
  )> }
);

export type GetBlockNumbersQueryVariables = Exact<{
  admin_id: Scalars['ID'];
}>;


export type GetBlockNumbersQuery = (
  { __typename?: 'Query' }
  & { getBlockNumbers?: Maybe<Array<Maybe<(
    { __typename?: 'BlockNumber' }
    & Pick<BlockNumber, 'id' | 'admin_id' | 'number' | 'type'>
  )>>> }
);

export type BlockNumberV2MutationVariables = Exact<{
  input?: Maybe<BlockNumberInput>;
}>;


export type BlockNumberV2Mutation = (
  { __typename?: 'Mutation' }
  & { blockNumberV2?: Maybe<Array<(
    { __typename?: 'BlockNumber' }
    & Pick<BlockNumber, 'id' | 'admin_id' | 'number' | 'type'>
  )>> }
);

export type DeleteBlockNumberMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteBlockNumberMutation = (
  { __typename?: 'Mutation' }
  & { deleteBlockNumber: (
    { __typename?: 'BlockNumber' }
    & Pick<BlockNumber, 'id' | 'admin_id' | 'number' | 'type'>
  ) }
);

export type GetMiscallsQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type GetMiscallsQuery = (
  { __typename?: 'Query' }
  & { getMiscalls?: Maybe<(
    { __typename?: 'CallDetailPaginator' }
    & { data: Array<(
      { __typename?: 'CallDetail' }
      & Pick<CallDetail, 'id' | 'status' | 'from' | 'to' | 'is_forwarded' | 'is_transferred' | 'timestamp' | 'duration' | 'caller_type' | 'called_type' | 'blockNumberTo' | 'blockNumberFrom'>
      & { caller?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, called?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, contactNumberFrom?: Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'CallContact' }
          & Pick<CallContact, 'id' | 'first_name' | 'last_name'>
          & { numbers?: Maybe<Array<Maybe<(
            { __typename?: 'FromNumber' }
            & Pick<FromNumber, 'phone_number'>
          )>>> }
        )> }
      )>, contactNumberTo?: Maybe<(
        { __typename?: 'ContactNumber' }
        & Pick<ContactNumber, 'id'>
        & { contact?: Maybe<(
          { __typename?: 'CallContact' }
          & Pick<CallContact, 'id' | 'first_name' | 'last_name'>
          & { numbers?: Maybe<Array<Maybe<(
            { __typename?: 'FromNumber' }
            & Pick<FromNumber, 'phone_number'>
          )>>> }
        )> }
      )>, leadNumberTo?: Maybe<(
        { __typename?: 'LeadMobile' }
        & { lead?: Maybe<(
          { __typename?: 'Lead' }
          & Pick<Lead, 'first_name' | 'last_name'>
        )> }
      )>, leadNumberFrom?: Maybe<(
        { __typename?: 'LeadMobile' }
        & { lead?: Maybe<(
          { __typename?: 'Lead' }
          & Pick<Lead, 'first_name' | 'last_name'>
        )> }
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'currentPage' | 'hasMorePages'>
    ) }
  )> }
);

export type GetLoginUserCompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLoginUserCompaniesQuery = (
  { __typename?: 'Query' }
  & { getLoginUserCompanies?: Maybe<Array<(
    { __typename?: 'Company' }
    & Pick<Company, 'id' | 'company_name'>
  )>> }
);

export type ConversationsQueryVariables = Exact<{
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type ConversationsQuery = (
  { __typename?: 'Query' }
  & { conversations?: Maybe<(
    { __typename?: 'ConversationPaginator' }
    & { data: Array<(
      { __typename?: 'Conversation' }
      & Pick<Conversation, 'id' | 'name' | 'admin_id' | 'is_group' | 'default_number'>
      & { members_details?: Maybe<Array<Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'member_type'>
        & { member?: Maybe<(
          { __typename?: 'memberDetail' }
          & Pick<MemberDetail, 'id' | 'first_name' | 'last_name' | 'avatar'>
        )>, meta?: Maybe<(
          { __typename?: 'info' }
          & Pick<Info, 'status'>
        )> }
      )>>>, binding?: Maybe<Array<Maybe<(
        { __typename?: 'ConversationBindings' }
        & Pick<ConversationBindings, 'id' | 'bind_from' | 'bind_to' | 'is_valid'>
        & { member?: Maybe<(
          { __typename?: 'Member' }
          & Pick<Member, 'id' | 'member_type'>
          & { member?: Maybe<(
            { __typename?: 'memberDetail' }
            & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
          )> }
        )> }
      )>>>, conversation_has_members?: Maybe<(
        { __typename?: 'ConversationHasMembers' }
        & Pick<ConversationHasMembers, 'conversation_id'>
        & { message?: Maybe<(
          { __typename?: 'Message' }
          & Pick<Message, 'id' | 'body' | 'created_at'>
          & { event?: Maybe<(
            { __typename?: 'MessageEvent' }
            & Pick<MessageEvent, 'action' | 'affected'>
          )>, sender?: Maybe<(
            { __typename?: 'Member' }
            & { member?: Maybe<(
              { __typename?: 'memberDetail' }
              & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
            )> }
          )>, read_by_me?: Maybe<(
            { __typename?: 'readBy' }
            & Pick<ReadBy, 'msg_id' | 'member_id' | 'read_at'>
          )> }
        )> }
      )>, latest_message?: Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'body' | 'created_at'>
        & { event?: Maybe<(
          { __typename?: 'MessageEvent' }
          & Pick<MessageEvent, 'action' | 'affected'>
        )>, sender?: Maybe<(
          { __typename?: 'Member' }
          & { member?: Maybe<(
            { __typename?: 'memberDetail' }
            & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
          )> }
        )>, read_by_me?: Maybe<(
          { __typename?: 'readBy' }
          & Pick<ReadBy, 'msg_id' | 'member_id' | 'read_at'>
        )> }
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'count' | 'currentPage' | 'hasMorePages' | 'total'>
    ) }
  )> }
);

export type ConversationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConversationQuery = (
  { __typename?: 'Query' }
  & { conversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'name' | 'admin_id' | 'is_group' | 'default_number'>
    & { members_details?: Maybe<Array<Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'member_type'>
      & { member?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name' | 'avatar'>
      )>, meta?: Maybe<(
        { __typename?: 'info' }
        & Pick<Info, 'status'>
      )> }
    )>>>, binding?: Maybe<Array<Maybe<(
      { __typename?: 'ConversationBindings' }
      & Pick<ConversationBindings, 'id' | 'bind_from' | 'bind_to' | 'is_valid'>
      & { member?: Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'member_type'>
        & { member?: Maybe<(
          { __typename?: 'memberDetail' }
          & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
        )> }
      )> }
    )>>>, conversation_has_members?: Maybe<(
      { __typename?: 'ConversationHasMembers' }
      & Pick<ConversationHasMembers, 'conversation_id'>
      & { message?: Maybe<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'body' | 'created_at'>
        & { event?: Maybe<(
          { __typename?: 'MessageEvent' }
          & Pick<MessageEvent, 'action' | 'affected'>
        )>, sender?: Maybe<(
          { __typename?: 'Member' }
          & { member?: Maybe<(
            { __typename?: 'memberDetail' }
            & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
          )> }
        )>, read_by_me?: Maybe<(
          { __typename?: 'readBy' }
          & Pick<ReadBy, 'msg_id' | 'member_id' | 'read_at'>
        )> }
      )> }
    )>, latest_message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'created_at'>
      & { event?: Maybe<(
        { __typename?: 'MessageEvent' }
        & Pick<MessageEvent, 'action' | 'affected'>
      )>, sender?: Maybe<(
        { __typename?: 'Member' }
        & { member?: Maybe<(
          { __typename?: 'memberDetail' }
          & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
        )> }
      )>, read_by_me?: Maybe<(
        { __typename?: 'readBy' }
        & Pick<ReadBy, 'msg_id' | 'member_id' | 'read_at'>
      )> }
    )> }
  )> }
);

export type MessagesQueryVariables = Exact<{
  conversationId: Scalars['ID'];
  first: Scalars['Int'];
  page?: Maybe<Scalars['Int']>;
}>;


export type MessagesQuery = (
  { __typename?: 'Query' }
  & { messages?: Maybe<(
    { __typename?: 'MessagePaginator' }
    & { data: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'type' | 'created_at'>
      & { event?: Maybe<(
        { __typename?: 'MessageEvent' }
        & Pick<MessageEvent, 'action' | 'affected'>
      )>, smsMessages?: Maybe<Array<Maybe<(
        { __typename?: 'SmsMessage' }
        & Pick<SmsMessage, 'id' | 'from' | 'to' | 'status'>
      )>>>, sender?: Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'member_type'>
        & { member?: Maybe<(
          { __typename?: 'memberDetail' }
          & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
        )> }
      )> }
    )>, paginatorInfo: (
      { __typename?: 'PaginatorInfo' }
      & Pick<PaginatorInfo, 'total' | 'currentPage' | 'hasMorePages'>
    ) }
  )> }
);

export type SendMsgMutationVariables = Exact<{
  input?: Maybe<SendMsgInput>;
}>;


export type SendMsgMutation = (
  { __typename?: 'Mutation' }
  & { sendMsg?: Maybe<(
    { __typename?: 'Message' }
    & Pick<Message, 'id' | 'body' | 'created_at'>
    & { sender?: Maybe<(
      { __typename?: 'Member' }
      & { member?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'first_name' | 'last_name'>
      )> }
    )> }
  )> }
);

export type UpdateConversationMutationVariables = Exact<{
  input?: Maybe<UpdateConversationInput>;
}>;


export type UpdateConversationMutation = (
  { __typename?: 'Mutation' }
  & { updateConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'name' | 'is_group' | 'default_number'>
    & { members_details?: Maybe<Array<Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'member_type'>
      & { member?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name' | 'avatar'>
      )>, meta?: Maybe<(
        { __typename?: 'info' }
        & Pick<Info, 'is_admin' | 'status'>
      )> }
    )>>>, latest_message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'created_at'>
      & { event?: Maybe<(
        { __typename?: 'MessageEvent' }
        & Pick<MessageEvent, 'action' | 'affected'>
      )>, read_by_me?: Maybe<(
        { __typename?: 'readBy' }
        & Pick<ReadBy, 'msg_id' | 'member_id' | 'read_at'>
      )> }
    )> }
  )> }
);

export type StartConversationMutationVariables = Exact<{
  input?: Maybe<StartConversationInput>;
}>;


export type StartConversationMutation = (
  { __typename?: 'Mutation' }
  & { startConversation?: Maybe<(
    { __typename?: 'Conversation' }
    & Pick<Conversation, 'id' | 'name' | 'is_group' | 'default_number'>
    & { members_details?: Maybe<Array<Maybe<(
      { __typename?: 'Member' }
      & Pick<Member, 'id' | 'member_type'>
      & { member?: Maybe<(
        { __typename?: 'memberDetail' }
        & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
      )>, meta?: Maybe<(
        { __typename?: 'info' }
        & Pick<Info, 'is_admin' | 'status'>
      )> }
    )>>>, latest_message?: Maybe<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'body' | 'created_at'>
      & { event?: Maybe<(
        { __typename?: 'MessageEvent' }
        & Pick<MessageEvent, 'action' | 'affected'>
      )> }
    )>, binding?: Maybe<Array<Maybe<(
      { __typename?: 'ConversationBindings' }
      & Pick<ConversationBindings, 'id' | 'bind_from' | 'bind_to' | 'is_valid'>
      & { member?: Maybe<(
        { __typename?: 'Member' }
        & Pick<Member, 'id' | 'member_type'>
        & { member?: Maybe<(
          { __typename?: 'memberDetail' }
          & Pick<MemberDetail, 'id' | 'first_name' | 'last_name'>
        )> }
      )> }
    )>>> }
  )> }
);

export type MessageStatusInfoQueryVariables = Exact<{
  input?: Maybe<MessageStatusInfo>;
}>;


export type MessageStatusInfoQuery = (
  { __typename?: 'Query' }
  & { messageStatusInfo?: Maybe<Array<Maybe<(
    { __typename?: 'SmsMessage' }
    & Pick<SmsMessage, 'id' | 'from' | 'to' | 'status'>
  )>>> }
);

export type GetTextCapabilityTokenQueryVariables = Exact<{
  platform?: Maybe<CallDeviceType>;
}>;


export type GetTextCapabilityTokenQuery = (
  { __typename?: 'Query' }
  & { getTextCapabilityToken: (
    { __typename?: 'textCapabilityToken' }
    & Pick<TextCapabilityToken, 'token' | 'expire'>
  ) }
);


export const GetMyNumbersDocument = gql`
    query GetMyNumbers {
  getMyNumbers {
    id
    friendly_name
    phone_number
  }
}
    `;

/**
 * __useGetMyNumbersQuery__
 *
 * To run a query within a React component, call `useGetMyNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNumbersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyNumbersQuery(baseOptions?: Apollo.QueryHookOptions<GetMyNumbersQuery, GetMyNumbersQueryVariables>) {
        return Apollo.useQuery<GetMyNumbersQuery, GetMyNumbersQueryVariables>(GetMyNumbersDocument, baseOptions);
      }
export function useGetMyNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyNumbersQuery, GetMyNumbersQueryVariables>) {
          return Apollo.useLazyQuery<GetMyNumbersQuery, GetMyNumbersQueryVariables>(GetMyNumbersDocument, baseOptions);
        }
export type GetMyNumbersQueryHookResult = ReturnType<typeof useGetMyNumbersQuery>;
export type GetMyNumbersLazyQueryHookResult = ReturnType<typeof useGetMyNumbersLazyQuery>;
export type GetMyNumbersQueryResult = Apollo.QueryResult<GetMyNumbersQuery, GetMyNumbersQueryVariables>;
export const GetCapabilityTokenDocument = gql`
    query GetCapabilityToken($id: ID) {
  getCapabilityToken(number_id: $id) {
    token
    expire
    number {
      id
      friendly_name
      phone_number
    }
  }
}
    `;

/**
 * __useGetCapabilityTokenQuery__
 *
 * To run a query within a React component, call `useGetCapabilityTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCapabilityTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCapabilityTokenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCapabilityTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetCapabilityTokenQuery, GetCapabilityTokenQueryVariables>) {
        return Apollo.useQuery<GetCapabilityTokenQuery, GetCapabilityTokenQueryVariables>(GetCapabilityTokenDocument, baseOptions);
      }
export function useGetCapabilityTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCapabilityTokenQuery, GetCapabilityTokenQueryVariables>) {
          return Apollo.useLazyQuery<GetCapabilityTokenQuery, GetCapabilityTokenQueryVariables>(GetCapabilityTokenDocument, baseOptions);
        }
export type GetCapabilityTokenQueryHookResult = ReturnType<typeof useGetCapabilityTokenQuery>;
export type GetCapabilityTokenLazyQueryHookResult = ReturnType<typeof useGetCapabilityTokenLazyQuery>;
export type GetCapabilityTokenQueryResult = Apollo.QueryResult<GetCapabilityTokenQuery, GetCapabilityTokenQueryVariables>;
export const SearchLeadDocument = gql`
    query SearchLead($query: String!, $call: Boolean) {
  searchConversationV2(query: $query, call: $call) {
    group {
      id
      name
      default_number
      is_group
      members_details {
        id
        member_type
        member {
          id
          first_name
          last_name
          avatar
        }
        meta {
          is_admin
          status
        }
      }
    }
    person {
      id
      full_name
      avatar
      phone_no
      typeClass
      is_primary
    }
  }
}
    `;

/**
 * __useSearchLeadQuery__
 *
 * To run a query within a React component, call `useSearchLeadQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLeadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLeadQuery({
 *   variables: {
 *      query: // value for 'query'
 *      call: // value for 'call'
 *   },
 * });
 */
export function useSearchLeadQuery(baseOptions: Apollo.QueryHookOptions<SearchLeadQuery, SearchLeadQueryVariables>) {
        return Apollo.useQuery<SearchLeadQuery, SearchLeadQueryVariables>(SearchLeadDocument, baseOptions);
      }
export function useSearchLeadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLeadQuery, SearchLeadQueryVariables>) {
          return Apollo.useLazyQuery<SearchLeadQuery, SearchLeadQueryVariables>(SearchLeadDocument, baseOptions);
        }
export type SearchLeadQueryHookResult = ReturnType<typeof useSearchLeadQuery>;
export type SearchLeadLazyQueryHookResult = ReturnType<typeof useSearchLeadLazyQuery>;
export type SearchLeadQueryResult = Apollo.QueryResult<SearchLeadQuery, SearchLeadQueryVariables>;
export const GetLeadFromNumberDocument = gql`
    query GetLeadFromNumber($q: String!) {
  getLeadFromNumber(number: $q) {
    id
    first_name
    last_name
    email
  }
}
    `;

/**
 * __useGetLeadFromNumberQuery__
 *
 * To run a query within a React component, call `useGetLeadFromNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLeadFromNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLeadFromNumberQuery({
 *   variables: {
 *      q: // value for 'q'
 *   },
 * });
 */
export function useGetLeadFromNumberQuery(baseOptions: Apollo.QueryHookOptions<GetLeadFromNumberQuery, GetLeadFromNumberQueryVariables>) {
        return Apollo.useQuery<GetLeadFromNumberQuery, GetLeadFromNumberQueryVariables>(GetLeadFromNumberDocument, baseOptions);
      }
export function useGetLeadFromNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLeadFromNumberQuery, GetLeadFromNumberQueryVariables>) {
          return Apollo.useLazyQuery<GetLeadFromNumberQuery, GetLeadFromNumberQueryVariables>(GetLeadFromNumberDocument, baseOptions);
        }
export type GetLeadFromNumberQueryHookResult = ReturnType<typeof useGetLeadFromNumberQuery>;
export type GetLeadFromNumberLazyQueryHookResult = ReturnType<typeof useGetLeadFromNumberLazyQuery>;
export type GetLeadFromNumberQueryResult = Apollo.QueryResult<GetLeadFromNumberQuery, GetLeadFromNumberQueryVariables>;
export const GetCallHistoryDocument = gql`
    query getCallHistory($first: Int!, $page: Int) {
  getCallHistory(first: $first, page: $page) {
    data {
      id
      status
      from
      to
      is_forwarded
      is_transferred
      timestamp
      duration
      caller_type
      called_type
      blockNumberTo
      blockNumberFrom
      caller {
        id
        first_name
        last_name
      }
      called {
        id
        first_name
        last_name
      }
      recording {
        id
        sid
        source
        url
        duration
      }
      contactNumberFrom {
        id
        contact {
          id
          numbers {
            phone_number
          }
          first_name
          last_name
        }
      }
      contactNumberTo {
        id
        contact {
          id
          numbers {
            phone_number
          }
          first_name
          last_name
        }
      }
      leadNumberTo {
        lead {
          first_name
          last_name
        }
      }
      leadNumberFrom {
        lead {
          first_name
          last_name
        }
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}
    `;

/**
 * __useGetCallHistoryQuery__
 *
 * To run a query within a React component, call `useGetCallHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetCallHistoryQuery(baseOptions: Apollo.QueryHookOptions<GetCallHistoryQuery, GetCallHistoryQueryVariables>) {
        return Apollo.useQuery<GetCallHistoryQuery, GetCallHistoryQueryVariables>(GetCallHistoryDocument, baseOptions);
      }
export function useGetCallHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallHistoryQuery, GetCallHistoryQueryVariables>) {
          return Apollo.useLazyQuery<GetCallHistoryQuery, GetCallHistoryQueryVariables>(GetCallHistoryDocument, baseOptions);
        }
export type GetCallHistoryQueryHookResult = ReturnType<typeof useGetCallHistoryQuery>;
export type GetCallHistoryLazyQueryHookResult = ReturnType<typeof useGetCallHistoryLazyQuery>;
export type GetCallHistoryQueryResult = Apollo.QueryResult<GetCallHistoryQuery, GetCallHistoryQueryVariables>;
export const GetCrmContactDocument = gql`
    query getCRMContact($first: Int!, $page: Int, $company_id: Int!, $key: String) {
  getCRMContact(first: $first, page: $page, company_id: $company_id, key: $key) {
    data {
      id
      first_name
      last_name
      type
      numbers {
        id
        phone_number
        queue {
          id
          name
        }
      }
    }
    paginatorInfo {
      hasMorePages
      currentPage
      total
    }
  }
}
    `;

/**
 * __useGetCrmContactQuery__
 *
 * To run a query within a React component, call `useGetCrmContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCrmContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCrmContactQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      company_id: // value for 'company_id'
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetCrmContactQuery(baseOptions: Apollo.QueryHookOptions<GetCrmContactQuery, GetCrmContactQueryVariables>) {
        return Apollo.useQuery<GetCrmContactQuery, GetCrmContactQueryVariables>(GetCrmContactDocument, baseOptions);
      }
export function useGetCrmContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCrmContactQuery, GetCrmContactQueryVariables>) {
          return Apollo.useLazyQuery<GetCrmContactQuery, GetCrmContactQueryVariables>(GetCrmContactDocument, baseOptions);
        }
export type GetCrmContactQueryHookResult = ReturnType<typeof useGetCrmContactQuery>;
export type GetCrmContactLazyQueryHookResult = ReturnType<typeof useGetCrmContactLazyQuery>;
export type GetCrmContactQueryResult = Apollo.QueryResult<GetCrmContactQuery, GetCrmContactQueryVariables>;
export const CallQueueDocument = gql`
    mutation callQueue($input: queueInput) {
  callQueue(input: $input) {
    id
    name
    members {
      id
      first_name
      last_name
    }
  }
}
    `;
export type CallQueueMutationFn = Apollo.MutationFunction<CallQueueMutation, CallQueueMutationVariables>;

/**
 * __useCallQueueMutation__
 *
 * To run a mutation, you first call `useCallQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCallQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [callQueueMutation, { data, loading, error }] = useCallQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCallQueueMutation(baseOptions?: Apollo.MutationHookOptions<CallQueueMutation, CallQueueMutationVariables>) {
        return Apollo.useMutation<CallQueueMutation, CallQueueMutationVariables>(CallQueueDocument, baseOptions);
      }
export type CallQueueMutationHookResult = ReturnType<typeof useCallQueueMutation>;
export type CallQueueMutationResult = Apollo.MutationResult<CallQueueMutation>;
export type CallQueueMutationOptions = Apollo.BaseMutationOptions<CallQueueMutation, CallQueueMutationVariables>;
export const DeleteQueueDocument = gql`
    mutation deleteQueue($id: ID!) {
  deleteQueue(id: $id)
}
    `;
export type DeleteQueueMutationFn = Apollo.MutationFunction<DeleteQueueMutation, DeleteQueueMutationVariables>;

/**
 * __useDeleteQueueMutation__
 *
 * To run a mutation, you first call `useDeleteQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteQueueMutation, { data, loading, error }] = useDeleteQueueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteQueueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteQueueMutation, DeleteQueueMutationVariables>) {
        return Apollo.useMutation<DeleteQueueMutation, DeleteQueueMutationVariables>(DeleteQueueDocument, baseOptions);
      }
export type DeleteQueueMutationHookResult = ReturnType<typeof useDeleteQueueMutation>;
export type DeleteQueueMutationResult = Apollo.MutationResult<DeleteQueueMutation>;
export type DeleteQueueMutationOptions = Apollo.BaseMutationOptions<DeleteQueueMutation, DeleteQueueMutationVariables>;
export const GetQueueDocument = gql`
    query getQueue($first: Int!, $page: Int, $company_id: [Int]) {
  getQueue(first: $first, page: $page, company_id: $company_id) {
    data {
      id
      name
      members {
        id
      }
      members_count
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}
    `;

/**
 * __useGetQueueQuery__
 *
 * To run a query within a React component, call `useGetQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQueueQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *      company_id: // value for 'company_id'
 *   },
 * });
 */
export function useGetQueueQuery(baseOptions: Apollo.QueryHookOptions<GetQueueQuery, GetQueueQueryVariables>) {
        return Apollo.useQuery<GetQueueQuery, GetQueueQueryVariables>(GetQueueDocument, baseOptions);
      }
export function useGetQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQueueQuery, GetQueueQueryVariables>) {
          return Apollo.useLazyQuery<GetQueueQuery, GetQueueQueryVariables>(GetQueueDocument, baseOptions);
        }
export type GetQueueQueryHookResult = ReturnType<typeof useGetQueueQuery>;
export type GetQueueLazyQueryHookResult = ReturnType<typeof useGetQueueLazyQuery>;
export type GetQueueQueryResult = Apollo.QueryResult<GetQueueQuery, GetQueueQueryVariables>;
export const AssignQueueDocument = gql`
    mutation assignQueue($input: assignQueueInput) {
  assignQueue(input: $input) {
    id
    name
  }
}
    `;
export type AssignQueueMutationFn = Apollo.MutationFunction<AssignQueueMutation, AssignQueueMutationVariables>;

/**
 * __useAssignQueueMutation__
 *
 * To run a mutation, you first call `useAssignQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignQueueMutation, { data, loading, error }] = useAssignQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAssignQueueMutation(baseOptions?: Apollo.MutationHookOptions<AssignQueueMutation, AssignQueueMutationVariables>) {
        return Apollo.useMutation<AssignQueueMutation, AssignQueueMutationVariables>(AssignQueueDocument, baseOptions);
      }
export type AssignQueueMutationHookResult = ReturnType<typeof useAssignQueueMutation>;
export type AssignQueueMutationResult = Apollo.MutationResult<AssignQueueMutation>;
export type AssignQueueMutationOptions = Apollo.BaseMutationOptions<AssignQueueMutation, AssignQueueMutationVariables>;
export const AdmininfoDocument = gql`
    query admininfo {
  admininfo {
    id
    first_name
    last_name
    type
    roles {
      id
      name
      permissions {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdmininfoQuery__
 *
 * To run a query within a React component, call `useAdmininfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdmininfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdmininfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdmininfoQuery(baseOptions?: Apollo.QueryHookOptions<AdmininfoQuery, AdmininfoQueryVariables>) {
        return Apollo.useQuery<AdmininfoQuery, AdmininfoQueryVariables>(AdmininfoDocument, baseOptions);
      }
export function useAdmininfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdmininfoQuery, AdmininfoQueryVariables>) {
          return Apollo.useLazyQuery<AdmininfoQuery, AdmininfoQueryVariables>(AdmininfoDocument, baseOptions);
        }
export type AdmininfoQueryHookResult = ReturnType<typeof useAdmininfoQuery>;
export type AdmininfoLazyQueryHookResult = ReturnType<typeof useAdmininfoLazyQuery>;
export type AdmininfoQueryResult = Apollo.QueryResult<AdmininfoQuery, AdmininfoQueryVariables>;
export const HoldCallDocument = gql`
    mutation holdCall($call_sid: [String!]!, $hold: Boolean!) {
  holdCall(call_sid: $call_sid, hold: $hold)
}
    `;
export type HoldCallMutationFn = Apollo.MutationFunction<HoldCallMutation, HoldCallMutationVariables>;

/**
 * __useHoldCallMutation__
 *
 * To run a mutation, you first call `useHoldCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHoldCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [holdCallMutation, { data, loading, error }] = useHoldCallMutation({
 *   variables: {
 *      call_sid: // value for 'call_sid'
 *      hold: // value for 'hold'
 *   },
 * });
 */
export function useHoldCallMutation(baseOptions?: Apollo.MutationHookOptions<HoldCallMutation, HoldCallMutationVariables>) {
        return Apollo.useMutation<HoldCallMutation, HoldCallMutationVariables>(HoldCallDocument, baseOptions);
      }
export type HoldCallMutationHookResult = ReturnType<typeof useHoldCallMutation>;
export type HoldCallMutationResult = Apollo.MutationResult<HoldCallMutation>;
export type HoldCallMutationOptions = Apollo.BaseMutationOptions<HoldCallMutation, HoldCallMutationVariables>;
export const AddToCallDocument = gql`
    mutation addToCall($call_sid: String!, $to: String!) {
  addToCall(call_sid: $call_sid, to: $to)
}
    `;
export type AddToCallMutationFn = Apollo.MutationFunction<AddToCallMutation, AddToCallMutationVariables>;

/**
 * __useAddToCallMutation__
 *
 * To run a mutation, you first call `useAddToCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToCallMutation, { data, loading, error }] = useAddToCallMutation({
 *   variables: {
 *      call_sid: // value for 'call_sid'
 *      to: // value for 'to'
 *   },
 * });
 */
export function useAddToCallMutation(baseOptions?: Apollo.MutationHookOptions<AddToCallMutation, AddToCallMutationVariables>) {
        return Apollo.useMutation<AddToCallMutation, AddToCallMutationVariables>(AddToCallDocument, baseOptions);
      }
export type AddToCallMutationHookResult = ReturnType<typeof useAddToCallMutation>;
export type AddToCallMutationResult = Apollo.MutationResult<AddToCallMutation>;
export type AddToCallMutationOptions = Apollo.BaseMutationOptions<AddToCallMutation, AddToCallMutationVariables>;
export const GetCallDetailDocument = gql`
    query getCallDetail($call_sid: String!) {
  getCallDetail(call_sid: $call_sid) {
    id
    duration
    is_forwarded
    conference {
      id
      name
      owner
      participant {
        added_by
        sid
        number
        hold
        muted
        status
      }
    }
  }
}
    `;

/**
 * __useGetCallDetailQuery__
 *
 * To run a query within a React component, call `useGetCallDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallDetailQuery({
 *   variables: {
 *      call_sid: // value for 'call_sid'
 *   },
 * });
 */
export function useGetCallDetailQuery(baseOptions: Apollo.QueryHookOptions<GetCallDetailQuery, GetCallDetailQueryVariables>) {
        return Apollo.useQuery<GetCallDetailQuery, GetCallDetailQueryVariables>(GetCallDetailDocument, baseOptions);
      }
export function useGetCallDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallDetailQuery, GetCallDetailQueryVariables>) {
          return Apollo.useLazyQuery<GetCallDetailQuery, GetCallDetailQueryVariables>(GetCallDetailDocument, baseOptions);
        }
export type GetCallDetailQueryHookResult = ReturnType<typeof useGetCallDetailQuery>;
export type GetCallDetailLazyQueryHookResult = ReturnType<typeof useGetCallDetailLazyQuery>;
export type GetCallDetailQueryResult = Apollo.QueryResult<GetCallDetailQuery, GetCallDetailQueryVariables>;
export const RemoveFromCallDocument = gql`
    mutation removeFromCall($call_sid: String!, $participant_sid: String!) {
  removeFromCall(call_sid: $call_sid, participant_sid: $participant_sid)
}
    `;
export type RemoveFromCallMutationFn = Apollo.MutationFunction<RemoveFromCallMutation, RemoveFromCallMutationVariables>;

/**
 * __useRemoveFromCallMutation__
 *
 * To run a mutation, you first call `useRemoveFromCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveFromCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeFromCallMutation, { data, loading, error }] = useRemoveFromCallMutation({
 *   variables: {
 *      call_sid: // value for 'call_sid'
 *      participant_sid: // value for 'participant_sid'
 *   },
 * });
 */
export function useRemoveFromCallMutation(baseOptions?: Apollo.MutationHookOptions<RemoveFromCallMutation, RemoveFromCallMutationVariables>) {
        return Apollo.useMutation<RemoveFromCallMutation, RemoveFromCallMutationVariables>(RemoveFromCallDocument, baseOptions);
      }
export type RemoveFromCallMutationHookResult = ReturnType<typeof useRemoveFromCallMutation>;
export type RemoveFromCallMutationResult = Apollo.MutationResult<RemoveFromCallMutation>;
export type RemoveFromCallMutationOptions = Apollo.BaseMutationOptions<RemoveFromCallMutation, RemoveFromCallMutationVariables>;
export const ColdTransferCallDocument = gql`
    mutation coldTransferCall($call_sid: String!, $to: String!, $type: TransferToType!, $dir: DirectionType!) {
  coldTransferCall(call_sid: $call_sid, to: $to, type: $type, dir: $dir)
}
    `;
export type ColdTransferCallMutationFn = Apollo.MutationFunction<ColdTransferCallMutation, ColdTransferCallMutationVariables>;

/**
 * __useColdTransferCallMutation__
 *
 * To run a mutation, you first call `useColdTransferCallMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useColdTransferCallMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coldTransferCallMutation, { data, loading, error }] = useColdTransferCallMutation({
 *   variables: {
 *      call_sid: // value for 'call_sid'
 *      to: // value for 'to'
 *      type: // value for 'type'
 *      dir: // value for 'dir'
 *   },
 * });
 */
export function useColdTransferCallMutation(baseOptions?: Apollo.MutationHookOptions<ColdTransferCallMutation, ColdTransferCallMutationVariables>) {
        return Apollo.useMutation<ColdTransferCallMutation, ColdTransferCallMutationVariables>(ColdTransferCallDocument, baseOptions);
      }
export type ColdTransferCallMutationHookResult = ReturnType<typeof useColdTransferCallMutation>;
export type ColdTransferCallMutationResult = Apollo.MutationResult<ColdTransferCallMutation>;
export type ColdTransferCallMutationOptions = Apollo.BaseMutationOptions<ColdTransferCallMutation, ColdTransferCallMutationVariables>;
export const GetContactDocument = gql`
    query getContact($first: Int!, $page: Int) {
  getContact(first: $first, page: $page) {
    data {
      id
      first_name
      last_name
      company_name
      numbers {
        id
        ext_no
        raw_number
        formatted_number
        blockNumber
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}
    `;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, baseOptions);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const ContactDocument = gql`
    mutation contact($input: createContact!) {
  contact(input: $input) {
    id
    first_name
    last_name
    company_name
    numbers {
      id
      ext_no
      raw_number
      formatted_number
    }
  }
}
    `;
export type ContactMutationFn = Apollo.MutationFunction<ContactMutation, ContactMutationVariables>;

/**
 * __useContactMutation__
 *
 * To run a mutation, you first call `useContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactMutation, { data, loading, error }] = useContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactMutation(baseOptions?: Apollo.MutationHookOptions<ContactMutation, ContactMutationVariables>) {
        return Apollo.useMutation<ContactMutation, ContactMutationVariables>(ContactDocument, baseOptions);
      }
export type ContactMutationHookResult = ReturnType<typeof useContactMutation>;
export type ContactMutationResult = Apollo.MutationResult<ContactMutation>;
export type ContactMutationOptions = Apollo.BaseMutationOptions<ContactMutation, ContactMutationVariables>;
export const DeleteContactDocument = gql`
    mutation deleteContact($id: ID!) {
  deleteContact(id: $id) {
    id
    first_name
    last_name
  }
}
    `;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>) {
        return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, baseOptions);
      }
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<DeleteContactMutation, DeleteContactMutationVariables>;
export const DeleteContactNumberDocument = gql`
    mutation deleteContactNumber($id: ID!) {
  deleteContactNumber(id: $id) {
    id
    ext_no
    raw_number
    formatted_number
  }
}
    `;
export type DeleteContactNumberMutationFn = Apollo.MutationFunction<DeleteContactNumberMutation, DeleteContactNumberMutationVariables>;

/**
 * __useDeleteContactNumberMutation__
 *
 * To run a mutation, you first call `useDeleteContactNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactNumberMutation, { data, loading, error }] = useDeleteContactNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteContactNumberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteContactNumberMutation, DeleteContactNumberMutationVariables>) {
        return Apollo.useMutation<DeleteContactNumberMutation, DeleteContactNumberMutationVariables>(DeleteContactNumberDocument, baseOptions);
      }
export type DeleteContactNumberMutationHookResult = ReturnType<typeof useDeleteContactNumberMutation>;
export type DeleteContactNumberMutationResult = Apollo.MutationResult<DeleteContactNumberMutation>;
export type DeleteContactNumberMutationOptions = Apollo.BaseMutationOptions<DeleteContactNumberMutation, DeleteContactNumberMutationVariables>;
export const GetVmDocument = gql`
    query getVM($first: Int!, $page: Int) {
  getVM(first: $first, page: $page) {
    data {
      id
      from
      to
      timestamp
      caller {
        id
        first_name
        last_name
      }
      called {
        id
        first_name
        last_name
      }
      contactNumberFrom {
        id
        contact {
          id
          numbers {
            phone_number
          }
          first_name
          last_name
        }
      }
      contactNumberTo {
        id
        contact {
          id
          numbers {
            phone_number
          }
          first_name
          last_name
        }
      }
      leadNumberTo {
        lead {
          first_name
          last_name
        }
      }
      leadNumberFrom {
        lead {
          first_name
          last_name
        }
      }
      voicemail {
        id
        sid
        source
        url
        duration
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}
    `;

/**
 * __useGetVmQuery__
 *
 * To run a query within a React component, call `useGetVmQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVmQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetVmQuery(baseOptions: Apollo.QueryHookOptions<GetVmQuery, GetVmQueryVariables>) {
        return Apollo.useQuery<GetVmQuery, GetVmQueryVariables>(GetVmDocument, baseOptions);
      }
export function useGetVmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVmQuery, GetVmQueryVariables>) {
          return Apollo.useLazyQuery<GetVmQuery, GetVmQueryVariables>(GetVmDocument, baseOptions);
        }
export type GetVmQueryHookResult = ReturnType<typeof useGetVmQuery>;
export type GetVmLazyQueryHookResult = ReturnType<typeof useGetVmLazyQuery>;
export type GetVmQueryResult = Apollo.QueryResult<GetVmQuery, GetVmQueryVariables>;
export const GetBlockNumbersDocument = gql`
    query getBlockNumbers($admin_id: ID!) {
  getBlockNumbers(admin_id: $admin_id) {
    id
    admin_id
    number
    type
  }
}
    `;

/**
 * __useGetBlockNumbersQuery__
 *
 * To run a query within a React component, call `useGetBlockNumbersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlockNumbersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlockNumbersQuery({
 *   variables: {
 *      admin_id: // value for 'admin_id'
 *   },
 * });
 */
export function useGetBlockNumbersQuery(baseOptions: Apollo.QueryHookOptions<GetBlockNumbersQuery, GetBlockNumbersQueryVariables>) {
        return Apollo.useQuery<GetBlockNumbersQuery, GetBlockNumbersQueryVariables>(GetBlockNumbersDocument, baseOptions);
      }
export function useGetBlockNumbersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBlockNumbersQuery, GetBlockNumbersQueryVariables>) {
          return Apollo.useLazyQuery<GetBlockNumbersQuery, GetBlockNumbersQueryVariables>(GetBlockNumbersDocument, baseOptions);
        }
export type GetBlockNumbersQueryHookResult = ReturnType<typeof useGetBlockNumbersQuery>;
export type GetBlockNumbersLazyQueryHookResult = ReturnType<typeof useGetBlockNumbersLazyQuery>;
export type GetBlockNumbersQueryResult = Apollo.QueryResult<GetBlockNumbersQuery, GetBlockNumbersQueryVariables>;
export const BlockNumberV2Document = gql`
    mutation blockNumberV2($input: blockNumberInput) {
  blockNumberV2(input: $input) {
    id
    admin_id
    number
    type
  }
}
    `;
export type BlockNumberV2MutationFn = Apollo.MutationFunction<BlockNumberV2Mutation, BlockNumberV2MutationVariables>;

/**
 * __useBlockNumberV2Mutation__
 *
 * To run a mutation, you first call `useBlockNumberV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockNumberV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockNumberV2Mutation, { data, loading, error }] = useBlockNumberV2Mutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBlockNumberV2Mutation(baseOptions?: Apollo.MutationHookOptions<BlockNumberV2Mutation, BlockNumberV2MutationVariables>) {
        return Apollo.useMutation<BlockNumberV2Mutation, BlockNumberV2MutationVariables>(BlockNumberV2Document, baseOptions);
      }
export type BlockNumberV2MutationHookResult = ReturnType<typeof useBlockNumberV2Mutation>;
export type BlockNumberV2MutationResult = Apollo.MutationResult<BlockNumberV2Mutation>;
export type BlockNumberV2MutationOptions = Apollo.BaseMutationOptions<BlockNumberV2Mutation, BlockNumberV2MutationVariables>;
export const DeleteBlockNumberDocument = gql`
    mutation deleteBlockNumber($id: ID!) {
  deleteBlockNumber(id: $id) {
    id
    admin_id
    number
    type
  }
}
    `;
export type DeleteBlockNumberMutationFn = Apollo.MutationFunction<DeleteBlockNumberMutation, DeleteBlockNumberMutationVariables>;

/**
 * __useDeleteBlockNumberMutation__
 *
 * To run a mutation, you first call `useDeleteBlockNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBlockNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBlockNumberMutation, { data, loading, error }] = useDeleteBlockNumberMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBlockNumberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBlockNumberMutation, DeleteBlockNumberMutationVariables>) {
        return Apollo.useMutation<DeleteBlockNumberMutation, DeleteBlockNumberMutationVariables>(DeleteBlockNumberDocument, baseOptions);
      }
export type DeleteBlockNumberMutationHookResult = ReturnType<typeof useDeleteBlockNumberMutation>;
export type DeleteBlockNumberMutationResult = Apollo.MutationResult<DeleteBlockNumberMutation>;
export type DeleteBlockNumberMutationOptions = Apollo.BaseMutationOptions<DeleteBlockNumberMutation, DeleteBlockNumberMutationVariables>;
export const GetMiscallsDocument = gql`
    query getMiscalls($first: Int!, $page: Int) {
  getMiscalls(first: $first, page: $page) {
    data {
      id
      status
      from
      to
      is_forwarded
      is_transferred
      timestamp
      duration
      caller_type
      called_type
      blockNumberTo
      blockNumberFrom
      caller {
        id
        first_name
        last_name
      }
      called {
        id
        first_name
        last_name
      }
      contactNumberFrom {
        id
        contact {
          id
          numbers {
            phone_number
          }
          first_name
          last_name
        }
      }
      contactNumberTo {
        id
        contact {
          id
          numbers {
            phone_number
          }
          first_name
          last_name
        }
      }
      leadNumberTo {
        lead {
          first_name
          last_name
        }
      }
      leadNumberFrom {
        lead {
          first_name
          last_name
        }
      }
    }
    paginatorInfo {
      currentPage
      hasMorePages
    }
  }
}
    `;

/**
 * __useGetMiscallsQuery__
 *
 * To run a query within a React component, call `useGetMiscallsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMiscallsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMiscallsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetMiscallsQuery(baseOptions: Apollo.QueryHookOptions<GetMiscallsQuery, GetMiscallsQueryVariables>) {
        return Apollo.useQuery<GetMiscallsQuery, GetMiscallsQueryVariables>(GetMiscallsDocument, baseOptions);
      }
export function useGetMiscallsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMiscallsQuery, GetMiscallsQueryVariables>) {
          return Apollo.useLazyQuery<GetMiscallsQuery, GetMiscallsQueryVariables>(GetMiscallsDocument, baseOptions);
        }
export type GetMiscallsQueryHookResult = ReturnType<typeof useGetMiscallsQuery>;
export type GetMiscallsLazyQueryHookResult = ReturnType<typeof useGetMiscallsLazyQuery>;
export type GetMiscallsQueryResult = Apollo.QueryResult<GetMiscallsQuery, GetMiscallsQueryVariables>;
export const GetLoginUserCompaniesDocument = gql`
    query getLoginUserCompanies {
  getLoginUserCompanies {
    id
    company_name
  }
}
    `;

/**
 * __useGetLoginUserCompaniesQuery__
 *
 * To run a query within a React component, call `useGetLoginUserCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoginUserCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoginUserCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLoginUserCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<GetLoginUserCompaniesQuery, GetLoginUserCompaniesQueryVariables>) {
        return Apollo.useQuery<GetLoginUserCompaniesQuery, GetLoginUserCompaniesQueryVariables>(GetLoginUserCompaniesDocument, baseOptions);
      }
export function useGetLoginUserCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoginUserCompaniesQuery, GetLoginUserCompaniesQueryVariables>) {
          return Apollo.useLazyQuery<GetLoginUserCompaniesQuery, GetLoginUserCompaniesQueryVariables>(GetLoginUserCompaniesDocument, baseOptions);
        }
export type GetLoginUserCompaniesQueryHookResult = ReturnType<typeof useGetLoginUserCompaniesQuery>;
export type GetLoginUserCompaniesLazyQueryHookResult = ReturnType<typeof useGetLoginUserCompaniesLazyQuery>;
export type GetLoginUserCompaniesQueryResult = Apollo.QueryResult<GetLoginUserCompaniesQuery, GetLoginUserCompaniesQueryVariables>;
export const ConversationsDocument = gql`
    query conversations($first: Int!, $page: Int) {
  conversations(first: $first, page: $page) {
    data {
      id
      name
      admin_id
      is_group
      default_number
      members_details {
        member_type
        member {
          id
          first_name
          last_name
          avatar
        }
        meta {
          status
        }
      }
      binding {
        id
        bind_from
        bind_to
        is_valid
        member {
          id
          member_type
          member {
            id
            first_name
            last_name
          }
        }
      }
      conversation_has_members {
        conversation_id
        message {
          id
          body
          created_at
          event {
            action
            affected
          }
          sender {
            member {
              id
              first_name
              last_name
            }
          }
          read_by_me {
            msg_id
            member_id
            read_at
          }
        }
      }
      latest_message {
        id
        body
        created_at
        event {
          action
          affected
        }
        sender {
          member {
            id
            first_name
            last_name
          }
        }
        read_by_me {
          msg_id
          member_id
          read_at
        }
      }
    }
    paginatorInfo {
      count
      currentPage
      hasMorePages
      total
    }
  }
}
    `;

/**
 * __useConversationsQuery__
 *
 * To run a query within a React component, call `useConversationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useConversationsQuery(baseOptions: Apollo.QueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
        return Apollo.useQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, baseOptions);
      }
export function useConversationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationsQuery, ConversationsQueryVariables>) {
          return Apollo.useLazyQuery<ConversationsQuery, ConversationsQueryVariables>(ConversationsDocument, baseOptions);
        }
export type ConversationsQueryHookResult = ReturnType<typeof useConversationsQuery>;
export type ConversationsLazyQueryHookResult = ReturnType<typeof useConversationsLazyQuery>;
export type ConversationsQueryResult = Apollo.QueryResult<ConversationsQuery, ConversationsQueryVariables>;
export const ConversationDocument = gql`
    query conversation($id: ID!) {
  conversation(id: $id) {
    id
    name
    admin_id
    is_group
    default_number
    members_details {
      id
      member_type
      member {
        id
        first_name
        last_name
        avatar
      }
      meta {
        status
      }
    }
    binding {
      id
      bind_from
      bind_to
      is_valid
      member {
        id
        member_type
        member {
          id
          first_name
          last_name
        }
      }
    }
    conversation_has_members {
      conversation_id
      message {
        id
        body
        created_at
        event {
          action
          affected
        }
        sender {
          member {
            id
            first_name
            last_name
          }
        }
        read_by_me {
          msg_id
          member_id
          read_at
        }
      }
    }
    latest_message {
      id
      body
      created_at
      event {
        action
        affected
      }
      sender {
        member {
          id
          first_name
          last_name
        }
      }
      read_by_me {
        msg_id
        member_id
        read_at
      }
    }
  }
}
    `;

/**
 * __useConversationQuery__
 *
 * To run a query within a React component, call `useConversationQuery` and pass it any options that fit your needs.
 * When your component renders, `useConversationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConversationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConversationQuery(baseOptions: Apollo.QueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
        return Apollo.useQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, baseOptions);
      }
export function useConversationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConversationQuery, ConversationQueryVariables>) {
          return Apollo.useLazyQuery<ConversationQuery, ConversationQueryVariables>(ConversationDocument, baseOptions);
        }
export type ConversationQueryHookResult = ReturnType<typeof useConversationQuery>;
export type ConversationLazyQueryHookResult = ReturnType<typeof useConversationLazyQuery>;
export type ConversationQueryResult = Apollo.QueryResult<ConversationQuery, ConversationQueryVariables>;
export const MessagesDocument = gql`
    query messages($conversationId: ID!, $first: Int!, $page: Int) {
  messages(conversationId: $conversationId, first: $first, page: $page) {
    data {
      id
      body
      type
      created_at
      event {
        action
        affected
      }
      smsMessages {
        id
        from
        to
        status
      }
      sender {
        member_type
        member {
          id
          first_name
          last_name
        }
      }
    }
    paginatorInfo {
      total
      currentPage
      hasMorePages
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      conversationId: // value for 'conversationId'
 *      first: // value for 'first'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, baseOptions);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, baseOptions);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const SendMsgDocument = gql`
    mutation sendMsg($input: SendMsgInput) {
  sendMsg(input: $input) {
    id
    body
    sender {
      member {
        first_name
        last_name
      }
    }
    created_at
  }
}
    `;
export type SendMsgMutationFn = Apollo.MutationFunction<SendMsgMutation, SendMsgMutationVariables>;

/**
 * __useSendMsgMutation__
 *
 * To run a mutation, you first call `useSendMsgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMsgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMsgMutation, { data, loading, error }] = useSendMsgMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMsgMutation(baseOptions?: Apollo.MutationHookOptions<SendMsgMutation, SendMsgMutationVariables>) {
        return Apollo.useMutation<SendMsgMutation, SendMsgMutationVariables>(SendMsgDocument, baseOptions);
      }
export type SendMsgMutationHookResult = ReturnType<typeof useSendMsgMutation>;
export type SendMsgMutationResult = Apollo.MutationResult<SendMsgMutation>;
export type SendMsgMutationOptions = Apollo.BaseMutationOptions<SendMsgMutation, SendMsgMutationVariables>;
export const UpdateConversationDocument = gql`
    mutation updateConversation($input: UpdateConversationInput) {
  updateConversation(input: $input) {
    id
    name
    is_group
    default_number
    members_details {
      id
      member_type
      member {
        id
        first_name
        last_name
        avatar
      }
      meta {
        is_admin
        status
      }
    }
    latest_message {
      id
      body
      created_at
      event {
        action
        affected
      }
      read_by_me {
        msg_id
        member_id
        read_at
      }
    }
  }
}
    `;
export type UpdateConversationMutationFn = Apollo.MutationFunction<UpdateConversationMutation, UpdateConversationMutationVariables>;

/**
 * __useUpdateConversationMutation__
 *
 * To run a mutation, you first call `useUpdateConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateConversationMutation, { data, loading, error }] = useUpdateConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateConversationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateConversationMutation, UpdateConversationMutationVariables>) {
        return Apollo.useMutation<UpdateConversationMutation, UpdateConversationMutationVariables>(UpdateConversationDocument, baseOptions);
      }
export type UpdateConversationMutationHookResult = ReturnType<typeof useUpdateConversationMutation>;
export type UpdateConversationMutationResult = Apollo.MutationResult<UpdateConversationMutation>;
export type UpdateConversationMutationOptions = Apollo.BaseMutationOptions<UpdateConversationMutation, UpdateConversationMutationVariables>;
export const StartConversationDocument = gql`
    mutation startConversation($input: StartConversationInput) {
  startConversation(input: $input) {
    id
    name
    is_group
    default_number
    members_details {
      id
      member_type
      member {
        id
        first_name
        last_name
      }
      meta {
        is_admin
        status
      }
    }
    latest_message {
      id
      body
      created_at
      event {
        action
        affected
      }
    }
    binding {
      id
      bind_from
      bind_to
      is_valid
      member {
        id
        member_type
        member {
          id
          first_name
          last_name
        }
      }
    }
  }
}
    `;
export type StartConversationMutationFn = Apollo.MutationFunction<StartConversationMutation, StartConversationMutationVariables>;

/**
 * __useStartConversationMutation__
 *
 * To run a mutation, you first call `useStartConversationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartConversationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startConversationMutation, { data, loading, error }] = useStartConversationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartConversationMutation(baseOptions?: Apollo.MutationHookOptions<StartConversationMutation, StartConversationMutationVariables>) {
        return Apollo.useMutation<StartConversationMutation, StartConversationMutationVariables>(StartConversationDocument, baseOptions);
      }
export type StartConversationMutationHookResult = ReturnType<typeof useStartConversationMutation>;
export type StartConversationMutationResult = Apollo.MutationResult<StartConversationMutation>;
export type StartConversationMutationOptions = Apollo.BaseMutationOptions<StartConversationMutation, StartConversationMutationVariables>;
export const MessageStatusInfoDocument = gql`
    query messageStatusInfo($input: messageStatusInfo) {
  messageStatusInfo(input: $input) {
    id
    from
    to
    status
  }
}
    `;

/**
 * __useMessageStatusInfoQuery__
 *
 * To run a query within a React component, call `useMessageStatusInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageStatusInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageStatusInfoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMessageStatusInfoQuery(baseOptions?: Apollo.QueryHookOptions<MessageStatusInfoQuery, MessageStatusInfoQueryVariables>) {
        return Apollo.useQuery<MessageStatusInfoQuery, MessageStatusInfoQueryVariables>(MessageStatusInfoDocument, baseOptions);
      }
export function useMessageStatusInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessageStatusInfoQuery, MessageStatusInfoQueryVariables>) {
          return Apollo.useLazyQuery<MessageStatusInfoQuery, MessageStatusInfoQueryVariables>(MessageStatusInfoDocument, baseOptions);
        }
export type MessageStatusInfoQueryHookResult = ReturnType<typeof useMessageStatusInfoQuery>;
export type MessageStatusInfoLazyQueryHookResult = ReturnType<typeof useMessageStatusInfoLazyQuery>;
export type MessageStatusInfoQueryResult = Apollo.QueryResult<MessageStatusInfoQuery, MessageStatusInfoQueryVariables>;
export const GetTextCapabilityTokenDocument = gql`
    query getTextCapabilityToken($platform: CallDeviceType) {
  getTextCapabilityToken(platform: $platform) {
    token
    expire
  }
}
    `;

/**
 * __useGetTextCapabilityTokenQuery__
 *
 * To run a query within a React component, call `useGetTextCapabilityTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTextCapabilityTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTextCapabilityTokenQuery({
 *   variables: {
 *      platform: // value for 'platform'
 *   },
 * });
 */
export function useGetTextCapabilityTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetTextCapabilityTokenQuery, GetTextCapabilityTokenQueryVariables>) {
        return Apollo.useQuery<GetTextCapabilityTokenQuery, GetTextCapabilityTokenQueryVariables>(GetTextCapabilityTokenDocument, baseOptions);
      }
export function useGetTextCapabilityTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTextCapabilityTokenQuery, GetTextCapabilityTokenQueryVariables>) {
          return Apollo.useLazyQuery<GetTextCapabilityTokenQuery, GetTextCapabilityTokenQueryVariables>(GetTextCapabilityTokenDocument, baseOptions);
        }
export type GetTextCapabilityTokenQueryHookResult = ReturnType<typeof useGetTextCapabilityTokenQuery>;
export type GetTextCapabilityTokenLazyQueryHookResult = ReturnType<typeof useGetTextCapabilityTokenLazyQuery>;
export type GetTextCapabilityTokenQueryResult = Apollo.QueryResult<GetTextCapabilityTokenQuery, GetTextCapabilityTokenQueryVariables>;