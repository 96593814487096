import React, { useState } from "react";
import { List, Empty } from "antd";
import { useHistory, Link } from "react-router-dom";
import { useGlobalContext } from "../../context/GlobalContext";
import { KConnectionManager } from "../../utils/KConnectionManager";
import { numberFormat } from "../../utils/utils";
import { useHoldCallMutation } from "../../generated/graphql";

const Calls = () => {
  const { activeNumber, status } = useGlobalContext();
  const K = KConnectionManager.getInstance();
  const device = K.getDevice(activeNumber);
  const activeConn = device && device.activeConnection();
  const history = useHistory();
  const [hold, setHold] = useState<boolean | null | undefined>(false);
  const [holdCall] = useHoldCallMutation();

  const hangUp = () => {
    if (device) {
      device.disconnectAll();
      history.push("/");
      const eventInfo = {
        type: "close"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  const handleHold = () => {
    if (activeConn) {
      holdCall({
        variables: {
          call_sid: activeConn.parameters.CallSid,
          hold: true
        }
      }).then(({ data }) => {
        if (data) {
          setHold(!data.holdCall);
        }
      });
    }
  };

  const handleCallAccept = () => {
    if (activeConn) {
      activeConn.accept();
      history.push(`/call/${activeConn.parameters.From}`);
      const eventInfo = {
        type: "accept"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  const handleCallReject = () => {
    if (activeConn) {
      activeConn.reject();
      const eventInfo = {
        type: "reject"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  const toCallScreen = () => {
    if (activeConn) {
      if (activeConn.direction === "OUTGOING") {
        const toNum = activeConn.customParameters.get("To");
        history.push(`/call/${toNum}`);
      } else if (activeConn.direction === "INCOMING" && status === "On call") {
        history.push(`/call/${activeConn.parameters.From}`);
      }
    }
  };

  return (
    <div>
      {/* {console.log("conn", conn)} */}
      {/* {console.log("activeConn", activeConn)} */}
      {activeConn ? (
        <List.Item
          key={1}
          actions={[
            <div>
              {activeConn.direction === "OUTGOING" && (
                <div>
                  <Link to={`/#`}>
                    <i
                      className="icon-call-hold"
                      style={{ color: hold ? "#FFB900" : "#000", fontSize: 16 }}
                      onClick={handleHold}
                    />
                  </Link>

                  <Link to={`/#`}>
                    <i
                      className="icon-call-end"
                      style={{ color: "red", fontSize: 16, marginLeft: "4px" }}
                      onClick={hangUp}
                    />
                  </Link>
                </div>
              )}
              {activeConn.direction === "INCOMING" && (
                <div>
                  {status === "Ready" || status === "Incoming" ? (
                    <Link to={`/#`}>
                      <i
                        className="icon-call-end"
                        style={{ color: "red", fontSize: 16 }}
                        onClick={handleCallReject}
                      />
                    </Link>
                  ) : null}

                  {status === "On call" && (
                    <Link to={`/#`}>
                      <i
                        className="icon-call-end"
                        style={{ color: "red", fontSize: 16 }}
                        onClick={hangUp}
                      />
                    </Link>
                  )}

                  {status === "On call" && (
                    <Link to={`/#`}>
                      <i
                        className="icon-call-hold"
                        style={{
                          color: hold ? "#FFB900" : "#000",
                          fontSize: 16
                        }}
                        onClick={handleHold}
                      />
                    </Link>
                  )}
                  {status === "Ready" || status === "Incoming" ? (
                    <Link to={`/#`}>
                      <i
                        className="icon-call"
                        style={{ color: "#4AC367", fontSize: 16 }}
                        onClick={handleCallAccept}
                      />
                    </Link>
                  ) : null}
                </div>
              )}
            </div>
          ]}
        >
          <List.Item.Meta
            title={
              <div style={{ cursor: "pointer" }}>
                {activeConn.direction === "INCOMING" && (
                  <div onClick={toCallScreen}>{activeConn.parameters.From}</div>
                )}
                {activeConn.direction === "OUTGOING" && (
                  <div onClick={toCallScreen}>
                    {numberFormat(activeConn.customParameters.get("To") || "")}
                  </div>
                )}
              </div>
            }
          />
        </List.Item>
      ) : (
        <Empty description="No calls" />
      )}
    </div>
  );
};

export default Calls;
