import React, { useRef, useState, RefObject } from "react";
import { Drawer, Card, Input, Row, Col, Button, Typography } from "antd";
import { useGlobalContext } from "../../context/GlobalContext";

const { Title } = Typography;

const audio = new Audio(
    "https://d366s8lxuwna4d.cloudfront.net/beep-34dd1e44313219e42f2bfad1a91b81d05909599e.mp3"
  );

interface numberPadProps {
  visible: boolean;
  hideNumberPad: () => void;
}

interface NumpadProps {
  link: RefObject<any>;
}

const numpadKeys = ['0','1','2','3','4','5','6','7','8','9','*','#'];

const NumberPad: React.FC<numberPadProps> = ({ visible, hideNumberPad }) => {
  const ref = useRef<HTMLInputElement>(null);
  
  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      placement={"bottom"}
      closable={false}
      onClose={() => hideNumberPad()}
      visible={visible}
      height={400}
      afterVisibleChange={() => {
        if (visible && ref && ref.current) {
          ref.current.focus();
        }
      }}
      headerStyle={{ padding: "0 8px", textAlign: "right" }}
      title={
        <i
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={hideNumberPad}
          className="icon-cancel"
        />
      }
    >
      <Numpad link={ref} />
    </Drawer>
  );
};

const Numpad: React.FC<NumpadProps> = ({ link }) => {
  const [number, setNumber] = useState<string>("");
  const { conn } = useGlobalContext();
    const clickBtn = (n: string) => {
      audio.play();
      setNumber(s => s + n);
      conn?.sendDigits(n);
    };

  const handleSendDigit = (n: string) => {
    if(numpadKeys.includes(n)){
      audio.play();
      conn?.sendDigits(n);
    }
  }

  let c = 1;
  let a = "*";
  let b = 0;
  let d = "#";
  const h = 50;

  return (
    <Card bordered={false} bodyStyle={{ padding: 15 }}>
      <Input
        className="dailer"
        bordered={false}
        width="100%"
        value={number}
        onChange={v => {
          setNumber(v.target.value);
        }}
        onKeyPress={e => handleSendDigit(e.key)} 
        autoFocus        
        ref={link}
        style={{ textAlign: 'center', fontSize: '22px', fontWeight: 'bold' }}
      />
      <br />
      <br />
      <div>
        {new Array(3).fill(new Array(3).fill(0)).map(a => (
          <Row key={Math.random()} justify="center">
            {a.map(() => {
              let n = c++;
              return (
                <Col span={8} key={Math.random()}>
                  <Button
                    style={{ height: h }}
                    block
                    onClick={() => {
                      clickBtn(n.toString());
                      link.current.focus();
                    }}
                    size="large"
                  >
                    <Title level={3}>{n}</Title>
                  </Button>
                </Col>
              );
            })}
          </Row>
        ))}

        <Row justify="center">
          <Col span={8}>
            <Button
              block
              onClick={() => {
                clickBtn(a.toString());
                link.current.focus();
              }}
              size="large"
              style={{ height: h }}
            >
              <Title level={3}>*</Title>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block
              onClick={() => {
                clickBtn(b.toString());
                link.current.focus();
              }}
              size="large"
              style={{ height: h }}
            >
              <Title level={3}>0</Title>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block
              onClick={() => {
                clickBtn(d.toString());
                link.current.focus();
              }}
              size="large"
              style={{ height: h }}
            >
              <Title level={3}>#</Title>
            </Button>
          </Col>
        </Row>
      </div>      
    </Card>
  );
};

export default NumberPad;
