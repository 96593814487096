import { Modal, Button, Tooltip } from "antd";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";
import { useLeadDetail } from "../hooks/useLeadDetail";
import { useStickyState } from "../hooks/useStickyState";
import { LeadDetail } from "./LeadDetail";
import { DeviceStatus } from "../enum/Index";
import {
  useColdTransferCallMutation,
  TransferToType,
  DirectionType
} from "../generated/graphql";

interface IncommingProps {
  visible: boolean;
  showCallTransfer: () => void;
  hideCallTransfer: () => void;
}

export const Incomming: React.FC<IncommingProps> = ({
  visible = false,
  showCallTransfer,
  hideCallTransfer
}) => {
  const { conn, status, setStatus } = useGlobalContext();
  const history = useHistory();
  const [coldTransfer] = useColdTransferCallMutation();
  // eslint-disable-next-line
  const [_, setRecent] = useStickyState([], "C_RECENT");
  const { loading, data } = useLeadDetail(conn?.parameters.From);

  const name = `${data?.getLeadFromNumber?.first_name || "HQ"} 
  ${data?.getLeadFromNumber?.last_name || "User"}`;

  // function addInboundEntry() {
  //   if (conn?.parameters.From && name) {
  //     setRecent((r: any) => [
  //       {
  //         name: name === "HQ User" ? "" : name,
  //         n: conn?.parameters.From,
  //         date: new Date(),
  //         direction: "miss",
  //       },
  //       ...r,
  //     ]);
  //   }
  // }

  useEffect(() => {
    // console.log(status, "YYY");
    return () => {
      // console.log(status, "STTT");
    };
  }, [conn, status]);

  const acceptConnection = () => {
    if (conn) {
      conn.accept();
      // addInboundEntry();
      history.push(`/call/${conn.parameters.From}`);
      const eventInfo = {
        type: "accept"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  const rejectConnection = () => {
    if (conn) {
      conn.reject();
      // addInboundEntry();
      const eventInfo = {
        type: "reject"
      };
      window.parent.postMessage(JSON.stringify(eventInfo), "*");
    }
  };

  const handleVoiceMessage = () => {
    if (conn) {
      coldTransfer({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: "0",
          type: TransferToType.Vm,
          dir: DirectionType.Inbound
        }
      });
    }
  };

  const btnStyle = {
    borderRadius: "50px",
    padding: "8px"
  };

  const acceptBtnStyle = {
    backgroundColor: "#52c41a",
    borderColor: "#52c41a",
    borderRadius: "50px",
    padding: "8px",
    color: "#fff"
  };

  const transferBtnStyle = {
    backgroundColor: "#0055FD",
    borderColor: "#0055FD",
    color: "#fff"
  };

  const VMBtnStyle = {
    backgroundColor: "#011689",
    borderColor: "#011689",
    color: "#fff"
  };

  return (
    <Modal
      title=""
      visible={visible}
      // closable={false}
      onCancel={() => {
        setStatus(DeviceStatus.READY);
        hideCallTransfer();
      }}
      afterClose={() => {
        window.dispatchEvent(
          new CustomEvent("updateCallLogs", { detail: { fetchCallLogs: true } })
        );
      }}
      cancelButtonProps={{
        danger: true,
        type: "primary",
        size: "large",
        style: btnStyle
      }}      
      okButtonProps={{ size: "large", style: acceptBtnStyle }}
      centered
      footer={
        <div style={{ display: "flex", justifyContent: "center" }}>
        <Tooltip title="Reject">
          <Button
            danger
            type="primary"
            size="large"
            style={btnStyle}
            onClick={rejectConnection}
          >
            <i className="icon-missed-call" />
          </Button>
          </Tooltip>
          <Tooltip title="Accept">
          <Button
            size="large"
            style={acceptBtnStyle}
            onClick={acceptConnection}
          >
            <i className="icon-call" />
          </Button>
          </Tooltip>
          <Tooltip title="Call transfer">
          <Button
            size="large"
            style={transferBtnStyle}
            shape="circle"
            onClick={showCallTransfer}
          >
            <i className="icon-transfer-lead" />
          </Button>
          </Tooltip>
          <Tooltip title="Voice mail">
          <Button
            size="large"
            style={VMBtnStyle}
            shape="circle"
            onClick={handleVoiceMessage}
          >
            VM
          </Button>
          </Tooltip>
        </div>
      }
    >
      {conn && (
        <LeadDetail
          name={name}
          number={conn?.parameters.From}
          loading={loading}
        />
      )}
    </Modal>
  );
};
