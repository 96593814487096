import React, { useState } from "react";
import {
  List,
  Form,
  Input,
  Button,
  Avatar,
  Tabs,
  Spin,
  Empty,
  Typography
} from "antd";
import { useLazyQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import "./index.css";
import { SEARCH_LEAD } from "../../../query";
import {
  SearchMemberPerson,
  Conversation,
  useStartConversationMutation
} from "../../../generated/graphql";
import { formatPhoneNumber } from "../../../utils/utils";
import StartConversation from "../StartConversation";
import { useStickyState } from "../../../hooks/useStickyState";

const { TabPane } = Tabs;
const { Paragraph } = Typography;

interface SearchListProps {
  hideNewConversetion: () => void;
}

const SearchList: React.FC<SearchListProps> = ({ hideNewConversetion }) => {
  const history = useHistory();
  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [search, { data, loading }] = useLazyQuery(SEARCH_LEAD, {
    fetchPolicy: `no-cache`
  });

  const [startConversationView, setStartConversationView] = useState<boolean>(
    false
  );

  const [startConversation] = useStartConversationMutation();

  const showStartConversation = () => {
    setStartConversationView(true);
  };

  const hideStartConversation = () => {
    setStartConversationView(false);
  };

  const handleOpenChatView = (item: SearchMemberPerson) => {
    if (item.phone_no && item.typeClass) {
      const input = {
        name: item.full_name || "HQ User",
        is_group: false,
        default_number: "",
        members: [
          {
            member_id: item.id,
            member_type: item.typeClass,
            phone_no: item.phone_no
          }
        ]
      };

      startConversation({ variables: { input } }).then(({ data }) => {
        if (data && data.startConversation) {
          history.push(`/text/${data.startConversation.id}`);
        }
      });
    }
  };

  return startConversationView ? (
    <StartConversation hideStartConversation={hideStartConversation} />
  ) : (
    <div>
      <List.Item>
        <Form.Item name="search" className="noMargin" style={{ width: "100%" }}>
          <Input
            prefix={
              <i
                className="icon-search"
                style={{ fontSize: "20px", cursor: "auto" }}
              />
            }
            placeholder="Search contact to start conversetion"
            //   ref={searchInput}
            onChange={e => {
              if (e.target.value !== "") {
                search({
                  variables: {
                    query: e.target.value,
                    call: true
                  }
                });
              }
            }}
            className="searchText"
            suffix={
              <i
                className="icon-cancel"
                onClick={hideNewConversetion}
                style={{ fontSize: "14px", cursor: "pointer" }}
              />
            }
          />
        </Form.Item>
      </List.Item>
      <List.Item className="newGroupItem" onClick={showStartConversation}>
        <Button className="newGroup">
          <div className="newGrpIcon">
            <i className="icon-lead-added" />
          </div>
          <span style={{ fontSize: "16px" }}>New Group</span>
        </Button>
      </List.Item>
      <div>
        <Tabs
          defaultActiveKey="1"
          // tabBarGutter={0}
          animated={false}

          // onTabClick={this.tabClick}
          // tabBarExtraContent={selectedTab === '1' && operations}
        >
          <TabPane tab="Leads" key="1">
            <div
              style={{ maxHeight: "calc(100vh - 292px)", overflowY: "auto" }}
            >
              {!loading &&
              data &&
              data.searchConversationV2 &&
              data.searchConversationV2.person.filter(
                (item: SearchMemberPerson) => item.typeClass === "LEAD"
              ).length === 0 ? (
                <Empty description="No leads found" />
              ) : null}
              {loading ? (
                <List.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Spin spinning />
                </List.Item>
              ) : null}

              {data &&
                data.searchConversationV2 &&
                data.searchConversationV2.person
                  .filter(
                    (item: SearchMemberPerson) => item.typeClass === "LEAD"
                  )
                  .map((item: SearchMemberPerson) => {
                    return (
                      <List.Item
                        className="pointer"
                        onClick={() =>
                          item.phone_no &&
                          item.typeClass &&
                          handleOpenChatView(item)
                        }
                        onMouseEnter={() => {
                          // setOpenChat(item);
                          // setHoverItem(true);
                          // setDefaultFirst(false);
                        }}
                        onMouseLeave={() => {
                          // setOpenChat(item);
                          // setHoverItem(false);
                        }}
                        // className={
                        //   searchValue !== "" && defaultFirst && i === 0
                        //     ? "searchFirstItem"
                        //     : ""
                        // }
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px"
                              }}
                            >
                              {item.full_name ? item.full_name.charAt(0) : "H"}
                            </Avatar>
                          }
                          title={
                            <span style={{ color: "#595959" }}>
                              {item.full_name !== null ? item.full_name : "HQ"}
                            </span>
                          }
                          description={
                            <span className="textBlue">
                              {item.phone_no &&
                                formatPhoneNumber(item.phone_no)}
                              {item.is_primary ? (
                                <i
                                  className="icon-verified"
                                  style={{ color: "#4ac367" }}
                                />
                              ) : null}
                            </span>
                          }
                        />
                      </List.Item>
                    );
                  })}
            </div>
          </TabPane>
          <TabPane tab="CRM Users" key="2">
            <div
              style={{ maxHeight: "calc(100vh - 292px)", overflowY: "auto" }}
            >
              {!loading &&
              data &&
              data.searchConversationV2 &&
              data.searchConversationV2.person.filter(
                (item: SearchMemberPerson) =>
                  item.typeClass === "AGENT" &&
                  item.id !== adminInfo.admininfo.id
              ).length === 0 ? (
                <Empty description="No CRM users found" />
              ) : null}
              {loading ? (
                <List.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Spin spinning />
                </List.Item>
              ) : null}
              {data &&
                data.searchConversationV2 &&
                data.searchConversationV2.person
                  .filter(
                    (item: SearchMemberPerson) =>
                      item.typeClass === "AGENT" &&
                      item.id !== adminInfo.admininfo.id
                  )
                  .map((item: SearchMemberPerson) => {
                    return (
                      <List.Item
                        className="pointer"
                        onClick={() => handleOpenChatView(item)}
                        onMouseEnter={() => {
                          // setOpenChat(item);
                          // setHoverItem(true);
                          // setDefaultFirst(false);
                        }}
                        onMouseLeave={() => {
                          // setOpenChat(item);
                          // setHoverItem(false);
                        }}
                      >
                        <List.Item.Meta
                          avatar={
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px"
                              }}
                            >
                              {item.full_name ? item.full_name.charAt(0) : "H"}
                            </Avatar>
                          }
                          title={
                            <span style={{ color: "#595959" }}>
                              {item.full_name !== null ? item.full_name : "HQ"}
                            </span>
                          }
                          description={
                            <span className="textBlue">
                              {item.phone_no &&
                                formatPhoneNumber(item.phone_no)}
                            </span>
                          }
                        />
                      </List.Item>
                    );
                  })}
            </div>
          </TabPane>
          <TabPane tab="Groups" key="3">
            <div
              style={{ maxHeight: "calc(100vh - 292px)", overflowY: "auto" }}
            >
              {!loading &&
              data &&
              data.searchConversationV2 &&
              data.searchConversationV2.person.length === 0 ? (
                <Empty description="No groups found" />
              ) : null}
              {loading &&
              data &&
              data.searchConversationV2 &&
              data.searchConversationV2.person.length === 0 ? (
                <List.Item
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Spin spinning />
                </List.Item>
              ) : null}

              {data &&
                data.searchConversationV2 &&
                data.searchConversationV2.group.map((item: Conversation) => {
                  return (
                    <List.Item
                      // className={
                      //   searchValue !== '' &&
                      //   leadList.length === 0 &&
                      //   i === 0 &&
                      //   defaultGroupFirst
                      //     ? `${styles.grpAvatar} ${styles.searchFirstItem}`
                      //     : styles.grpAvatar
                      // }
                      className="pointer"
                      onClick={() => {
                        history.push(`/text/${item.id}`);
                        // openChat(item.id, item);
                        // handleChatView();
                      }}
                      onMouseEnter={() => {
                        // setOpenChat(item);
                        // setHoverItem(true);
                        // setDefaultGroupFirst(false);
                      }}
                      onMouseLeave={() => {
                        // setOpenChat(item);
                        // setHoverItem(false);
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          // eslint-disable-next-line no-nested-ternary
                          item &&
                          item.members_details &&
                          item.members_details.length < 4 ? (
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px"
                              }}
                              className="iconCenter"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <div
                                  className="avatarHt"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                </div>
                                <div
                                  className="avatarHt"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                </div>
                              </div>
                            </Avatar>
                          ) : item &&
                            item.members_details &&
                            item.members_details.length > 4 ? (
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px"
                              }}
                              className="iconCenter"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <div
                                  className="avatarHt"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                </div>
                                <div
                                  className="avatarHt"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                  <div className="grpRemainCount">
                                    <Avatar
                                      style={{ fontSize: "28px" }}
                                      className="bgTransparent"
                                    >
                                      {item.members_details.length - 3}
                                    </Avatar>
                                  </div>
                                </div>
                              </div>
                            </Avatar>
                          ) : (
                            <Avatar
                              size="large"
                              style={{
                                fontSize: "24px"
                              }}
                              className="iconCenter"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column"
                                }}
                              >
                                <div
                                  className="avatarHt"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                </div>
                                <div
                                  className="avatarHt"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row"
                                  }}
                                >
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                  <Avatar
                                    size="large"
                                    icon={<i className="icon-add-user" />}
                                    className="bgTransparent"
                                  />
                                </div>
                              </div>
                            </Avatar>
                          )
                        }
                        title={
                          <div className="nameEllipsis">
                            <Paragraph
                              ellipsis
                              style={{ color: "#595959" }}
                              className="noMargin"
                            >
                              <span style={{ color: "#595959" }}>
                                {" "}
                                {item.name}
                              </span>
                            </Paragraph>
                          </div>
                        }
                        description={
                          <div className="memberEllipsis">
                            <Paragraph ellipsis className="noMargin">
                              {item.members_details &&
                                item.members_details.map((member, i) =>
                                  item.members_details &&
                                  i + 1 < item.members_details.length
                                    ? member &&
                                      member.member &&
                                      `${member.member &&
                                        member.member.first_name} ${
                                        member.member.last_name
                                      }, `
                                    : member &&
                                      member.member &&
                                      `${member.member.first_name} ${member.member.last_name}`
                                )}
                            </Paragraph>
                          </div>
                        }
                      />
                    </List.Item>
                  );
                })}
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default SearchList;
