import React, { FC, useEffect, useState } from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import { GlobalContextProvider } from "./context/GlobalContext";
import HomePage from "./pages/Home";
import { Call } from "./pages/Call";
import Login from "./pages/Login";
import { Result } from "antd";
import { Setup } from "./layouts/Setup";
import { useGetMyNumbersLazyQuery } from "./generated/graphql";
import Conversation from "./components/Text/Conversation";
import SetUserAuthority from "./components/SetUserAuthority";

interface MatchParams {
  number: string;
  id: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {}

const App: FC = () => {
  const [getNumbers, { data }] = useGetMyNumbersLazyQuery();
  const [auth] = useQueryParam("a", StringParam);
  const [mic, setMic] = useState<boolean | string>(false);
  const [numbers, setNumbers] = useState<string[]>([]);

  useEffect(() => {
    if (getNumbers) {
      getNumbers();
    }
  }, [getNumbers]);

  useEffect(() => {
    if (data) {
      const idNumbers = data.getMyNumbers.map((num: any) => num.id);
      const allNumbers = data.getMyNumbers.map((num: any) => num.phone_number);
      localStorage.setItem("twilio_numbers", JSON.stringify(allNumbers));
      setNumbers(idNumbers || []);
    }
  }, [data]);

  useEffect(() => {
    if (auth) {
      localStorage.setItem("authData", auth);
    }
  }, [auth]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {})
      .catch(error => {
        console.log({ error });
        setMic(error.code.toString());
      });
  }, [mic]);

  if (mic) {
    return (
      <Result
        status="warning"
        title={
          mic.toString() === "8"
            ? "Can't find your microphone"
            : "Your microphone is blocked"
        }
      >
        <h3>HQ Dailer needs access to your microphone.</h3>
        <br />
        {mic.toString() === "8" ? (
          <>
            <p>
              Check your system settings to make sure that a microphone is
              available. If not, plug one in. You might then need to restart
              your browser.
            </p>
          </>
        ) : (
          <ol>
            <li>Click the camera blocked icon in your browser's address bar</li>
            <li>Allow access and then refresh the page</li>
          </ol>
        )}
      </Result>
    );
  }

  return (
    <div>
      <GlobalContextProvider>
        {/* <Phone /> */}
        <SetUserAuthority />
        {numbers.map(numberId => (
          <Setup id={numberId} />
        ))}

        <Switch>
          <Route
            path="/call/:number"
            render={({ match }: MatchProps) => (
              <Call number={match.params.number} />
            )}
          />
          <Route
            path="/text/:id"
            render={({ match }: MatchProps) => (
              <Conversation id={match.params.id} />
            )}
          />
          <Route path="/login" component={Login} />
          <Route path="/" component={HomePage} />
        </Switch>
      </GlobalContextProvider>
    </div>
  );
};

export default App;
