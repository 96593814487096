import React from "react";
import { List, Skeleton } from "antd";
// import { MyAvatar } from "../MyAvatar";
// import { LEAD_LINK } from "../../utils/constant";
import { useHistory } from "react-router-dom";
import { numberFormat } from "../../utils/utils";
import { LEAD_LINK } from "../../utils/constant";
import { useGlobalContext } from "../../context/GlobalContext";
import { useAddToCallMutation } from "../../generated/graphql";

interface ListItemProps {
  loading: boolean;
  full_name?: string | null | undefined;
  phone_no?: string | null | undefined;
  avatar?: string | null | undefined;
  id: string;
  typeClass?: string | null | undefined;
  is_primary?: number | null;
}

const handleCallStatus = (num: string | undefined | null) => {
  if (num) {
    const eventJson = {
      type: "calling",
      number: num
    };
    window.parent.postMessage(JSON.stringify(eventJson), "*");
  }
};

export const ListItem: React.FC<ListItemProps> = ({
  id,
  loading,
  full_name,
  phone_no,
  typeClass,
  is_primary
}) => {
  const { conn } = useGlobalContext();
  const [addtoCall] = useAddToCallMutation();
  const history = useHistory();

  const handleDialCall = (n: string) => {
    if (conn) {
      addtoCall({
        variables: {
          call_sid: conn.parameters.CallSid,
          to: n
        }
      });
      history.push(`/call/${n}`);
    } else {
      history.push(`/call/${n}?out=true`);
      handleCallStatus(n);
    }
  };

  return (
    <List.Item
      key={id}
      actions={[
        // <Link to={`/#`}>
        //   <i
        //     className="icon-text-message"
        //     style={{ color: "#4ac367", fontSize: 16 }}
        //   />
        // </Link>,
        <div
          onClick={() => {
            if (phone_no) {
              handleDialCall(phone_no);
              handleCallStatus(phone_no);
            }
          }}
        >
          <i
            className="icon-call"
            style={
              phone_no
                ? { color: "#4ac367", fontSize: 16, cursor: "pointer" }
                : { color: "red", cursor: "not-allowed" }
            }
          />
        </div>
      ]}
    >
      <Skeleton avatar title={false} loading={loading} active>
        <List.Item.Meta
          // avatar={
          //   <MyAvatar name={full_name || "HQ User"} src={avatar} size={38} />
          // }
          title={
            <>
              <div
                style={typeClass === "LEAD" ? { cursor: "pointer" } : {}}
                onClick={() => {
                  if (typeClass === "LEAD") {
                    window.open(`${LEAD_LINK}${id}`);
                  }
                }}
              >
                {full_name}
              </div>
              <div
                onClick={() => {
                  if (phone_no) {
                    handleDialCall(phone_no);
                    handleCallStatus(phone_no);
                  }
                }}
                style={{ fontWeight: 400, cursor: "pointer" }}
              >
                {phone_no ? numberFormat(phone_no.replace(/\+1/g, ``)) : ""}
                {is_primary ? (
                  <i className="icon-verified" style={{ color: "#4ac367" }} />
                ) : null}
              </div>
            </>
          }
        />
      </Skeleton>
    </List.Item>
  );
};
