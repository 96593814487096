import React, { createContext, FC, useState, useMemo, useContext } from "react";
import { Connection, Device } from "twilio-client";
import { DeviceStatus, CallStatus } from "../enum/Index";

interface IGlobal {
  activeNumber: string;
  status: DeviceStatus;
  callStatus: CallStatus;
  device: null | Device;
  conn: null | Connection;
  setActiveNumber: React.Dispatch<React.SetStateAction<string>>;
  setStatus: React.Dispatch<React.SetStateAction<DeviceStatus>>;
  setCallStatus: React.Dispatch<React.SetStateAction<CallStatus>>;
  setDevice: React.Dispatch<React.SetStateAction<Device | null>>;
  setConn: React.Dispatch<React.SetStateAction<Connection | null>>;
  myNumbers: string[];
  setMyNumbers: React.Dispatch<React.SetStateAction<string[]>>;
  syncClient: any;
  setSyncClient: React.Dispatch<React.SetStateAction<any>>;
  authority: string[];
  setAuthority: React.Dispatch<React.SetStateAction<string[]>>;
}

export const GlobalContext = createContext<IGlobal | undefined>(undefined);

export const GlobalContextProvider: FC = ({ children }) => {
  const [activeNumber, setActiveNumber] = useState<string>("");
  const [status, setStatus] = useState<DeviceStatus>(DeviceStatus.CONNECTING);
  const [callStatus, setCallStatus] = useState<CallStatus>(
    CallStatus.CONNECTING
  );
  const [device, setDevice] = useState<Device | null>(null);
  const [conn, setConn] = useState<Connection | null>(null);
  const [myNumbers, setMyNumbers] = useState<string[]>([]);
  const [syncClient, setSyncClient] = useState<any>();
  const [authority, setAuthority] = useState<string[]>([]);

  const value = useMemo(
    () => ({
      activeNumber,
      setActiveNumber,
      status,
      setStatus,
      device,
      setDevice,
      conn,
      setConn,
      myNumbers,
      setMyNumbers,
      callStatus,
      setCallStatus,
      syncClient,
      setSyncClient,
      authority,
      setAuthority
    }),
    [
      activeNumber,
      status,
      device,
      conn,
      myNumbers,
      callStatus,
      syncClient,
      authority
    ]
  );

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

export const GlobalComsumer = GlobalContext.Consumer;
export const useGlobalContext = () => {
  const globalContext = useContext(GlobalContext);

  if (!globalContext) {
    throw new Error(
      "useGlobalContext must be used within the GlobalContext.Provider"
    );
  }
  return globalContext;
};
