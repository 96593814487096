import React, { useEffect, useState, createRef } from "react";
import { Spin, Typography, Form, Input, Button, Col, Row, Popover } from "antd";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { useLazyQuery } from "@apollo/client";
import { Base } from "../../../layouts/Base";
import {
  useConversationLazyQuery,
  useSendMsgMutation,
  SmsMessage,
  Member,
  Message
} from "../../../generated/graphql";
import "./index.css";
import { useStickyState } from "../../../hooks/useStickyState";
import { MESSAGE_STATUS, GET_MESSAGES } from "../../../query";
import UpdateConversation from "./UpdateConversation";
import UpdateMembers from "./UpdateMembers";
import { CRM_LINK } from "../../../utils/constant";
import { KConnectionManager } from "../../../utils/KConnectionManager";

interface grpMessages {
  [key: string]: Array<Message>;
}

interface CallProps {
  id: string;
}

const { Paragraph } = Typography;
const { TextArea } = Input;

const Conversation: React.FC<CallProps> = ({ id }) => {
  const history = useHistory();
  const [form] = Form.useForm();

  const K = KConnectionManager.getInstance();
  const syncClient = K.getTextClient("textClient");
  const scrollRef = createRef<HTMLDivElement>();

  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [
    conversation,
    { data, loading, error, refetch: refetchCOnversation }
  ] = useConversationLazyQuery();

  const [sendMessage] = useSendMsgMutation();

  const [groupMessages, setGroupMessages] = useState<grpMessages>();
  const [messages, setMessages] = useState<Array<Message>>([]);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [currentpage, setcurrentpage] = useState<number>(2);
  const [messageLoading, setMessageLoading] = useState<boolean>(false);
  const [hasMoreMessage, sethasMoreMessage] = useState<boolean>(true);
  const [msgText, setMsgText] = useState<string>("");
  const [wordCount, setWordCount] = useState<number>(0);
  const [textareaHeight, setTextareaHeight] = useState<number>(34);
  const [updateDetailsView, setUpdateDetailsView] = useState<boolean>(false);
  const [updateMembersView, setUpdateMembersView] = useState<boolean>(false);
  const [addPeopleView, setaddPeopleView] = useState<boolean>(false);
  const [msgInfo, setmsgInfo] = useState<Array<SmsMessage>>([]);

  const [getMessages, { fetchMore, refetch }] = useLazyQuery(GET_MESSAGES, {
    fetchPolicy: `network-only`,
    onCompleted: (msgData: any) => {
      const msgReversed = [...msgData.messages.data].reverse();

      const groupByMsg = groupBy(msgReversed, (date: any) => {
        return dayjs
          .utc(date.created_at)
          .local()
          .startOf("day")
          .format();
      });

      setMessages(msgData.messages.data);
      setGroupMessages(groupByMsg);

      scrollToBottom();

      setTimeout(() => {
        const scrollerEl = document.getElementById("scroller");
        if (scrollerEl) {
          setScrollPosition(scrollerEl.scrollHeight - scrollerEl.clientHeight);
        }
      }, 1500);
    }
  });

  const [msgStatus] = useLazyQuery(MESSAGE_STATUS, {
    fetchPolicy: `no-cache`,
    onCompleted: (data: any) => {
      if (data.messageStatusInfo && Array.isArray(data.messageStatusInfo)) {
        setmsgInfo(data.messageStatusInfo);
      }
    }
  });

  useEffect(() => {
    if (error) {
      error.graphQLErrors.forEach((err: any) => {
        if (err.extensions.category === "authorization") {
          history.push({
            pathname: "/"
          });
          window.dispatchEvent(
            new CustomEvent("updateAuth", { detail: { fetchAuthority: true } })
          );
        }
      });
    }
  }, [error, history]);

  useEffect(() => {
    getMessages({
      variables: {
        conversationId: id,
        first: 20,
        page: 1
      }
    });
  }, [id, getMessages]);

  const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
    list.reduce(
      (previous, currentItem) => {
        const group = getKey(currentItem);
        if (!previous[group]) previous[group] = [];
        previous[group].push(currentItem);
        return previous;
      },
      {} as Record<K, T[]>
    );

  const scrollToBottom = () => {
    setTimeout(() => {
      const scrollerEl = document.getElementById("scroller");
      if (scrollerEl) {
        scrollerEl.scrollTop =
          scrollerEl.scrollHeight - scrollerEl.clientHeight;
      }
    }, 1000);
  };

  useEffect(() => {
    if (syncClient && id) {
      syncClient.map(`CONV_${id}`).then(function(map: any) {
        map.getItems().then(function(item: any) {
          // console.log("items", item.items);
        });
        map.on("itemAdded", function({ item }: any) {
          if (
            refetchCOnversation &&
            (item.data.body === "attach" || item.data.body === "detach")
          ) {
            refetchCOnversation();
          }

          const newMessages = [item.data, ...messages];

          const msgReversed = [...newMessages].reverse();

          const groupByMsg = groupBy(msgReversed, (date: Message) => {
            return dayjs
              .utc(date.created_at)
              .local()
              .startOf("day")
              .format();
          });

          setGroupMessages(groupByMsg);
          setMessages(newMessages);
        });
      });
    }
  }, [syncClient, id, refetchCOnversation, messages]);

  useEffect(() => {
    conversation({
      variables: {
        id
      }
    });
  }, [conversation, id]);

  const handleScrollPosition = (position: number) => {
    setScrollPosition(position);
  };

  const heightManager = (e: any) => {
    const h = e.target.scrollHeight >= 94 ? 73 : e.target.scrollHeight;
    setTextareaHeight(h);

    if (
      textareaHeight !== e.target.scrollHeight &&
      e.target.scrollHeight <= 73
    ) {
      setTimeout(() => {
        const scrollerEl = document.getElementById("scroller");
        if (scrollerEl) {
          scrollerEl.scrollTop =
            scrollerEl.scrollHeight - scrollerEl.clientHeight;
          handleScrollPosition(
            scrollerEl.scrollHeight - scrollerEl.clientHeight
          );
        }
      }, 1000);
    }
  };

  const handleScroll = (e: any) => {
    if (e.target.scrollTop === 0) {
      if (hasMoreMessage) {
        handleFetchMore();
      }
    }
  };

  const handleFetchMore = () => {
    if (fetchMore && data) {
      setMessageLoading(true);
      fetchMore({
        variables: {
          first: 20,
          page: currentpage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          setcurrentpage(page => page + 1);
          const data = Object.assign({}, fetchMoreResult);
          data.messages.data = [
            ...prev.messages.data,
            ...fetchMoreResult.messages.data
          ];
          setMessages(data.messages.data);
          sethasMoreMessage(
            fetchMoreResult.messages.paginatorInfo.hasMorePages
          );
          const msgReversed = [...data.messages.data].reverse();

          const groupByMsg = groupBy(msgReversed, (date: any) => {
            return dayjs
              .utc(date.created_at)
              .local()
              .startOf("day")
              .format();
          });

          setGroupMessages(groupByMsg);
          setMessageLoading(false);
          setTimeout(() => {
            const scrollerEl = document.getElementById("scroller");
            if (scrollerEl) {
              const scrollVal =
                scrollerEl.scrollHeight - scrollerEl.clientHeight;
              scrollerEl.scrollTop = scrollVal - scrollPosition;
              setScrollPosition(scrollVal);
            }
          }, 0);
          return data;
        }
      });
    }
  };

  const handleSendMessage = () => {
    form.validateFields().then(values => {
      if (/\S/.test(values.msgbox)) {
        const input = {
          conversation_id: id,
          body: values.msgbox
        };
        sendMessage({ variables: { input } }).then(() => {
          if (refetch) {
            refetch();
          }
          form.setFieldsValue({ msgbox: "" });
          setMsgText("");
          setWordCount(0);
        });
      }
    });
  };

  const hideUpdateDetailsView = () => {
    setUpdateDetailsView(false);
  };

  const hideUpdateMembersView = () => {
    setUpdateMembersView(false);
  };

  const hideAddPeopleView = () => {
    setaddPeopleView(false);
  };

  const showAddPeopleView = () => {
    setaddPeopleView(true);
  };

  return (
    <div>
      <Base onBack={() => history.goBack()}>
        <Spin
          spinning={loading || !data}
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center"
          }}
        >
          {data && data.conversation && (
            <div>
              <div className="chatHeader">
                <div className="flex w-100">
                  {data.conversation.is_group ? (
                    <div className="w-100">
                      <Row>
                        <Col span={2}>
                          <i
                            className="icon-back pointer"
                            onClick={() => {
                              history.push({
                                pathname: "/",
                                state: { tab: "text" }
                              });
                            }}
                            style={{ marginRight: 8 }}
                          />
                        </Col>
                        <Col span={16}>
                          <Paragraph
                            ellipsis
                            style={{ color: "#fff" }}
                            className="noMargin"
                          >
                            {data.conversation.name !== ""
                              ? data.conversation.name
                              : ""}
                          </Paragraph>
                        </Col>
                        <Col span={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end"
                            }}
                          >
                            <div
                              onClick={() => {
                                setUpdateMembersView(true);
                                setUpdateDetailsView(false);
                              }}
                              className="pointer"
                            >
                              <i className="icon-add-user" />
                              {data &&
                                data.conversation &&
                                data.conversation.members_details && (
                                  <span>
                                    {
                                      data.conversation.members_details.filter(
                                        member =>
                                          member &&
                                          member.meta &&
                                          member.meta.status
                                      ).length
                                    }
                                  </span>
                                )}
                            </div>
                            <i
                              onClick={() => {
                                setUpdateDetailsView(true);
                                setUpdateMembersView(false);
                              }}
                              className="icon-settings pointer"
                              style={{ marginLeft: 8 }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="w-100">
                      <Row>
                        <Col span={2}>
                          <i
                            className="icon-back pointer"
                            onClick={() => {
                              history.push({
                                pathname: "/",
                                state: { tab: "text" }
                              });
                            }}
                            style={{ marginRight: 8 }}
                          />
                        </Col>
                        <Col span={16}>
                          <Paragraph
                            ellipsis
                            style={{ color: "#fff" }}
                            className="noMargin"
                          >
                            {data.conversation.members_details &&
                              data.conversation.members_details.map(
                                (m: Member | null) =>
                                  m &&
                                  m.member &&
                                  m.member.id !== adminInfo.admininfo.id
                                    ? `${
                                        m.member_type === "CONTACT"
                                          ? m.member.first_name
                                          : `${m.member.first_name} ${m.member.last_name}`
                                      }`
                                    : ""
                              )}{" "}
                            {data.conversation.binding &&
                            data.conversation.binding[0] &&
                            data.conversation.binding[0].bind_to
                              ? `(${data.conversation.binding[0].bind_to})`
                              : ""}
                          </Paragraph>
                        </Col>

                        <Col span={6}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end"
                            }}
                          >
                            <i
                              className="icon-lead-added pointer"
                              style={{ marginLeft: 8 }}
                              onClick={() => {
                                setUpdateMembersView(true);
                                showAddPeopleView();
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}
                </div>
              </div>
              {updateDetailsView && (
                <UpdateConversation
                  name={
                    data && data.conversation && data.conversation.name
                      ? data.conversation.name
                      : ""
                  }
                  conversationData={data.conversation}
                  hideUpdateDetailsView={hideUpdateDetailsView}
                  refetchConversation={() => {
                    if (refetchCOnversation) {
                      refetchCOnversation();
                    }
                  }}
                />
              )}
              {updateMembersView && (
                <UpdateMembers
                  conversationData={data.conversation}
                  hideUpdateDetailsView={hideUpdateMembersView}
                  addPeopleView={addPeopleView}
                  hideAddPeopleView={hideAddPeopleView}
                  showAddPeopleView={showAddPeopleView}
                  refetchConversation={() => {
                    if (refetchCOnversation) {
                      refetchCOnversation();
                    }
                  }}
                />
              )}
              {!updateDetailsView && !updateMembersView && (
                <div>
                  <div
                    id="scroller"
                    className="scroller conversationContainer"
                    style={{
                      height: `calc(100vh - ${textareaHeight + 132}px)`
                    }}
                    ref={scrollRef}
                    onScroll={e => handleScroll(e)}
                  >
                    {messageLoading ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Spin spinning />
                      </div>
                    ) : null}
                    {groupMessages &&
                      Object.keys(groupMessages).map(k => {
                        const difference = dayjs().diff(dayjs(k), "days");
                        return (
                          <div>
                            <span className="dateSticky">
                              <span className="stickyDateStyle">
                                {difference === 0 ? (
                                  <span>TODAY</span>
                                ) : (
                                  <span>
                                    {difference === 1
                                      ? "YESTERDAY"
                                      : dayjs(k, "YYYY-MM-DD hh:mm:ss").format(
                                          "MM/DD/YYYY"
                                        )}
                                  </span>
                                )}
                              </span>
                            </span>
                            {groupMessages[k].map((convers: any) => {
                              const itemMsg = convers.body.split(/\r\n|\r|\n/g);

                              return (
                                <div className="scroller">
                                  <div style={{ marginRight: "16px" }}>
                                    {convers.type === "NOTIFICATION" && (
                                      <div>
                                        {convers.body !== "attach" &&
                                        convers.body !== "detach" ? (
                                          <div>
                                            <p
                                              className="textCenter"
                                              style={{ marginBottom: 0 }}
                                            >
                                              {convers.body}
                                            </p>
                                          </div>
                                        ) : null}
                                        {convers.body === "attach" ? (
                                          <div>
                                            <p className="textCenter">
                                              {`${convers.sender.member
                                                .first_name || ""} ${convers
                                                .sender.member.last_name ||
                                                ""}`}{" "}
                                              added
                                              {data &&
                                                data.conversation &&
                                                data.conversation
                                                  .members_details &&
                                                data.conversation.members_details.map(
                                                  (m: any) =>
                                                    convers.event.affected.includes(
                                                      m.member.id
                                                    )
                                                      ? ` ${
                                                          m.member
                                                            .first_name !== null
                                                            ? m.member
                                                                .first_name
                                                            : "HQ"
                                                        } ${
                                                          m.member.last_name !==
                                                          null
                                                            ? m.member.last_name
                                                            : "User"
                                                        } `
                                                      : ""
                                                )}
                                            </p>
                                          </div>
                                        ) : null}
                                        {convers.body === "detach" ? (
                                          <div>
                                            <p className="textCenter">
                                              {`${convers.sender.member
                                                .first_name || ""} ${convers
                                                .sender.member.last_name ||
                                                ""}`}{" "}
                                              Removed
                                              {data &&
                                                data.conversation &&
                                                data.conversation
                                                  .members_details &&
                                                data.conversation.members_details.map(
                                                  (m: any) =>
                                                    convers.event.affected.includes(
                                                      m.member.id
                                                    )
                                                      ? ` ${
                                                          m.member
                                                            .first_name !== null
                                                            ? m.member
                                                                .first_name
                                                            : "HQ"
                                                        } ${
                                                          m.member.last_name !==
                                                          null
                                                            ? m.member.last_name
                                                            : "user"
                                                        } `
                                                      : ""
                                                )}
                                            </p>
                                          </div>
                                        ) : null}
                                      </div>
                                    )}

                                    {convers.type === "CHAT" && (
                                      <div>
                                        <p
                                          className={
                                            convers.sender.member.id.toString() ===
                                            adminInfo.admininfo.id
                                              ? "sendMessage"
                                              : "receivedMessage"
                                          }
                                        >
                                          {itemMsg.map((msg: string) =>
                                            msg === "" ? (
                                              <br />
                                            ) : (
                                              <p style={{ marginBottom: 0 }}>
                                                {msg}
                                              </p>
                                            )
                                          )}
                                        </p>

                                        <p
                                          className={
                                            convers.sender.member.id.toString() ===
                                            adminInfo.admininfo.id
                                              ? "sendNumberMsg"
                                              : "receivedNumberMsg"
                                          }
                                        >
                                          {data && data.conversation && (
                                            <span
                                              onClick={() => {
                                                if (
                                                  convers.sender.member_type ===
                                                  "LEAD"
                                                ) {
                                                  window.open(
                                                    `${CRM_LINK}secured/leadDetail/${convers.sender.member.id}`
                                                  );
                                                }
                                              }}
                                              className={
                                                convers.sender.member_type ===
                                                "LEAD"
                                                  ? "leadLink"
                                                  : ""
                                              }
                                            >
                                              {convers.sender.member.id.toString() ===
                                              adminInfo.admininfo.id
                                                ? data.conversation
                                                    .default_number
                                                : `${convers.sender.member.first_name} ${convers.sender.member.last_name}`}{" "}
                                            </span>
                                          )}
                                          <span style={{ margin: "0px 4px" }}>
                                            •
                                          </span>
                                          <span>
                                            {dayjs
                                              .utc(
                                                convers.created_at,
                                                "YYYY-MM-DD hh:mm:ss"
                                              )
                                              .local()
                                              .format("hh:mm A")}
                                          </span>
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="popoverSpace"
                                          >
                                            {convers.sender.member.id.toString() ===
                                              adminInfo.admininfo.id &&
                                            convers.smsMessages &&
                                            convers.smsMessages.length !== 0 ? (
                                              <Popover
                                                content={
                                                  <div>
                                                    <div>
                                                      {msgInfo && data ? (
                                                        msgInfo.map(
                                                          (
                                                            msgStatus: SmsMessage
                                                          ) => {
                                                            const filteredName =
                                                              data &&
                                                              data.conversation &&
                                                              data.conversation
                                                                .binding &&
                                                              data.conversation.binding.filter(
                                                                (
                                                                  bindNumm: any
                                                                ) =>
                                                                  bindNumm.bind_to ===
                                                                  msgStatus.to
                                                              );
                                                            return (
                                                              filteredName &&
                                                              filteredName[0] &&
                                                              filteredName[0]
                                                                .member && (
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    justifyContent:
                                                                      "space-between"
                                                                  }}
                                                                >
                                                                  <div
                                                                    onClick={() => {
                                                                      if (
                                                                        filteredName[0] &&
                                                                        filteredName[0]
                                                                          .member &&
                                                                        filteredName[0]
                                                                          .member
                                                                          .member &&
                                                                        filteredName[0]
                                                                          .member
                                                                          .member_type ===
                                                                          "LEAD"
                                                                      ) {
                                                                        window.open(
                                                                          `${CRM_LINK}secured/leadDetail/${filteredName[0].member.member.id}`
                                                                        );
                                                                      }
                                                                    }}
                                                                    className={
                                                                      filteredName[0]
                                                                        .member
                                                                        .member_type ===
                                                                      "LEAD"
                                                                        ? "leadLink"
                                                                        : ""
                                                                    }
                                                                  >
                                                                    {filteredName[0]
                                                                      .member
                                                                      .member
                                                                      ? `${filteredName[0]
                                                                          .member
                                                                          .member
                                                                          .first_name ||
                                                                          ""} ${filteredName[0]
                                                                          .member
                                                                          .member
                                                                          .last_name ||
                                                                          ""}`
                                                                      : msgStatus.to}
                                                                  </div>
                                                                  <div
                                                                    style={
                                                                      msgStatus.status ===
                                                                      "failed"
                                                                        ? {
                                                                            color:
                                                                              "#F51942",
                                                                            marginLeft:
                                                                              "16px",
                                                                            textTransform:
                                                                              "capitalize"
                                                                          }
                                                                        : {
                                                                            marginLeft:
                                                                              "16px",
                                                                            textTransform:
                                                                              "capitalize"
                                                                          }
                                                                    }
                                                                  >
                                                                    {
                                                                      msgStatus.status
                                                                    }
                                                                  </div>
                                                                </div>
                                                              )
                                                            );
                                                          }
                                                        )
                                                      ) : (
                                                        <div>
                                                          {convers &&
                                                            convers.smsMessages &&
                                                            convers.smsMessages.map(
                                                              (
                                                                msgStatus: SmsMessage
                                                              ) => {
                                                                const filteredName =
                                                                  data &&
                                                                  data.conversation &&
                                                                  data
                                                                    .conversation
                                                                    .binding &&
                                                                  data.conversation.binding.filter(
                                                                    (
                                                                      bindNumm: any
                                                                    ) =>
                                                                      bindNumm.bind_to ===
                                                                      msgStatus.to
                                                                  );

                                                                return (
                                                                  filteredName &&
                                                                  filteredName[0] &&
                                                                  filteredName[0]
                                                                    .member && (
                                                                    <div
                                                                      style={{
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "space-between"
                                                                      }}
                                                                    >
                                                                      <div
                                                                        onClick={() => {
                                                                          if (
                                                                            filteredName[0] &&
                                                                            filteredName[0]
                                                                              .member &&
                                                                            filteredName[0]
                                                                              .member
                                                                              .member &&
                                                                            filteredName[0]
                                                                              .member
                                                                              .member_type ===
                                                                              "LEAD"
                                                                          ) {
                                                                            window.open(
                                                                              `${CRM_LINK}secured/leadDetail/${filteredName[0].member.member.id}`
                                                                            );
                                                                          }
                                                                        }}
                                                                        className={
                                                                          filteredName[0]
                                                                            .member &&
                                                                          filteredName[0]
                                                                            .member
                                                                            .member_type ===
                                                                            "LEAD"
                                                                            ? "leadLink"
                                                                            : ""
                                                                        }
                                                                      >
                                                                        {filteredName[0]
                                                                          .member
                                                                          .member
                                                                          ? `${filteredName[0]
                                                                              .member
                                                                              .member
                                                                              .first_name ||
                                                                              ""} ${filteredName[0]
                                                                              .member
                                                                              .member
                                                                              .last_name ||
                                                                              ""}`
                                                                          : msgStatus.to}
                                                                      </div>
                                                                      <div
                                                                        style={
                                                                          msgStatus.status ===
                                                                          "failed"
                                                                            ? {
                                                                                color:
                                                                                  "#F51942",
                                                                                marginLeft:
                                                                                  "16px",
                                                                                textTransform:
                                                                                  "capitalize"
                                                                              }
                                                                            : {
                                                                                marginLeft:
                                                                                  "16px",
                                                                                textTransform:
                                                                                  "capitalize"
                                                                              }
                                                                        }
                                                                      >
                                                                        {
                                                                          msgStatus.status
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  )
                                                                );
                                                              }
                                                            )}
                                                        </div>
                                                      )}
                                                    </div>
                                                  </div>
                                                }
                                                placement="topRight"
                                                trigger="click"
                                                getPopupContainer={trigger =>
                                                  trigger
                                                }
                                                onVisibleChange={(
                                                  visible: boolean
                                                ) => {
                                                  if (visible) {
                                                    msgStatus({
                                                      variables: {
                                                        input: {
                                                          message_id: convers.id
                                                        }
                                                      }
                                                    });
                                                  }
                                                }}
                                              >
                                                {/* {convers?.smsMessages[0]?.status} */}
                                                <i className="icon-client-information" />
                                              </Popover>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                  <div className="anchor" />
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    <div id="anchor" className="anchor" />
                  </div>

                  <div className="msgBoxContainer">
                    <i className="icon-emoji iconEmoji" />

                    <div
                      style={{
                        width: "100%"
                      }}
                    >
                      <Form form={form} initialValues={{ msgbox: msgText }}>
                        <Form.Item name="msgbox" className="noMargin">
                          <TextArea
                            rows={4}
                            placeholder="Type your message here..."
                            className="msgboxBorder"
                            style={{
                              padding: "5px 5px 5px 0"
                            }}
                            autoSize={{ maxRows: 3 }}
                            onChange={e => {
                              setMsgText(e.target.value);
                              setWordCount(e.target.value.length);
                              heightManager(e);
                            }}
                            onKeyDown={e => heightManager(e)}
                            onKeyUp={e => heightManager(e)}
                            onPressEnter={e => {
                              if (!e.shiftKey) {
                                e.preventDefault();
                                handleSendMessage();
                              }
                            }}
                            // disabled={
                            //   !activeMembers.some(
                            //     obj => obj.member.id === adminInfo.id
                            //   )
                            // }
                            autoFocus
                          />
                        </Form.Item>
                      </Form>
                    </div>
                    <Button
                      shape="circle"
                      style={{
                        marginLeft: "8px"
                      }}
                      disabled={!(wordCount !== 0 && /\S/.test(msgText))}
                      className={
                        !(wordCount !== 0 && /\S/.test(msgText))
                          ? ""
                          : "btnSecondary"
                      }
                      onClick={() => handleSendMessage()}
                    >
                      <i className=" icon-send" style={{ marginLeft: "5px" }} />
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </Spin>
      </Base>
    </div>
  );
};

export default Conversation;
