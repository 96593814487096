import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Spin, List, Input, Select } from "antd";
// import { CheckboxValueType } from "antd/lib/checkbox/Group";
import { GET_CRM_CONTACT, GET_COMPANIES } from "../../query";
import { AgentListItem } from "./AgentListItem";
import { TransferToType, ConferenceParticipant } from "../../generated/graphql";
import { useStickyState } from "../../hooks/useStickyState";

interface AgentListProps {
  coldTransferCall: (to: string, type: TransferToType) => void;
  participants?: Array<ConferenceParticipant>;
}

const { Option } = Select;
const { Search } = Input;

export const AgentList: React.FC<AgentListProps> = ({
  coldTransferCall,
  participants
}) => {
  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [crmContact, { data, loading }] = useLazyQuery(GET_CRM_CONTACT);
  const plainOptions = ["Admin", "Concierge", "Realtor", "Super Concierge"];
  // const [checkedList, setCheckedList] = useState<Array<CheckboxValueType>>(
  //   plainOptions
  // );
  const [searchKey, setSearchKey] = useState<string>("");
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [getCompanies, { data: companies }] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data.getLoginUserCompanies.length !== 0) {
        setSelectedCompany(Number(data.getLoginUserCompanies[0].id));
      }
    }
  });

  const participantNumbers: string[] = [];
  participants &&
    participants.map(participant =>
      participantNumbers.push(participant.number)
    );

  useEffect(() => {
    crmContact({
      variables: {
        first: 100,
        company_id: selectedCompany,
        key: searchKey
      }
    });
  }, [crmContact, selectedCompany, searchKey]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const onChangeCompany = (id: number) => {
    setSelectedCompany(id);
  };

  return (
    <div>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "calc(100vh - 210px)",
          backgroundColor: "#fff"
        }}
        //   onScroll={handleScroll}
      >
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <div>
              {companies &&
              companies.getLoginUserCompanies &&
              companies.getLoginUserCompanies.length > 1 ? (
                <Select
                  value={selectedCompany}
                  style={{ width: 160, marginRight: "16px" }}
                  onChange={onChangeCompany}
                >
                  {companies.getLoginUserCompanies.map((company: any) => (
                    <Option value={Number(company.id)}>
                      {company.company_name}
                    </Option>
                  ))}
                </Select>
              ) : null}
            </div>
            <Search
              allowClear
              placeholder="Search leads and contacts"
              onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                setSearchKey(value.target.value);
              }}
            />
            {/* <Popover
              content={
                <Checkbox.Group
                  value={checkedList}
                  onChange={e => setCheckedList(e)}
                >
                  {plainOptions.map(item => (
                    <Row>
                      <Col>
                        <Checkbox value={item}>{item}</Checkbox>
                      </Col>
                    </Row>
                  ))}
                </Checkbox.Group>
              }
              trigger="click"
              placement="bottom"
            >
              <i
                className={
                  checkedList.length !== 0
                    ? "icon-filter-filled"
                    : "icon-column-filter"
                }
                style={checkedList.length !== 0 ? { color: "#0055fd" } : {}}
              />
            </Popover> */}
          </div>
          
          <Spin spinning={loading}>
            {plainOptions.includes("Admin") && (
              <List
                loading={false}
                itemLayout="horizontal"
                header={<div style={{ color: "#011689" }}>Admin</div>}
                dataSource={
                  data
                    ? data.getCRMContact.data.filter(
                        (item: any) =>
                          item.type === "admin" &&
                          adminInfo.admininfo.id !== item.id &&
                          !participantNumbers.includes(
                            item.numbers[0].phone_number
                          )
                      )
                    : []
                }
                renderItem={item => (
                  <AgentListItem
                    coldTransferCall={coldTransferCall}
                    loading={loading}
                    {...item}
                  />
                )}
              />
            )}
            {plainOptions.includes("Concierge") && (
              <List
                loading={false}
                itemLayout="horizontal"
                header={<div style={{ color: "#011689" }}>Concierge</div>}
                dataSource={
                  data
                    ? data.getCRMContact.data.filter(
                        (item: any) =>
                          item.type === "concierge" &&
                          adminInfo.admininfo.id !== item.id &&
                          !participantNumbers.includes(
                            item.numbers[0].phone_number
                          )
                      )
                    : []
                }
                renderItem={item => (
                  <AgentListItem
                    coldTransferCall={coldTransferCall}
                    loading={loading}
                    {...item}
                  />
                )}
              />
            )}
            {plainOptions.includes("Realtor") && (
              <List
                loading={false}
                itemLayout="horizontal"
                header={<div style={{ color: "#011689" }}>Realtor</div>}
                dataSource={
                  data
                    ? data.getCRMContact.data.filter(
                        (item: any) =>
                          item.type === "realtor" &&
                          adminInfo.admininfo.id !== item.id &&
                          !participantNumbers.includes(
                            item.numbers[0].phone_number
                          )
                      )
                    : []
                }
                renderItem={item => (
                  <AgentListItem
                    coldTransferCall={coldTransferCall}
                    loading={loading}
                    {...item}
                  />
                )}
              />
            )}
            {plainOptions.includes("Super Concierge") && (
              <List
                loading={false}
                itemLayout="horizontal"
                header={<div style={{ color: "#011689" }}>Super Concierge</div>}
                dataSource={
                  data
                    ? data.getCRMContact.data.filter(
                        (item: any) =>
                          item.type === "super_concierge" &&
                          adminInfo.admininfo.id !== item.id &&
                          !participantNumbers.includes(
                            item.numbers[0].phone_number
                          )
                      )
                    : []
                }
                renderItem={item => (
                  <AgentListItem
                    coldTransferCall={coldTransferCall}
                    loading={loading}
                    {...item}
                  />
                )}
              />
            )}
          </Spin>
        </>
      </div>
    </div>
  );
};
