import { useEffect, useState } from "react";
import { GET_QUEUE } from "../query";
import { useLazyQuery } from "@apollo/client";

export function useCallQueue(
  first: number,
  page: number | undefined,
  company_id: number | undefined
) {
  const [queueData, setQueueData] = useState<any>([]);
  const [callQueue, { data, loading, refetch, fetchMore }] = useLazyQuery(
    GET_QUEUE,
    {
      fetchPolicy: `network-only`
    }
  );

  useEffect(() => {
    callQueue({
      variables: {
        first,
        page,
        company_id
      }
    });
  }, [first, page, company_id, callQueue]);

  useEffect(() => {
    if (data) {
      setQueueData(data);
    }
  }, [data]);

  const handleFetchMore = (currentPage: number) => {
    // console.log("paginatorInfo", currentPage);
    if (fetchMore && data) {
      fetchMore({
        variables: {
          first,
          page: currentPage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const data = Object.assign({}, fetchMoreResult);
          data.getQueue.data = [
            ...queueData.getQueue.data,
            ...fetchMoreResult.getQueue.data
          ];
          setQueueData(data);
        }
      });
    }
  };

  return {
    queueData,
    loading,
    fetchMore,
    refetch,
    handleFetchMore
  };
}
