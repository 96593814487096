import React from "react";
import { Tabs } from "antd";
import DialPad from "./DialPad";
import Calls from "./Calls";

const { TabPane } = Tabs;

export default function Call() {
  return (
    <div>
      <Tabs defaultActiveKey="1" animated={false} className="mainTabBar">
        <TabPane tab="DialPad" key="dial">
          <br />
          <DialPad />
        </TabPane>
        <TabPane tab="Calls" key="call">
          <br />
          <Calls />
        </TabPane>
      </Tabs>
    </div>
  );
}
