import { useState } from "react";
import { CONVERSATION_LIST } from "../query";
import { useConversationsQuery } from "../generated/graphql";

export function useConversationList(first: number, page: number | undefined) {
  const [fetchMoreLoading, setfetchMoreLoading] = useState<boolean>(false);
  const { data, loading, refetch, fetchMore } = useConversationsQuery({
    query: CONVERSATION_LIST,
    variables: { first, page }
  });

  const handleFetchMore = (currentPage: number) => {
    setfetchMoreLoading(true);
    if (fetchMore && data) {
      fetchMore({
        variables: {
          first,
          page: currentPage
        },
        updateQuery: (prev: any, { fetchMoreResult }: any) => {
          const data = Object.assign({}, fetchMoreResult);
          data.conversations.data = [
            ...prev.conversations.data,
            ...fetchMoreResult.conversations.data
          ];
          setfetchMoreLoading(false);
          return { ...data };
        }
      });
    }
  };

  return {
    data,
    loading,
    fetchMore,
    refetch,
    handleFetchMore,
    fetchMoreLoading
  };
}
