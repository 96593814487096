import React, { useRef, useState, RefObject } from "react";
import { Row, Col, Typography, Button, Input, Card, Drawer } from "antd";
import { MyIcon } from "./MyIcon";
import { useStickyState } from "../hooks/useStickyState";
import useLongPress from "../hooks/useLongpress";
import { dialFormatPhoneNumber } from "../utils/utils";
const { Title } = Typography;

const audio = new Audio(
  "https://d366s8lxuwna4d.cloudfront.net/beep-34dd1e44313219e42f2bfad1a91b81d05909599e.mp3"
);

interface KeypadProps {
  visible: boolean;
  close: React.Dispatch<React.SetStateAction<boolean>>;
  onCall: (n: string) => void;
  hideDialer: () => void;
}

interface NumberpadProps {
  onCall: (n: string) => void;
  link: RefObject<any>;
  hideDialer?: () => void;
}

export const Keypad: React.FC<KeypadProps> = ({
  onCall,
  visible,
  close,
  hideDialer
}) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <Drawer
      bodyStyle={{ padding: 0 }}
      placement={"bottom"}
      closable={false}
      onClose={() => close(false)}
      visible={visible}
      height={450}
      afterVisibleChange={() => {
        if (visible && ref && ref.current) {
          ref.current.focus();
        }
      }}
      headerStyle={{ padding: "0 8px", textAlign: "right" }}
      title={
        <i
          style={{ fontSize: "12px", cursor: "pointer" }}
          onClick={hideDialer}
          className="icon-cancel"
        />
      }
      zIndex={1040}
    >
      <Numberpad onCall={onCall} link={ref} hideDialer={hideDialer} />
    </Drawer>
  );
};

export const Numberpad: React.FC<NumberpadProps> = ({ onCall, link }) => {
  const [number, setNumber] = useState<string>("+1");

  const [lastDial] = useStickyState(null, "last_dial");

  const clickBtn = (n: string) => {
    audio.play();
    setNumber(s => s + n);
  };

  const clickBackSpace = () => {
    audio.play();
    setNumber(number.slice(0, -1));
  };

  let c = 1;
  let a = "*";
  let b = 0;
  let d = "#";
  let e = "+";
  let f = ",";
  const h = window.innerHeight >= 600 ? 70 : 48;

  const onLongPress = () => {
    clickBtn(f.toString());
    link.current.focus();
  };

  const onClick = () => {
    clickBtn(d.toString());
    link.current.focus();
  };

  const longPressEvent = useLongPress(onLongPress, onClick, 1000);

  // const onLongPressCall = () => {
  //   if (lastDial && lastDial.length >= 10) {
  //     setNumber(lastDial);
  //   }
  // };

  // const onClickCall = () => {
  //   onCall(number);
  //   setNumber("");
  // };

  // const longPressCallEvent = useLongPress(onLongPressCall, onClickCall, 1000);

  return (
    <Card bordered={false} style={{ width: "100%" }} bodyStyle={{ padding: 0 }}>
      <Input
        className="dailer"
        bordered={false}
        width="100%"
        onPressEnter={() => {
          onCall(number);
          setNumber("");
          // hideDialer();
        }}
        value={number}
        onPaste={v => {
          const fotmatedNumber: string | null = dialFormatPhoneNumber(
            v.clipboardData.getData("text")
          );
          const reg = /^[+]?[0-9]*$/;
          if (fotmatedNumber && reg.test(fotmatedNumber)) {
            setNumber(fotmatedNumber);
          }
        }}
        onChange={v => {
          const reg = /^[+]?[0-9]*$/;
          if (reg.test(v.target.value)) {
            setNumber(v.target.value);
          }
        }}
        autoFocus
        allowClear
        ref={link}
      />
      <br />
      <br />
      <div>
        {new Array(3).fill(new Array(3).fill(0)).map(a => (
          <Row key={Math.random()} justify="center">
            {a.map(() => {
              let n = c++;
              return (
                <Col span={8} key={Math.random()}>
                  <Button
                    style={{ height: h }}
                    block
                    onClick={() => {
                      clickBtn(n.toString());
                      link.current.focus();
                    }}
                    size="large"
                  >
                    <Title level={3}>{n}</Title>
                  </Button>
                </Col>
              );
            })}
          </Row>
        ))}

        <Row justify="center">
          <Col span={8}>
            <Button
              block
              onClick={() => {
                clickBtn(a.toString());
                link.current.focus();
              }}
              size="large"
              style={{ height: h }}
            >
              <Title level={3}>*</Title>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block
              onClick={() => {
                clickBtn(b.toString());
                link.current.focus();
              }}
              size="large"
              style={{ height: h }}
            >
              <Title level={3}>0</Title>
            </Button>
          </Col>
          <Col span={8}>
            <Button
              block
              // onClick={() => {
              //   clickBtn(d.toString());
              //   link.current.focus();
              // }}
              {...longPressEvent}
              size="large"
              style={{ height: h }}
            >
              <Title level={3}>#</Title>
            </Button>
          </Col>
        </Row>
      </div>

      <Row>
        <Col span={8}>
          <Button
            block
            onClick={() => {
              clickBtn(e.toString());
              link.current.focus();
            }}
            size="large"
            style={{ height: h }}
          >
            <Title level={3}>+</Title>
          </Button>
        </Col>
        <Col span={8}>
          <div
            style={{ marginTop: 15, display: "flex", justifyContent: "center" }}
            onClick={() => {
              if (number === "" || number === "+1") {
                if (lastDial && lastDial.length >= 10) {
                  setNumber(lastDial);
                }
              } else {
                onCall(number);
                setNumber("");
              }
              // hideDialer();
            }}
            // {...longPressCallEvent}
          >
            <MyIcon icon="icon-call" bgColor="#4ac367" color="#fff" />
          </div>
        </Col>
        <Col span={8}>
          <Button
            block
            onClick={() => {
              clickBackSpace();
              link.current.focus();
            }}
            size="large"
            style={{ height: h }}
          >
            <Title level={3}>
              <i className="icon-backspace" />
            </Title>
          </Button>
        </Col>
      </Row>
    </Card>
  );
};
