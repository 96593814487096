import React, { useEffect } from "react";
import { PageHeader, Tag, Result, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../context/GlobalContext";
import { DeviceStatusColor } from "../enum/Index";
import { numberFormat } from "../utils/utils";
import { useGetMyNumbersLazyQuery } from "../generated/graphql";
import { KConnectionManager } from "../utils/KConnectionManager";

const { Option } = Select;

interface BaseProps {
  children: React.ReactNode;
  title?: React.ReactNode;
  onBack?: () => void;
}

export const Base: React.FC<BaseProps> = ({ children, onBack, title }) => {
  const [getNumbers, { data }] = useGetMyNumbersLazyQuery();
  const {
    conn,
    activeNumber,
    setActiveNumber,
    status,
    myNumbers,
    setMyNumbers
  } = useGlobalContext();

  const history = useHistory();

  const K = KConnectionManager.getInstance();
  const device = K.getDevice(activeNumber);
  const activeConn = device && device.activeConnection();

  useEffect(() => {
    if (getNumbers) {
      getNumbers();
    }
  }, [getNumbers]);

  useEffect(() => {
    if (data) {
      const allNumbers = data.getMyNumbers.map((num: any) => num.phone_number);
      setMyNumbers(allNumbers || []);
    }
  }, [data, setMyNumbers]);

  const toCallScreen = () => {
    if (activeConn) {
      if (
        activeConn.direction === "OUTGOING" &&
        !window.location.pathname.includes("/call")
      ) {
        const toNum = activeConn.customParameters.get("To");
        history.push(`/call/${toNum}`);
      } else if (
        activeConn.direction === "INCOMING" &&
        status === "On call" &&
        !window.location.pathname.includes("/call")
      ) {
        history.push(`/call/${activeConn.parameters.From}`);
      }
    }
  };

  // TODO
  if (!activeNumber) {
    return (
      <Result
        status="error"
        title={
          <p>
            No number assinged to perform this operation.
            <br />
            <br /> Please contact your adminstrator.
          </p>
        }
      />
    );
  }

  return (
    <div style={{ height: "100vh", overflowX: "hidden" }}>
      <PageHeader
        style={{
          border: "1px solid rgb(235, 237, 240)",
          position: "sticky",
          padding: "8px 16px"
        }}
        // title={title || numberFormat(activeNumber.replace(/\+1/g, ``))}
        title={
          <Select
            value={activeNumber}
            style={{ width: 160 }}
            onChange={v => setActiveNumber(v)}
            disabled={conn ? true : false}
          >
            {myNumbers &&
              myNumbers.map(nu => (
                <Option value={nu} key={Math.random()}>
                  {numberFormat(nu.replace(/\+1/g, ``))}
                </Option>
              ))}
          </Select>
        }
        {...(onBack ? { onBack: onBack } : {})}
        {...(status
          ? {
              tags: [
                <Tag
                  color={DeviceStatusColor.get(status)}
                  onClick={toCallScreen}
                  style={status === "On call" ? { cursor: "pointer" } : {}}
                  key="1"
                >
                  {status}
                </Tag>
              ]
            }
          : {})}
      />

      {children}
    </div>
  );
};
