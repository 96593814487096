import React, { useState } from "react";
import { Input, Form, Space, Button, Spin, message } from "antd";
import {
  useContactMutation,
  useDeleteContactNumberMutation
} from "../../generated/graphql";

interface numberProps {
  id: string;
  raw_number: string;
}

interface contactData {
  contactData: {
    id: string;
    first_name: string;
    last_name: string;
    numbers: { id: string; raw_number: string }[];
    company_name: string;
  };
  onCancelModal: () => void;
}

const CreateContact: React.FC<contactData> = ({
  contactData,
  onCancelModal
}) => {
  const [saveContact] = useContactMutation();
  const [deleteNumbers] = useDeleteContactNumberMutation();
  const [submitLoading, setsubmitLoading] = useState<boolean>(false);
  const [form] = Form.useForm();
  const onFinish = (values: any) => {
    setsubmitLoading(true);
    const numbers: { id: string; raw_number: string }[] = [];
    if (values.numbers) {
      values.numbers.map((num: numberProps) =>
        numbers.push({ id: num.id || "0", raw_number: num.raw_number })
      );
    }

    const input = {
      id: contactData?.id || "0",
      company_name: values.company_name,
      first_name: values.first_name,
      last_name: values.last_name,
      numbers
    };

    saveContact({
      variables: {
        input
      }
    })
      .then(res => {
        if (res) {
          onCancelModal();
          setsubmitLoading(false);
        }
      })
      .catch(err => {
        if (err) {
          err.graphQLErrors.map((errMsg: any) => {
            if (errMsg && errMsg.message) {
              message.error(errMsg.message);
            }
            return null
          });

          setsubmitLoading(false);
        }
      });
  };

  const handleDeleteNumber = (id: number, remove: any) => {
    // 'Contact.numbers.0.raw_number'
    const allNumbers = form.getFieldValue(`numbers`);

    if (allNumbers[id]) {
      deleteNumbers({
        variables: {
          id: allNumbers[id].id
        }
      }).then(res => {
        if (res) {
          const newData = contactData.numbers.filter(
            (num: any) => num.id !== res?.data?.deleteContactNumber.id
          );
          form.setFieldsValue({ numbers: newData });
        }
      });
    } else {
      remove(id);
    }
  };

  return (
    <div
      style={{
        margin: 16,
        overflowX: "auto",
        maxHeight: "calc(100vh - 210px)"
      }}
    >
      <Form
        name="contact_item"
        onFinish={onFinish}
        autoComplete="off"
        form={form}
        initialValues={contactData}
      >
        <Form.Item
          name="first_name"
          rules={[{ required: true, message: "First Name is required" }]}
        >
          <Input placeholder="Enter First name" />
        </Form.Item>
        <Form.Item
          name="last_name"
          rules={[{ required: true, message: "Last Name is required" }]}
        >
          <Input placeholder="Enter Last name" />
        </Form.Item>
        <Form.Item name="company_name">
          <Input placeholder="Enter Company name" />
        </Form.Item>
        <Form.List name="numbers">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 8 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, `raw_number`]}
                    fieldKey={[fieldKey, "raw_number"]}
                    rules={[
                      { required: true, message: "Number is required" },
                      { max: 15, message: "Max 15 digits allowed" },
                      { min: 10, message: "Mobile number too short" },
                      {
                        pattern: /^[+]?[0-9]*$/,
                        message: "Please enter valid phone number"
                      }
                    ]}
                  >
                    <Input placeholder="Phone Number" />
                  </Form.Item>
                  <i
                    className="icon-remove-filter"
                    onClick={() => handleDeleteNumber(name, remove)}
                    style={{ cursor: "pointer" }}
                  />
                </Space>
              ))}
              <Form.Item>
                <Button type="dashed" onClick={() => add()} block>
                  Add phone Number
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
            {contactData?.id ? (
              <span>
                {submitLoading ? (
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#fff"
                    }}
                    className="whiteLoader"
                    spinning={submitLoading}
                    tip="Updating.."
                  />
                ) : (
                  "Update"
                )}
              </span>
            ) : (
              <span>
                {submitLoading ? (
                  <Spin
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      color: "#fff"
                    }}
                    className="whiteLoader"
                    spinning={submitLoading}
                    tip="Creating.."
                  />
                ) : (
                  "Create"
                )}
              </span>
            )}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateContact;
