export enum DeviceStatus {
  CONNECTING = "Connecting",
  READY = "Ready",
  INCOMING = "Incoming",
  ON_CALL = "On call",
  OFFLINE = "Offline"
}

export enum CallStatus {
  CONNECTING = "Connecting",
  RINGING = "Ringing",
  CONNECTED = "Connected"
}

export const DeviceStatusColor = new Map([
  ["Connecting", "default"],
  ["Ready", "success"],
  ["Incoming", "warning"],
  ["On call", "processing"],
  ["Offline", "error"]
]);

export const CallStatusColor = new Map([
  ["Connecting", "default"],
  ["Ringing", "processing"],
  ["completed", "success"],
  ["busy", "warning"],
  ["no-answer", "error"],
  ["failed", "error"],
  ["Connected", "success"]
]);
