import React, { FC } from "react";
import { List, Spin } from "antd";
import { ListItem } from "./ListItem";
import { UseGetVM } from "../../hooks/useGetVM";

export const VoiceMessageList: FC = () => {
  const { loading, data, handleFetchMore } = UseGetVM(30, 1);

  const handleScroll = (e: any) => {    
    if (
      e.target.scrollHeight - Math.ceil(e.target.scrollTop) <=
      e.target.clientHeight
    ) {      
      if (data && data.getVM && data.getVM.paginatorInfo.hasMorePages) {
        handleFetchMore(data.getVM.paginatorInfo.currentPage + 1);
      }
    }
  };


  return (
    <div
      style={{
        overflowX: "auto",
        maxHeight: "calc(100vh - 192px)",
        backgroundColor: "#fff"
      }}
      onScroll={handleScroll}
    >
      <Spin spinning={loading}>
        <List
          loading={false}
          itemLayout="horizontal"
          dataSource={
            data?.getVM?.data || []
          }
          renderItem={item => <ListItem {...item} />}
        />
      </Spin>
    </div>
  );
};
