import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Spin, List, Select, Input } from "antd";

import { ListItem } from "./ListItem";
// import { CheckboxValueType } from "antd/lib/checkbox/Group";
// import { UserSettings } from "./UserSettings";
import { GET_CRM_CONTACT, GET_COMPANIES } from "../../query";
import { useStickyState } from "../../hooks/useStickyState";

interface adminDetailsProps {
  name: string;
  phoneNumber: string;
}

const { Option } = Select;
const { Search } = Input;

export default function CrmMembers() {
  const [adminInfo] = useStickyState(null, "AdminInfo");
  const [crmContact, { data, loading }] = useLazyQuery(GET_CRM_CONTACT);
  const [selectedCompany, setSelectedCompany] = useState<number>();
  const [getCompanies, { data: companies }] = useLazyQuery(GET_COMPANIES, {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data.getLoginUserCompanies.length !== 0) {
        setSelectedCompany(Number(data.getLoginUserCompanies[0].id));
      }
    }
  });

  const plainOptions = ["Admin", "Concierge", "Realtor", "Super Concierge"];
  // const [checkedList, setCheckedList] = useState<Array<CheckboxValueType>>(
  //   plainOptions
  // );
  const [isSettings, setIsSettings] = useState<boolean>(false);
  // const [numberDetails, setNumberDetails] = useState<any[]>([]);
  // const [adminDetails, setSdminDetails] = useState<adminDetailsProps>();
  const [searchKey, setSearchKey] = useState<string>("");

  useEffect(() => {
    crmContact({
      variables: {
        first: 100,
        company_id: selectedCompany,
        key: searchKey
      }
    });
  }, [crmContact, selectedCompany, searchKey]);

  useEffect(() => {
    getCompanies();
  }, [getCompanies]);

  const handleAssignQueue = (numbers: any[], name: string) => {
    if (numbers.length !== 0) {
      setIsSettings(true);
      // setNumberDetails(numbers);
      // setSdminDetails({ name, phoneNumber: numbers[0].phone_number });
    }
  };

  const onChangeCompany = (id: number) => {
    setSelectedCompany(id);
  };

  return (
    <div>
      <div
        style={{
          overflowX: "auto",
          maxHeight: "calc(100vh - 210px)",
          backgroundColor: "#fff"
        }}
        //   onScroll={handleScroll}
      >
        {isSettings ? (
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginRight: "16px"
              }}
            >
              <i
                className="icon-cancel"
                onClick={() => {
                  setIsSettings(false);
                }}
              />
            </div>
            {/* <UserSettings
              numberDetails={numberDetails}
              refetchCRMUser={() => {
                if (refetch) {
                  refetch();
                }
              }}
              hideSettingView={() => setIsSettings(false)}
              adminDetails={adminDetails}
            /> */}
          </div>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <div>
                {companies &&
                companies.getLoginUserCompanies &&
                companies.getLoginUserCompanies.length > 1 ? (
                  <Select
                    value={selectedCompany}
                    style={{ width: 160, marginRight: "16px" }}
                    onChange={onChangeCompany}
                  >
                    {companies.getLoginUserCompanies.map((company: any) => (
                      <Option value={Number(company.id)}>
                        {company.company_name}
                      </Option>
                    ))}
                  </Select>
                ) : null}
              </div>
              <Search
                allowClear
                placeholder="Search leads and contacts"
                onChange={(value: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchKey(value.target.value);
                }}
              />
              {/* <Popover
                content={
                  <Checkbox.Group
                    value={checkedList}
                    onChange={e => setCheckedList(e)}
                  >
                    {plainOptions.map(item => (
                      <Row>
                        <Col>
                          <Checkbox value={item}>{item}</Checkbox>
                        </Col>
                      </Row>
                    ))}
                  </Checkbox.Group>
                }
                trigger="click"
                placement="bottom"
              >
                <i
                  className={
                    checkedList.length !== 0
                      ? "icon-filter-filled"
                      : "icon-column-filter"
                  }
                  style={checkedList.length !== 0 ? { color: "#0055fd" } : {}}
                />
              </Popover> */}
            </div>
            <Spin spinning={loading}>
              {plainOptions.includes("Admin") && (
                <List
                  loading={false}
                  itemLayout="horizontal"
                  header={<div style={{ color: "#011689" }}>Admin</div>}
                  dataSource={
                    (data &&
                      data.getCRMContact.data.filter(
                        (item: any) =>
                          item.type === "admin" &&
                          adminInfo.admininfo.id !== item.id
                      )) ||
                    []
                  }
                  renderItem={item => (
                    <ListItem
                      loading={loading}
                      {...item}
                      dispplaySettings={handleAssignQueue}
                    />
                  )}
                />
              )}
              {plainOptions.includes("Concierge") && (
                <List
                  loading={false}
                  itemLayout="horizontal"
                  header={<div style={{ color: "#011689" }}>Concierge</div>}
                  dataSource={
                    data
                      ? data.getCRMContact.data.filter(
                          (item: any) =>
                            item.type === "concierge" &&
                            adminInfo.admininfo.id !== item.id
                        )
                      : []
                  }
                  renderItem={item => (
                    <ListItem
                      loading={loading}
                      {...item}
                      dispplaySettings={handleAssignQueue}
                    />
                  )}
                />
              )}
              {plainOptions.includes("Realtor") && (
                <List
                  loading={false}
                  itemLayout="horizontal"
                  header={<div style={{ color: "#011689" }}>Realtor</div>}
                  dataSource={
                    data
                      ? data.getCRMContact.data.filter(
                          (item: any) =>
                            item.type === "realtor" &&
                            adminInfo.admininfo.id !== item.id
                        )
                      : []
                  }
                  renderItem={item => (
                    <ListItem
                      loading={loading}
                      {...item}
                      dispplaySettings={handleAssignQueue}
                    />
                  )}
                />
              )}
              {plainOptions.includes("Super Concierge") && (
                <List
                  loading={false}
                  itemLayout="horizontal"
                  header={
                    <div style={{ color: "#011689" }}>Super Concierge</div>
                  }
                  dataSource={
                    data
                      ? data.getCRMContact.data.filter(
                          (item: any) =>
                            item.type === "super_concierge" &&
                            adminInfo.admininfo.id !== item.id
                        )
                      : []
                  }
                  renderItem={item => (
                    <ListItem
                      loading={loading}
                      {...item}
                      dispplaySettings={handleAssignQueue}
                    />
                  )}
                />
              )}
            </Spin>
          </>
        )}
      </div>
    </div>
  );
}
